


















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CockpitButton from '@/components/Cockpit/Controls/CockpitButton.vue';
import URLHelper from '@/components/Helper/URLHelper';
import UserData from '../models/UserData';
import ExporoVue from '@/components/ExporoVue';
import LandingVideo from '@/components/Cockpit/pages/LandingVideo.vue';
import LandingLottiImg from '@/components/Cockpit/pages/LandingLottiImg.vue';

@Component({
    components: {
        CockpitButton,
        LandingVideo,
        LandingLottiImg
    }
})
class PageFinancingLanding extends ExporoVue {

    constructor() {
        super();
    }

    setActionBtnMargin() {
        const userAgent = window.navigator.userAgent;
        return userAgent.match(/iPhone/i) ? {marginBottom: '70px'} : {};
    }

    getLink() {

        let region = 'de';

        if ('AT' === this.cctld) {
            region = 'at';
        }

        return `https://exporo.${region}/finanzierung/`;
    }
}

export default PageFinancingLanding;
