




















































































































































































    import Accordion from '../../Accordion/Accordion.vue';
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import IbanService from '../../Iban/services/IbanService';
    import ForceEmailCheckbox from '@/components/Checkout/ForceEmailCheckbox/ForceEmailCheckbox.vue';
    import InvestService from '../services/InvestService';
    import IbanInput from '../../Iban/IbanInput.vue';
    import Error from '@/components/Checkout/Validator/Error.vue';
    import CheckoutValidator from '../Validator/CheckoutValidator';
    import Checkbox from '@/components/Checkbox/Checkbox.vue';
    import {Contract} from '../models/CheckoutDataWpHGLight';
    import StoreInvestmentRequestService from '../services/StoreInvestmentRequestService';
    import InvestmentRequestPart from '../models/InvestmentRequestPart';
    import Select from '@/components/Select/Select.vue';
    import Input from '@/components/Input/Input.vue';
    import ActionCode from '@/components/ActionCode/ActionCode.vue';
    import PubSubService, {TYPE_BOND} from '@/components/PubSub/services/PubSubService';
    import ActionCodeUserDetails from '@/components/ActionCode/ActionCodeUserDetails';
    import ExporoVue from '@/components/ExporoVue';

    @Component({
        components: {
            Accordion,
            ForceEmailCheckbox,
            IbanInput,
            Error,
            Checkbox,
            Input,
            Select,
            ActionCode,
        },
        props: {
            shouldHide: Boolean,
            switchStep: Function,
        },
    })
    class InvestmentWpHGLite extends ExporoVue implements InvestmentRequestPart {

        private payloadPart: any = {
            piece_value: 0,
            pieces: 1,
            investtype: 'debit',
            debitMandate: false,
            vib_file: false,
            legal_obligation: false,
            invest_iban: '',
            invest_bic: '',
            single_unit_threshold: false,
        };

        public TITLE = this.trans.get('checkout.WpHGLite.investmentWpHGLite.title');
        private pieces: number = 1;
        private pieceValue: number = 0;
        private investsumText: string = '7.000';
        @Prop()
        private isBondTypeBlockchain!: boolean;
        @Prop()
        private singleUnitThresholdText!: string;
        @Prop()
        private investService!: InvestService;
        private investtype: string = this.investService.getInvestTypeDebit();
        private ibanService: IbanService;
        @Prop()
        private contract!: Contract;
        @Prop()
        private isFirstInvestment!: boolean;
        @Prop()
        private tradingAccountExists!: boolean;
        @Prop()
        private user!: any;
        private vibFile = null;
        @Prop()
        private step!: number;
        @Prop()
        private checkoutValidator!: CheckoutValidator;
        @Prop()
        private legalTextInvestment!: string;
        @Prop()
        private legalTextInformationDocument!: string;
        @Prop()
        private legalTextVermittlerinformationen!: string;
        @Prop()
        private availablePieces!: number;
        @Prop()
        private legalTextSepa!: string;
        @Prop()
        private requiredFileLegalVermittlerinformationen!: string;
        @Prop()
        private requiredFileLegalWib!: string;
        @Prop()
        private showKistam!: boolean;
        @Prop()
        private isFinancing!: boolean;
        @Prop()
        private storeInvestmentRequestService!: StoreInvestmentRequestService;
        private debitMandate: boolean = false;

        private remainingPieces: any = 0;

        constructor() {
            super();

            this.pieceValue = this.contract.piece_value || 1000;
            this.investsumText = this.formatPrice(this.pieceValue * this.pieces);
            this.ibanService = new IbanService();
            this.investService.setInvestSum(this.pieceValue * this.pieces);
            this.investService.updateFomData('pieces', this.pieces);
            this.storeInvestmentRequestService.registerRequestPart(this);
			this.pieces = this.contract.min_investment_sum / this.pieceValue;

            if (this.pieceValue < 500) {
				this.pieces = 1000;
			}

            this.investsumText = this.formatPrice(this.pieceValue * this.pieces);
            this.investService.setInvestSum(this.pieceValue * this.pieces);
            this.investService.updateFomData('pieces', this.pieces);
            this.payloadPart.pieces = this.pieces;
            this.payloadPart.piece_value = this.pieceValue;

            this.payloadPart.invest_iban = this.user.usersIban;
            this.payloadPart.invest_bic = this.user.usersBic;

            PubSubService.getInstance(() => {
                this.remainingPieces = PubSubService.getInstance().getProject(this.contract.id, TYPE_BOND).availableInvestment;
            });

        }

        mounted() {
            this.updateInvestType();
        }

        onChangeIbanOrBic(ibanBic: { iban: string, bic: string }) {
            this.payloadPart.invest_iban = ibanBic.iban;
            this.payloadPart.invest_bic = ibanBic.bic;
        }

        updateInvestType() {
            this.investService.setInvestType(this.investtype);
            this.investService.updateFomData('investtype', this.investtype);
            this.investService.setInvestSum(this.pieceValue * this.pieces);
        }

        onActionCodeChanged(values: ActionCodeUserDetails) {
            this.payloadPart.action_code = values.code;
            this.payloadPart.action_code_confirm = values.confirmed;
            this.payloadPart.action_code_reuse_iban = values.reuseIban;
            this.payloadPart.action_code_iban = values.iban ? values.iban : '';
            this.payloadPart.action_code_bic = values.bic ? values.bic : '';
        }

        @Watch('debitMandate')
        onDebitMandateChanged() {
            this.investService.updateFomData('debitMandate', this.debitMandate);
            this.payloadPart.debitMandate = this.debitMandate;
        }

        @Watch('investtype')
        onTypeChanged(val: string, oldVal: string) {
            this.payloadPart.investtype = this.investtype;
            this.updateInvestType();
        }

        @Watch('vibFile')
        onVibChanged(val: string, oldVal: string) {
            this.payloadPart.vib_file = val;
            this.investService.updateFomData('vib_file', val);
        }

        @Watch('pieces')
        onPiecesChanged(val: string, oldVal: string) {

            if (Number(val) > this.availablePieces && Number(oldVal) > 1) {
                this.pieces = this.availablePieces;
            }
            this.investsumText = this.pieces < 1 ? '1.000' : this.formatPrice(this.pieceValue * this.pieces);

            this.investService.setInvestSum(this.pieceValue * this.pieces);
            this.investService.updateFomData('pieces', this.pieces);
            this.payloadPart.pieces = this.pieces;
        }

        onBlur() {
            if (!this.pieces || 0 >= this.pieces) {
                this.pieces = 1;
            }
        }

        formatPrice(value: number): string {

            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        }

        getRequestPart(): object {

            return this.payloadPart;
        }
    }

    export default InvestmentWpHGLite;
