








import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CheckoutValidator from './CheckoutValidator';

@Component
export default class Error extends Vue {
    @Prop()
    private name!: string;

    @Prop()
    private step!: number;

    @Prop()
    private checkoutValidator!: CheckoutValidator;

    constructor() {
        super();
    }

    mounted() {
        let key = this.name;

        if (key[0] === key[0].toUpperCase()) {
            key = key.toLowerCase();
        }

        if (this.checkoutValidator) {
            this.checkoutValidator.addError(key, this.step, this.$vnode.elm);
        }
    }
}
