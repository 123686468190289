var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"root",staticClass:"root",class:{'mobile': _vm.isMobile}},[(_vm.sendRegisterComplete)?_c('div',{staticClass:"successText",domProps:{"innerHTML":_vm._s(_vm.successText)}}):_vm._e(),(!_vm.sendRegisterComplete)?_c('div',{staticClass:"registerForm"},[_c('div',{staticClass:"form-group"},[_c('Input',{attrs:{"onChange":function (val) {_vm.input.email = val;},"id":"email","type":"email","placeholder":_vm.trans.get('auth.modal.email_placeholder'),"onEnterPress":_vm.register}}),_c('span',{staticClass:"error",domProps:{"innerHTML":_vm._s(_vm.errorText)}})],1),(_vm.legalText.REGISTER_HEADLINE)?_c('p',[_vm._v(_vm._s(_vm.legalText.REGISTER_HEADLINE))]):_c('p',[_vm._v(_vm._s(_vm.trans.get('auth.modal.register_checkbox_pre_hint',[this.getCCTLD()])))]),(this.getCCTLD() === 'de' || this.getCCTLD() === 'at')?_c('div',[('undefined' === typeof _vm.legalText.REGISTER_AGREE_1)?_c('div',{staticClass:"form-group"},[_c('Checkbox',{attrs:{"id":"exporo-ag"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.trans.get('auth.modal.checkbox_exporo_ag', [
                    this.buildLinkByOrigin('/einwilligungserklaerung-exporo-ag'),
                    this.buildLinkByOrigin('/agb'),
                    this.buildLinkByOrigin('/datenschutz')
                  ]))}})])],1):(_vm.legalText.REGISTER_AGREE_1.length)?_c('div',{staticClass:"form-group"},[_c('Checkbox',{attrs:{"id":"exporo-ag"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.legalText.REGISTER_AGREE_1)}})])],1):_vm._e()]):_vm._e(),('undefined' === typeof _vm.legalText.REGISTER_AGREE_2)?_c('div',{staticClass:"form-group"},[_c('Checkbox',{attrs:{"id":"exporo-investment-gmbh"}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.trans.get('auth.modal.checkbox_exporo_investment_gmbh', [
                    this.buildLinkByOrigin('/einwilligungserklaerung-exporo-investment-gmbh'),
                    this.buildLinkByOrigin('/agb-exporo-investment-gmbh'),
                    this.buildLinkByOrigin('/datenschutz-exporo-investment-gmbh')
                  ]))}})])],1):(_vm.legalText.REGISTER_AGREE_2.length)?_c('div',{staticClass:"form-group"},[_c('Checkbox',{attrs:{"id":"exporo-investment-gmbh"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.legalText.REGISTER_AGREE_2)}})])],1):_vm._e(),_c('div',{staticClass:"form-group"},[(_vm.legalText.REGISTER_SUBLINE)?_c('p',[_vm._v(_vm._s(_vm.legalText.REGISTER_SUBLINE))]):[_c('p',[_vm._v(_vm._s(_vm.trans.get('auth.modal.register_checkbox_suf_hint_1')))]),_c('p',[_vm._v(_vm._s(_vm.trans.get('auth.modal.register_checkbox_suf_hint_2')))])]],2),_c('div',{staticClass:"btn-container"},[_c('button',{attrs:{"id":"register-submit"},on:{"click":_vm.register}},[_vm._v(_vm._s(_vm.trans.get('auth.modal.submits.register'))+"\n            ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }