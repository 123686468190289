















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import CockpitTabBar from '@/components/Cockpit/Controls/CockpitTabBar.vue';
import OptionEntry from '@/components/Cockpit/models/OptionEntry';
import ViewPager from '@/components/ViewPager/ViewPager.vue';
import PageView from '@/components/ViewPager/PageView.vue';
import ListFinancingPayments from '@/components/Cockpit/Lists/ListFinancingPayments.vue';
import ListFinancingInvestment from '@/components/Cockpit/Lists/ListFinancingInvestment.vue';
import Investment from '@/components/Cockpit/Financing/interfaces/investment';
import UserData from '@/components/Cockpit/models/UserData';


@Component({
    components: {
        CockpitTabBar,
        ViewPager,
        PageView,
        ListFinancingInvestment,
        ListFinancingPayments
    }
})

class ListFinancing extends ExporoVue {
    @Prop() private investmentTableData!: Investment[];
    @Prop() private paymentTableData!: any;
    @Prop() private userdata!: UserData;

    private pageOptions: any[][];
    private onChangeTabCallback: any = null;


    constructor() {
        super();
        this.pageOptions = [
            [
                new OptionEntry(
                    this.trans.get('cockpit.pages.financing.tabbar.projects'),
                    'projects',
                    () => {
                    },
                ),
                new OptionEntry(
                    this.trans.get('cockpit.pages.financing.tabbar.payments'),
                    'payments',
                    () => {
                    },
                ),
            ],
        ];
    }

    mounted() {
        const viewPager: ViewPager = this.$refs.viewPager as ViewPager;
        this.onChangeTabCallback = (tab: any) => {
            viewPager.setCurrentItem(tab);
        };
    }

}


export default ListFinancing;
