











import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Events from '@/events';

@Component
class CockpitButton extends Vue {

    @Prop({default: 'Checkbox'}) private label!: string;
    @Prop() private icon!: string;
    @Prop() private name!: string;
    @Prop() private isOpen: boolean = false;

    // private isActive: boolean = false;
    // private isOpen: boolean = false;

    constructor() {
        super();
    }

    clicked() {
        // this.isOpen = !this.isOpen;
        // Events.$emit('checkbox-check', {name: this.name, value: this.isOpen});
        // this.$emit('checked');
    }

}

export default CockpitButton;

