import BuyTransactionResponseInterface from '../interfaces/BuyTransactionResponse';

class BuyTransactionController {
    get piecesToBuy(): number {
        return this._piecesToBuy;
    }

    set piecesToBuy(value: number) {
        this._piecesToBuy = value;
    }

    availableAssets: number;
    projectName: string;
    pieceValue: number;
    private _piecesToBuy: number = 1;
    totalBuyValue: number = 0;

    constructor(buyTransactionResponse: BuyTransactionResponseInterface) {
        this.availableAssets = Number(buyTransactionResponse.availableAssets);
        this.pieceValue = Number(buyTransactionResponse.pieceValue.replace('€', ''));
        this.projectName = buyTransactionResponse.projectName;
        this.totalBuyValue = this.pieceValue * this._piecesToBuy;
    }

    piecesChanged(pieces) {
        this._piecesToBuy = pieces;

        this.totalBuyValue = this._piecesToBuy * this.pieceValue;
    }

}

export default BuyTransactionController;
