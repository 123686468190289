import BaseRule from '../BaseRule';

export default class AcceptedRule extends BaseRule {

    constructor(rulesString, input, name, type) {
        super(rulesString, input, name, type);

        this._valid = this.validate();
    }

    validate() {

        return this._input.checked;
    }
}
