import TradeOffer from '@/components/Cockpit/KPI/services/TradeOffer';

class OfferTransactionController {
    get salePricePerPiece(): number {
        return this._salePricePerPiece;
    }

    set salePricePerPiece(value: number) {
        this._salePricePerPiece = value;
    }

    get pieces(): number {
        return this.piecesToOffer;
    }

    set pieces(value: number) {
        this.piecesToOffer = value;
    }

    availableAssets: number;
    projectName: string;
    availablePieces: number = 0;
    salesOffer: number = 0;
    _insertioncharge!: number;
    piecesToOffer: number = 1;
    private _salePricePerPiece: number = 1000;
    private chargeTransition: number = 6000;
    private percentageCharge: number = 0.005;
    private tradeOffer: TradeOffer;

    constructor(tradeOffer: TradeOffer) {
        this.tradeOffer = tradeOffer;
        this.availableAssets = tradeOffer.remainingPieces;
        this.projectName = tradeOffer.projectName;

        this.convertValues();
    }

    convertValues() {
        this.salesOffer = this.piecesToOffer * this._salePricePerPiece;
        this.availablePieces = this.availableAssets / this.tradeOffer.pieceValue;
        this._insertioncharge = this.salesOffer * this.percentageCharge;
    }

    salePricePerPieceChanged(price) {
        this._salePricePerPiece = price;

        this.piecesChanged(this.piecesToOffer);
    }

    piecesChanged(pieces) {
        this.piecesToOffer = pieces;

        if (this.piecesToOffer > this.availablePieces) {
            this.piecesToOffer = this.availablePieces;
        }

        this.salesOffer = this.piecesToOffer * this._salePricePerPiece;

        this._insertioncharge = this.salesOffer * this.percentageCharge;
    }
}

export default OfferTransactionController;
