class PercentFormatHelper {

    static numberToDecimal(ogNumber: number): string | null {
        if (ogNumber.toString().indexOf('.') !== -1) {
            const splitNumber = ogNumber.toString().split('.');
            if (splitNumber[1].length < 2) splitNumber[1] = splitNumber[1] + '0';
            return splitNumber.join(',') + '%';
        } else {
            return ogNumber.toString() + ',00%';
        }
    }

    static stringToDecimal(ogString: string): string | null {
        const cleanNumber = ogString.replace(/[^,0-9.]/g, '').replace(',', '.');
        return this.numberToDecimal(parseFloat(cleanNumber));
    }

    static parseNumberToPercent(value: number) {
        return value * 100;
    }

    static numberToFixedPercent(value: number, fractionDigits: number) {
        return (value * 100).toFixed(fractionDigits) + '%';
    }

}

export default PercentFormatHelper;
