class TableData {

    private readonly _data: object[] = [];
    private _options: any;
    private _hasLoaded: boolean;
    private _emptyStateMessage: string;
    private _rows: any;

    constructor(data: object[]) {
        this._data = data;
        this._hasLoaded = false;
        this._emptyStateMessage = 'No Data';
    }

    get data(): object[] {
        return this._data;
    }

    get options(): any {
        return this._options;
    }

    set options(value: any) {
        this._options = value;
    }

    get hasLoaded() {
        return this._hasLoaded;
    }

    set hasLoaded(value: any) {
        this._hasLoaded = value;
    }

    set emptyStateMessage(message: string) {
        this._emptyStateMessage = message;
    }

    get emptyStateMessage(): string {
        return this._emptyStateMessage;
    }

    set rows(rows: any) {
        this._rows = rows;
    }

    get rows(): any {
        return this._rows;
    }
}

export default TableData;
