import BaseRoute from '../components/Router/BaseRoute';
import {Route} from '@/components/Router/decorators/RouteDecorator';

class UserRoutes extends BaseRoute {

    @Route('/user/{id}')
    private edit;
}

export default UserRoutes;
