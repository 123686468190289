

















import {Component, Prop, Vue} from 'vue-property-decorator';
import ExporoVue from '../ExporoVue';

@Component
export default class Accordion extends ExporoVue {

    @Prop({default: false})
    open!: boolean;

    show: boolean = false;


    mounted() {
        this.show = this.open;

    }

    data() {
        return {
            show: false,
        };
    }

    toggleAccordion() {
        if (null !== this.$parent && this.$parent.$el.classList.contains('accordion-group'))
            return false;
        this.show = !this.show;

    }
}
