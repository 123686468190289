





















    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import OptionEntry from '../models/OptionEntry';
    import Events from '@/events';
    import CockpitRouter from '@/components/Cockpit/CockpitRouter';
    import Router from '@/components/Router/Router';
    import UserData from '@/components/Cockpit/models/UserData';

@Component
class CockpitTabBar extends Vue {

    @Prop() private userdata!: UserData;
    @Prop({default: 'tabbar'}) private id!: string;
    @Prop() private options!: any[][];
    @Prop() private small!: false;
    @Prop() private selected!: string;
    @Prop() private minwidth!: string;
    @Prop() private onChangeTabCallback!: (key: any) => void;
    @Prop() private setHeroImageUrl!: (isInvestmentPlan20?: boolean) => void;

    private activeTab: string = 'PageYield';
    private barElement!: HTMLElement;
    private bubble!: HTMLElement;
    private selection: string = 'yield';
    private isHidden: boolean = false;
    private regionId: string = '';
    private hasInvestments: boolean = false;

    mounted() {
        Events.$emit('cockputTabBarMounted');

        if (this.userdata) {
            this.regionId = this.userdata.rawData.userData.registration_region_id;
            this.hasInvestments = this.userdata.rawData.hasInvestments.finanzierung;
        }

        this.selection = this.selected;

        // this.$nextTick(() => {
        //     this.barElement = this.$el.querySelector('ul')!;
        //     this.bubble = this.$el.querySelector('.bubble')! as HTMLElement;
        //
        //     const activeElement: HTMLElement = (this.$el.querySelector('.active') as HTMLElement);
        //
        //     if (activeElement instanceof HTMLElement) {
        //         this.updateBubble(activeElement);
        //     }
        // });

        Events.$on('updateBubble', (data) => {

            if ('tabbarDetail' !== this.id) {
                this.flatOptions.forEach((option) => {
                    const page = data.page.split('/')[0];
                    if (page && page === option.key) {
                        this.navigationChangedHandler(option);
                    }
                });
            }
        });

        Events.$on('updateSliderBubble', (data) => {

            this.$nextTick(() => {
                if ('tabbarDetail' === this.id) {
                    const bubble = document.getElementById(`option_${data}`);
                    if (bubble) {
                        this.updateBubble(bubble);
                    }
                    this.selection = 'reporting' === data ? 'Reporting' : 'details';
                }
            });
        });

        Events.$emit('cockputTabBarMounted');

        this.selection = this.selected;


        this.$nextTick(() => {
            this.barElement = this.$refs.bar as HTMLElement;
            this.bubble = this.$refs.bubble as HTMLElement;
            const activeElement: HTMLElement = this.$el.querySelector('.active') as HTMLElement;
            if (activeElement instanceof HTMLElement) {
                this.updateBubble(activeElement);
            }
        });


        Events.$on('router-went', (pageEntry) => {
            let inTabBar = false;
            for (const option of this.flatOptions) {

                if (pageEntry.page.page === option.key) {
                    this.changeTab(option);
                    inTabBar = true;
                    break;
                }
            }

            if (!inTabBar && 'Detail' !== pageEntry.page.page) {
                // @TODO
                // this.nullifyTab();
            }

        });

    }

    changeTab(option: OptionEntry, e?) {

      if (e) {
        e.preventDefault();
      }

      this.navigationChangedHandler(option);

      if (this.onChangeTabCallback) {
        this.onChangeTabCallback(option.key);
      }
      if (this.setHeroImageUrl) {
        if (this.selection === 'new') {
          this.setHeroImageUrl(true);
        } else {
          this.setHeroImageUrl();
        }
      }
    }

    navigationChangedHandler(option: OptionEntry) {
        this.isHidden = false;
        this.selection = option.key;

        this.$nextTick(() => {
            const bubble = this.$refs[option.key][0];
            this.updateBubble(bubble);
            option.handler();
        });
    }

    nullifyTab() {
        this.isHidden = true;
        this.selection = '';
        this.updateBubble();
    }

    updateBubble(tab?: HTMLElement) {

        if (void (0) === tab) {
            this.isHidden = true;
            return;
        }
        const rectTab = tab!.getBoundingClientRect();
        const rectBar = this.barElement.getBoundingClientRect();

        this.bubble.style.width = `${rectTab.width}px`;
        this.bubble.style.height = `${rectTab.height}px`;
        this.bubble.style.left = `${rectTab.left - rectBar.left - 1}px`;
        this.bubble.style.top = `${rectTab.top - rectBar.top - 1}px`;
    }

    get flatOptions() {
        const flatOptions: any[] = [];
        for (const options of this.options) {
            for (const option of options) {
                flatOptions.push(option);
            }
        }
        return flatOptions;
    }


    get selectables() {
        const selectables = {};
        for (const optiongroup of this.options) {
            for (const option of optiongroup) {
                selectables[option.key] = option.value;
            }
        }
        return selectables;
    }

    regionHasFinancing(): boolean {
        const regionsWithFinancing = ['at', 'de'];
        return regionsWithFinancing.includes(this.regionId);
    }
}

export default CockpitTabBar;
