

















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CockpitButton from '@/components/Cockpit/Controls/CockpitButton.vue';
import URLHelper from '@/components/Helper/URLHelper';
import ExporoVue from '@/components/ExporoVue';
import LandingVideo from '@/components/Cockpit/pages/LandingVideo.vue';
import LandingLottiImg from '@/components/Cockpit/pages/LandingLottiImg.vue';

@Component({
    components: {
        CockpitButton,
        LandingVideo,
        LandingLottiImg
    }
})
class PagePortfolioLanding extends ExporoVue {

    public region: string = 'DE';


    constructor() {
        super();

        this.region = this.cctld;
    }

    setActionBtnMargin() {
        const userAgent = window.navigator.userAgent;
        return userAgent.match(/iPhone/i) ? {marginBottom: '70px'} : {};
    }

    mounted() {

    }


    getLink(): string {
        let region = '';
        let extension = /bestand/;
        // @TODO: change extension according to language once the extancion name is translated.

        switch (this.cctld) {
            default:
                region = 'de';
                extension = /bestand/;
                break;
            case 'AT':
                region = 'at';
                break;
            case 'NL':
                region = 'nl';
                break;
            case 'FR':
                region = 'fr';
                break;
        }
        return `https://exporo.${region + extension}`;
    }
}

export default PagePortfolioLanding;
