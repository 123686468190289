import pathToRegexp from 'path-to-regexp';
import Router from '@/components/Router/Router';

class BaseRoute {

    init(): any {
        const urls: any = {};

        for (const apiMethod of Object.getOwnPropertyNames(Object.getPrototypeOf(this))) {
            const result = this[apiMethod];

            if (result.hasOwnProperty('url')) {

                if (result.url.indexOf('/') === 0) {
                    result.url = result.url.substr(1);
                }

                urls[apiMethod] = result.url;

                const path = this.replaceParams(result.url);

                Router.add(pathToRegexp(path), apiMethod);
            }
        }

        return urls;
    }

    replaceParams(url: string) {
        let final;

        final = url.replace(/{.*}/, (match, key) => {
            return match.replace('{', ':')
            .replace('}', '');
        });

        if (url.match(/{.*}/)) {
            final = this.replaceParams(final);
        }

        return final;
    }
}

export default BaseRoute;
