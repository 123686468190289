import ReportingKpis from '@/components/Cockpit/interfaces/ReportingKpis';
import DescriptionPerQuarterObject from '@/components/Cockpit/KPI/models/DescriptionPerQuarterObject';
import QuarterlyIncome from '@/components/Cockpit/KPI/models/QuarterlyIncome';
import CurrentDevelopmentItems from '@/components/Cockpit/KPI/models/CurrentDevelopmentItems';

export default class YieldReporting {
    readonly _kpis: ReportingKpis;
    private _quarterlyReport: DescriptionPerQuarterObject[] = [];
    private _quarterlyIncome: QuarterlyIncome[] = [];
    private _kpiCharts: CurrentDevelopmentItems[] = [];

    constructor(kpis: ReportingKpis) {
        this._kpis = {
            project: kpis.project,
            expected_interest: kpis.expected_interest,
            isin: kpis.isin,
            wkn: kpis.wkn,
        };
    }

    get kpis(): ReportingKpis {
        return this._kpis;
    }

    set quarterlyReport(data: DescriptionPerQuarterObject[]) {
        this._quarterlyReport = data;
    }

    get quarterlyReport(): DescriptionPerQuarterObject[] {
        return this._quarterlyReport;
    }

    set quarterlyIncome(data: QuarterlyIncome[]) {
        this._quarterlyIncome = data;
    }

    get quarterlyIncome(): QuarterlyIncome[] {
        return this._quarterlyIncome;
    }

    set kpiCharts(data: CurrentDevelopmentItems[]) {
        this._kpiCharts = data;
    }

    get kpiCharts(): CurrentDevelopmentItems[] {
        return this._kpiCharts;
    }
}
