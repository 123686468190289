import Logger from '@/components/Helper/LogHelper';
import Events from '@/events';
import ExporoVue from '@/components/ExporoVue';

export default class SimpleSpinner {

    static _instance: SimpleSpinner | null = null;

    static getInstance(): SimpleSpinner {
        if (!this._instance) {
            this._instance = new SimpleSpinner();
        }

        return this._instance;
    }


    private constructor() {
    }

    show() {
        Events.$emit(ExporoVue.SIMPLE_SPINNER_TOGGLE_EVENT, true);
    }

    hide(delay?: number) {
        Events.$emit(ExporoVue.SIMPLE_SPINNER_TOGGLE_EVENT, false);
    }
}
