import ImplementationMissingError from './Errors/ImplementationMissingError';

export default class BaseRule {

    _rulesString: string;
    _input;
    _name;
    _type;
    _valid;

    constructor(rulesString, input, name, type) {
        this._rulesString = rulesString;
        this._input = input;
        this._name = name;
        this._type = type;
        // Should be filled by subClass on constructor
        this._valid = null;
    }

    get valid() {

        if (null === this._valid) {
            throw new ImplementationMissingError('You must implement and call an method that can fill an boolean named "_valid" in your rule. EX: this._valid = this.validate();');
        }

        return this._valid;
    }

    get error() {
        return `${this._name} is required!`;
    }

    get type() {
        return this._type;
    }
}
