import InvestmentRequestPart from '../models/InvestmentRequestPart';

class StoreInvestmentRequestService {

    private requestParts: InvestmentRequestPart[];
    private payload: object;

    constructor() {
        this.requestParts = [];
        this.payload = {};
    }

    getPayload(): object {
        this.mergeRequestParts();

        return this.payload;
    }

    private mergeRequestParts() {
        this.payload = {};
        this.requestParts.forEach((requestPart: InvestmentRequestPart) => {

            this.payload = {...this.payload, ...requestPart.getRequestPart()};
        });
    }

    registerRequestPart(requestPart: InvestmentRequestPart) {

        this.requestParts.push(requestPart);
    }
}

export default StoreInvestmentRequestService;
