import BaseRule from '../BaseRule';

export default class AcceptedWithRule extends BaseRule {

    constructor(rulesString, input, name, type) {
        super(rulesString, input, name, type);

        this._valid = this.validate();
    }

    validate() {
        if (this._input.checked) {
            return true;
        }

        const refId = this.parseRulesString();

        const toCheckInputNode: any = document.getElementById(refId);

        if (!toCheckInputNode) {
            return false;
        }

        return this.isBlank(toCheckInputNode.value);
    }

    parseRulesString(): string {
        const rules = this._rulesString.split('|');
        const ruleIndex = rules.findIndex((rule) => rule.includes('accepted_with'));

        return rules[ruleIndex].split(':')[1];
    }

    isBlank(value: string): boolean {

        return value.trim().length === 0;
    }
}
