import Events from '@/events';
import Toast from '@/components/Toast/Toast';

class ToastService {
    addToast(toast: Toast) {
        Events.$emit('onToastAdded', toast);
    }
}

export default ToastService;
