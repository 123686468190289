import IntegrationOptions from '@/components/Rest/models/IntegrationOptions';
import axios from 'axios';
import RestApiService from '@/components/Rest/services/RestApiService';
import Closure from '@/components/Rest/middleware/core/Closure';

class BaaderIntegration {

    private restApiService!: RestApiService;

    constructor() {

        this.restApiService = new RestApiService();
    }

    requestBaader(options: IntegrationOptions) {

        const config = {
            method: 'get',
            url: 'https://baader.exporo.de/api/customer/data/wdp',
            headers: {
                'Authorization': 'Bearer ' + options.data.token
            }
        };
        this.restApiService.callMiddleware({config: config, id: options.id}, new Closure(() => {
            axios.request(config).then((data) => {
                this.restApiService.callMiddleware({response: data, id: options.id}, new Closure(() => {
                    options.callback.onSuccess({data: data, callback: options.data.callback} as any, options.id);
                }), true);
            }).catch((error) => {
                this.restApiService.callMiddleware(error, new Closure(() => {
                    options.callback.onFailure(error as any, options.id);
                }), true);
            });
        }));
    }

    requestDocumentsBaader(options: IntegrationOptions) {

        const config = {
            method: 'get',
            url: 'https://baader.exporo.de/api/customer/data/document',
            headers: {
                'Authorization': 'Bearer ' + options.data.token
            }
        };
        this.restApiService.callMiddleware({config: config, id: options.id}, new Closure(() => {
            axios.request(config).then((data) => {
                this.restApiService.callMiddleware({response: data, id: options.id}, new Closure(() => {
                    options.callback.onSuccess({data: data, callback: options.data.callback} as any, options.id);
                }), true);
            }).catch((error) => {
                this.restApiService.callMiddleware(error, new Closure(() => {
                    options.callback.onFailure(error as any, options.id);
                }), true);
            });
        }));
    }
}

export default BaaderIntegration;
