







import {Component, Vue, Prop, Watch} from 'vue-property-decorator';

@Component
class Input extends Vue {
    @Prop()
    private onChange!: (value?: any) => void;

    @Prop()
    private onEnterPress!: () => void;

    @Prop()
    private onTabPress!: () => void;

    @Prop()
    private onBlur!: () => void;

    @Prop()
    private type!: string;

    @Prop()
    private value!: string;

    @Prop()
    private name!: string;

    @Prop()
    private id!: string;

    @Prop()
    private step!: number;

    @Prop()
    private min!: number;

    @Prop()
    private max!: number;

    @Prop()
    private placeholder!: string;

    @Prop()
    private autocompleteValue!: string;

    @Prop()
    private checked!: boolean;

    @Prop()
    private disabled!: boolean;


    constructor() {
        super();
    }

    blur() {
        if (this.onBlur) {
            this.onBlur();
        }
    }

    onKeydown(event) {
        if (event.which === 13 && this.onEnterPress) {
            this.onEnterPress();
        }

        if (event.which === 9 && this.onTabPress) {
            this.onTabPress();
        }
    }

    update(event) {
        if (this.onChange) {
            this.onChange(event.target.value);
        }
    }
}
export default Input;
