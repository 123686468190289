import BaseRule from '../BaseRule';

export default class InvestsumAmountRule extends BaseRule {
    private _value;
    private _min;
    private _max;

    constructor(rulesString, input, name, type) {
        super(rulesString, input, name, type);
        this._value = null;
        this._min = null;
        this._max = null;

        this.parseRulesString();

        this._valid = this.validate();
    }

    validate() {
        const value = Number(this._input.value);

        return value >= this._min && value <= this._max;
    }

    parseRulesString() {
        const rules = this._rulesString.split('|');
        const minPosition = rules.findIndex((rule: string) => rule.includes('investsum_amount_min'));
        const maxPosition = rules.findIndex((rule: string) => rule.includes('investsum_amount_max'));

        this._min = Number(rules[minPosition].split(':')[1]);
        this._max = Number(rules[maxPosition].split(':')[1]);
    }
}
