import Factory from './interfaces/factory';
import Investment from './interfaces/investment';
import MezzBondInvestment from './MezzBondInvestment';
import LoanInvestment from './LoanInvestment';
import SigningInvestment from '@/components/Cockpit/Financing/SigningInvestment';

class InvestmentFactory implements Factory {
    createInvestment(data, userdata: any): Investment {

        switch (data.type) {
            case 'product.loan':
                return new LoanInvestment(data, userdata);
            case 'product.mezzbond':
                return new MezzBondInvestment(this.transformBondData(data), userdata);
            case 'product.signing':
                return new SigningInvestment(this.transformBondData(data), userdata);
            default:
                return new SigningInvestment(this.transformBondData(data), userdata);
        }
    }

    private transformBondData(data: any) {
        return {
            datePayBack: data.first_payback_at,
            ...(Object.keys(data).filter((key: string) => key !== 'first_payback_at'))
                .reduce((accumulator: any, key: string) => {
                    return {
                        ...accumulator,
                        [key]: data[key],
                    };
                }, {}),
        };
    }
}

export default InvestmentFactory;
