









import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Events from '@/events';

@Component
class CockpitButton extends Vue {

    @Prop({default: 'Button'}) private label!: string;
    @Prop() private icon!: string;
    @Prop() private name!: string;
    @Prop() private onClick!: () => void;

    constructor() {
        super();
    }

    clicked() {
        if (this.onClick) {
            this.onClick();
        } else {
            Events.$emit('button-click', {name: this.name});
        }
    }

}

export default CockpitButton;

