












    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import TableData from '@/components/Cockpit/models/TableData';
    import OptionEntry from '@/components/Cockpit/models/OptionEntry';
    import Events from '@/events';
    import InvestMenu from '@/components/Cockpit/InvestList/InvestMenu.vue';
    import ExporoVue from '@/components/ExporoVue';
    import ListView from '@/components/ListView/ListView.vue';
    import Row from '@/components/ListView/interfaces/Row';
    import CockpitRouter from '@/components/Cockpit/CockpitRouter';
    import DateFormatHelper from '@/components/Helper/DateFormatHelper';
    import StringHelper from '@/components/Helper/StringHelper';
    import Router from '@/components/Router/Router';
    import FinancingListFilter from '@/components/Cockpit/Lists/FinancingListFilter.vue';
    import Investment from '@/components/Cockpit/Financing/interfaces/investment';
    import UserData from '@/components/Cockpit/models/UserData';


    @Component({
        components: {
            FinancingListFilter,
            ListView,
        }
    })
    class ListFinancingInvestment extends ExporoVue {

        @Prop() private tableData!: Investment[];
        @Prop() private userdata!: UserData;

        private filterStatus!: any[];
        private defaultFilter: string = 'ongoingInvestments';
        private selectable: boolean = true;
        private rows: any[] = [];
        private emptyStateMessage: string = '';


        emptyCTA() {
            return {
                text: this.trans.get('invest_button.label'),
                action: (e) => {
                    e.preventDefault();
                    window.location.href = 'https://exporo.de/projekte/';
                }
            };
        }

        constructor() {
            super();
            this.selectable = !this.isMobile;
            this.emptyStateMessage = this.trans.get('cockpit.list_financing_investments.empty_state_message.ongoing');

            this.filterStatus = [
                new OptionEntry(
                    this.trans.get('cockpit.pages.financing.filter.investments.ongoingInvestments'),
                    this.defaultFilter,
                    () => {
                        Events.$emit('filter', {
                            type: 'state',
                            selection: 'ongoingInvestments',
                            subMenu: 'investments'
                        });
                        Events.$emit('filter-investments', {
                            type: 'state',
                            selection: 'ongoingInvestments',
                            subMenu: 'investments'
                        });
                    }
                ),
                new OptionEntry(
                    this.trans.get('cockpit.pages.financing.filter.investments.disbursedInvestments'),
                    'disbursedInvestments',
                    () => {
                        Events.$emit('filter', {
                            type: 'state',
                            selection: 'disbursedInvestments',
                            subMenu: 'investments'
                        });
                        Events.$emit('filter-investments', {
                            type: 'state',
                            selection: 'disbursedInvestments',
                            subMenu: 'investments'
                        });
                    }
                ),
                new OptionEntry(
                    this.trans.get('cockpit.pages.financing.filter.investments.allInvestments'),
                    'allinvestments',
                    () => {
                        Events.$emit('filter', {
                            type: 'state',
                            selection: 'allInvestments',
                            subMenu: 'investments'
                        });
                        Events.$emit('filter-investments', {
                            type: 'state',
                            selection: 'allInvestments',
                            subMenu: 'investments'
                        });
                    }
                ),
            ];

            const rows: any[] = [];
            this.tableData.forEach((investment: Investment) => {
                if (investment.isRunning()) {
                    rows.push(this.createRowEntry(investment));
                }
        });

            this.rows = rows;

            Events.$on('filter-investments', (data: any) => {
                this.filterInvestments(data);
            });
        }

        filterInvestments(data: any) {
            const filteredRows: any[] = [];

            this.tableData.forEach((investment: Investment) => {

                if ('all' === data.selection || 'allInvestments' === data.selection || 'allPayments' === data.selection) {
                    filteredRows.push(this.createRowEntry(investment));
                } else if ('ongoingInvestments' === data.selection) {
                    if (investment.isRunning()) {
                        filteredRows.push(this.createRowEntry(investment));
                    }
                } else if ('disbursedInvestments' === data.selection) {
                    if (!investment.isRunning()) {
                        filteredRows.push(this.createRowEntry(investment));
                    }
                }
            });

            this.rows = filteredRows;
            this.setEmptyStateMessage(data);

        }

        setEmptyStateMessage(data: any): void {
            if (!this.rows.length) {
                if ('ongoingInvestments' === data.selection) {
                    this.emptyStateMessage = this.trans.get('cockpit.list_financing_investments.empty_state_message.ongoing');
                } else if ('disbursedInvestments' === data.selection) {
                    this.emptyStateMessage = this.trans.get('cockpit.list_financing_investments.empty_state_message.disbursed');
                } else {
                    this.emptyStateMessage = this.trans.get('cockpit.list_financing_investments.empty_state_message.all');
                }
            } else {
                this.emptyStateMessage = '';
            }
        }

        getImageByInvestment(investment: Investment) {
            const projects = this.userdata.projects;
            const project = projects[investment.getFinancingEntity()];

            return {path: project ? project.image : null};
        }

        createRowEntry(investment: Investment): any {
            return {
                _image: this.getImageByInvestment(investment),
                signingId: investment.getFinancingEntity(),
                realID: `${investment.getType()}-${investment.getId()}`,
                title: investment.getTitle(),
                investment: investment.getInvestSum(),
                return_interest: investment.getInterest() / 100,
                investor: investment.getInvestor(),
                running_time: DateFormatHelper.getFormattedDateFromString(DateFormatHelper.stringToAmericanDate(investment.getDatePayBack()), true),
                invested_at: investment.getCreatedAt().split(' ')[0],
                project_updates: this.getUpdateFromInvestment(investment),
                action: true
            };
        }

        getUpdateFromInvestment(investment: Investment) {
          const projects = this.userdata.projects;
          const project = projects[investment.getFinancingEntity()];

          if (project && project.updates && project.updates.length > 0) {

            const newestDate = new Date(Math.max.apply(null, project.updates.map((e) => {
              return +new Date(e.date);
            })));

            if (newestDate) {
              return DateFormatHelper.getFormattedDate(newestDate, true);
            }
          }

          return '01.01.1970';
        }

        onClick(row: Row) {

            const tagName = (row.event.target as any).tagName;

            if (tagName !== 'INPUT' && tagName !== 'TH') {
                const investment = this.tableData.find((investment: Investment) => {
                    return `${investment.getType()}-${investment.getId()}` === row.row.realID;
                });

                if (investment) {
                    this.open(investment, row.event);
                }
            }
        }

        open(row: Investment, event) {
            event.stopPropagation();

            const rootEl = document.querySelector('.exporo-cockpit-root');
            const rootHead = document.querySelector('.exporo-cockpit-header');
            if (rootEl && rootHead) {
                const rootHeadHeight = rootHead.scrollHeight;
                rootEl.scroll({top: rootHeadHeight, behavior: 'smooth'});
            }

            const [type, detailSlug, id] = document.location.href.split('cockpit/');

			Router.navigate('cockpitDetailRoute', [detailSlug, `${row.getType()}-${row.getId()}`]);
		}

        columns() {
            return [
                {
                    label: '',
                    field: '_image',
                    sortable: false,
                },
                {
                    label: this.trans.get('cockpit.pages.financing.list_headers.project'),
                    field: 'title',
                    thClass: 'vgt-left-align',
                },
                {
                    label: this.trans.get('cockpit.pages.financing.list_headers.investment'),
                    field: 'investment',
                    type: 'number',
                    formatFn: this.formatE,
                    tdClass: 'text-right',
                    thClass: 'vgt-right-align',
                },
                {
                    label: this.trans.get('cockpit.pages.financing.list_headers.return_interest'),
                    field: 'return_interest',
                    type: 'percentage',
                    tdClass: 'text-right',
                    thClass: 'vgt-right-align',
                },
                {
                    label: this.trans.get('cockpit.pages.financing.list_headers.investor'),
                    field: 'investor',
                    tdClass: 'text-right',
                    thClass: 'vgt-right-align',
                },
                {
                    label: this.trans.get('cockpit.pages.financing.list_headers.running_time'),
                    field: 'running_time',
                    type: 'number',
                    tdClass: 'text-right',
                    thClass: 'vgt-right-align',
                    width: '175px',
                    sortFn: this.sortFn,
                },
                {
                    label: this.trans.get('cockpit.pages.financing.list_headers.invested_at'),
                    field: 'invested_at',
                    type: 'date',
                    dateInputFormat: 'yyyy-mm-dd',
                    dateOutputFormat: 'dd.mm.yyyy',
                    sortFn: this.sortFn,
                    tdClass: 'text-right',
                    thClass: 'vgt-right-align',
                },
                {
                  label: this.trans.get('cockpit.pages.financing.list_headers.project_updates'),
                  field: 'project_updates',
                  type: 'date',
                  dateInputFormat: 'dd.mm.yyyy',
                  dateOutputFormat: 'dd.mm.yyyy',
                  sortFn: this.sortFn,
                  tdClass: 'text-right',
                  thClass: 'vgt-right-align',
                  formatFn: (value) => {
                    return value !== '01.01.1970' ? value : 'Keine Updates';
                  },
                },
                {
                    label: '',
                    field: 'action',
                    width: '60px',
                    sortable: false
                }
            ];
        }

        sortFn(x, y, col, rowX, rowY) {
            const xDate = new Date(DateFormatHelper.stringToAmericanDate(x)).getTime();
            const yDate = new Date(DateFormatHelper.stringToAmericanDate(y)).getTime();

            return (xDate < yDate ? -1 : (xDate > yDate ? 1 : 0));
        }

        format(value) {
            return `${value} Monate`;
        }

        formatE(value) {
            return StringHelper.toEur(value);
        }
    }

    export default ListFinancingInvestment;
