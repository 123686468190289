class VersionManagerService {

    static getInstance() {

        if (!VersionManagerService._instance) {
            VersionManagerService._instance = new VersionManagerService();
        }

        return VersionManagerService._instance;
    }

    private static _instance: VersionManagerService;
    private version: string | null = null;

    constructor() {

    }

    initVersion(version: string) {
        this.version = version;
    }

    getVersion(): string | null {

        return this.version;
    }
}

export default VersionManagerService;
