














import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import LoadingBarService from '@/components/LoadingBar/services/LoadingBarService';
import {MDCLinearProgress} from '@material/linear-progress/component';

@Component
export default class LoadingBar extends Vue {
    @Prop()
    private loadingBarService!: LoadingBarService;
    private progress!: MDCLinearProgress;

    constructor() {
        super();

        this.loadingBarService.addStartCallback(this.onStart);
        this.loadingBarService.addStopCallback(this.onStop);
    }

    mounted() {
        if (!this.progress ) {
            const linearProgress = new MDCLinearProgress((this.$refs.progress as Element));
            linearProgress.determinate = false;

            this.progress = linearProgress;
            this.progress.close();
        }

    }

    onStart() {
        this.progress.open();
    }

    onStop() {
        this.progress.close();
    }
}

