


















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ProgressData from '../models/ProgressData';
import Events from '@/events';

@Component
class CockpitProgress extends Vue {

    @Prop() private progressdata!: ProgressData;

}

export default CockpitProgress;

