





































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Events from '@/events';
import ExporoVue from '@/components/ExporoVue';
import LocalStorage from '@/components/LocalStorage/LocalStorage';

@Component
class ExporoSpinner extends Vue {

    private open: boolean = false;

    @Prop({default: false})
    private shouldShow!: boolean;

    constructor() {
        super();

        this.open = this.shouldShow;
    }

    mounted() {
        Events.$on(ExporoVue.SPINNER_TOGGLE_EVENT, (open) => {

            if (LocalStorage.getBoolean(ExporoVue.ACTIVATE_SPINNER_KEY, false)) {
                this.open = open;
            }
        });
    }
}

export default ExporoSpinner;
