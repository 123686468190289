





import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import PubSubService from '@/components/PubSub/services/PubSubService';
import URLHelper from '@/components/Helper/URLHelper';
import ExporoVue from '@/components/ExporoVue';

@Component
export default class HomeFact extends ExporoVue {

    private pubSubService!: PubSubService;
    private total: number = 0;
    @Prop()
    private type!: string;
    @Prop()
    private euro!: boolean;

    constructor() {
        super();
    }

    mounted() {
        this.pubSubService = PubSubService.getInstance((data) => {

            if ('totalFoundedProjects' === this.type) {
                this.total = 249;
            } else {
                if (data && data.hasOwnProperty(this.type)) {
                    this.total = data[this.type];
                }
            }
        });
    }

    convert(value) {

        let number = ((Number(value) || 0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'));

        const numbers = number.split('.');

        if (numbers.length > 1) {
            number = numbers[0] + '.' + numbers[1].substring(0, 1) + ' Mio';
        }

        if (numbers.length > 1 && 'NL' === this.cctld) {
            number = numbers[0] + '.' + numbers[1].substring(0, 1) + ' M    ';
        }

        if (this.euro && 'NL' === this.cctld) {
            return `€ ${number}`;
        }

        if (this.euro) {
            number += ' €';
        }

        return number;
    }
}
