




import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import * as Highcharts from 'highcharts';
import ExporoVue from '@/components/ExporoVue';
import HighChartsService from '@/components/Cockpit/KPI/services/HighChartsService';
import CurrentDevelopmentItems from '@/components/Cockpit/KPI/models/CurrentDevelopmentItems';
import PercentFormatHelper from '@/components/Helper/PercentFormatHelper';
import YieldReporting from '@/components/Cockpit/models/YieldReporting';

@Component
class KpiChart extends ExporoVue {
    @Prop({default: '2018'}) private year!: string;
    @Prop({default: 'percent'}) private type!: string;
    @Prop({default: 1}) private id!: number;
    @Prop() private yieldReporting!: YieldReporting;


    readonly highchartsService: HighChartsService;
    private isVisible: boolean = false;

    constructor() {
        super();
        this.highchartsService = new HighChartsService();
    }

    mounted() {
        if (this.yieldReporting.kpiCharts.length > 1) {
            this.extractQuarterlyReportValues();
            if (this.highchartsService.validateHighCharts(this.yieldReporting.kpiCharts.length) && !this.highchartsService.validateEmptyCharts()) {
                this.isVisible = true;
                Highcharts.chart(
                    `highcharts-${this.type}`,
                    this.highchartsService.getOptions()
                );
            }
        }
    }

    extractQuarterlyReportValues() {
        this.yieldReporting.kpiCharts.forEach((currentDevelopment: CurrentDevelopmentItems) => {
            switch (this.type) {
                case 'cash':
                    this.highchartsService.addActual(currentDevelopment.payoutActualEuro);
                    this.highchartsService.addTarget(currentDevelopment.payoutTargetEuro);
                    this.highchartsService.addDate(currentDevelopment.date);
                    this.highchartsService.type = 'spline';
                    this.highchartsService.unit = ' €';
                    this.highchartsService.decimal = 2;
                    this.highchartsService.legend = true;
                    this.highchartsService.title = this.trans.get('cockpit.pages.detail.reportings_charts.title_payout_euro');
                    break;
                case 'percent':
                    this.highchartsService.addActual(
                        PercentFormatHelper.parseNumberToPercent(currentDevelopment.payoutActualPercent)
                    );
                    this.highchartsService.addTarget(
                        PercentFormatHelper.parseNumberToPercent(currentDevelopment.payoutYieldPercent)
                    );
                    this.highchartsService.addDate(currentDevelopment.date);
                    this.highchartsService.type = 'spline';
                    this.highchartsService.unit = ' %';
                    this.highchartsService.decimal = 2;
                    this.highchartsService.legend = true;
                    this.highchartsService.title = this.trans.get('cockpit.pages.detail.reportings_charts.title_payout_percent');
                    break;
                case 'vacancy':
                    this.highchartsService.addActual(currentDevelopment.rentalFeeActual);
                    this.highchartsService.addTarget(currentDevelopment.rentalFeeTarget);
                    this.highchartsService.addDate(currentDevelopment.date);
                    this.highchartsService.type = 'spline';
                    this.highchartsService.unit = ' €';
                    this.highchartsService.decimal = 2;
                    this.highchartsService.legend = true;
                    this.highchartsService.title = this.trans.get('cockpit.pages.detail.reportings_charts.title_rental_income');
                    break;
                case 'reserve':
                    this.highchartsService.addActual(currentDevelopment.maintenanceReserve);
                    this.highchartsService.noTarget();
                    this.highchartsService.addDate(currentDevelopment.date);
                    this.highchartsService.type = 'column';
                    this.highchartsService.unit = ' €';
                    this.highchartsService.decimal = 2;
                    this.highchartsService.legend = false;
                    this.highchartsService.title = this.trans.get('cockpit.pages.detail.reportings_charts.title_maintenance_reserve');
                    break;
            }
        });
    }
}

export default KpiChart;

