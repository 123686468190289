class ContextService {

    private static _instance: ContextService;

    private context: any = {};

    static getInstance(): ContextService {

        if (!ContextService._instance) {
            ContextService._instance = new ContextService();
        }

        return ContextService._instance;
    }

    setContext(ctx: any, name: string) {
        this.context[name] = ctx;
    }

    getContext(name: string) {

        return this.context[name];
    }

}

export default ContextService;
