






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Events from '../../events';

@Component
class SliderComponent extends Vue {

    private pageCount = 0;
    private classList = ['vis', 'invis', 'slide-in-next', 'slide-out-next', 'slide-in-prev', 'slide-out-prev'];

    constructor() {
        super();
        Events.$on('switch-panel', (key) => this.switchPanel(key));
    }

    mounted() {
        if (this.$slots && this.$slots.default) {
           this.$slots.default.forEach((vnode: any) => {
               if (parseInt(vnode.data.key, 10) === this.pageCount) {
                   vnode.elm.classList.add('vis');
               } else {
                   vnode.elm.classList.add('invis');
               }
           });
       }
    }

    switchPanel(key) {

        let animationDirIn: string = '';
        let animationDirOut: string = '';

        if (key > this.pageCount) {
            animationDirOut = 'slide-out-next';
            animationDirIn = 'slide-in-next';
        } else {
            animationDirOut = 'slide-out-prev';
            animationDirIn = 'slide-in-prev';
        }

        this.pageCount = key;

        if (this.$slots && this.$slots.default) {
            this.$slots.default.forEach((vnode: any) => {

                if (parseInt(vnode.data.key, 10) === this.pageCount) {
                    vnode.elm.classList.remove(...this.classList);
                    vnode.elm.classList.add(animationDirIn);
                } else {
                    vnode.elm.classList.remove(...this.classList);
                    vnode.elm.classList.add(animationDirOut);
                }
            });
        }
    }
}


export default SliderComponent;
