import Logger from '@/components/Helper/LogHelper';
import Events from '@/events';
import ExporoVue from '@/components/ExporoVue';

const SPINNER_LOG_TAG = 'ExporoSpinner';

export default class Spinner {

    static _instance: Spinner | null = null;

    static getInstance(): Spinner {
        if (!this._instance) {
            this._instance = new Spinner();
        }

        return this._instance;
    }

    private constructor() {

        Logger.defaultColor = 'orange';
        Logger.tag(SPINNER_LOG_TAG).log('Call Constructor');
        Logger.defaultColor = 'blue';
    }


    show() {
        Logger.tag(SPINNER_LOG_TAG).log('Show Spinner');
        Events.$emit(ExporoVue.SPINNER_TOGGLE_EVENT, true);
    }

    hide(delay?: number) {
        Logger.tag(SPINNER_LOG_TAG).log('Hide Spinner');
        Events.$emit(ExporoVue.SPINNER_TOGGLE_EVENT, false);
    }
}
