export const base64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    let byteCharacters;
        try {
          byteCharacters = atob(b64Data);
        } catch (e) {
            b64Data = btoa(encodeURIComponent(b64Data).replace(/%([0-9A-F]{2})/g,
            (match, p1) => String.fromCharCode(Number(`0x${p1}`))));
            byteCharacters = atob(b64Data);
        }

        const byteArrays: Uint8Array[] = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray: Uint8Array = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
  };
