






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Events from '@/events';

@Component
class GalleryButton extends Vue {

    @Prop()
    private id!: string;

    constructor() {
        super();
    }

    openGallery() {
        Events.$emit(`openGallery${this.id}`, 0);
    }
}

export default GalleryButton;
