import DateFormatHelper from '@/components/Helper/DateFormatHelper';

class PayoutService {

    constructor() {

    }

    getNextPayoutForBestand(firstPaybackDate: string): string | null {

    	if (!firstPaybackDate)  {
    		return null;
		}

        const dateToday = new Date();
        let paybackDateObject = new Date(DateFormatHelper.stringToAmericanDate(firstPaybackDate));
        const todayInTime = dateToday.getTime();

        let nextPayoutAt = firstPaybackDate;

        if (isNaN(paybackDateObject.getTime())) {
            paybackDateObject = new Date(firstPaybackDate.replace(/-/g, '/'));
        }


        if (todayInTime > paybackDateObject.getTime()) {
            const closest = DateFormatHelper.getClosestNextDate();

            if ('object' === typeof closest) {
                nextPayoutAt = closest.toISOString();
            } else {

                return null;
            }
        }

        return nextPayoutAt;
    }

    getPayoutSumForFinancing(project: any) {
        if (project.contract.expected_return_on_equity_rate) {
            const interest = project.contract.expected_return_on_equity_rate / 100 + 1;
            return project.invest_sum * Math.pow(interest, (project.runningtime_in_months / 12));
        } else {
            const investSum = project.invest_sum;
            const runTime = project.contract.payback_interval_in_months;
            const interest = project.contract.interest_rate / 100 + 1;
            return investSum * Math.pow(interest, (runTime / 12));
        }
    }
}

export default PayoutService;
