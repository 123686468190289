class ProgressData {

    private _stepnames: string[];
    private _stepprogress: number;

    constructor(stepnames: string[], stepprogress: number) {
        this._stepnames = stepnames;

        this._stepprogress = 0;
        if (stepprogress === stepnames.length || stepprogress > stepnames.length) {
            this._stepprogress  = stepnames.length + 1;
        } else if (stepprogress < stepnames.length) {
            this._stepprogress = stepprogress;
        } else {
            this._stepprogress = stepnames.length;
        }
    }

    get stepnames(): string[] {
        return this._stepnames;
    }

    get stepprogress(): number {
        return this._stepprogress;
    }
}

export default ProgressData;
