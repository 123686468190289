






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
class AdobeIFrame extends Vue {

    @Prop()
    private name!: string;

    @Prop({default: '500px'})
    private width!: string;

    @Prop({default: '500px'})
    private height!: string;

    private intervalRef: any = 0;

    constructor() {
        super();
    }

    getSrc() {

        return `https://exporo-webapp-adobe-files.s3.amazonaws.com/${this.name}/index.html`;
    }

    getStyle() {

        return {
            width: this.width,
            height: this.height
        };
    }
}

export default AdobeIFrame;
