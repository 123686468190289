




import Component from 'vue-class-component';
import ExporoVue from '@/components/ExporoVue';
import {Prop} from 'vue-property-decorator';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import DateFormatHelper from '@/components/Helper/DateFormatHelper';
import Events from '@/events';

@Component
class UnreadUpdateDot extends ExporoVue {
  @Prop() private title!: string;
  @Prop() private date!: string;

  private isRead: boolean = false;
  private isUpdate: boolean = true;

  constructor() {
    super();
  }

  mounted() {
    this.isUpdate = 'Keine Updates' !== this.date;

    this.updateDot();

    Events.$on('updateDots', () => {
      this.updateDot();
      this.$forceUpdate();
    });
  }

  updateDot() {
    // @TODO by click on PageDetail.vue for the project title is a date saved in the localStorage
    const lastRead = LocalStorage.get('UPDATES_' + this.title, null);
    const dateY = +new Date(DateFormatHelper.stringToAmericanDate(this.date));

    this.isRead = this.isUpdate && dateY < +new Date('2020-09-13');

    if (lastRead && this.isUpdate) {
      const dateX = +new Date(DateFormatHelper.stringToAmericanDate(lastRead));

      this.isRead = dateX > dateY;
    }
  }
}

export default UnreadUpdateDot;
