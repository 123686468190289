

































































































































































































































































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {HOST, default as RestApiService} from '../../Rest/services/RestApiService';
import RequestCallback from '../../Rest/RequestCallback';
import RestError from '../../Rest/models/RestError';
import {AxiosResponse} from 'axios';
import LocalStorage from '../../LocalStorage/LocalStorage';
import ExporoVue from '../../ExporoVue';
import UserEditOptions from './models/UserEditOptions';
import UserRequestApi from '../../Rest/requests/UserRequestApi';
import Spinner from '../../ExporoSpinner/Spinner';
import {NOT_LOGGED_IN, VALIDATION_ERROR} from '../../Rest/models/Errors';
import Select from '@/components/Select/Select.vue';
import Input from '@/components/Input/Input.vue';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import ShowError from '../../ErrorForm/ShowError.vue';

import * as CheckoutWphGLite from './../../Checkout/CheckoutWpHGLite.vue';
import * as CheckoutVermAnlG from './../../Checkout/CheckoutVermAnlG.vue';
import UserTooltip from '@/components/User/Edit/UserTooltip.vue';
import ToastService from '@/components/Toast/ToastService';
import Toast from '@/components/Toast/Toast';
import OnSiteTracking from '@/components/GoogleTagManager/interfaces/OnSiteTracking';
import Tracking from '@/components/GoogleTagManager/services/Tracking';
import ExporoTokenService from '@/components/JWT/services/ExporoTokenService';
import FormData from '@/components/User/Edit/services/FormData.ts';
import DateDropDown from '@/components/DropDown/DateDropDown.vue';
import DropDown from '@/components/DropDown/DropDown.vue';
import KeyValue from '@/components/DropDown/KeyValue';
import ExporoAlert from '@/components/Alert/ExporoAlert.vue';

const spinner = Spinner.getInstance();

@Component({
    components: {
        Select,
        Checkbox,
        Input,
        ShowError,
        UserTooltip,
        DateDropDown,
        DropDown,
        ExporoAlert
    }
})
export default class UserEdit extends ExporoVue implements RequestCallback, OnSiteTracking {

    @Prop({default: false})
    private showToasts!: boolean;
    @Prop({default: false})
    private notModal!: boolean;

    private formData: FormData | null = null;

    private restApiService: RestApiService;
    private userEditData: object | null = null;
    private options: UserEditOptions;
    private currentTab: number = 1;
    private validationRules: string = ExporoVue.USER_VALIDATION;
    private userRestApi: UserRequestApi;
    private error: any = null;
    private isSuccess: boolean = false;
    private isInsideCockpit: boolean = false;
    private priorityCountries: object = {
        'DE': this.trans.get('userEdit.countries.DE'),
        'AT': this.trans.get('userEdit.countries.AT'),
        'NL': this.trans.get('userEdit.countries.NL'),
        'CH': this.trans.get('userEdit.countries.CH'),
        'FR': this.trans.get('userEdit.countries.FR'),
        'HU': this.trans.get('userEdit.countries.HU'),
        'IE': this.trans.get('userEdit.countries.IE'),
        'LU': this.trans.get('userEdit.countries.LU'),
        '': '----'
    };
    private offsetTaxId: number;
    private pagePadding: number;

    private titleDropdownValues: KeyValue[] = [];
    private martialStatusDropdownValues: KeyValue[] = [];
    private legitimationDocumentDropdownValues: KeyValue[] = [];
    private countriesDropdownValues: KeyValue[] = [];
    private initialTaxId: any = null;
    private isBirthNameEmpty: boolean = true;

    constructor() {
        super();
        this.restApiService = new RestApiService(this);
        this.options = new UserEditOptions();
        this.userRestApi = this.restApi.create(new UserRequestApi());
        this.offsetTaxId = !this.isMobile && !this.notModal ? 60 : 0;
        this.pagePadding = !this.notModal ? 14 : 10;
    }

    mounted() {
        if (this.$parent && this.$parent.$el.className.indexOf('cockpit-page') > -1) {
            this.isInsideCockpit = true;
        }

        spinner.show();
        Tracking.trackOpen('user edit form', false, undefined);

        const from = LocalStorage.get(ExporoVue.MODAL_FROM_KEY);

        if (from && from === CheckoutVermAnlG.CHECKOUT_MODAL_NAME) {
            this.validationRules = 'CROWDFUNDING';
        } else if (from && from === CheckoutWphGLite.CHECKOUT_MODAL_NAME && LocalStorage.getBoolean(ExporoVue.REQUIRED_USER_EDIT_FORM_FIELDS_STO, false)) {
            this.validationRules = 'BOND_STO';
        } else if (from && from === CheckoutWphGLite.CHECKOUT_MODAL_NAME) {
            this.validationRules = 'BOND';
        } else {
            this.validationRules = '';
        }

        LocalStorage.remove(ExporoVue.MODAL_FROM_KEY);
        LocalStorage.remove(ExporoVue.REQUIRED_USER_EDIT_FORM_FIELDS_STO);
        const externalUserId = LocalStorage.get(ExporoVue.EXTERNAL_USER_COCKPIT_KEY, null);

        if (null !== externalUserId) {
            this.userRestApi.getUserEditById(this).addParam('id', externalUserId);
        } else {
            this.userRestApi.getUser(this);
        }
    }

    showError(error: any): void {
        const form: any = this.$refs.form;

        for (const requiredField in error.errorMessage) {
            if (error.errorMessage.hasOwnProperty(requiredField)) {
                const input = form.querySelector('input[name=' + requiredField + ']');

                if (input) {
                    input.className += ' error';

                    input.addEventListener('keyup', () => {
                        if (input.value) {
                            input.className = '';
                            this.error[requiredField] = null;
                            this.$forceUpdate();
                        }
                    });
                }
            }

        }
    }

    validateCountry(value: string): string {
        return null !== value && '' !== value ? value : this.cctld;
    }

    isSexChecked(value: string): boolean {

        return value === this.formData?.sex;
    }

    isPepChecked(value: number): boolean {
        const pep = this.formData?.pep;

        return null === pep ? false : value === Number(pep || 0);
    }

    updateUser() {
        if (this.validationRules) {
            this.formData?.setValidation(this.validationRules);
        }

        const externalUserId = LocalStorage.get(ExporoVue.EXTERNAL_USER_COCKPIT_KEY, null);
        if (null !== externalUserId && this.formData) {
            this.formData['id'] = externalUserId;
        }

        this.userRestApi.updateUser(this.formData?.getFormData(), this);
        Tracking.trackSubmit('user edit form', false);
    }

    onCloseModal() {
        LocalStorage.remove(ExporoVue.REDIRECT_TO_KEY);
        Tracking.trackClose('user edit form', false, undefined);
    }

    onFailure(error: RestError): void {
        spinner.hide();
        Tracking.trackError('user edit form', true, undefined);

        if (error.errorCode === NOT_LOGGED_IN) {
            this.getModalService().closeModal('user-edit');

            this.getModalService().openModal('login-modal', {
                redirectToModal: 'user-edit'
            });
        }

        if (error.errorCode === VALIDATION_ERROR) {
            this.error = error.errorMessage;
            this.showError(error);
        }
    }

    onTabClick(value: number, e: any) {
        this.currentTab = value;
        e.preventDefault();
    }

    onSuccess(response: AxiosResponse): void {
        const redirectToName = LocalStorage.get(ExporoVue.REDIRECT_TO_KEY, null);

        if ('post' === response.config.method && redirectToName) {
            this.getModalService().closeModal('user-edit');
            this.getModalService().openModal(redirectToName);
            LocalStorage.remove(ExporoVue.REDIRECT_TO_KEY);
        }

        if ('post' === response.config.method && ('reload' === response.data || `/user/${LocalStorage.get(ExporoVue.EXTERNAL_USER_COCKPIT_KEY, '-1')}` === response.data)) {
            if (this.showToasts) {
                const toastService = new ToastService();
                toastService.addToast(new Toast('Daten erfolgreich aktualisiert!', 5000, 1, true));
            }

            this.isSuccess = true;
            setTimeout(() => this.isSuccess = false, 4000);

            this.getModalService().closeModal('user-edit');
        } else {
            spinner.hide();
            this.userEditData = response.data;

            this.titleDropdownValues = this.convertObjectToArrayObject([response.data['titleDropdownValues']]);
            this.martialStatusDropdownValues = this.convertObjectToArrayObject([response.data['maritalStatusDropdownValues']]);
            this.legitimationDocumentDropdownValues = this.convertObjectToArrayObject([response.data['legitimationDocumentDropdownValues']]);
            this.countriesDropdownValues = this.convertObjectToArrayObject([this.priorityCountries, response.data['countries']]);

            const resD = response.data.user;

            this.initialTaxId = resD.tax_id;

            this.formData = new FormData({
                id: resD.id,
                sex: resD.sex,
                first_name: resD.first_name,
                last_name: resD.last_name,
                birth_name: resD.birth_name,
                pob: resD.pob,
                email: resD.email,
                phone: resD.phone,
                phone_mobile: resD.phone_mobile,
                pass: resD.pass,
                pass_authority: resD.pass_authority,
                street: resD.street,
                street_no: resD.street_no,
                street_additional: resD.street_additional,
                postal_code: resD.postal_code,
                city: resD.city,
                miles_id: resD.miles_id,
                tax_id: resD.tax_id,
                pep: resD.pep,
                partner_bank: resD.partner_bank,
                partner_bic: resD.partner_bic,
                partner_city: resD.partner_city,
                partner_company: resD.partner_company,
                partner_iban: resD.partner_iban,
                partner_name: resD.partner_name,
                partner_postal_code: resD.partner_postal_code,
                partner_street: resD.partner_street,
                partner_tax: resD.partner_tax,
                partner_tax_office: resD.partner_tax_office,
                partner_url: resD.partner_url,
                created_at: resD.created_at,
                affiliated_partner_id: resD.affiliated_partner_id,
                friend_id: resD.friend_id,
                consultant_id: resD.consultant_id,

                _not_us_taxed: resD.not_us_taxed,
                _title: resD.title ?? '',
                _marital_status: resD.marital_status,
                _country: resD.country,
                _citizenship: this.validateCountry(resD.citizenship),
                _legitimation_document: resD.legitimation_document ?? 'id_card',
                _pass_country_of_issue: this.validateCountry(resD.pass_country_of_issue),
                _country_of_birth: this.validateCountry(resD.country_of_birth),
                _dob: this.validateDate(resD.dob),
                _pass_created_at: this.validateDate(resD.pass_created_at),
                _pass_expires_at: this.validateDate(resD.pass_expires_at),
            });
            this.isBirthNameEmpty = !!this.formData.birth_name;
        }
    }

    validateDate(date: string | null): string {
        if (!date || date.charAt(0) === '-') {
            return '0000-00-00 00:00:00';
        }
        return date;
    }

    convertObjectToArrayObject(params: object[]): KeyValue[] {
        const keyValue: KeyValue[] = [];
        params.forEach((object) => {
            Object.entries(object).forEach((value: any[]) => {
                keyValue.push({
                    key: value[0],
                    value: value[1]
                });
            });
        });

        return keyValue;
    }

    getCheckoutType(): string {
        return '';
    }

    getViewType(): string {
        return 'user edit form';
    }
}
