class LocalStorage {
    public static add<T>(key: string, value: T) {
        localStorage.setItem(key, String(value));
    }

    public static addBoolean(key: string, value: boolean) {
        localStorage.setItem(key, String(value));
    }

    public static addArray(key: string, value: []) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public static get<T>(key: string, defaultValue?: T): T {
        if (!LocalStorage.has(key)) {

            return defaultValue as T;
        }

        return this.getTransformedItem(key, defaultValue) as T;
    }

    public static getBoolean(key: string, defaultValue: boolean = false): boolean {
        if (!LocalStorage.has(key)) {
            return defaultValue;
        }

        const item = localStorage.getItem(key);

        return 'true' === item || '1' === item;
    }

    public static getArray(key: string, defaultValue: [] = []): [] {
        if (!LocalStorage.has(key)) {

            return defaultValue;
        }

        const item = localStorage.getItem(key);

        return item ? JSON.parse(item) : [];
    }

    public static remove(key: string) {
        if (LocalStorage.has(key)) {
            localStorage.removeItem(key);
        }
    }

    public static has(key: string): boolean {

        return null !== localStorage.getItem(key);
    }

    private static getTransformedItem<T>(key: string, defaultValue: T) {
        let item: any = localStorage.getItem(key);

        if ('number' === typeof defaultValue) {
            item = Number(item);
        } else if ('string' === typeof defaultValue) {
            item = String(item);
        }

        return item;
    }
}

export default LocalStorage;
