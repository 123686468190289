class NumberHelper {
    static formatNumber(value, trailingDigits = 0) {
        if ('number' !== typeof value) {
            value = parseFloat(value);
        }
        if ('number' !== typeof trailingDigits) {
            trailingDigits = 0;
        }
        return value.toLocaleString('de-DE', {
            minimumFractionDigits: trailingDigits,
            maximumFractionDigits: trailingDigits,
        });
    }
}

export default NumberHelper;
