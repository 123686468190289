





















































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CockpitPanel from '@/components/Cockpit/Controls/CockpitPanel.vue';
import UserData from '../models/UserData';
import ListItem from '@/components/Cockpit/Controls/ListItem.vue';
import {HOST} from '@/components/Rest/services/RestApiService';
import Events from '@/events';
import ExporoVue from '@/components/ExporoVue';
import BaaderBankApiService from '@/components/Baader/services/BaaderBankApiService';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import axios from 'axios';
import {saveAs} from 'file-saver';
import {base64toBlob} from '@/components/Helper/Base64';

interface TaxReport {
    user_id: string;
    document_type: string;
    path: string;
    brand: string;
    document_id: string;
    security_name: string;
    file_name: string;
    investment_mode: InvestmentModes;
    date_of_creation: string;
    security_id: string;
}

enum InvestmentModes {
    Investmentplan = 'strategy-investmentplan',
    Selfmanged = 'strategy-selfmanaged',
    Default = 'default'
}

@Component({
    components: {
        CockpitPanel,
        ListItem,
    },
    filters: {
        readableFilename(url: string) {
            const indexFile = url.lastIndexOf('/');
            const indexExtension = url.lastIndexOf('.');
            const filename = url.substring(indexFile + 1, indexExtension);
            return filename;
        }
    }
})
class PageDocuments extends ExporoVue {

    @Prop() private userdata!: UserData;
    private documentsYearlyList: any[] = [];
    private userFileBasePath: string = HOST + '/api/intern/cockpit/files/download/';
    private baaderRequestApi: BaaderBankApiService;
    private baaderDocumentsList: any[] = [];
    private taxReports: TaxReport[] = [];

    constructor() {
        super();
        this.baaderRequestApi = new BaaderBankApiService();
    }

    async mounted() {
      const token = localStorage.getItem('authToken');
      if (token) {
          const taxUrl = 'https://api.pat.exporo.com/tax-reports';
          const taxReports: TaxReport[] = (await axios.get(taxUrl, {
              headers: {
                  'Authorization': 'Bearer ' + JSON.parse(token)?.idToken
              }
          })).data;

          this.taxReports = taxReports.filter((taxReport: TaxReport) => taxReport.investment_mode !== InvestmentModes.Investmentplan);
      }

      Events.$on('documentsYearlyLoaded', () => {

          if (0 === this.documentsYearlyList.length) {
              this.loadDocuments();
          }
      });

      this.loadDocuments();
    }

  isUpvestAndHasWallet(document: string): boolean {
      if (document.indexOf('Upvest_Kuendigung_Kryptoverwahrung') !== -1 && localStorage.getItem('hasWallet') === 'false') {
        return false;
      }

      return true;
  }

    async downloadPdf(fileName: string): Promise<void> {
      const token = localStorage.getItem('authToken');
      if (token) {
        const response = await axios.get('https://api.pat.exporo.com/tax-reports/' + encodeURIComponent(fileName), {
          headers: {
            'Authorization': 'Bearer ' +  JSON.parse(token)?.idToken
          }
        });

        saveAs(base64toBlob(response.data), `${response.headers['x-file-name']}.pdf`);
      }
    }

    loadDocuments() {
        const externalUserID = LocalStorage.get(ExporoVue.EXTERNAL_USER_COCKPIT_KEY, undefined);
        this.baaderRequestApi.getDocuments((data: any) => {
            if (data) {
                this.baaderDocumentsList = data.data;
            }
        }, externalUserID);

        for (const key of Object.keys(this.userdata.documentsYearly)) {
            this.userdata.documentsYearly[key].forEach((item) => {
                this.documentsYearlyList.push({
                    key: key,
                    value: item
                });
            });
        }

        this.documentsYearlyList.sort((a, b) => {
            const dateA = Number(a.key);
            const dateB = Number(b.key);

            if (dateA === dateB) {

                return 0;
            }

            return dateA < dateB ? 1 : -1;
        });
    }

}

export default PageDocuments;
