import {Route} from '@/components/Router/decorators/RouteDecorator';
import BaseRoute from '../components/Router/BaseRoute';

class CockpitRoutes extends BaseRoute {

    @Route('/')
    private cockpitClose;

    @Route('/cockpit/{type}')
    private cockpitRoute;

    @Route('/cockpit/{type}/detail/{id}')
    private cockpitDetailRoute;

    @Route('/cockpit/menu/{type}')
    private cockpitRouteMenu;

    @Route('/cockpit/{type}/detail/{id}/{slide}')
    private detailSlider;
}

export default CockpitRoutes;
