






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';

@Component({
    components: {}
})

class CustomerRatingFooter extends ExporoVue {
    @Prop() maxSlidesNumber!: number;
    @Prop() activeSlide!: number;
    @Prop() jump!: () => {};
    constructor() {
        super();
    }
}


export default CustomerRatingFooter;
