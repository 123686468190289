































































































    import {Component, Prop, Vue} from 'vue-property-decorator';
    import RestApiService, {HOST} from '../Rest/services/RestApiService';
    import {AxiosResponse} from 'axios';
    import Investor from './Investor/Investor.vue';
    import ExplorationVermAnlG from './Exploration/ExplorationVermAnlG.vue';
    import InvestmentVermAnlG from './Investment/InvestmentVermAnlG.vue';
    import SummaryVermAnlG from './Summary/SummaryVermAnlG.vue';
    import AuthService from '../Auth/services/AuthService';
    import LocalStorage from '../LocalStorage/LocalStorage';
    import RestError from '../Rest/models/RestError';
    import {MISSING_USER_DETAILS, NOT_LOGGED_IN} from '../Rest/models/Errors';
    import ExporoVue from '../ExporoVue';
    import InvestService from './services/InvestService';
    import Spinner from '../ExporoSpinner/Spinner';
    import VIB from './VIB/VIB.vue';
    import CheckoutDataVermAnlG from './models/CheckoutDataVermAnlG';
    import CheckoutRequestApi from '../Rest/requests/CheckoutRequestApi';
    import ExplorationService from './Exploration/services/ExplorationService';
    import ValidatorCallback from './Validator/ValidatorCallback';
    import CheckoutValidator, {MESSAGE_TYPE_SIMPLE} from './Validator/CheckoutValidator';
    import {Actions} from '@/decorators/ModalDecorators';
    import Events from '../../events';
    import InvestmentRequests from './models/InvestmentRequests';
    import StoreInvestmentRequestService from './services/StoreInvestmentRequestService';
    import Tracking from '@/components/GoogleTagManager/services/Tracking';
    import VerAnlGStoreSuccess from '@/components/Checkout/StoreSuccesView/VerAnlGStoreSuccess.vue';
    import URLHelper from '@/components/Helper/URLHelper';
    import Controller from '@/components/Router/Controller';
    import Router from '@/components/Router/Router';
    import {Action} from '@/components/Router/decorators/RouteDecorator';
	import OnSiteTracking from '@/components/GoogleTagManager/interfaces/OnSiteTracking';


    const spinner = Spinner.getInstance();

    export const CHECKOUT_MODAL_NAME = 'checkout-modal-vermanlg';

    @Component({
        components: {
            ExplorationVermAnlG,
            InvestmentVermAnlG,
            SummaryVermAnlG,
            VIB,
            Investor,
            VerAnlGStoreSuccess,
        },
    })
    class CheckoutVermAnlG extends Controller implements InvestmentRequests, ValidatorCallback, OnSiteTracking {
        static CHECKOUT_MODAL_NAME = CHECKOUT_MODAL_NAME;
        private restApiService: RestApiService;
        private step: number = 1;
        private maxSteps: number = 4;
        private checkoutData: CheckoutDataVermAnlG | null = null;
        private error: string | null = null;
        private authService: AuthService;
        private investService: InvestService;
        private backButtonText: string = this.trans.get('checkout.modal_controls.back');
        private nextButtonText: string = ExporoVue.CHECKOUT_NEXT_TEXT;
        private nextButtonClass: string = 'btn';
        private projectTitle: string = '';
        private projectId: string = '';
        storeInvestmentRequestService: StoreInvestmentRequestService;
        private hasInvestors: boolean = false;
        private checkoutRequestApi: CheckoutRequestApi;
        private explorationService!: ExplorationService;
        @Prop()
        private slug!: string;
        private successResponseData: any = null;
        private checkoutValidator: CheckoutValidator;
        private payload: any = null;
        private currentPayload: any = null;
        private shouldLockCheckoutRequest: boolean = false;
        private actualSum: string = '';
        private controls: any;

        constructor() {
            super('CheckoutVermAnlG');
            this.restApiService = new RestApiService(this);
            this.authService = new AuthService(this.restApiService);
            this.investService = new InvestService();
            this.checkoutRequestApi = this.restApi.create(new CheckoutRequestApi());
            this.checkoutValidator = new CheckoutValidator(this, MESSAGE_TYPE_SIMPLE);
            this.storeInvestmentRequestService = new StoreInvestmentRequestService();
        }

		getCheckoutType(): string {

			return 'crowd';
		}

		getViewType(): string {

			return 'checkout form';
		}

        getName() {

            return this.getClassName() || 'CheckoutVermAnlG';
        }

        nextCallback() {
            this.next();
            this.updateControls();
        }

        backCallback() {
            this.back();
            this.updateControls();
        }

        mounted() {
            if (this.authService.isLoggedIn()) {
                this.checkoutRequestApi.getCheckoutVermAnlG(this).addParam('slug', this.slug);
            } else {
                this.closeModal();
                LocalStorage.add(ExporoVue.REDIRECT_TO_KEY, CHECKOUT_MODAL_NAME);
                this.getModalService().openModal('login-modal');
            }

            Events.$on('checkout-ver-next', this.nextCallback);
            Events.$on('checkout-ver-back', this.backCallback);
        }

        updateControls() {
            Events.$emit('checkout-ver-', {
                step: this.step,
                nextButtonClass: this.nextButtonClass,
                nextButtonText: this.nextButtonText,
                shouldHide: null !== this.successResponseData,
            });
        }

        @Actions('checkout-ver-')
        getActions(): [object, object] {

            return [
                {
                    title: this.backButtonText,
                    function: 'back',
                    options: {
                        spanClass: 'left',
                    },
                },
                {
                    title: ExporoVue.CHECKOUT_NEXT_TEXT,
                    function: 'next',
                    options: {
                        spanClass: 'right',
                    },
                },
            ];
        }

        closeModal(): void {
            this.step = 1;
            this.getModalService().closeModal(CHECKOUT_MODAL_NAME);
        }

        onCloseModal() {
            Tracking.trackClose('checkout form', false, undefined, 'bond/mezz', this.step);

            Events.$off('checkout-ver-next', this.nextCallback);
            Events.$off('checkout-ver-back', this.backCallback);
            this.successResponseData = null;
        }

        next() {
            if (this.checkoutData && this.checkoutValidator.prevalidate(JSON.parse(this.checkoutData.validation), this.step )) {
                if (this.step < this.maxSteps) {
                    this.step++;
                    this.switchStep(this.step);
                    this.onStepSwitched();
                } else if (this.step === this.maxSteps) {
                    spinner.show();
                    this.investService.mergeFormData(this.explorationService.form);

                    this.payload = this.storeInvestmentRequestService.getPayload();

                    if ('exploration' && 'investsum' && 'i_have_read_all_docs' in this.payload) {

                        if (!this.shouldLockCheckoutRequest) {
                            Tracking.trackSubmit('checkout form', false, 'bond/mezz', this.step);
                            this.shouldLockCheckoutRequest = true;
                            this.checkoutRequestApi.storeCheckoutVermAnlG(this.payload, this);
                        }
                    }
                }
            }
        }

        back() {
            if (this.step > 1) {
                this.step--;
                this.onStepSwitched();
                Router.navigate('switchCheckoutStep', [this.step]);
            }
        }

        @Action('switchCheckoutStep', {'openCheckout': [{config: {always: true}}]})
        switchStepAction(step: string) {
            this.switchStepAndUpdate(Number(step));
        }

        switchStep(step: number) {
            Router.navigate('switchCheckoutStep', [step]);
        }

        switchStepAndUpdate(step: number) {
            this.step = step;
            this.onStepSwitched();
            this.updateControls();
            Tracking.trackOpen('checkout form', false, 'checkout step', 'bond/mezz', this.step);
            this.trackCheckoutStep();
        }

        onStepSwitched() {
            if (this.step >= this.maxSteps) {
                this.nextButtonText = 'Jetzt zahlungspflichtig investieren';
                this.nextButtonClass = 'btn green pull-right clearfix text-center finish';
            } else {
                this.nextButtonText = 'Weiter';
                this.nextButtonClass = 'btn';

            }

            this.$nextTick(() => {
                const stepdivider = document.querySelector('.steps h2.active');
                if (stepdivider) {
                    stepdivider.scrollIntoView({block: 'start', behavior: 'smooth'});
                }
            });
        }

        onFailure(error: RestError): void {
            spinner.hide(700);
            this.shouldLockCheckoutRequest = false;
            Tracking.trackError('checkout form', true, 'checkout step', 'bond/mezz', this.step);
            if (error.errorCode === MISSING_USER_DETAILS) {
                this.getModalService().openModal('user-edit', {
                    redirectToModal: CHECKOUT_MODAL_NAME,
                    from: CHECKOUT_MODAL_NAME,
                });
                this.closeModal();
            } else if (error.errorCode === NOT_LOGGED_IN) {
                this.getModalService().openModal('login-modal', {
                    redirectToModal: CHECKOUT_MODAL_NAME,
                });
                this.closeModal();
            } else {
                this.checkoutValidator.check(error.errorMessage);
            }

            if (404 === error.response.status) {
                this.error = error.errorMessage;
            }
        }

        trackOnInvest() {
            const actualSum = this.investService.getInvestSum().toString();
            this.currentPayload = this.storeInvestmentRequestService.getPayload();

            Tracking.trackPurchase(
                `crowd-${this.successResponseData.investmentId}`,
                '',
                actualSum,
                '0',
                '0',
                '',
                this.projectId,
                this.projectTitle,
                'bond/mezz',
                '',
                0,
                '');


          if ((window as any).abtasty) {
            (window as any).abtasty.send('transaction', {
              tid: `crowd-${this.successResponseData.investmentId}`
            });
          }

            if (this.checkoutData && this.checkoutData.isFirstInvestment) {
                const aid = LocalStorage.get(ExporoVue.AFFILIATE_ID_KEY, null);
                Tracking.trackFirstInvestment(aid);
            }
        }

        trackCheckoutStep() {
            const brand = 'Exporo ' + URLHelper.getCCTLD();
            this.currentPayload = this.storeInvestmentRequestService.getPayload();
            const actualSum = this.investService.getInvestSum().toString();

            Tracking.trackCheckoutSteps(
                this.step,
                'non-bond',
                this.projectId,
                this.projectTitle,
                brand,
                'bond/mezz',
                actualSum,
                '');
        }


        onSuccess(response: AxiosResponse): void {
            spinner.hide(700);

            if (response.config.url && response.config.url.indexOf('/invest/store') > 0) {

                const checkoutRoot = document.getElementsByClassName('checkout-root')[0];
                checkoutRoot.className += ' finish';

                this.successResponseData = response.data;
                this.trackOnInvest();

                this.shouldLockCheckoutRequest = false;
                this.updateControls();
            } else {
                this.$nextTick(() => {
                    this.checkoutData = response.data;
                    Tracking.trackOpen('checkout form', false, 'checkout step', 'bond/mezz', this.step);

                    if (this.checkoutData) {
                        this.projectTitle = this.checkoutData.investmentProduct.textTitle;
                        this.projectId = this.checkoutData.investmentProduct.nid.toString();
                        this.hasInvestors = this.checkoutData.user.investors.length > 0;

                        this.explorationService = new ExplorationService(this.checkoutData.explorationDefault);
                        this.investService.updateFomData('nid', this.checkoutData.investmentProduct.nid);
                        this.investService.updateFomData('slug', this.checkoutData.investmentProduct.slug);

                        this.trackCheckoutStep();
                    }

                    if (this.hasInvestors) {
                        this.maxSteps++;
                    }
                });
            }
        }

        onValidatorError(step: any): void {
            this.switchStep(step);
            Tracking.trackError('checkout form', true, 'checkout step', 'bond/mezz', step);
        }
    }

    export default CheckoutVermAnlG;
