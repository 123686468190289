













































    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import SecondaryMarketRequestApi from '@/components/Rest/requests/SecondaryMarketRequestApi';
    import RestApiService from '@/components/Rest/services/RestApiService';
    import RequestCallback from '@/components/Rest/RequestCallback';
    import ExporoVue from '@/components/ExporoVue';
    import {AxiosResponse} from 'axios';
    import TableData from '@/components/Cockpit/models/TableData';
    import InvestList from '@/components/Cockpit/InvestList/InvestList.vue';
    import TableDataService from '@/components/Cockpit/services/TableDataService';
    import RestError from '@/components/Rest/models/RestError';
    import CockpitSelect from '@/components/Cockpit/Controls/CockpitSelect.vue';
    import OptionEntry from '@/components/Cockpit/models/OptionEntry';
    import FilterTableDataHelper from '@/components/Helper/FilterTableDataHelper';
    import Events from '@/events';
    import ExporoModal from '@/components/Modal/ExporoModal.vue';
    import BuyTransaction from '@/components/SecondaryMarket/BuyTransaction.vue';
    import AuthService from '@/components/Auth/services/AuthService';
    import ModalOptions from '@/components/Modal/models/ModalOptions';
    import BuyModalContainer from '@/components/SecondaryMarket/BuyModalContainer.vue';
    import BuySuccess from '@/components/SecondaryMarket/BuySuccess.vue';
    import LogToSeeModalInnerContent from '@/components/SecondaryMarket/LoginToSeeModalContent.vue';
	import SecondaryMarketApiService from '@/components/SecondaryMarket/services/SecondaryMarketApiService';
	import LocalStorage from '@/components/LocalStorage/LocalStorage';
	import CockpitRequestApi from '@/components/Rest/requests/CockpitRequestApi';

    interface STOData {
      id: number;
      wallet: string;
      security_wallet: string;
      user_id: number;
      asset_id: string;
      initial_max_pieces: number;
      available: number;
      tradeing: number;
      develivered: number;
      unit_price: number;
      total: number;
      stage: string;
      created_at: string;
      updated_at: string;
    }

    @Component({
        components: {
            ExporoModal,
            CockpitSelect,
            InvestList,
            BuyTransaction,
            BuyModalContainer,
            BuySuccess,
            LogToSeeModalInnerContent
        }
    })
    class SecondaryMarket extends ExporoVue implements RequestCallback {

        private hasTradingAccountOrPendingCreation: boolean = false;
        private restApiService: RestApiService;
        private secondaryMarketRequestApi: SecondaryMarketRequestApi;
		private secondaryMarketApiService: SecondaryMarketApiService;
        private tableData!: TableData;
        private loaded: boolean = false;
        private dropdownOptions: any = [[]];
        private filteredTableData!: TableData;
        private filtered: boolean = false;
        private buyTransactionData: any = null;
        private authService: AuthService;
        private isLoggedIn: boolean = false;
        private ident: object = {};
        private isInvestableData: object = {};
        private buySuccess: boolean = false;
        private trades: any = null;
		private tradeData: STOData[] | null = null;
        private showTrades: boolean = true;
		private cockpitRequestApi: CockpitRequestApi;
		private allContracts: any = null;

        constructor() {
            super();
            this.restApiService = new RestApiService(this);
			this.cockpitRequestApi = this.restApi.create(new CockpitRequestApi());
			if (this.showTrades) {
				this.cockpitRequestApi.getContracts(this);
			}
            this.secondaryMarketRequestApi = this.restApi.create(new SecondaryMarketRequestApi());
            this.secondaryMarketApiService = new SecondaryMarketApiService();
            this.secondaryMarketRequestApi.getOffers(this);
            const api = new RestApiService();
            this.authService = new AuthService(api);

            Events.$on('filter-table-data', (project) => this.filterTableData(project));
            Events.$on('secondary-market-buy', (data) => {
                this.parseOfferTransactionData(data);
                this.getModalService().openModal('secondary-market-buy');
            });
            Events.$on('secondary-market-get-data', (data) => {
                this.updateData(data);
            });
            Events.$on('secondary-market-do-buy', () => {
                this.buySuccess = true;
            });
        }

        mounted() {
			if (this.showTrades) {
				this.secondaryMarketApiService.requestSecondaryMarket((response: AxiosResponse) => {
					this.tradeData = response.data;
					Events.$emit('tradedata-loaded');
				});
			}

            this.checkLoginState();
            this.openLogToSeeModal();
        }

        updateData(data) {
            this.ident = data.ident;
            this.hasTradingAccountOrPendingCreation = data.userData.hasTradingAccountOrPendingCreation;
            this.isInvestableData = data.isInvestableData;
            this.loaded = true;
            this.checkLoginState();
            this.$forceUpdate();
        }

        checkLoginState() {
            this.isLoggedIn = document.body.className.indexOf('authenticated') > -1;
        }

        openLogToSeeModal() {
            const btn = `<button class="btn" @click="">{{ trans.get('secondaryMarket.secondaryMarketVue.login_btn') }}</button>`;
            this.getModalService().openModal('login-to-see-secondarymarket', {}, true);
        }

        parseOfferTransactionData(data) {
            this.buyTransactionData = {
                'offerId': data.ID,
                'projectName': data.Projekt,
                'pieceValue': data['Verkaufspreis pro Stück'],
                'availableAssets': data['Angebotene Stücke'],
            };
        }

        filterTableData(project) {
            this.filteredTableData = this.tableData;
            if (project !== 'no-filter') {
                this.filteredTableData = FilterTableDataHelper.filterByColumn(this.filteredTableData, project);
            }
            this.filtered = true;
            this.$forceUpdate();
        }

        updateTableData() {
            if (this.tableData) {
                const data: any = [];
                this.tableData.data.forEach((row: any) => {
                    const offeredPieces = row.remaining_pieces;
                    const total = offeredPieces * row.sale_price_per_piece;
                    if (offeredPieces > 0) {
                        data.push({
                            'ID': row.id,
                            'Projekt': row.value,
                            'Verkaufsangebot': total + '€',
                            'Gesamtausschüttungsrendite': row.return_rate_overall + '%',
                            'Mietausschüttungsrendite': row.return_rate_miete + '%',
                            'Angebotene Stücke': offeredPieces,
                            'Nennbetrag': '1000€',
                            'Verkaufspreis pro Stück': row.sale_price_per_piece + '€'
                        });
                    }
                });

                TableDataService.add('secondary_market_offers', data);
                this.tableData = new TableData((data));
                this.tableData.hasLoaded = true;
            }
        }

        updateDropdownOptions() {
            const uniqueProjects: any = [];


            this.tableData.data.forEach((row: any) => {
                if (!uniqueProjects.includes(row.Projekt)) {
                    uniqueProjects.push(row.Projekt);
                    this.dropdownOptions[0].push(
                        new OptionEntry(
                            row.Projekt,
                            row.Projekt.replace(' ', '').toLowerCase(),
                            () => {
                                Events.$emit('filter-table-data', row.Projekt);
                            }
                        ),
                    );
                }
            });
            this.dropdownOptions[1] = [
                new OptionEntry(
                    'Alle Projekte',
                    'allprojects',
                    () => {
                        Events.$emit('filter-table-data', 'no-filter');
                    }
                )
            ];
        }

		mapTrades() {

			if (this.tradeData) {
				const values: any[] = [];
				this.tradeData.forEach((item: STOData) => {
					let matchingContract: any;

					matchingContract = this.allContracts.bond.find((contract) => {
						return contract.blockchain_asset_id === item.asset_id || contract.isin === item.asset_id;
					});

					if (matchingContract) {
						values.push({
							'ID': item.id,
							'Projekt': matchingContract.title,
							'Verkaufsangebot': item.total / 100 + '€',
							'Gesamtausschüttungsrendite': (matchingContract.return_rate_overall || matchingContract.return_on_equity_rate_overall) + '%',
							'Mietausschüttungsrendite': (matchingContract.return_rate_miete || matchingContract.return_on_equity_rate_miete) + '%',
							'Angebotene Stücke': item.available,
							'Nennbetrag': matchingContract.piece_value + '€',
							'Verkaufspreis pro Stück': item.unit_price / 100 + '€'
						});
					}
				});

				this.trades = new TableData((values));
			}
		}

        onSuccess(response: AxiosResponse, id): void {
            if (id === CockpitRequestApi.GET_CONTRACTS) {
				this.allContracts = response.data;

				if (null === this.tradeData) {
					Events.$on('tradedata-loaded', () => {
						this.mapTrades();
					});
				} else {
					this.mapTrades();
				}
			} else {
				this.tableData = new TableData(response.data.allOffers);
				this.updateTableData();
				this.updateDropdownOptions();
			}
        }

        onFailure(error: RestError) {
        }

    }


    export default SecondaryMarket;
