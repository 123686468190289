import RequestCallback from '../RequestCallback';
import RestApi from '@/components/Rest/RestApi';
import {Wallet} from '@/decorators/WalletDecorators';
import RequestApi from '@/components/Rest/models/RequestApi';

class WalletRequestApi extends RequestApi {

    @Wallet('getWallet')
    requestWallet(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }
}

export default WalletRequestApi;
