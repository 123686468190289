import {Contentful} from '@/decorators/ContenfulDecorators';
import RequestCallback from '../RequestCallback';
import RestApi from '@/components/Rest/RestApi';
import RequestApi from '@/components/Rest/models/RequestApi';

class ContentfulRequestApi extends RequestApi {

    @Contentful()
    getEntries(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Contentful()
    getUpdates(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Contentful()
    getKpiValues(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Contentful()
    getQuarterlySurplus(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

}

export default ContentfulRequestApi;
