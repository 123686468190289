import Pusher, {default as pusher} from 'pusher-js';
import RestApiService, {HOST} from '@/components/Rest/services/RestApiService';
import RequestCallback from '@/components/Rest/RequestCallback';
import RestError from '@/components/Rest/models/RestError';
import {AxiosResponse} from 'axios';
import ExporoConfig from '@/ExporoConfig';
import Logger from '@/components/Helper/LogHelper';
import ExporoVue from '@/components/ExporoVue';
import PubSubRequestApi from '@/components/Rest/requests/PubSubRequestApi';

export const TYPE_PROJECT = 'project';
export const TYPE_BOND = 'bond';

class PubSubService extends ExporoVue implements RequestCallback {

    static getInstance(callback?) {
        if (!PubSubService._instance) {
            PubSubService._instance = new PubSubService();
        }

        if (PubSubService._instance._data && callback) {
            const data = {...PubSubService._instance._data};
            callback(data);
        }

        if (callback) {
            PubSubService._instance.callbacks.push(callback);
        }

        return PubSubService._instance;
    }

    get data(): object | null {

        return this._data;
    }

    get projects(): object {

        return this._projects;
    }

    private static _instance: PubSubService;

    public callbacks: any = [];

    private client!: pusher.Pusher;
    private _projects: object = {};
    private _bonds: object = {};
    private _data: any | null = null;

    constructor() {
        super();
    }

    init() {
        this.client = new Pusher(ExporoConfig.PUSHER_KEY, {cluster: 'eu', encrypted: true}) || [];
        const pubSubRequestApi: PubSubRequestApi = this.restApi.create(new PubSubRequestApi());
        pubSubRequestApi.getInitialData(this);


        this.client.subscribe('projects');
        this.client.subscribe('home-facts');

        this.client.bind('new-investment', (data) => {
            this.updateProject(data);
        });

        this.client.bind('home-facts-updated', (data) => {
            this.updateHomeFacts(data);
        });
    }

    updateHomeFacts(data) {
        this.callbacks.forEach((callback) => {
            callback(data);
        });
    }

    updateProject(data: any) {

        if (data.hasOwnProperty('project_id')) {
            if (this._projects.hasOwnProperty(data.project_id)) {
                this._projects[data.project_id] = data;
            }
        } else if (data.hasOwnProperty('contract_id')) {
            if (this._bonds.hasOwnProperty(data.contract_id)) {
                this._bonds[data.contract_id] = data;
            }
        }

        this.callbacks.forEach((callback) => {
            callback();
        });
    }

    getProject(id, type: string = TYPE_PROJECT) {
        if (this.hasProject(id, type)) {

            return TYPE_PROJECT === type ? this._projects[id] : this._bonds[id];
        }

        return null;
    }

    hasProject(id, type: string) {

        if (TYPE_BOND === type) {

            return this._bonds.hasOwnProperty(id);
        }

        return this._projects.hasOwnProperty(id);
    }

    onFailure(error: RestError): void {
        Logger.tag('PubSub').log(error);
    }

    onSuccess(response: AxiosResponse): void {
        this._data = response.data;
        this._projects = response.data.projects;
        this._bonds = response.data.projects.bonds;

        this.callbacks.forEach((callback) => {
            const data = {...this._data};
            callback(data);
        });

    }

}

export default PubSubService;
