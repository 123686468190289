













import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {HOST} from '@/components/Rest/services/RestApiService';

@Component({})
class ListItem extends Vue {

    @Prop() private data!: any[];
    private groupExtended: boolean = false;
    private chevronToggle!: HTMLElement | null;
    private host = HOST + '/';

    constructor() {
        super();
    }

    toggleExtended() {
        this.chevronToggle = this.$refs.chevron as HTMLElement;

        if (this.chevronToggle.className.indexOf('chevron-open') === -1) {
            this.chevronToggle.className = this.chevronToggle.className + ' chevron-open';
        } else if (this.chevronToggle.className.indexOf('chevron-open') !== -1) {
            this.chevronToggle.className = this.chevronToggle.className.replace('chevron-open', '');
        }
        this.groupExtended = !this.groupExtended;
    }
}

export default ListItem;
