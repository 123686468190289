export default class ImplementationMissingError extends Error {
    constructor(...args) {
        super(...args);
        this.name = this.constructor.name;

        if ('function' === typeof Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(...args)).stack;
        }
    }
}
