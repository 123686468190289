import BaseRule from '../BaseRule';

export default class RequiredIfLtRule extends BaseRule {

    constructor(rulesString, input, name, type) {
        super(rulesString, input, name, type);

        this._valid = this.validate();
    }

    validate() {
        const inputValuePair = this._rulesString.split(':')[1].split(',');
        const toCheckInput = inputValuePair[0];
        const value = inputValuePair[1];

        const toCheckInputNode: any = document.getElementById(toCheckInput);

        // @TODO: register all inputs with validation key's instead of this
        if (toCheckInputNode) {

            if (+toCheckInputNode.value >= +value) {

                return true;
            } else {

                return this._input.checked;
            }
        }

        return true;
    }
}
