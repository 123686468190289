import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import RequestCallback from '@/components/Rest/RequestCallback';
import RestError from '@/components/Rest/models/RestError';
import {UNDEFINED_ERROR, VALIDATION_ERROR} from '@/components/Rest/models/Errors';
import {USER_EDIT_RESPONSE} from '@/components/Rest/mocks/UserEditMock';
import RestErrorInterface from '@/components/Rest/models/RestErrorInterface';
import ExporoConfig from '@/ExporoConfig';
import Middleware from '@/components/Rest/middleware/interfaces/Middleware';
import ActivationMiddleware from '@/components/Rest/middleware/ActivationMiddleware';
import Closure from '@/components/Rest/middleware/core/Closure';
import LoadingBarMiddleware from '@/components/Rest/middleware/LoadingBarMiddleware';
import CacheBag from '@/components/Rest/CacheBag';
import Events from '@/events';
import ExporoVue from '@/components/ExporoVue';

export const HOST = ExporoConfig.HOST;
export const BASE_TOKEN = ExporoConfig.BASE_TOKEN;
export const REDIRECT_AFTER_LOGIN_URL = ExporoConfig.REDIRECT_AFTER_LOGIN_URL;

class RestApiService {

    public axios: AxiosInstance;
    private requestCallback?: RequestCallback;
    private defaultConfig: object = {
        withCredentials: true,
        maxRedirects: 0,
        timeout: 360 * 1000,
    };

    private cacheBag?: CacheBag;

    private middlewares: Middleware[] = [
        new ActivationMiddleware(),
        new LoadingBarMiddleware(),
    ];

    constructor(requestCallback?: RequestCallback) {
        this.axios = axios;
        this.requestCallback = requestCallback;
    }

    public setRequestCallback(requestCallback: RequestCallback) {
        this.requestCallback = requestCallback;
    }

    public setCacheBag(cacheBag: CacheBag) {
        this.cacheBag = cacheBag;
    }

    /**
     * @deprecated since restApi.ts
     */
    public post(url: string, data?: any, id?: string) {

        this.callByMethodName('post', url, data, id);
    }

    /**
     * @deprecated since restApi.ts
     */
    public get(url: string, data?: any, id?: string) {

        this.callByMethodName('get', url, data, id);
    }

    public getCached(url: string, data?: any, id?: string) {

        if (this.cacheBag) {
            this.cacheBag.addResponse(url, null);
        }

        this.callByMethodName('getCached', url, data, id);
    }

    isRestError(error: RestErrorInterface | object): error is RestErrorInterface {
        return void(0) !== (error as RestErrorInterface).errorMsg;
    }

    private callByMethodName(methodName: string, url: string, data?: any, id?: string) {
        const config = this.createConfig(url, methodName, data);

        this.callMiddleware({config, id}, new Closure(() => {
            this.sendRequestWithConfig(config, 'getCached' === methodName, id);
        }));
    }

    private sendRequestWithConfig(config: AxiosRequestConfig, cached: boolean, id?: string) {
        Events.$emit(ExporoVue.REST_API_ON_REQUEST_EVENT, config.url);
        axios.request(config)
            .then((response: AxiosResponse) => {
                this.callMiddleware({response, id}, new Closure(() => {
                    if (this.requestCallback) {

                        if (this.cacheBag && cached) {
                            this.cacheBag.addResponse(config.url || '', response);

                            this.cacheBag.getListener(config.url || '').forEach((listener: any) => {
                                listener.callback(response);
                            });
                        } else {
                            this.requestCallback.onSuccess.bind(this.requestCallback, response, id)();
                            Events.$emit(ExporoVue.REST_API_ON_SUCCESS_EVENT, config.url);
                        }
                    }
                }), true);
            }).catch((error: any) => {
            let restError: RestError = new RestError({
                errorCode: UNDEFINED_ERROR,
                errorMsg: error.response ? error.response.data : error
            }, error.response);

            if (error.response && 422 === error.response.status && this.isRestError(error.response.data)) {
                restError = new RestError(error.response.data, error.response);
            } else if (error.response && 422 === error.response.status) {
                restError =  new RestError({
                    errorCode: VALIDATION_ERROR,
                    errorMsg: error.response.data
                }, error.response);
            }

            if (this.cacheBag && cached) {
                this.cacheBag.getListener(config.url || '').forEach((listener: any) => {
                    listener.callback(restError);
                });
            }

            Events.$emit(ExporoVue.REST_API_ON_FAILURE_EVENT, config.url, restError);
            this.callFailureCallback(restError, id);
        });
    }

    private callFailureCallback(error: RestError, id?: string) {
        const response = error.response;
        this.callMiddleware({response, id, error}, new Closure(() => {
            if (this.requestCallback) {
                this.requestCallback.onFailure(error, id);
            }
        }), true);
    }

    callMiddleware(data: any, closure: Closure, after: boolean = false) {
        const middlewareLength = this.middlewares.length;
        if (middlewareLength > 0) {
            closure.setMiddlewareLength(this.middlewares.length);
            this.middlewares.forEach((middleware: Middleware) => {
                if (after) {
                    middleware.after(data, closure);
                } else {
                    middleware.before(data, closure);
                }
            });
        } else {
            closure.call(data);
        }
    }

    private createConfig(url: string, methodName: string, data: any) {

    	// @TODO HACK!
    	if (url === 'https://backend.exporo.de/pubsub/initial') {
    		url = 'https://backend-cdn.exporo.de/pubsub/initial';
		}

        let config: AxiosRequestConfig = {
            url,
            method: methodName === 'getCached' ? 'get' : methodName,
            headers: {
                'x-api-access-key': BASE_TOKEN,
                'x-requested-with': 'XMLHttpRequest',
                'content-type': 'post' === methodName ?
                    'application/json;charset=UTF-8' :
                    'application/x-www-form-urlencoded'
            },
        };

        config = {...config, ...this.defaultConfig} as AxiosRequestConfig;

        if (data) {
            if (data.config) {
                config = {...config, ...data.config} as AxiosRequestConfig;
                delete data.config;
            }
            config.data = data;
        }
        return config;
    }
}

export default RestApiService;
