

















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import Events from '@/events';
import UserData from './models/UserData';
import PageEntry from '@/components/Cockpit/models/PageEntry';
import Logger from '@/components/Helper/LogHelper';
import RestApi from '../Rest/RestApi';

import PageFinancingLanding from '@/components/Cockpit/pages/PageFinancingLanding.vue';
import PagePortfolioLanding from '@/components/Cockpit/pages/PagePortfolioLanding.vue';
import PageFinancing from '@/components/Cockpit/pages/PageFinancing.vue';
import PageYield from '@/components/Cockpit/pages/PageYield.vue';
import PageInvestplan from '@/components/Cockpit/pages/PageInvestplan.vue';
import PageDetail from '@/components/Cockpit/pages/PageDetail.vue';
import PageDocuments from '@/components/Cockpit/pages/PageDocuments.vue';
import PageDisbursed from '@/components/Cockpit/pages/PageDisbursed.vue';
import PageInvestors from '@/components/Cockpit/pages/PageInvestors.vue';
import PageUserEdit from '@/components/Cockpit/pages/PageUserEdit.vue';
import PageLoading from '@/components/Cockpit/pages/PageLoading.vue';
import PagePasswordChange from '@/components/Cockpit/pages/PagePasswordChange.vue';
import PageContactCenter from '@/components/Cockpit/pages/PageContactCenter.vue';
import PageRecruitFriends from '@/components/Cockpit/pages/PageRecruitFriends.vue';
import SecondaryMarket from '@/components/SecondaryMarket/SecondaryMarket.vue';
import PageData from '@/components/Cockpit/models/PageData';
import PageExporoAccount from '@/components/Cockpit/pages/PageExporoAccount.vue';
import PageWallet from '@/components/Cockpit/pages/PageWallet.vue';


const LOGGER_TAG: string = 'Router';

@Component({
    components: {
        PageFinancingLanding,
        PagePortfolioLanding,
        PageFinancing,
        PageYield,
        PageInvestplan,
        PageDetail,
        PageDocuments,
        PageDisbursed,
        PageInvestors,
        PageUserEdit,
        PageLoading,
        PagePasswordChange,
        PageContactCenter,
        PageRecruitFriends,
        SecondaryMarket,
        PageExporoAccount,
        PageWallet
    }
})

class ComponentRouter extends ExporoVue {

    public currentSite: PageEntry;
    @Prop() private startSite!: string;
    @Prop() private userdata!: UserData;
    @Prop() private userRestApi!: RestApi;
    private lastSite: PageEntry; // @TODO: ideally should be replaced w browser history stack (window history api)
    readonly pageData: PageData;
    private currentDetailPage: PageEntry | null = null;
    private lastScrollTop: number = -1;

    constructor() {
        super();
        this.pageData = new PageData();
        if (this.startSite) {
            this.currentSite = new PageEntry(this.startSite);
        } else {
            this.currentSite = new PageEntry('Yield');
        }

        this.lastSite = new PageEntry(this.currentSite.file);
    }

    mounted() {
        const cockpitRoot = document.getElementsByClassName('exporo-cockpit-root')[0];

        Events.$emit('componentRouterHasMounted');
        Events.$on('router-go', (payload) => {

            this.currentDetailPage = null;

            if (cockpitRoot && this.lastScrollTop > -1) {
                this.$nextTick(() => {
                    cockpitRoot.scrollTop = this.lastScrollTop;
                    this.lastScrollTop = -1;
                });
            }

            if (payload.url) {
                window.open(payload.url, '_blank');
            } else if (!payload.page) {
                Logger.tag(LOGGER_TAG).log('No page property stated in event:', payload);
            } else {
                if (payload.data) {
                    this.pageData.setData(payload.data.data, this.userdata.contracts);
                }
                const page = new PageEntry(payload.page, this.pageData, payload.title);
                if ('Detail' !== payload.page) {
                    this.changePage(page);
                } else {
                    if (cockpitRoot) {
                        this.lastScrollTop = cockpitRoot.scrollTop;
                        cockpitRoot.scrollTop = 0;
                    }

                    this.currentDetailPage = new PageEntry(payload.page, this.pageData, payload.title);

                    if (payload.callback) {
                        setTimeout(() => {
                            payload.callback();
                        }, 100);
                    }
                }
            }
        });

        Events.$on('router-back', () => {
            this.currentDetailPage = null;
        });
    }

    changePage(page: PageEntry) {
        if (null === page || page.file === this.currentSite.file) {
            return;
        }

        this.lastSite = this.currentSite;
        this.currentSite = page;

        Events.$emit('router-went', {page});
    }

}

export default ComponentRouter;

