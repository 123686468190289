class PrintHelper {

    static print(divToPrint, stylesArray: string[] = []) {
        const newWin = window.open('');
        let styleString = `<style>`;

        stylesArray.forEach( (style) => styleString += `${style}`);
        styleString += `</style>`;
        newWin?.document.write('<!DOCTYPE html>', divToPrint.outerHTML, styleString);
        newWin?.document.close();
        newWin?.focus();
        newWin?.print();
        newWin?.close();
    }
}

export default PrintHelper;
