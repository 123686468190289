import Investment from '@/components/Cockpit/Financing/interfaces/investment';

export default class FinancingService {
    calcSumOfFundedProjects(dataInvestment: Investment[]): number {
        return dataInvestment.reduce((acc, investment: Investment) => {
            if (investment.isRunning()) {
                return acc + investment.getInvestSum();
            } else {
                return acc;
            }
        }, 0);
    }

    calcAverageInvestment(dataInvestment: Investment[]): number {
        const averageInterest = dataInvestment.reduce((acc, investment: Investment) => {
            if (investment.isRunning()) {
                return {
                    sum: (acc.sum + investment.getInterest()),
                    count: (acc.count + 1),
                };
            } else {
                return acc;
            }
        }, {sum: 0, count: 0});

        return averageInterest.sum / averageInterest.count;
    }

    formatNumber(value, trailingDigits = 0) {
        if ('number' !== typeof value) {
            value = parseFloat(value);
        }
        if ('number' !== typeof trailingDigits) {
            trailingDigits = 0;
        }
        return value.toLocaleString('de-DE', {
            minimumFractionDigits: trailingDigits,
            maximumFractionDigits: trailingDigits,
        });
    }

    readableFilename(url: string) {
        const indexFile = url.lastIndexOf('/');
        const indexExtension = url.lastIndexOf('.');
        const filename = url.substring(indexFile + 1, indexExtension);
        return filename;
    }

    getFiles(project: any): string | void {
        if (project.documents) {
            return project.documents.split(',').map((filePath) =>
                ({uuid: 'filesString', path: filePath}),
            );
        }
    }

    getPaidAt(project: any): string {
        return project.paid_at ? project.paid_at : project.expected_at;
    }
}
