




import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';


@Component
class ExplorationHint extends ExporoVue {

    static STATE_0 = '';
    static STATE_1 = '';
    static STATE_2 = '';

    @Prop()
    private state: number = 0;

    @Prop()
    private className!: string;

    constructor() {
        super();
        ExplorationHint.STATE_0 = this.trans.get('checkout.WpHGLite.explorationWpHGLite.exploration_hint.state_0'),
            ExplorationHint.STATE_1 = this.trans.get('checkout.WpHGLite.explorationWpHGLite.exploration_hint.state_1');
    }

    getHTMLFromState(): string {
        switch (this.state) {
            case 0:
                return ExplorationHint.STATE_0;
            case 1:
                return ExplorationHint.STATE_1;
            case 2:
                return ExplorationHint.STATE_2;
        }

        return ExplorationHint.STATE_0;
    }
}

export default ExplorationHint;
