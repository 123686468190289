import BaseRoute from '../components/Router/BaseRoute';
import {Route} from '@/components/Router/decorators/RouteDecorator';

class CheckoutRoutes extends BaseRoute {

    @Route('/checkout')
    private openCheckout;

    @Route('/checkout/step/{step}')
    private switchCheckoutStep;
}

export default CheckoutRoutes;
