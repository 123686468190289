import ExporoTokenService from '../../JWT/services/ExporoTokenService';
import RequestCallback from '../../Rest/RequestCallback';
import RestError from '../../Rest/models/RestError';
import {AxiosResponse} from 'axios';
import BaaderRequestApi from '../../Rest/requests/BaaderRequestApi';
import ExporoVue from '../../ExporoVue';
import ExporoConfig from '../../../ExporoConfig';

const EXPORO_MOCK_TOKEN_BAADER = ExporoConfig.EXPORO_MOCK_TOKEN_BAADER;
class BaaderBankApiService extends ExporoVue implements RequestCallback {

    private baaderRequestApi!: BaaderRequestApi;
    private callback!: any;

    constructor() {
        super();
        this.baaderRequestApi = this.restApi.create(new BaaderRequestApi());
    }

    getWdp(callback: (data: any) => void, externalUserId?: string) {
        this.callback = callback;
        const tokenService = new ExporoTokenService();
        if (!EXPORO_MOCK_TOKEN_BAADER) {

            tokenService.fetchToken((token: string) => {

                this.baaderRequestApi.requestBaader({token: token, callback: callback}, this);
            }, externalUserId);
        } else {
            this.baaderRequestApi.requestBaader({token: EXPORO_MOCK_TOKEN_BAADER, callback: callback}, this);
        }
    }

    getDocuments(callback: (data: any) => void, externalUserId?: string) {
        this.callback = callback;
        const tokenService = new ExporoTokenService();
        if (!EXPORO_MOCK_TOKEN_BAADER) {

            tokenService.fetchToken((token: string) => {

                this.baaderRequestApi.requestDocumentsBaader({token: token, callback: callback}, this);
            }, externalUserId);
        } else {
            this.baaderRequestApi.requestDocumentsBaader({token: EXPORO_MOCK_TOKEN_BAADER, callback: callback}, this);
        }
    }

    onFailure(error: RestError, id?: string): void {
        this.callback();
    }

    onSuccess(response: AxiosResponse<any>, id?: string): void {
        (response as any).callback(response.data);
    }

}

export default BaaderBankApiService;
