const montsInMills: number = 2592000000;

class CookieHelper {


    static setCookie(name: string, val: string, validFor: number = montsInMills) {
        const date = new Date();
        const value = val;

        date.setTime(date.getTime() + validFor);

        let domain = window.location.hostname;

        if ('localhost' !== domain) {
            domain = '.' + domain;
        }

        document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + ';domain=' + domain + '; path=/';
    }

    static getCookie(name: string): string | null {
        const nameLenPlus = (name.length + 1);

        return document.cookie
            .split(';')
            .map((c) => c.trim())
            .filter((cookie: any) => {
                return cookie.substring(0, nameLenPlus) === `${name}=`;
            })
            .map((cookie: any) => {
                return decodeURIComponent(cookie.substring(nameLenPlus));
            })[0] || null;
    }
}

export default CookieHelper;
