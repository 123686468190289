




import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ContactCenter from '@/components/Cockpit/ContactCenter/ContactCenter.vue';



@Component({
    components: {
        ContactCenter
    }
})
class PageContactCenter extends Vue {

    constructor() {
        super();
    }
}

export default PageContactCenter;
