import BaseRoute from '../components/Router/BaseRoute';
import {Route} from '@/components/Router/decorators/RouteDecorator';

class AuthRoutes extends BaseRoute {

    @Route('/auth/{auth}')
    private openAuth;

    @Route('/activation/{token}')
    private openActivation;
}

export default AuthRoutes;

