











import {Component, Prop} from 'vue-property-decorator';
import TableData from '../models/TableData';
import ExporoVue from '@/components/ExporoVue';
import Investment from '@/components/Cockpit/Financing/interfaces/investment';



@Component({})
class FinancingListFilter extends ExporoVue {
    @Prop() private headline!: string;
    @Prop() private filterStatus!: any[];
    @Prop() private selected!: string;

    private selectedValue: string;

    constructor() {
        super();
        if (typeof this.selected === 'undefined') {
            this.selectedValue = this.filterStatus[0].key;
        } else {
            this.selectedValue = this.selected;
        }
    }

    setSelectedValue(option): void {
        this.selectedValue = option.key;
        option.handler();
    }

    isSelected(key): boolean {
        return key === this.selectedValue;
    }
}

export default FinancingListFilter;
