class CockpitRouter {
    private static _instance: CockpitRouter | null = null;

    private _routes: any[];
    private _mode: string | null;
    private _root: string;
    private _interval;
    private _handler = {};
    private _initialized: boolean = false;
    private _currentRoute: string | null = null;
    private _subRoute: string | null = null;

    constructor(mode?: string) {
        this._routes = [];
        this._mode = mode || 'hash';
        this._root = '/';
    }

    static config(options: any) {
        const instance: CockpitRouter = CockpitRouter.getInstance();

        instance._mode = options && options.mode && options.mode === 'history' && !!(history.pushState) ? 'history' : 'hash';
        instance._root = options && options.root ? '/' + instance.clearSlashes(options.root) + '/' : '/';
        return this;
    }

    getFragment() {
        let fragment = '';
        if ('history' === this._mode) {
            fragment = this.clearSlashes(decodeURI(location.pathname + location.search));
            fragment = fragment.replace(/\?(.*)$/, '');
            fragment = this._root !== '/' ? fragment.replace(this._root, '') : fragment;
        } else {
            const uri = decodeURI(window.location.href);
            const match = uri.match(/#(.*)$/);
            fragment = match ? match[1] : '';
        }
        return this.clearSlashes(fragment);
    }


    clearSlashes(path) {
        return path.toString().replace(/\/$/, '').replace(/^\//, '');
    }

    remove(param) {
        for (const [i, route] of this._routes.entries()) {
            if (route.handler === param || route.re.toString() === param.toString()) {
                this._routes.splice(i, 1);
                return this;
            }
        }
        return this;
    }

    flush() {
        this._routes = [];
        this._mode = null;
        this._root = '/';

        return this;
    }

    check(f) {
        const fragment = f || this.getFragment();

        for (const route of this._routes) {
            const match = fragment.match(route.re);
            if (match) {
                match.shift();
                route.handler.apply({}, match);
                return this;
            }
        }
        return this;
    }

    listen() {
        let current = this.getFragment();
        const fn = () => {
            if (current !== this.getFragment()) {
                current = this.getFragment();
                this.check(current);
            }
        };

        if (this._interval) {
            clearInterval(this._interval);
        }

        this._interval = setInterval(fn, 50);

        if (false === this._initialized) {
            this.check(current);
            this._initialized = true;
        }

        return this;
    }

    static getHandler(path) {
        const instance: CockpitRouter = CockpitRouter.getInstance();

        return instance._handler[path];
    }

    static getInstance(mode?: string) {
        if (!CockpitRouter._instance) {
            CockpitRouter._instance = new CockpitRouter(mode);
        }

        return CockpitRouter._instance;
    }

    static add(re?: any, handler?: any): CockpitRouter {
        const instance: CockpitRouter = CockpitRouter.getInstance();

        if ('function' === typeof re) {
            handler = re;
            re = '';
        }

        instance._routes.push({ re: re, handler: handler});

        return instance;
    }

    static navigate(path?, handler?, shouldResetSub: boolean = true) {

        return false;

        const instance: CockpitRouter = CockpitRouter.getInstance();

        instance._handler[`/${path}`] = handler;
        instance._currentRoute = path;

        path = path ? path : '';

        if ('history' === instance._mode) {
            history.pushState(null, '', instance._root + instance.clearSlashes(path));
        } else {
            window.location.href = window.location.href.replace(/#(.*)$/, '') + '#' + path;
        }

        if (shouldResetSub) {
            instance._subRoute = null;
        }

        return this;
    }

    static getCurrentRoute(defaultRoute?: string) {
        const instance: CockpitRouter = CockpitRouter.getInstance();

        return instance._currentRoute ? instance._currentRoute : (defaultRoute || null);
    }

    static getCurrentRouteWithSub(route: string) {
        const instance: CockpitRouter = CockpitRouter.getInstance();

        const currentRoute = (instance._currentRoute || '');
        let newRoute = currentRoute;

        if (instance._subRoute) {
            newRoute = currentRoute.replace(instance._subRoute, route);
        } else {
            newRoute = `${currentRoute}/${route}`;
        }

        instance._subRoute = route;

        return newRoute;
    }
}

export default CockpitRouter;
