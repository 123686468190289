import {RULE_TYPE} from './Constants';

import RequiredRule from './Rules/RequiredRule';
import AcceptedRule from './Rules/AcceptedRule';
import AcceptedWithRule from './Rules/AcceptedWithRule';
import NotInRule from './Rules/NotInRule';
import RequiredIfRule from './Rules/RequiredIfRule';
import InvestsumAmountRule from './Rules/InvestsumAmountRule';
import RequiredIfLtRule from '@/components/Checkout/Validator/Rules/RequiredIfLtRule';
import RequiredIfGtRule from '@/components/Checkout/Validator/Rules/RequiredIfGtRule';
import NotEqRequiredIfEq from '@/components/Checkout/Validator/Rules/NotEqRequiredIfEq';
import ArrayHelper from '@/components/Helper/ArrayHelper';
import MinRule from '@/components/Checkout/Validator/Rules/MinRule';
import MaxRule from '@/components/Checkout/Validator/Rules/MaxRule';
import {MESSAGE_TYPE_EXTENDET} from '@/components/Checkout/Validator/CheckoutValidator';
import AcceptedIfRule from '@/components/Checkout/Validator/Rules/AcceptedIfRule';

class RuleHelper {

    private _types: string[];
    private _input = null;
    private _rulesString;
    private _errors: object[];
    private _errorTypes: string[];
    private _name;

    constructor(ruleString, name) {
        this._types = [];
        this._rulesString = ruleString;
        this._errors = [];
        this._name = name;
        this._errorTypes = [];

        this.parseRuleString();
    }

    conditionsAreComplied() {
        const typedRules = this.getTypedRules();

        typedRules.forEach((rule: any) => {

            if (!rule.valid) {
                this._errors.push(rule.error);
                this._errorTypes.push(rule.type);
            }
        });

        return this._errors.length <= 0;
    }

    getMessageKey(messageType: number): string {

        let messageKey: string | null = null;

        this._errorTypes.forEach((errorType: string) => {

            if (!messageKey) {

                messageKey = this._name;

                if (MESSAGE_TYPE_EXTENDET === messageType) {
                    messageKey += `.${errorType.toLowerCase()}`;
                }
            }
        });

        return messageKey || '';
    }

    set input(input) {
        this._input = input;
    }

    parseRuleString() {
        const rulesByPipe = this._rulesString.split('|');
        const rulesByColon = this._rulesString.split(':');

        if (ArrayHelper.isStringPartExist(rulesByColon, 'accepted_if'))
            this._types.push(RULE_TYPE.ACCEPTED_IF);

        if (ArrayHelper.isStringPartExist(rulesByPipe, 'accepted_with'))
            this._types.push(RULE_TYPE.ACCEPTED_WITH);

        if (ArrayHelper.isStringPartExist(rulesByPipe, 'accepted')) {
            if (this._types.indexOf('ACCEPTED_IF') === -1 && this._types.indexOf(RULE_TYPE.ACCEPTED_WITH) === -1) {
                this._types.push(RULE_TYPE.ACCEPTED);
            }
        }

        if (ArrayHelper.isStringPartExist(rulesByPipe, 'min'))
            this._types.push(RULE_TYPE.MIN);

        if (ArrayHelper.isStringPartExist(rulesByPipe, 'max'))
            this._types.push(RULE_TYPE.MAX);

        if (ArrayHelper.isStringPartExist(rulesByColon, 'not_eq_required_if_eq'))
            this._types.push(RULE_TYPE.NOT_EQ_REQUIRED_IF_EQ);

        if (ArrayHelper.isStringPartExist(rulesByColon, 'required_if_lt'))
            this._types.push(RULE_TYPE.REQUIRED_IF_LT);

        if (ArrayHelper.isStringPartExist(rulesByColon, 'required_if_gt'))
            this._types.push(RULE_TYPE.REQUIRED_IF_GT);

        if (ArrayHelper.isStringPartExist(rulesByColon, 'required_if'))
            this._types.push(RULE_TYPE.REQUIRED_IF);

        if (ArrayHelper.isStringPartExist(rulesByPipe, 'required')) {
            if (this._types.indexOf('REQUIRED_IF') === -1) {
                this._types.push(RULE_TYPE.REQUIRED);
            }
        }

        if (ArrayHelper.isStringPartExist(rulesByColon, 'not_in:0;0'))
            this._types.push(RULE_TYPE.NOT_IN);

        if (ArrayHelper.isStringPartExist(rulesByPipe, 'investsum_amount')) {
            this._types.push(RULE_TYPE.INVESTSUM_AMOUNT);

            this._types = ArrayHelper.remove(this._types, RULE_TYPE.MAX);
        }
    }

    getTypedRules() {

        const typedRules: object[] = [];

        this._types.forEach((type: string) => {

            if (type === RULE_TYPE.REQUIRED)
                typedRules.push(new RequiredRule(this._rulesString, this._input, this._name, type));

            if (type === RULE_TYPE.ACCEPTED)
                typedRules.push(new AcceptedRule(this._rulesString, this._input, this._name, type));

            if (type === RULE_TYPE.ACCEPTED_WITH)
                typedRules.push(new AcceptedWithRule(this._rulesString, this._input, this._name, type));

            if (type === RULE_TYPE.NOT_IN)
                typedRules.push(new NotInRule(this._rulesString, this._input, this._name, type));

            if (type === RULE_TYPE.REQUIRED_IF)
                typedRules.push(new RequiredIfRule(this._rulesString, this._input, this._name, type));

            if (type === RULE_TYPE.ACCEPTED_IF)
                typedRules.push(new AcceptedIfRule(this._rulesString, this._input, this._name, type));

            if (type === RULE_TYPE.REQUIRED_IF_LT)
                typedRules.push(new RequiredIfLtRule(this._rulesString, this._input, this._name, type));

            if (type === RULE_TYPE.REQUIRED_IF_GT)
                typedRules.push(new RequiredIfGtRule(this._rulesString, this._input, this._name, type));

            if (type === RULE_TYPE.MIN)
                typedRules.push(new MinRule(this._rulesString, this._input, this._name, type));

            if (type === RULE_TYPE.MAX)
                typedRules.push(new MaxRule(this._rulesString, this._input, this._name, type));

            if (type === RULE_TYPE.INVESTSUM_AMOUNT)
                typedRules.push(new InvestsumAmountRule(this._rulesString, this._input, this._name, type));

            if (type === RULE_TYPE.NOT_EQ_REQUIRED_IF_EQ)
                typedRules.push(new NotEqRequiredIfEq(this._rulesString, this._input, this._name, type));

        });

        return typedRules;
    }
}

export default RuleHelper;
