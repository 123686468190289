




















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import BuyTransactionResponseInterface from '@/components/SecondaryMarket/interfaces/BuyTransactionResponse';
import RequestCallback from '@/components/Rest/RequestCallback';
import {AxiosResponse} from 'axios';
import Events from '@/events';

import ExporoVue from '@/components/ExporoVue';
import SecondaryMarketRequestApi from '@/components/Rest/requests/SecondaryMarketRequestApi';
import IbanService from '@/components/Iban/services/IbanService';
import StringHelper from '@/components/Helper/StringHelper';
import BuyTransactionController from '@/components/SecondaryMarket/controllers/BuyTransactionController';
import RestError from '@/components/Rest/models/RestError';
import Input from '@/components/Input/Input.vue';
import Checkbox from '@/components/Checkbox/Checkbox.vue';

@Component({
    components: {
        Input,
        Checkbox
    },
    filters: {
        toEur(value) {
            return StringHelper.toEur(value);
        }
    }
})
class BuyTransaction extends ExporoVue implements RequestCallback {

    @Prop() private data!: BuyTransactionResponseInterface;
    @Prop() private metaData!: any;
    @Prop() private iban!: string;

    private buyTransactionController: BuyTransactionController;
    private secondaryMarketRequestApi: SecondaryMarketRequestApi;
    private ibanService: IbanService;

    private postData!: object;
    private validInput: boolean = true;
    private AGBChecked: boolean = false;

    constructor() {
        super();
        this.ibanService = new IbanService();
        this.buyTransactionController = new BuyTransactionController(this.data);
        this.secondaryMarketRequestApi = this.restApi.create(new SecondaryMarketRequestApi());
        this.$nextTick(this.updateData);
    }

    piecesChanged(pieces) {
        if (pieces < this.buyTransactionController.availableAssets) {
            this.buyTransactionController.piecesChanged(Number(pieces));
        } else {
            this.buyTransactionController.piecesChanged(this.buyTransactionController.availableAssets);
        }

    }

    piecesBlured() {
        if (this.buyTransactionController.piecesToBuy <= 0) {
            this.buyTransactionController.piecesToBuy = 1;
            this.buyTransactionController.piecesChanged(1);
        }
    }

    openLinkInNewTab(link) {
        window.open(link, '_blank');
    }

    changeAGBStatus(val) {
        this.AGBChecked = val;
    }

    updateData() {
        this.validInput = true;
        this.$forceUpdate();
    }

    validateInputs() {
        if (this.buyTransactionController.piecesToBuy > 0 && this.AGBChecked)
            return true;
    }

    send(e) {
        e.preventDefault();
        if (this.validateInputs()) {
            this.createPostData();
            this.secondaryMarketRequestApi.postBuy(this.postData, this);
            this.getModalService().closeModal('secondary-market-buy');
            this.getModalService().openModal('buy-success');
            Events.$emit('secondary-market-do-buy');
        } else {
            this.validInput = false;
        }

    }

    createPostData() {
        this.postData = {
            offer_id: this.data.offerId,
            pieces: this.buyTransactionController.piecesToBuy,
            iban: this.iban
        };
    }

    onSuccess(response: AxiosResponse) {
    }

    onFailure(error: RestError) {
    }
}

export default BuyTransaction;
