declare const BUILD_NUMBER: string | undefined;

class ExporoConfig {
    static config = (window as any).exporoConfig || {};

    static HOST: string = ExporoConfig.config.EXPORO_BASE_URL || '';
    static REDIRECT_AFTER_LOGIN_URL: string = ExporoConfig.config.REDIRECT_AFTER_LOGIN_URL || '';
    static EXPORO_MOCK_TOKEN_BAADER: string = ExporoConfig.config.EXPORO_MOCK_TOKEN_BAADER || '';
    static EXPORO_LANGUAGE: string = ExporoConfig.config.EXPORO_LANGUAGE || '';
    static BASE_TOKEN: string = ExporoConfig.config.EXPORO_API_TOKEN || '';
    static PUSHER_KEY: string = ExporoConfig.config.PUSHER_KEY || '';
    static LEGAL_TEXT: LegalText = ExporoConfig.config.LEGAL_TEXT || {};
    static ENV: string = ExporoConfig.config.ENV || '';
    static BUILD_NUMBER: string = (typeof BUILD_NUMBER === 'undefined' ? 'local' : BUILD_NUMBER);
}

export default ExporoConfig;

export interface LegalText {
    REGISTER_HEADLINE?: string;
    REGISTER_AGREE_1?: string;
    REGISTER_AGREE_2?: string;
    REGISTER_SUBLINE?: string;
    REGISTER_SUCCESS?: string;
}
