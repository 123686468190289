














import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
class InvestOption extends Vue {

    private isOpen: boolean = false;

    constructor() {
        super();
    }

    toggleNavigation() {
        this.isOpen = !this.isOpen;
    }

}

export default InvestOption;
