import GhostLayer from './GhostLayer.vue';
import InvestEntryData from '@/components/Cockpit/InvestList/InvestEntryData';

class AppRouter {
    private ghostLayer_!: GhostLayer;
    private ghostContainer_!: any;
    private sharedElement_!: any;
    private sharedElementPosition_!: any;
    private prevUrl = '';
    private currentUrl = '';
    private onRouteChanged: (any, string) => {};
    private _params: object = [];
    private textPos: object[] = [];

    constructor(ghostLayer: GhostLayer, onRouteChanged: (any, string) => {}) {
        this.ghostLayer_ = ghostLayer;
        this.ghostContainer_ = this.ghostLayer_.$el;
        this.prevUrl = '';
        this.currentUrl = window !== undefined ? window.location.href : '';
        this.onRouteChanged = onRouteChanged;
    }

    setRoute(url, params?: object) {
        this.prevUrl = this.currentUrl;
        this.currentUrl = url;

        if (params) {
            this._params = params;
        }

        this.onRouteChanged(this.prevUrl, this.currentUrl);
    }

    getTextPos(): object[] {

        return this.textPos;
    }

    getPrevUrl() {

        return this.prevUrl;
    }

    addSharedElement(sharedElementPosition, ghostData?: InvestEntryData) {
        this.sharedElementPosition_ = sharedElementPosition;

        if (ghostData) {

            this.textPos = sharedElementPosition.textPos;

            // @ts-ignore
            this.ghostLayer_.setGhost(ghostData, sharedElementPosition.sizes);

            this.ghostLayer_.$nextTick(() => {
                // @ts-ignore
                const element: any = this.ghostLayer_.$refs.ghost.$el;
                this.sharedElement_ = element;

                element.classList.add('shared-element');

                element.style.top = `${sharedElementPosition.top}px`;
                element.style.left = `${sharedElementPosition.left}px`;
                element.style.height = `${sharedElementPosition.height}px`;
                element.style.width = `${sharedElementPosition.width}px`;

                element.style.transitionDuration = '500ms';
            });
        }
    }

    runAnimation(top, left = 0) {

        return new Promise((resolve, reject) => {
            try {
                const verticalTravelDistance = top - this.sharedElementPosition_.top;
                const horizontalTravelDistance = left - this.sharedElementPosition_.left;

                this.ghostContainer_.addEventListener('transitionend', () => {
                    resolve();

                    setTimeout(() => {
                        // @ts-ignore
                        this.ghostLayer_.clearGhostContainer();
                    }, 100);

                }, { once: true });

                requestAnimationFrame(() => {

                    this.sharedElement_.style.transform = `translateY(${verticalTravelDistance}px) translateX(${horizontalTravelDistance}px)`;
                });
            } catch (e) {
                // @ts-ignore
                this.ghostLayer_.clearGhostContainer();
                reject();
            }
        });
    }

    get params(): any {
        return this._params;
    }
}

export default AppRouter;
