import {format, parse, isValid} from 'date-fns';

class DateFormatHelper {

    static getDatesPerIntervallInMonths(startDate: Date, endDate: Date, intervallInMonths: number): Date[] {
        const dates: Date[] = [];

        const addMonths = (inputDate, months) => {
            const date = new Date(inputDate.valueOf());
            date.setMonth(date.getMonth() + months);

            return date;
        };

        let currentDate: Date = startDate;

        while (currentDate <= endDate) {
            dates.push(currentDate);
            currentDate = addMonths(currentDate, intervallInMonths);
        }

        return dates;
    }

    static getClosestNextDate(): Date {
        const currentYear = new Date().getFullYear();
        const today = new Date();
        const quartales = [
            new Date(`${currentYear}-01-15`),
            new Date(`${currentYear}-04-15`),
            new Date(`${currentYear}-07-15`),
            new Date(`${currentYear}-10-15`),
            new Date(`${currentYear + 1}-01-15`)
        ];
        let closest: any = Infinity;

        for (const q of quartales) {
            if (q >= today) {
                closest = q;
                break;
            }
        }
        return closest;
    }

    // keep here for debugging javascript datetime format inconsistencies
    static getFormattedDateFromString(dateString: string, padNumbers: boolean = false): string {

        if (!dateString) {
            return '-';
        }

        const date = new Date(dateString.split(' ')[0]);
        return this.getFormattedDate(date, padNumbers);
    }

    static getFormattedDate(date: Date, padNumbers: boolean = false) {
        if (padNumbers) {
            const day = this.padStart(date.getDate().toString(), 2, '0');
            const month = this.padStart((date.getMonth() + 1).toString(), 2, '0');
            const year = date.getFullYear();

            return `${day}.${month}.${year}`;
        } else {

            return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
        }
    }

    // Polyfill adaption from
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
    private static padStart(text: string, targetLength: number, padString: string): string {
        targetLength = targetLength >> 0; // truncate if number, or convert non-number to 0;
        padString = String(typeof padString !== 'undefined' ? padString : ' ');

        if (text.length >= targetLength) {

            return String(text);
        } else {
            targetLength = targetLength - text.length;

            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
            }

            return padString.slice(0, targetLength) + String(text);
        }
    }

    static differenceInMonth(dateFrom, dateTo, isString) {


        if (!dateTo || !dateFrom) {
            return '-';
        }

        if (isString) {
            dateFrom = this.createDateWithCustomDelimiter(dateFrom, '/');
            dateTo = this.createDateWithCustomDelimiter(dateTo, '/');
        }

        return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
    }

    static stringToAmericanDate(dateString: string): string {
		if (!dateString) {
			return '-';
		}

		let date = dateString.split('.').reverse().join('/');
        date = date.split('-').join('/');
        return date;
    }

    static parse(dateString: string, dateInputFormat: string, dateOutputFormat: string): string | null {
        const date = parse(dateString, dateInputFormat, new Date());

        if (isValid(date)) {
            return format(date, dateOutputFormat);
        }

        return 'Invalid Date';
    }

    private static createDateWithCustomDelimiter(dateString: string, delimiter: string) {

        if (!dateString) {
            return '-';
        }

        return new Date(dateString.split('-').join(delimiter));
    }

}

export default DateFormatHelper;
