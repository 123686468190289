











import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
class CockpitSwapper extends Vue {

    @Prop() private show!: boolean;

    @Watch('show')
    onChildChanged(val: boolean, oldVal: boolean) {
        this.$nextTick(() => {
            if (val) {
                const height = (this.$refs.inner as HTMLElement).offsetHeight;
                (this.$refs.content as HTMLElement).style.height = height + 'px';
            } else {
                (this.$refs.content as HTMLElement).style.height = '0px';
            }
        });
    }

}

export default CockpitSwapper;

