class PageEntry {

    private _page: string;
    private _data: object;
    private _file: string;
    private _title: string;

    constructor(page: string, pagedata?: object, title?: string) {

        const pageFormated = page[0].toUpperCase() + page.substr(1);
        this._page = page;
        this._data = pagedata || {};
        this._file = 'Page' + pageFormated;

        if ('undefined' === typeof title) {
            this._title = pageFormated;
        } else {
            this._title = title;
        }

    }

    get page(): string {
        return this._page;
    }
    get file(): string {
        return this._file;
    }
    get address(): string {
        return '/cockpit/' + this._title.toLowerCase();
    }
    get title(): string {
        return this._title;
    }
    get data(): object {
        return this._data;
    }

}

export default PageEntry;
