






















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import AuthButton from '@/components/Auth/AuthButton.vue';

@Component({
    components: {
        AuthButton
    }
})

class LoginToSeeModalContent extends ExporoVue {
    constructor() {
        super();
    }
}
export default LoginToSeeModalContent;

