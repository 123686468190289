






















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({})
class PageLoading extends Vue {

    constructor() {
        super();
    }

    mounted() {

    }
}

export default PageLoading;
