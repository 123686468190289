




import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';

@Component
class BuySuccess extends ExporoVue {

    constructor() {
        super();
    }
}

export default BuySuccess;
