import BaseRule from '../BaseRule';

export default class RequiredIfRule extends BaseRule {

    constructor(rulesString, input, name, type) {
        super(rulesString, input, name, type);

        this._valid = this.validate();
    }

    validate() {
        const inputValuePair = this._rulesString.split(':')[1].split(',');
        const toCheckInput = inputValuePair[0];
        const value = inputValuePair[1];

        let toCheckInputNode: any = document.getElementById(toCheckInput);

        if (toCheckInputNode && 'div' === toCheckInputNode.tagName.toLowerCase()) {
            toCheckInputNode = toCheckInputNode.querySelectorAll('input')[0];
        }

        // @TODO: fix it
        if ('invest_iban' === this._name) {
            const type: any = document.querySelectorAll('input[name=investtype]')[0];

            if (type && 'prepay' === type.value) {

                return true;
            }
        }

        if (toCheckInputNode) {

            if (toCheckInputNode.value === value) {

                if ('checkbox' === this._input.type) {

                    return this._input.checked;
                } else {

                    return null !== this._input.value && '' !== this._input.value;
                }
            }
        }

        return true;
    }
}
