import Events from '@/events';

class Toast {

    public progress: number =  0;
    public id: number =  -1;
    public shouldShow: boolean = false;
    public success: boolean = false;

    private text: string;
    private duration: number;


    constructor(text: string, duration: number, id: number = -1, success: boolean = false) {
        this.text = text;
        this.duration = duration;
        this.id = id;
        this.success = success;
    }

    show() {
        this.shouldShow = true;
        let time = 0;

        const id = setInterval(() => {
            this.progress = time / this.duration * 100;
            time += 10;

            if (this.progress >= 100) {
                clearInterval(id);
                this.shouldShow = false;
                Events.$emit('onToastRemoved', this);
            }
        }, 10);

    }
}

export default Toast;
