

















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import TableData from '../models/TableData';
import InvestEntry from './InvestEntry.vue';
import Events from '@/events';

@Component({
    components: {
        InvestEntry,
    }
})
class InvestListRow extends Vue {

    @Prop() private row!: any;
    @Prop() private columns!: any;
    private cdnBaseUrl = 'https://cdn.exporo.de/';

}

export default InvestListRow;
