/**
 * class Logger
 *
 * Logger.log('lorem') or Logger.log('lorem', 'ipsum')
 * Logger.tag('tagName').log('foo');
 *
 * Logger.isProduction = false | true // Stop Logging;
 * Logger.defaultColor = '#ff0000' | 'red';
 * Logger.filter = 'tagName';
 *
 */
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import ExporoVue from '@/components/ExporoVue';

class Logger {
    static DEBUG: string = 'DEBUG';
    static ERROR: string = 'ERROR';

    static isProduction: boolean = true;
    static filter: string | null = null;
    static defaultColor: string = 'blue';
    static defaultTag: string = Logger.DEBUG;
    static type: string = Logger.DEBUG;

    static _instance: Logger;

    static isDebug: boolean = false;

    private isGrouped: boolean = false;
    private groupName: string = '';

    static log(...messages) {

        this.getLogger().log(...messages);
    }

    static error(...messages) {

        this.setType(this.ERROR).log(...messages);
    }

    static getGroupedLogger(name: string): Logger {
        const shouldDebug = LocalStorage.getBoolean(ExporoVue.ACTIVATE_LOGS_KEY, false);

        return new Logger(shouldDebug, true, name);
    }

    static tag(tag: string): Logger {
        this.defaultTag = tag;

        return this.getLogger();
    }

    static setType(type: string): Logger {
        this.type = type;

        return this.getLogger();
    }

    static getLogger(): Logger {

        if (!Logger._instance) {
            const shouldDebug = LocalStorage.getBoolean(ExporoVue.ACTIVATE_LOGS_KEY, false);
            Logger._instance = new Logger(shouldDebug, false);
        }

        return Logger._instance;
    }

    static isLoggerActive(): boolean {

        return !this.isProduction || this.isDebug;
    }

    static shouldLog(tag: string): boolean {

        return this.isLoggerActive() && (null === this.filter || tag === this.filter);
    }

    static getColor(type: string): string {
        if (type === this.DEBUG)
            return this.defaultColor;
        if (type === this.ERROR)
            return 'red';

        return this.defaultColor;
    }

    constructor(isDebug: boolean, isGrouped: boolean, groupName?: string) {
        Logger.isDebug = isDebug;

        if (isGrouped && groupName) {
            this.isGrouped = isGrouped;
            this.groupName = groupName;

            this.initGroupConfig();
        }
    }

    public error(...messages) {

        Logger.error(...messages);
    }

    public endGroup() {
        // tslint:disable-next-line:no-console
        console.groupEnd();
    }

    public log(...messages): Logger {

        const tag = Logger.defaultTag;

        if (Logger.shouldLog(tag)) {
            // tslint:disable-next-line:no-console
            console.log(`[%c${tag}%c]`, `color:${Logger.getColor(Logger.type)}`, 'color:black',  ...messages);
        }

        Logger.type = Logger.DEBUG;
        Logger.defaultTag = Logger.DEBUG;

        return this;
    }

    private initGroupConfig() {
        // tslint:disable-next-line:no-console
        console.groupCollapsed(`[%c${Logger.defaultTag}%c]`, `color:${Logger.getColor(Logger.type)}`, 'color:black',  this.groupName);
    }
}

export default Logger;
