import { render, staticRenderFns } from "./PageDisbursed.vue?vue&type=template&id=f9965cfe&scoped=true&"
import script from "./PageDisbursed.vue?vue&type=script&lang=ts&"
export * from "./PageDisbursed.vue?vue&type=script&lang=ts&"
import style0 from "./PageDisbursed.vue?vue&type=style&index=0&id=f9965cfe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9965cfe",
  null
  
)

export default component.exports