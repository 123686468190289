import {Cached, Get, Post} from '@/decorators/RestDecorators';
import RequestCallback from '../RequestCallback';
import RestApi from '@/components/Rest/RestApi';
import RequestApi from '@/components/Rest/models/RequestApi';

class UserRequestApi extends RequestApi {

    static GET_IDENT_KEY = 'get-ident-url';

    @Get('/user/me/edit')
    getUser(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/user/{id}/edit')
    getUserEditById(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/user')
    updateUser(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Cached()
    @Get('/api/user/authenticated')
    isAuthenticated(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/auth/logout')
    logout(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/activation/webapp/{token}')
    activate(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/activation/webapp/complete')
    completeActivation(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/user/me/password-change')
    changePassword(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('{url}', UserRequestApi.GET_IDENT_KEY)
    getIdentUrl(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }
}

export default UserRequestApi;
