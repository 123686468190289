









import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';

@Component({
    components: {}
})

class CustomerRatingButton extends ExporoVue {
    @Prop({default: false}) left!: boolean;
    @Prop() actionClick!: () => {};

    constructor() {
        super();
    }
}


export default CustomerRatingButton;
