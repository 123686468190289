import BaseRoute from '../components/Router/BaseRoute';
import {Route} from '@/components/Router/decorators/RouteDecorator';

class ModalRoutes extends BaseRoute {

    @Route('/modal/{name}')
    private openModal;

    @Route('/modal/{name}/{redirect}')
    private openModalAndRedirectBack;
}

export default ModalRoutes;
