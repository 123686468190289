import {Get, Post} from '@/decorators/RestDecorators';
import RequestCallback from '../RequestCallback';
import RestApi from '@/components/Rest/RestApi';
import RequestApi from '@/components/Rest/models/RequestApi';

class ContactCenterRequestApi extends RequestApi {

    @Get('/user/email/controls')
    getControls(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/user/email/save-subscription-settings')
    saveSubscriptionSetiings(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }
}

export default ContactCenterRequestApi;
