






















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CockpitButton from '../Controls/CockpitButton.vue';
import UserData from '../models/UserData';
import ExporoVue from '@/components/ExporoVue';
import ExporoModal from '@/components/Modal/ExporoModal.vue';

@Component({
    components: {
        CockpitButton,
        ExporoModal,
    }
})
class PageRecruitFriends extends ExporoVue {

    @Prop() userdata!: UserData;

    copySharelink() {
        const textfieldSharelink = this.$refs.sharelink;
        (textfieldSharelink as HTMLInputElement).select();
        document.execCommand('copy');
    }
}

export default PageRecruitFriends;
