import IntegrationOptions from '@/components/Rest/models/IntegrationOptions';
import axios, {AxiosRequestConfig} from 'axios';
import RestApiService, {BASE_TOKEN} from '@/components/Rest/services/RestApiService';
import Closure from '@/components/Rest/middleware/core/Closure';
import RestError from '@/components/Rest/models/RestError';
import {UNDEFINED_ERROR} from '@/components/Rest/models/Errors';

class WalletIntegration {

    private restApiService!: RestApiService;

    constructor() {
        this.restApiService = new RestApiService();
    }

    requestWallet(options: IntegrationOptions) {

        const config: AxiosRequestConfig = {
            method: 'post',
            url: 'https://wallet.exporo.de/api/v1/user/' + options.data.userId + '/wallet/state',
            data: {
                'token': options.data.token
            }
        };

        this.restApiService.callMiddleware({config: config, id: options.id}, new Closure(() => {
            axios.request(config).then((data) => {
                this.restApiService.callMiddleware({response: data, id: options.id}, new Closure(() => {
                    options.callback.onSuccess({data: data, callback: options.data.callback} as any, options.id);
                }), true);
            }).catch((error) => {
                const restError: RestError = new RestError({
                    errorCode: UNDEFINED_ERROR,
                    errorMsg: error.response ? error.response.data : error
                }, error.response);

                const data = {
                    response: restError.response,
                    id: options.id,
                    error: restError
                };
                this.restApiService.callMiddleware(data, new Closure(() => {
                    options.callback.onFailure({error: error, callback: options.data.callback} as any, options.id);
                }), true);
            });
        }));
    }
}

export default WalletIntegration;
