








import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';

@Component({
    components: {}
})

class ExporoAlert extends ExporoVue {
    @Prop({default: 'error'})
    private type!: string;
    @Prop({default: 'normal'})
    private shape!: string;
    private wrapperStyle: object;
    private textStyle: object;
    private color!: string;

    constructor() {
        super();
        this.setColor();
        this.wrapperStyle = {
            'background-color': this.setBackgroundColor(),
            'border': this.setBorder()
        };
        this.textStyle = {
            'color': this.setTextColor(),
        };
    }

    setColor() {
        switch (this.type) {
            default:
                this.color = 'white';
                break;
            case 'warning':
                this.color =  '#E2B226';
                break;
            case 'error':
                this.color =  '#FF5252';
                break;
            case 'success':
                this.color = '#4CAF50';
                break;
            case 'info':
                this.color = '#B8C1CE';
                break;
        }
    }

    setBackgroundColor(): string {
        if (this.shape === 'outlined') {
            return 'white';
        } else {
            return this.color;
        }
    }

    setBorder(): string {
        if (this.shape === 'normal') {
            return 'unset';
        } else {
            return `solid 1px ${this.color}`;
        }
    }

    setTextColor(): string {
        if (this.shape === 'normal') {
            return 'white';
        } else {
            return this.color;
        }
    }
}

export default ExporoAlert;
