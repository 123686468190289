








































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CockpitExpander from './CockpitExpander.vue';
import ExporoVue from '@/components/ExporoVue';


@Component({
    components: {
        CockpitExpander
    }
})
class CockpitPanel extends ExporoVue {
    private isExtended: boolean = false;
    private isOpen: boolean = false;

}

export default CockpitPanel;
