







import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import ExporoTokenService from '@/components/JWT/services/ExporoTokenService';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import UserRequestApi from '@/components/Rest/requests/UserRequestApi';

@Component
class PageWallet extends ExporoVue {
    private walletLink: string = 'https://fr.wallet.exporo.de/';

    mounted() {
        const userRestApi: UserRequestApi = this.restApi.create(new UserRequestApi());
        const externalUserId = LocalStorage.get(ExporoVue.EXTERNAL_USER_COCKPIT_KEY, null);
        if (null !== externalUserId) {
            userRestApi.getUserEditById(this).addParam('id', externalUserId);
            const tokenService = new ExporoTokenService();
            tokenService.fetchToken((token: string) => {
                this.walletLink = `https://fr.wallet.exporo.de/?jwtToken=${token}`;
            }, externalUserId);
        } else {
            userRestApi.getUser(this);
        }
    }
}

export default PageWallet;
