













import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import KeyfigureData from '../models/KeyfigureData';
import LoadingAnimation from '@/components/LoadingAnimation/LoadingAnimation.vue';

@Component({
    components: {
        LoadingAnimation
    }
})
class CockpitKeyfigures extends Vue {

    @Prop() private keyfigures!: KeyfigureData[];

    constructor() {
        super();
    }

}

export default CockpitKeyfigures;
