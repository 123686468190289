






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ListView from '../AList/ListView.vue';
import InvestMenu from '@/components/Cockpit/InvestList/InvestMenu.vue';
import TableData from '../models/TableData';
import ExporoVue from '@/components/ExporoVue';

@Component({
    components: {
        ListView,
        InvestMenu,
    }
})
class PageDisbursed extends ExporoVue {

    private tableData: TableData;

    constructor() {
        super();

        const dataFinancing: any = [];
        for (let i = 0; i < 10; i++) {
            dataFinancing.push({
                'Projekt': 'Project ' + (i + 1),
                'Investment': '25.000€',
                'Rendite': '4,3',
                'Laufzeit': '10 Jahre',
                'Ausschüttung': '15.04.19',
                'Ausschüttungssumme': '387,00€',
            });
        }

        this.tableData = new TableData(dataFinancing);
    }
}

export default PageDisbursed;
