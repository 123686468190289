






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {VNode} from 'vue';
import PageView from '@/components/ViewPager/PageView.vue';

@Component
class ViewPager extends Vue {

    @Prop()
    private selected!: string;

    constructor() {
        super();
    }

    public setCurrentItem(key: string) {
        if (this.$slots && this.$slots.default) {
            this.$slots.default.forEach((slot: VNode) => {
                if (slot.componentOptions && 'PageView' === slot.componentOptions.tag) {
                    const pageView: PageView = slot.componentInstance as PageView;

                    pageView.isVisible = key === pageView.id;
                    pageView.onChanged();
                }
            });
        }
    }

    mounted() {
        this.setCurrentItem(this.selected);
    }
}

export default ViewPager;
