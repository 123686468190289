import BaseRule from '../BaseRule';

export default class MinRule extends BaseRule {

    private _value;
    private _min;

    constructor(rulesString, input, name, type) {
        super(rulesString, input, name, type);

        this._value = null;
        this._min = null;
        this.parseRulesString();
        this._valid = this.validate();

    }

    validate() {

        return '' !== this._value && this._value >= this._min;
    }

    parseRulesString() {
        const rules = this._rulesString.split('|');

        rules.forEach((item: string) => {
            if (item.indexOf('min') > -1) {
                this._min = item.split(':')[1];
            }
        });

        this._value = Number(this._input.value);
    }
}
