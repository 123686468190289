


























































































































    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import TableData from '../models/TableData';
    import InvestEntry from './InvestEntry.vue';
    import InvestMenu from './InvestMenu.vue';
    import InvestListRow from './InvestListRow.vue';
    import AppRouter from '../AList/AppRouter';
    import Events from '@/events';
    import CockpitButton from '../Controls/CockpitButton.vue';
    import CockpitRouter from '@/components/Cockpit/CockpitRouter';
    import SortButton from './SortButton.vue';
    import ExporoVue from '@/components/ExporoVue';
    import RightArrow from '@/components/SVG/RightArrow.vue';
    import SortHelper from '@/components/Helper/SortHelper';
    import DateFormatHelper from '@/components/Helper/DateFormatHelper';
    import BuyButton from '@/components/SecondaryMarket/BuyButton.vue';
    import UserTooltip from '@/components/User/Edit/UserTooltip.vue';
    import Router from '@/components/Router/Router';
    import UserData from '@/components/Cockpit/models/UserData';
    import Logger from '@/components/Helper/LogHelper';
    import LoadingAnimation from '@/components/LoadingAnimation/LoadingAnimation.vue';

    @Component({
        components: {
            InvestEntry,
            InvestMenu,
            InvestListRow,
            CockpitButton,
            SortButton,
            RightArrow,
            BuyButton,
            UserTooltip,
            LoadingAnimation
        }
    })
    class InvestList extends ExporoVue {

        @Prop() private appRouter!: AppRouter;
        @Prop() private viewChange!: () => void;
        @Prop() private tableData!: TableData;
        @Prop() private isDownload!: boolean;
        @Prop({default: null}) private additionalText!: string;
        @Prop() private defaultFilter!: string;
        @Prop() private defaultActiveCol!: string;
        @Prop() private subMenu!: string;
        @Prop() private isSecondaryMarket!: boolean;
		@Prop() private isTrades!: boolean;
        @Prop() private userdata!: UserData;
        @Prop() private isPendingList!: boolean;
        @Prop() private pendingDefaultFilter!: string;
        @Prop() private isPortfolioNew!: boolean;

        private filterState!: string;
        private sortedTableData!: any;

        private toggle: string;

        private sortedAsce: boolean = true;
        private activeCol!: string;
        private isTablet: boolean = window.innerWidth <= 992;
        private isSmall: boolean = window.innerWidth <= 720;

        constructor() {
            super();
            this.filterState = this.defaultFilter ? this.defaultFilter : 'all';
            this.activeCol = this.defaultActiveCol;
            this.toggle = this.pendingDefaultFilter;
        }

        mounted() {
            window.addEventListener('resize', () => {
                this.isTablet = window.innerWidth <= 992 && window.innerWidth > 719;
                this.isSmall = window.innerWidth <= 720;
            });

            Events.$on('filter-pending', (data) => {
                this.toggle = data.selection;
            });

            Events.$on('filter', (x) => {
                if ('state' === x.type) {
                    if (this.subMenu === x.subMenu) {
                        x.selection === 'all' ? this.filterState = 'all' : this.filterState = x.selection;
                    }
                }
            });

            this.sortTableData(0, this.activeCol);

            Events.$on('sort-mobile', (sortOption) => this.sortTableData(0, sortOption.sortOption));
            Events.$on('sort-mobile-table', () => this.sortTableData(0, undefined));
        }

        isIdentState(expectedProgress) {

            if (!this.userdata) {
                return false;
            }

            const identState = this.userdata.identificationState;
            let progress = 1;
            if ('open' === identState) {
                progress = 2;
            } else if ('success' === identState) {
                progress = 3;
            }

            return progress === expectedProgress || expectedProgress < progress;
        }

        hasTradingAccount(): boolean {

            try {
                return this.userdata.rawData.userData.has_trading_account;
            } catch (e) {
                Logger.tag('InvestList').error(e);
            }

            return false;
        }

        hasPaid(investment): boolean {
            return investment.is_paid;
        }

        sortTableData(index, col) {
            if (!col) {
                col = this.activeCol;
            }

            if (col !== 'Projekt') {

                this.sortedTableData = this.tableData.data;

                if (this.activeCol === col) {
                    this.sortedAsce = !this.sortedAsce;
                } else {
                    this.activeCol = col;
                    this.sortedAsce = true;
                }

                // @TODO should be translated?
                const dateColList = ['Investiert am', 'Auszahlungsdatum'];

                if (dateColList.indexOf(col) === -1) {
                    const value = this.tableData.data[0][col];

                    if (value) {
                        const toPlainNumber = (value: any): number => {
                            const numberString = value.toString().replace(/\./g, '').replace(',', '').replace('Monate', '').replace('%', '').replace('€', '').trim();

                            return numberString ? Number(numberString) : numberString;
                        };

                        const plainValue = toPlainNumber(value);
                        const isNumber = plainValue && !isNaN(plainValue);

                        if (!isNumber) {

                            return this.activeCol === col ? SortHelper.sortByName(this.sortedTableData, col, this.sortedAsce) : null;
                        } else {

                            return this.activeCol === col ? SortHelper.sortByNumber(this.sortedTableData, col, this.sortedAsce) : null;
                        }
                    }
                } else {

                    return this.activeCol === col ? SortHelper.sortByDate(this.sortedTableData, col, this.sortedAsce) : null;
                }
            }
        }

        buy(index, row, event) {
            event.stopPropagation();

            if (this.isTrades) {
				window.open(`https://user.exporo.com/#onboarding?successUrl=https://trade.exporo.com/selloffer/${row.ID}`);

            	return;
			}

            Events.$emit('secondary-market-buy', row);
        }

        open(index, row, event) {
            event.stopPropagation();
            if (this.isDownload) {
                return;
            }

            if (this.isSecondaryMarket) {

                this.openTabWithDetails(index, row, event);

                if (this.isMobile) {
                    Events.$emit('secondary-market-buy', row);
                } else {
                    return;
                }
            }

            const rootEl = document.querySelector('.exporo-cockpit-root');
            const rootHead = document.querySelector('.exporo-cockpit-header');
            if (rootEl && rootHead) {
                const rootHeadHeight = rootHead.scrollHeight;
                rootEl.scroll({top: rootHeadHeight, behavior: 'smooth'});
            }

            const cardEntries = Object.keys(row)
                .map((entry) => ({'key': entry, 'value': row[entry]}));


            const [type, detailSlug, id] = document.location.href.split('#cockpit/');

            if (this.isPortfolioNew) {
                CockpitRouter.navigate(CockpitRouter.getCurrentRoute(`cockpit/${detailSlug.split('/')[0]}`) + '/detail/' + row._isin, this.routerGoDetail.bind(this, row, cardEntries));
            } else {
                CockpitRouter.navigate(CockpitRouter.getCurrentRoute(`cockpit/${detailSlug.split('/')[0]}`) + '/detail/' + row._signingId, this.routerGoDetail.bind(this, row, cardEntries));
            }
        }

        routerGoDetail(data, cardEntries) {
            Events.$emit('router-go', {
                'page': 'Detail', 'data': {
                    'card': cardEntries,
                    'data': data
                }
            });
        }

        intentInvestment() {
            Events.$emit('router-go', {url: 'https://exporo.de/projekte/'});
        }

        openTabWithDetails(index, row, event) {
            event.stopPropagation();
            if (this.isSecondaryMarket) {
                const urls = {
                    'Villa Berlin': 'https://backend.exporo.de/investment/villa-berlin',
                    'Kita Winterhude': 'https://backend.exporo.de/investment/kita-winterhude',
                    'Polizei Hattingen': 'https://backend.exporo.de/investment/polizei-hattingen?cache_buster',
                    'Gründerzeit Portfolio': 'https://backend.exporo.de/investment/gruenderzeit-portfolio',
                    'Am Vahrenwalder Platz': 'https://backend.exporo.de/investment/am-vahrenwalder-platz-1',
                    'Institut Mannheim': 'https://backend.exporo.de/investment/institut-mannheim',
                    'Servicewohnen Hürth': 'https://backend.exporo.de/investment/servicewohnen-huerth-1',
                    'Betreutes Wohnen Hürth': 'https://backend.exporo.de/investment/betreutes-wohnen-huerth',
                    'Mitten in Hannover': 'https://backend.exporo.de/investment/mitten-in-hannover',
                    'Apartments am Markt': 'https://exporo.de/equity/apartments-am-markt',
                    'Am Holstenplatz': 'https://exporo.de/equity/am-holstenplatz',
                    'Modern Office Munich': 'https://exporo.de/equity/modern-office-munich',
                    'Mitten in Göttingen I': 'https://exporo.de/equity/mitten-in-goettingen-i',
                    'Behörde am Schlossplatz': 'http://exporo.de/equity/behoerde-am-schlossplatz/',
                    'Altstadt Ensemble am Dom': 'http://exporo.de/equity/altstadt-ensemble-am-dom/',
                    'Büropark an der deutschen Börse': 'https://exporo.de/equity/bueropark-an-der-deutschen-boerse/',
                    'Nikolai Groner Ensemble': 'https://exporo.de/equity/nikolai-groner-ensemble/',
					'Ärztehaus Poppenbüttel': 'https://exporo.de/equity/aerztehaus-poppenbuettel/',
					'Stadtteilzentrum Unterbach': 'https://exporo.de/equity/stadtteilzentrum-unterbach/',
					'Ensemble im historischen Stadtkern': 'https://exporo.de/equity/ensemble-im-historischen-stadtkern/',
					'Wohntrio': 'https://exporo.de/equity/wohntrio-berlin-hannover/',
					'An der Kö': 'https://exporo.de/equity/an-der-koe/',
					'Sozialrathaus Frankfurt': 'https://exporo.de/equity/sozialrathaus-frankfurt/',
					'Elbstadt Schiller Center': 'https://exporo.de/equity/elbstadt-schiller-center/',
					'Ensemble an der Luther-Universität': 'https://exporo.de/equity/ensemble-an-der-luther-universitaet-5843/',
					'Carl-Zeiss-Ensemble': 'https://exporo.de/equity/carl-zeiss-ensemble-7754/',
					'Leben am Maschsee': 'https://exporo.de/equity/leben-am-maschsee-8117/'
                };
                window.open(urls[row.Projekt], '_blank');
            }
        }

        // @todo make if clause generic
        get filteredRows() {
            if ('all' === this.filterState || 'allInvestments' === this.filterState || 'allPayments' === this.filterState) return this.tableData.data;
            else if ('ongoingInvestments' === this.filterState) return this.tableData.data.filter((row: any) => 'repaid' !== row._state);
            else if ('disbursedInvestments' === this.filterState) return this.tableData.data.filter((row: any) => 'repaid' === row._state);
            else if ('comingPayments' === this.filterState) return this.tableData.data.filter((row: any) => {
                return row.Auszahlungsdatum
                    ? new Date(DateFormatHelper.stringToAmericanDate(row.Auszahlungsdatum)).getTime() > new Date().getTime()
                    : null;
            });
            else if ('pastPayments' === this.filterState) return this.tableData.data.filter((row: any) => {
                return row.Auszahlungsdatum
                    ? new Date(DateFormatHelper.stringToAmericanDate(row.Auszahlungsdatum)).getTime() < new Date().getTime()
                    : null;
            });
        }

        get columns() {
            if (this.tableData.data.length === 0) {

                return [];
            }

            const keys = Object.keys(this.tableData.data[0]);
            return keys.filter((k) => '_' !== k[0]);
        }
    }

    export default InvestList;
