









import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Events from '@/events';

@Component
class GalleryThumbnailsButton extends Vue {

    @Prop()
    private imagesString!: string;
    @Prop()
    private id!: string;
    @Prop()
    private base!: string;
    private images: string[] | null = null;

    constructor() {
        super();

        const images: string[] = [];
        JSON.parse(this.imagesString).forEach((image: any) => {
            images.push(this.base + image.image + '?w=330&h=300&fit=crop');
        });

        this.images = images;
    }

    openGalleryByIndex(index) {
        Events.$emit(`openGallery${this.id}`, index);
    }
}

export default GalleryThumbnailsButton;
