/* This switch case was created to cover team yield from not developing the feature on time of release.
* Please remove once all project have consistency in url and contractId */
import YieldDataEntry from '@/components/Cockpit/interfaces/YieldDataEntry';

export default function getReportingUrlFromYieldDataEntry(data: YieldDataEntry, contracts: any): string {
    if (!data._title ) {
        throw new Error ('getReportingUrlFromYieldDataEntry expects data._title');
    }

    let trancheId: any = null;

    function getFpId(contract: any) {
        if (data._contractId === contract.id) {
            if (contract.fp_id === null) {
                trancheId = data._contractId;
            } else {
                trancheId = contract.fp_id;
            }
        }
    }

    if (contracts) {
        if (data._type !== 'equity') {
            contracts.bond.forEach((contract: any) => {
                getFpId(contract);
            });
        }

        if (trancheId === null) {
            contracts.equity.forEach((contract: any) => {
                getFpId(contract);
            });
        }
    }

    return `https://pdp.yield.exporo.de/${trancheId}/reporting`;
}
