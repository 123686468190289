







import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';

@Component
class PageExporoAccount extends ExporoVue {
    private accountLink: string = 'https://emoney.exporo.com/';
}

export default PageExporoAccount;
