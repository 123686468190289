














import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import PubSubService, {TYPE_PROJECT, TYPE_BOND} from '../PubSub/services/PubSubService';
import Logger from '@/components/Helper/LogHelper';
import ExporoVue from '@/components/ExporoVue';

@Component
class ProgressBar extends ExporoVue {

    private pubSubService!: PubSubService;

    @Prop()
    private id!: string;

    @Prop()
    private type?: string;

    private fundingInPercentage: number = 0;
    private remaining: number = 0;

    constructor() {
        super();
    }

    mounted() {

        // @TODO: Find another solution to decide if is an update or initial event
        this.pubSubService = PubSubService.getInstance((data?) => {

            let project: any = null;

            if (data && data.projects) {
                const allProjects = data.projects;

                if (this.type && data.projects.hasOwnProperty(this.type)) {
                    data.projects = data.projects[this.type];
                }

                if (data.projects.hasOwnProperty(this.id)) {
                    project = data.projects[this.id];
                } else {
                    project = allProjects[this.id];
                }
            } else if (void(0) === data) {
                project = this.pubSubService.getProject(this.id, (void(0) !== this.type && 'bonds' === this.type ? TYPE_BOND : TYPE_PROJECT));
            }

            if (project) {
                this.displayUpdateByProject(project);
            } else {
                Logger.tag('ProgressBar').log(`Project with ID: ${this.id} not exist. Type is: ${this.type}`);
            }
        });
    }

    displayUpdateByProject(project: any) {
        this.fundingInPercentage = project.fundingInPercentage;
        this.remaining = project.fundingTarget - project.total_as_float;
    }


    convert(value) {

        if (value < 0) {
            value = 0;
        }

        return ((Number(value) || 0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'));
    }
}

export default ProgressBar;
