class FormData {
    phone_mobile: string = '';
    postal_code: string = '';
    city: string = '';
    partner_bank: string = '';
    partner_bic: string = '';
    partner_city: string = '';
    partner_iban: string = '';
    partner_name: string = '';
    partner_postal_code: string = '';
    partner_street: string = '';
    partner_tax: string = '';
    partner_tax_office: string = '';
    partner_url: string = '';
    consultant_id: string = '';
    sex: string | null = null;
    first_name: string | null = null;
    last_name: string | null = null;
    birth_name: string | null = null;
    pob: string | null = null;
    email: string | null = null;
    phone: string | null = null;
    pass: string | null = null;
    pass_authority: string | null = null;
    street: string | null = null;
    street_no: string | null = null;
    street_additional: string | null = null;
    miles_id: string | null = null;
    tax_id: string | null = null;
    pep: number | null = null;
    partner_company: string | null = null;
    created_at: string | null = null;
    affiliated_partner_id: number | null = null;
    friend_id: number | null = null;
    id: number = 0;

    private _not_us_taxed: boolean = false;
    private _title: string = '--';
    private _marital_status: string = '';
    private _country: string = 'DE';
    private _citizenship: string = 'DE';
    private _legitimation_document: string = 'id_card';
    private _pass_country_of_issue: string = 'DE';
    private _country_of_birth: string = 'DE';
    private _dob: string = '0000-00-00 00:00:00';
    private _pass_created_at: string = '0000-00-00 00:00:00';
    private _pass_expires_at: string = '0000-00-00 00:00:00';

    private validation: string | null = null;

    constructor(formData: any) {
        Object.assign(this, formData);
    }

    set not_us_taxed(value: boolean) {
        this._not_us_taxed = value;
    }

    get not_us_taxed(): boolean {
        return this._not_us_taxed;
    }

    set title(data: string) {
        this._title = data;
    }

    get title(): string {
        return this._title;
    }

    set martialStatus(data: string) {
        this._marital_status = data;
    }

    get martialStatus(): string {
        return this._marital_status;
    }

    set country(data: string) {
        this._country = data;
    }

    get country(): string {
        return this._country;
    }

    set citizenship(data: string) {
        this._citizenship = data;
    }

    get citizenship(): string {
        return this._citizenship;
    }

    set legitimationDocument(data: string) {
        this._legitimation_document = data;
    }

    get legitimationDocument(): string {
        return this._legitimation_document;
    }

    set passCountryOfIssue(data: string) {
        this._pass_country_of_issue = data;
    }

    get passCountryOfIssue(): string {
        return this._pass_country_of_issue;
    }

    set countryOfBirth(data: string) {
        this._country_of_birth = data;
    }

    get countryOfBirth(): string {
        return this._country_of_birth;
    }

    set dob(data: string) {
        this._dob = data;
    }

    get dob(): string {
        return this._dob;
    }

    set passCreatedAt(data: string) {
        this._pass_created_at = data;
    }

    get passCreatedAt(): string {
        return this._pass_created_at;
    }

    set passExpiresAt(data: string) {
        this._pass_expires_at = data;
    }

    get passExpiresAt(): string {
        return this._pass_expires_at;
    }

    setValidation(validation: string) {
        this.validation = validation;
    }

    validateDates(date: string) {
        return date === '0000-00-00 00:00:00' ? null : date;
    }

    getFormData(): any {
        return {
            id: this.id,
            phone_mobile: this.phone_mobile,
            postal_code: this.postal_code,
            city: this.city,
            partner_bank: this.partner_bank,
            partner_bic: this.partner_bic,
            partner_city: this.partner_city,
            partner_iban: this.partner_iban,
            partner_name: this.partner_name,
            partner_postal_code: this.partner_postal_code,
            partner_street: this.partner_street,
            partner_tax: this.partner_tax,
            partner_tax_office: this.partner_tax_office,
            partner_url: this.partner_url,
            consultant_id: this.consultant_id,
            sex: this.sex,
            first_name: this.first_name,
            last_name: this.last_name,
            birth_name: this.birth_name,
            pob: this.pob,
            email: this.email,
            phone: this.phone,
            pass: this.pass,
            pass_authority: this.pass_authority,
            street: this.street,
            street_no: this.street_no,
            street_additional: this.street_additional,
            miles_id: this.miles_id,
            tax_id: this.tax_id,
            not_us_taxed: this.not_us_taxed,
            pep: this.pep,
            partner_company: this.partner_company,
            created_at: this.created_at,
            affiliated_partner_id: this.affiliated_partner_id,
            friend_id: this.friend_id,
            title: this.title,
            marital_status: this._marital_status,
            country: this.country,
            citizenship: this.citizenship,
            legitimation_document: this._legitimation_document,
            pass_country_of_issue: this._pass_country_of_issue,
            country_of_birth: this._country_of_birth,
            dob: this.validateDates(this.dob),
            pass_created_at: this.validateDates(this._pass_created_at),
            pass_expires_at: this.validateDates(this._pass_expires_at),
            validation: this.validation
        };
    }

}

export default FormData;
