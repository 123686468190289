






























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CockpitButton from './Controls/CockpitButton.vue';
import Events from '../../events';
import UserData from './models/UserData';
import CockpitRequestApi from '@/components/Rest/requests/CockpitRequestApi';
import ExporoVue from '@/components/ExporoVue';
import RequestCallback from '@/components/Rest/RequestCallback';
import {AxiosResponse} from 'axios';
import RestError from '@/components/Rest/models/RestError';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import CookieHelper from '@/components/Helper/CookieHelper';
import Router from '@/components/Router/Router';
import {Action} from '@/components/Router/decorators/RouteDecorator';

@Component({
    components: {
        CockpitButton
    }
})
class CockpitHeader extends ExporoVue implements RequestCallback {

    @Prop({default: false})
    open!: boolean;
    @Prop() userdata!: UserData;
    @Prop() isMobileApp!: boolean;

    private cockpitRequestApi: CockpitRequestApi;
    private isDE: boolean = false;
    private isPortfolioFeature: boolean;
    private isOtherProfile: boolean = false;
    private norrisCounter: number = 0;

    constructor() {
        super();
        this.isPortfolioFeature = CookieHelper.getCookie('portfolio-v2') === 'true';
        this.cockpitRequestApi = this.restApi.create(new CockpitRequestApi());
    }

    mounted() {
        this.cctld === 'DE' ? this.isDE = true : this.isDE = false;

        this.isOtherProfile = null !== LocalStorage.get(ExporoVue.EXTERNAL_USER_COCKPIT_KEY, null);

        Events.$on('button-click', (e) => {
            if ('button-close-cockpit' === e.name) {
                if (this.isOtherProfile) {

                    LocalStorage.remove(ExporoVue.EXTERNAL_USER_COCKPIT_KEY);
                    window.location.reload();
                } else {
                  this.close();
                }
            }
        });
    }

    switchCockpit(e) {
        e.preventDefault();

        this.cockpitRequestApi.switchVersion(this).addParam('version', '1');
    }

    close() {
        window.location.href = 'https://exporo.' + this.cctld;
    }

    onFailure(error: RestError, id?: string): void {
    }

    onSuccess(response: AxiosResponse, id?: string): void {
        if ('switchVersion' === id) {
            window.location.href = 'https://backend.exporo.de/user';
        }
    }

}

export default CockpitHeader;
