















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import CockpitOptions from './CockpitOptions.vue';
import CockpitCheckbox from './CockpitCheckbox.vue';
import Events from '@/events';

@Component({
    components: {
        CockpitOptions,
        CockpitCheckbox
    }
})
class CockpitSelect extends ExporoVue {

    @Prop() private selected!: string;
    @Prop() private align!: string;
    @Prop() private options!: any[][];
    @Prop() private noNavigation!: boolean;

    private menuId: string;
    private isOpen: boolean = false;
    private selection: string = '';


    constructor() {
        super();

        this.menuId = this.randomID();

        if (typeof this.selected === 'undefined') {
            this.selection = this.options[0][0].key;
        } else {
            this.selection = this.selected;
        }
    }

    mounted() {
        Events.$on('cockpit-click', (id) => {
            if (id !== this.menuId) {
                this.isOpen = false;
            }
        });
    }

    get label() {
        return this.selectables[this.selection];
    }

    get alignment() {
        if ('left' === this.align) {
            return 'flex-start';
        } else if ('right' === this.align) {
            return 'flex-end';
        } else {
            return 'center';
        }
    }

    get selectables() {

        const selectables = {};
        for (const optiongroup of this.options) {
            for (const option of optiongroup) {
                selectables[option.key] = option.value;
            }
        }
        return selectables;
    }


    get optionsplus() {
        const optionsplus = this.options;
        for (const optiongroup of optionsplus) {
            for (const option of optiongroup) {
                if ('undefined' === typeof option.key) {
                    option.key = option.value.toLowerCase();
                }
                if ('undefined' === typeof option.handler) {
                    option.handler = () => {

                    };
                }
            }
        }
        return optionsplus;
    }


    changeSelection(key) {
        this.selection = key;
    }

    toggleNavigation() {
        this.isOpen = !this.isOpen;
        if (!this.noNavigation) {
            Events.$emit('cockpit-click', this.menuId);
        }
    }

    optionSelected(option) {
        this.changeSelection(option.key);
        this.toggleNavigation();

        if (this.isMobile)
            Events.$emit('reset-mobile-sort');
    }

    randomID(): string {
        return Math.random().toString(36).substr(2, 6);
    }

}

export default CockpitSelect;
