
























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import UserRequestApi from '@/components/Rest/requests/UserRequestApi';
import RequestCallback from '@/components/Rest/RequestCallback';
import RestError from '@/components/Rest/models/RestError';
import {AxiosResponse} from 'axios';
import {LOGOUT} from '@/store';
import AuthService from '@/components/Auth/services/AuthService';
import RestApiService from '@/components/Rest/services/RestApiService';
import Events from '@/events';

@Component
class CockpitDropBar extends ExporoVue implements RequestCallback {

    private userRequestApi: UserRequestApi;
    private authService: AuthService;
    private isOpen: boolean = false;
    private value: string = 'Bestand';
    private menuId: string;

    constructor() {
        super();

        const api = new RestApiService();
        this.authService = new AuthService(api);
        this.userRequestApi = this.restApi.create(new UserRequestApi());
        this.menuId = this.randomID();
    }

    mounted() {
        Events.$on('cockpit-click', (id) => {
            if (id !== this.menuId) {
                this.isOpen = false;
            }
        });
    }

    toggleNavigation() {
        this.isOpen = !this.isOpen;
        Events.$emit('cockpit-click', this.menuId);
    }

    changeValue(value: string) {
        this.toggleNavigation();
        this.value = value;
    }


    onFailure(error: RestError): void {
    }

    onSuccess(response: AxiosResponse): void {
        this.getStore().commit(LOGOUT);
        this.authService.logout();
    }

    randomID(): string {
        return Math.random().toString(36).substr(2, 6);
    }
}

export default CockpitDropBar;
