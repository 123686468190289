
































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CockpitButton from '../Controls/CockpitButton.vue';
import UserData from '../models/UserData';
import ExporoVue from '@/components/ExporoVue';
import ExporoModal from '@/components/Modal/ExporoModal.vue';
import InvestorForm from '@/components/User/Investor/InvestorForm.vue';
import CockpitRequestApi from '@/components/Rest/requests/CockpitRequestApi';
import RequestCallback from '@/components/Rest/RequestCallback';
import RestError from '@/components/Rest/models/RestError';
import {AxiosResponse} from 'axios';
import ToastService from '@/components/Toast/ToastService';
import Toast from '@/components/Toast/Toast';
import CockpitInvestor from '@/components/Cockpit/interfaces/UserDataCockpitInvestor';

@Component({
    components: {
        CockpitButton,
        ExporoModal,
        InvestorForm
    }
})
class PageInvestors extends ExporoVue implements RequestCallback {

    @Prop() private userdata!: UserData;
    private formData: object | null = null;
    private shouldShowInvestorForm: boolean = false;
    private cockpitRequestApi: CockpitRequestApi;
    private isNewInvestor: boolean = false;

    constructor() {
        super();
        this.cockpitRequestApi = this.restApi.create(new CockpitRequestApi());
    }

    deleteInvestor(index: any) {
        const investor = this.userdata.investorList[index];

        if (this.isInvestorDeletable(investor)) {
            this.cockpitRequestApi.deleteInvestor(this).addParam('id', investor.id);
        }
    }

    removeInvestorFromView(responseUrl: string) {
        const regexInvestorId = /investor\/(\d+)\/delete/;
        const regexMatch = regexInvestorId.exec(responseUrl);
        const investorId = regexMatch ? regexMatch[1] : null;
        if (investorId) {
            this.userdata.removeInvestor(Number(investorId));
        }
    }

    editInvestor(key: any) {
        this.isNewInvestor = false;
        this.formData = this.userdata.investorList[key];
        this.shouldShowInvestorForm = true;
    }

    addInvestor() {
        this.isNewInvestor = true;
        this.formData = null;
        this.shouldShowInvestorForm = true;
    }

    isInvestorDeletable(investor: CockpitInvestor) {
        return (investor.investments_count === 0);
    }

    onInvestorChangeCallback(updatedInvestor) {

        if (updatedInvestor.hasOwnProperty('fullname')) {
            delete updatedInvestor.fullname;
        } else {
            this.userdata.investorList.push({
                fullname: `${updatedInvestor.title} ${updatedInvestor.first_name} ${updatedInvestor.last_name}`,
                ...updatedInvestor
            });
        }

        this.userdata.investorList.forEach((investor, key) => {
            if (updatedInvestor.id === investor.id) {
                this.userdata.investorList[key] = {
                    fullname: updatedInvestor.first_name + ' ' + updatedInvestor.last_name,
                    ...updatedInvestor
                };
            }
        });

        this.$forceUpdate();
        this.shouldShowInvestorForm = false;
    }

    onFailure(error: RestError, id?: string): void {
        if (CockpitRequestApi.DELETE_INVESTOR === id) {
            const toastService = new ToastService();
            toastService.addToast(new Toast(this.trans.get('cockpit.pages.investors.investor_not_found_error'), 3000));
        }
    }

    onSuccess(response: AxiosResponse, id?: string): void {
        if (CockpitRequestApi.DELETE_INVESTOR === id) {
            if (response.config.url) {
                this.removeInvestorFromView(response.config.url);
            }
        }
    }
}

export default PageInvestors;
