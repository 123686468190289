import {Cached, Get, Post} from '@/decorators/RestDecorators';
import RequestCallback from '../RequestCallback';
import RestApi from '@/components/Rest/RestApi';
import RequestApi from '@/components/Rest/models/RequestApi';

class AuthRequestApi extends RequestApi {
    static GET_TOKEN = 'GET_TOKEN';
    static GET_TOKEN_FOR_USER = 'GET_TOKEN_FOR_USER';

    @Post('/auth/login/webapp')
    login(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/auth/jwt/create', AuthRequestApi.GET_TOKEN)
    getToken(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/auth/jwt/{id}/create', AuthRequestApi.GET_TOKEN_FOR_USER)
    getTokenForUser(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/auth/register/webapp')
    register(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/password/email')
    resetPassword(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/password/reset')
    resetPasswordComplete(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }
}

export default AuthRequestApi;
