import 'es6-promise/auto';
import cssVars from 'css-vars-ponyfill';
import smoothscroll from 'smoothscroll-polyfill';
require('formdata-polyfill');
require('babel-polyfill');

import Vue from 'vue/dist/vue';
import PortalVue from 'portal-vue';
Vue.use(PortalVue);

import AuthButton from './components/Auth/AuthButton.vue';
import AuthButtonLogout from './components/Auth/AuthButtonLogout.vue';
import AuthModals from './components/Auth/AuthModals.vue';
import CheckoutWpHGLite from './components/Checkout/CheckoutWpHGLite.vue';
import CheckoutVermAnlG from './components/Checkout/CheckoutVermAnlG.vue';
import InvestButton from './components/InvestButton/InvestButton.vue';
import ExporoModal from './components/Modal/ExporoModal.vue';
import ExporoModalService from './components/Modal/services/ExporoModalService.vue';
import UserEdit from './components/User/Edit/UserEdit.vue';
import ForceEmailCheckbox from './components/Checkout/ForceEmailCheckbox/ForceEmailCheckbox.vue';
import App from './App.vue';
import PubSubService from '@/components/PubSub/services/PubSubService';
import HomeFact from './components/Home/HomeFact.vue';
import ProjectTotalInvestCount from './components/Home/ProjectTotalInvestCount.vue';
// import Cockpit from '@/components/Cockpit/Cockpit.vue';
import CockpitV2 from '@/components/Cockpit/CockpitV2.vue';
import ContactCenter from '@/components/Cockpit/ContactCenter/ContactCenter.vue';
import Table from '@/components/Cockpit/List.vue';
import PasswordChange from '@/components/Auth/PasswordChange.vue';
import Contact from '@/components/Contact/Contact.vue';
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue';
import ProgressBarCSSTest from '@/components/ProgressBarCSSTest/ProgressBarCSSTest.vue';

import Checkbox from '@/components/Checkbox/Checkbox.vue';
import Select from '@/components/Select/Select.vue';
import Input from '@/components/Input/Input.vue';
import ErrorForm from '@/components/ErrorForm/ErrorForm.vue';
import Accordion from '@/components/Accordion/Accordion.vue';
import Iban from '@/components/Iban/IbanInput.vue';
import ActionCode from '@/components/ActionCode/ActionCode.vue';
import ImageUpload from '@/components/ImageUpload/ImageUpload.vue';
import Toggle from '@/components/Toggle/Toggle.vue';
import ToastContainer from '@/components/Toast/ToastContainer.vue';

import CTabBar from '@/components/Cockpit/Controls/CockpitTabBar.vue';
import ExporoSpinner from '@/components/ExporoSpinner/ExporoSpinner.vue';
import ExporoSimpleSpinner from '@/components/ExporoSimpleSpinner/ExporoSimpleSpinner.vue';

import SecondaryMarket from '@/components/SecondaryMarket/SecondaryMarket.vue';


import * as Sentry from '@sentry/vue';
import VersionManagerService from '@/components/VersionManager/VersionManagerService';
import Router from '@/components/Router/Router';
import Logger from '@/components/Helper/LogHelper';
import Gallery from '@/components/Gallery/Gallery.vue';
import GalleryButton from '@/components/Gallery/GalleryButton.vue';
import GalleryThumbnailsButton from '@/components/Gallery/GalleryThumbnailsButton.vue';
import AdobeIFrame from '@/components/AdobeCaptivate/AdobeIFrame.vue';
import TeaserProject from '@/components/TeaserProject/TeaserProject.vue';
import LoginToSeeModal from '@/components/TeaserProject/LoginToSeeModal.vue';
import CustomerRating from '@/components/CustomerRating/CustomerRating.vue';
export const RELEASE_VERSION: string = `exporo-webapp@2.1.0-${ExporoConfig.BUILD_NUMBER}`;

const browserOptions: any = {
    Vue,
    dsn: 'https://6ef2e3c1cc6445f982a3950cd01a37d4@o38895.ingest.sentry.io/5708932',
    environment: 'production',
    whitelistUrls: [
        /https?:\/\/((www|cockpit|backend|api.strategies|api.vault|wallet|baader|backend-cdn|kpis.dwh)\.)?exporo\.(de|com)/,
        /https?:\/\/((www|stats)\.)?pusher\.(de|com)/,
        /https?:\/\/((www|cdn)\.)?contentful\.(de|com)/
    ],
    beforeSend(event) {
        if (event.user && event.user.id) {
            return event;
        }
    }
};

Sentry.init(browserOptions);

VersionManagerService.getInstance().initVersion(RELEASE_VERSION);

const pubSubService = PubSubService.getInstance();
pubSubService.init();

KPIStore.getInstance().fetchKPIS();

cssVars();
smoothscroll.polyfill();

Vue.config.productionTip = false;
// Vue.config.performance = true;
import Lightbox from 'vue-my-photos';
import Button from '@/components/Button/Button.vue';
import ExporoSiri from '@/components/ExperimentalFeatures/ExporoSiri.vue';
import ExporoConfig from '@/ExporoConfig';
import KPIStore from '@/components/KPIS/KPIStore';
import KPIS from '@/components/KPIS/KPIS.vue';
Vue.component('lightbox', Lightbox);
// require('dotenv').config()


// Logger.isProduction = false;
// Logger.filter = 'GTM';

Vue.component('exporo-style', App);
Vue.component('Button', Button);
Vue.component('exporo-auth-button', AuthButton);
Vue.component('exporo-spinner-component', ExporoSpinner);
Vue.component('exporo-simple-spinner-component', ExporoSimpleSpinner);
Vue.component('exporo-auth-button-logout', AuthButtonLogout);
Vue.component('exporo-auth-modals', AuthModals);
Vue.component('exporo-checkout-vermanlg', CheckoutVermAnlG);
Vue.component('exporo-checkout-wphg-lite', CheckoutWpHGLite);
Vue.component('exporo-invest', InvestButton);
Vue.component('exporo-modal', ExporoModal);
Vue.component('exporo-modal-service', ExporoModalService);
Vue.component('exporo-user-edit', UserEdit);
Vue.component('exporo-contact-center', ContactCenter);
Vue.component('exporo-password-change', PasswordChange);
Vue.component('force-email-checkbox', ForceEmailCheckbox);
Vue.component('exporo-home-fact', HomeFact);
Vue.component('exporo-project-total-invest-count', ProjectTotalInvestCount);
// Vue.component('cockpit-page-overview', PageOverview);
// Vue.component('cockpit-page-ident', PageIdent);
// Vue.component('cockpit-page-contact-center', PageContactCenter);
// Vue.component('exporo-cockpit', Cockpit);
Vue.component('exporo-cockpit2', CockpitV2);
Vue.component('exporo-table', Table);
Vue.component('exporo-contact', Contact);
Vue.component('exporo-progress', ProgressBar);
Vue.component('exporo-progresstest', ProgressBarCSSTest);

Vue.component('checkbox', Checkbox);
Vue.component('exporo-select', Select);
Vue.component('exporo-input', Input);
Vue.component('error-msg', ErrorForm);
Vue.component('accordion', Accordion);
Vue.component('iban', Iban);
Vue.component('exporo-action-code', ActionCode);
Vue.component('imageupload', ImageUpload);
Vue.component('toggle', Toggle);
Vue.component('toast-container', ToastContainer);
Vue.component('exporo-gallery', Gallery);
Vue.component('gallery-button', GalleryButton);
Vue.component('gallery-thumbnails-button', GalleryThumbnailsButton);
Vue.component('adobeiframe', AdobeIFrame);

Vue.component('exporo-secondary-market', SecondaryMarket);
Vue.component('exporo-siri', ExporoSiri);

Vue.component('ctabbar', CTabBar);
Vue.component('teaser-project', TeaserProject);
Vue.component('login-to-see-modal', LoginToSeeModal);
Vue.component('customer-rating', CustomerRating);
Vue.component('exporo-kpis', KPIS);
const components: object = {
    'exporo-style': App,
    'exporo-auth-button': AuthButton,
    'exporo-spinner-component': ExporoSpinner,
    'exporo-simple-spinner-component': ExporoSimpleSpinner,
    'exporo-auth-button-logout': AuthButtonLogout,
    'exporo-auth-modals': AuthModals,
    'exporo-checkout-vermanlg': CheckoutVermAnlG,
    'exporo-checkout-wphg-lite': CheckoutWpHGLite,
    'exporo-invest': InvestButton,
    'exporo-modal': ExporoModal,
    'exporo-modal-service': ExporoModalService,
    'exporo-user-edit': UserEdit,
    'exporo-contact-center': ContactCenter,
    'force-email-checkbox': ForceEmailCheckbox,
    'exporo-home-fact': HomeFact,
    'exporo-project-total-invest-count': ProjectTotalInvestCount,
    // 'exporo-cockpit': Cockpit,
    'exporo-cockpit2': CockpitV2,
    // 'cockpit-page-overview': PageOverview,
    'exporo-table': Table,
    'exporo-password-change': PasswordChange,
    'exporo-contact': Contact,
    'exporo-progress': ProgressBar,
    'exporo-progresstest': ProgressBarCSSTest,

    'checkbox': Checkbox,
    'exporo-select': Select,
    'exporo-input': Input,
    'error-msg': ErrorForm,
    'accordion': Accordion,
    'iban': Iban,
    'exporo-action-code': ActionCode,
    'imageupload': ImageUpload,
    'toggle': Toggle,
    'toast-container': ToastContainer,
    'ctabbar': CTabBar,
    'exporo-secondary-market': SecondaryMarket,
    'exporo-gallery': Gallery,
    'gallery-button': GalleryButton,
    'gallery-thumbnails-button': GalleryThumbnailsButton,
    'adobeiframe': AdobeIFrame,
    'teaser-project': TeaserProject,
    'login-to-see-modal': LoginToSeeModal,
    'customer-rating': CustomerRating,
    'exporo-siri': ExporoSiri,
	'exporo-kpis': KPIS
};

const initSPAs = () => {
    Object.keys(components).forEach( (key) => {
        const exporoComponent = components[key];

        if ('exporo-modal' !== key && 'exporo-modal-service' !== key) {

            Array.prototype.forEach.call(document.querySelectorAll('.' + key), (el) => new Vue({
                el,
                components: {
                    exporoComponent
                }
            }));

        } else if ('exporo-modal-service' === key) {

            Array.prototype.forEach.call(document.querySelectorAll('.exporo-modal-service'), (el) => new Vue({
                el,
                components: {
                    ExporoModal,
                    ExporoModalService
                }
            }));
        }
    });

    Router.config({mode: 'hash'}).listen();
};

if (document.readyState !== 'loading') {
    Logger.tag('APP').log( 'document is already ready, init SPA`s' );
    initSPAs();
} else {
    document.addEventListener('DOMContentLoaded', () => {
        Logger.tag('APP').log( 'document was not ready, init SPA`s' );
        initSPAs();
    });
}


