import BaseRule from '../BaseRule';

export default class AcceptedIfRule extends BaseRule {

    constructor(rulesString, input, name, type) {
        super(rulesString, input, name, type);

        this._valid = this.validate();
    }

    validate() {
        const inputValuePair = this._rulesString.split(':')[1].split(',');
        const toCheckInput = inputValuePair[0];
        const value = inputValuePair[1];

        let toCheckInputNode: any = document.getElementById(toCheckInput);

        if (toCheckInputNode && 'div' === toCheckInputNode.tagName.toLowerCase()) {
            toCheckInputNode = toCheckInputNode.querySelectorAll('input')[0];
        }

        if (toCheckInputNode) {

            if (toCheckInputNode.value === value) {

                return this._input.checked;
            }
        }

        return true;
    }
}
