class ArrayHelper {


    static isStringPartExist(array: [], stringPart: string): boolean {

        return array.findIndex((part: string) => {

            return part.includes(stringPart);
        }) > -1;
    }

    static remove(array, key): any[] {
        const index = array.indexOf(key, 0);
        if (index > -1) {
            array.splice(index, 1);
        }

        return array;
    }

}

export default ArrayHelper;
