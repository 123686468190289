

































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Accordion from '../../Accordion/Accordion.vue';
import AccordionGroup from '../../Accordion/AccordionGroup.vue';
import InvestService from '../services/InvestService';
import ExplorationService from './services/ExplorationService';
import Knowledge from './Knowledge/Knowledge.vue';
import CheckoutValidator from '../Validator/CheckoutValidator';
import Error from '@/components/Checkout/Validator/Error.vue';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import ExplorationStructure from './data/ExplorationStructure';
import StoreInvestmentRequestService from '../services/StoreInvestmentRequestService';
import InvestmentRequestPart from '../models/InvestmentRequestPart';
import ExplorationHint from './ExplorationHint.vue';
import Select from '@/components/Select/Select.vue';
import Tracking from '@/components/GoogleTagManager/services/Tracking';

import ExporoVue from '@/components/ExporoVue';

@Component({
    components: {
        Accordion,
        AccordionGroup,
        Knowledge,
        Error,
        Checkbox,
        ExplorationHint,
        Select,
    },
    props: {
        shouldHide: Boolean,
        switchStep: Function,
    },
})
class ExplorationWpHGLite extends ExporoVue implements InvestmentRequestPart {

    private show = true;
    private showBranche = false;
    private explorationState: number = 0;
    public TITLE = this.trans.get('checkout.WpHGLite.explorationWpHGLite.title');
    public $data: any;
    @Prop()
    private shouldHide!: boolean;
    @Prop()
    private switchStep!: () => void;
    @Prop()
    private step!: number;
    @Prop()
    private checkoutValidator!: CheckoutValidator;

    public formData;

    @Prop()
    private investService!: InvestService;
    @Prop()
    private explorationService!: ExplorationService;
    @Prop()
    private explorationData!: any;
    @Prop()
    private storeInvestmentRequestService!: StoreInvestmentRequestService;

    private structure: ExplorationStructure = new ExplorationStructure();

    onJobChanged() {
        this.explorationService.updateValue();
        this.showBranche = this.structure.branche.showIfEmploymentStatus === this.explorationService.form.exploration.employmentStatus;
    }

    onValueChanged() {
        const value = +this.explorationService.form.exploration.value;
        this.show = !(value >= ExplorationService.MIN_VALUE);

        if (0 === value) {
            this.explorationState = 0;
            this.explorationService.form.exploration.explorationWarning = ExplorationHint.STATE_0;
        }

        if (value > 0) {
            this.explorationState = 1;
            this.explorationService.form.exploration.explorationWarning = ExplorationHint.STATE_1;
        }

        if (!this.show) {
            this.explorationState = 2;
            this.explorationService.form.exploration.explorationWarning = ExplorationHint.STATE_2;
        }
    }

    constructor() {
        super();

        this.explorationService.setValueChangedCallback(this.onValueChanged);
        this.storeInvestmentRequestService.registerRequestPart(this);
    }

    getRequestPart(): object {

        return this.explorationService.form;
    }

}

export default ExplorationWpHGLite;
