import TableData from '@/components/Cockpit/models/TableData';
import Events from '../../../events';
import Investment from '@/components/Cockpit/Financing/interfaces/investment';

class TableDataService {
    private static _instance: TableDataService | null = null;
    private _tabledata: {} = {};

    constructor() {

    }

    static add(id: string, data: any) {
        const instance: TableDataService = this.getInstance();

        if (!instance._tabledata.hasOwnProperty(id)) {

            if ('financing' === id) {
                instance._tabledata[id] = data;
            } else {
                instance._tabledata[id] = new TableData(data);
            }

            Events.$emit(`${id}DetailLoaded`);
        }
    }

    static get(id: string): TableData | Investment[] {
        const instance: TableDataService = this.getInstance();

        return instance._tabledata[id];
    }

    static getInstance() {
        if (!TableDataService._instance) {
            TableDataService._instance = new TableDataService();
        }

        return TableDataService._instance;
    }
}

export default TableDataService;
