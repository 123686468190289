












































import {Component, Prop, Vue} from 'vue-property-decorator';

import ExporoVue from '../ExporoVue';
import Events from '../../events';
import Scrollbar from './Scrollbar.vue';
import Tracking from '@/components/GoogleTagManager/services/Tracking';
import URLHelper from '@/components/Helper/URLHelper';

@Component({
    components: {
        Scrollbar
    }
})
export default class ExporoModal extends ExporoVue {

    @Prop()
    private name!: string;

    @Prop() private mobilePropStyles!: object;

    @Prop()
    private scrollable!: string;

    @Prop({default: false})
    private nestedOverlay!: boolean;

    @Prop()
    private onClose!: () => void;

    @Prop({default: '900px'})
    private width!: string;

    @Prop({default: 'false'})
    private fullscreen!: string;

    @Prop({default: false})
    private hideCloseIcon!: boolean;

    @Prop({default: false})
    private preventNavigateOnClosing!: boolean;

    private shouldFullscreen: boolean = false;
    private widthStyle: string = '900px';
    private marginLeftStyle: string = '-450px';
    private open: boolean = false;
    private nextButtonText: string = this.trans.get('checkout.modal_controls.next');
    private step: number = 0;
    private shouldHide: boolean = false;
    private scrollableMounted: boolean = false;
    private checkoutNextText: string = ExporoVue.CHECKOUT_NEXT_TEXT;


    private controls: object | null = null;

    private mobileHeightInVH: number = window.innerHeight;

    constructor() {
        super();
    }

    triggerAction(action: string) {
        if (this.controls && this.controls.hasOwnProperty('id')) {
            Events.$emit((this.controls as { id }).id + action);
        }
    }

    mounted() {
        this.shouldFullscreen = 'true' === this.fullscreen;
        this.initStyles();
        this.initObserver();

        window.addEventListener('resize', () => {
            // @TODO:  Remove listeneres!!!
            this.mobileHeightInVH = window.innerHeight;
        });

    }

    isScrollable(): boolean {

        return void (0) !== this.scrollable;
    }

    initControls() {
        const defaultSlots = this.$slots.default;
        const scrollable: HTMLElement = this.$refs.scrollable as HTMLElement;

        scrollable?.scrollIntoView({block: 'start', behavior: 'smooth'});

        if (defaultSlots && defaultSlots.length > 0) {
            const childVue: any = defaultSlots[0].componentInstance;

            if (childVue && childVue.hasOwnProperty('getActions')) {
                const ob = childVue.getActions();
                if (ob && ob.hasOwnProperty('id')) {
                    this.controls = ob;
                    this.scrollableMounted = true;

                    Events.$on(ob.id || '', (value) => {
                        this.nextButtonText = value.nextButtonText;
                        this.step = value.step;
                        this.shouldHide = value.shouldHide;
                        if (value.hasOwnProperty('scrollTo')) {
                            scrollable.scrollTo(0, value.scrollTo);
                        }
                    });
                }
            }
        }
    }

    initStyles() {
        if (this.shouldFullscreen) {
            this.widthStyle = '98%';
            this.marginLeftStyle = '0';
        } else {
            this.widthStyle = Number(this.width.split('px')[0]) + 'px';
            this.marginLeftStyle = '-' + (Number(this.width.split('px')[0]) / 2) + 'px';
        }
    }

    initObserver() {
        const scrollable: HTMLElement = this.$refs.scrollable as HTMLElement;

        Events.$on('validationError', (value) => {

            this.$nextTick(() => {

                scrollable.scrollTo(0, value.scrollTop);
            });
        });

        const observer = new MutationObserver((event: any) => {
            const record: any = event[0];
            if (record) {
                this.open = record.target.className.indexOf('open') > -1;
                if (this.open) {
                    this.$nextTick(() => {
                        this.initControls();
                    });
                }
            }
        });

        const modal = this.getModalService().getModalByName(this.name);

        if (modal) {
            observer.observe(modal, {
                attributes: true,
                attributeFilter: ['class'],
                childList: false,
                characterData: false
            });
        }
    }

    closeModal() {
        const defaultSlots = this.$slots.default;

        if (defaultSlots && defaultSlots.length > 0) {
            const childVue: any = defaultSlots[0].componentInstance;

            if (childVue && childVue.onCloseModal) {
                childVue.onCloseModal();
            }
        }

        this.shouldHide = false;

        if (URLHelper.hasFragment('login')) {
            Tracking.trackClose('login form', false, undefined);
        }
        if (URLHelper.hasFragment('register')) {
            Tracking.trackClose('registration form', false, undefined);
        }

        if (this.onClose) {
            this.onClose();
        }

        this.getModalService().closeModal(this.name, {}, this.preventNavigateOnClosing);
    }

    getWrapperStyle() {
        if (this.isScrollable()) {
            return {
                height: '100%',
                width: '100%',
                overflow: 'hidden',
                paddingTop: '50px'
            };
        } else {
            return {};
        }
    }

    getHeaderStyle() {
        if (this.isScrollable()) {
            return {
                position: 'absolute',
                top: '0',
                left: '0'
            };
        } else {
            return {};
        }
    }

    getOverlayStyle() {

        const defaultStyle =  {
            height: this.mobileHeightInVH + 'px !important'
        };

        const encapsulatedStyle = {
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: 'unset'
        };

        return this.nestedOverlay ? encapsulatedStyle : defaultStyle;
    }

    getContentStyle() {
        if (this.isScrollable()) {
            return {
                height: '90%',
                width: '100%',
                padding: 'inherit',
                paddingTop: '0',
                paddingRight: '17px',
                overflowY: 'scroll',
                boxSizing: 'content-box'
            };
        } else {
            return {};
        }
    }

    getModalStyle() {

        if (this.isScrollable()) {
            return {
                width: this.widthStyle,
                marginLeft: this.marginLeftStyle,
                height: '85vh',
                top: '2vh',
                marginBottom: '50px'
            };
        }

        if (this.isMobile) {

            return {
                width: '100%',
                marginLeft: '0'
            };
        }

        return {
            width: this.widthStyle,
            marginLeft: this.marginLeftStyle
        };
    }

}
