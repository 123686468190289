import Events from '@/events';
import Routes from '@/components/Router/routes';

class Router {
    private static _instance: Router | null = null;

    private _routes: any[];
    private _mode: string | null;
    private _root: string;
    private _initialized: boolean = false;
    private _routeHelper: Routes;

    constructor(mode?: string) {
        this._routes = [];
        this._mode = mode || 'hash';
        this._root = '/';
        this._routeHelper = new Routes();
    }

    static config(options: any): Router {
        const instance: Router = Router.getInstance();

        instance._mode = options && options.mode && options.mode === 'history' && !!(history.pushState) ? 'history' : 'hash';
        instance._root = options && options.root ? '/' + instance.clearSlashes(options.root) + '/' : '/';

        return instance;
    }

    static getFragment() {
        const instance: Router = Router.getInstance();

        return instance.getFragment();
    }

    getFragment() {
        let fragment = '';
        if ('history' === this._mode) {
            fragment = this.clearSlashes(decodeURI(location.pathname + location.search));
            fragment = fragment.replace(/\?(.*)$/, '');
            fragment = this._root !== '/' ? fragment.replace(this._root, '') : fragment;
        } else {
            const uri = decodeURI(window.location.href);
            const match = uri.match(/#(.*)$/);
            fragment = match ? match[1] : '';
        }

        return this.clearSlashes(fragment);
    }

    clearSlashes(path) {
        return path.toString().replace(/\/$/, '').replace(/^\//, '');
    }

    remove(param) {
        for (const [i, route] of this._routes.entries()) {
            if (route.handler === param || route.re.toString() === param.toString()) {
                this._routes.splice(i, 1);
                return this;
            }
        }
        return this;
    }

    flush() {
        this._routes = [];
        this._mode = null;
        this._root = '/';

        return this;
    }

    check(f) {
        const fragment = f || this.getFragment();

        for (const route of this._routes) {
            const match = fragment.match(route.re);

            if (match) {
                match.shift();
                Events.$emit(route.methodName, match);

                return this;
            }
        }

        return this;
    }

    listen() {
        this._routeHelper.initRoutes();

        let current = this.getFragment();
        const fn = () => {
            if (current !== this.getFragment()) {
                current = this.getFragment();
                this.check(current);
            }
        };

        if (false === this._initialized) {
            this.check(current);
            window.addEventListener('hashchange', fn);
            this._initialized = true;
        }

        return this;
    }


    static navigate(name: string, parameters?: any[]) {
        const instance: Router = Router.getInstance();
        window.location.href = window.location.href.replace(/#(.*)$/, '') + instance._routeHelper.getRoute(name, parameters || []);
    }


    static getInstance(mode?: string) {
        if (!Router._instance) {
            Router._instance = new Router(mode);
        }

        return Router._instance;
    }

    static add(re: any, methodName: string): Router {
        const instance: Router = Router.getInstance();

        instance._routes.push({ re: re, methodName: methodName});

        return instance;
    }
}

export default Router;
