









import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
class RightArrow extends Vue {

    constructor() {
        super();
    }
}

export default RightArrow;
