import SecondaryMarketIntegration from '@/components/Rest/integrations/SecondaryMarketIntegration';


export function SecondaryMarket(id?: string) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        descriptor.value = () => {

            return {
                id: id ? id : propertyKey,
                integration: new SecondaryMarketIntegration()
            };
        };
    };
}
