class InvestEntryData {

    private _id: number;
    private _data: object;

    constructor(id: number, data: object) {
        this._id = id;
        this._data = data;
    }

    get id(): number {

        return this._id;
    }

    get data(): object {

        return this._data;
    }
}

export default InvestEntryData;
