import ExporoVue from '@/components/ExporoVue';

class ExplorationStructure extends ExporoVue {

    public employmentStatus: any = {
        title: this.trans.get('checkout.employment_status.job_category'),
        mappingKey: 'employmentStatus'
    };

    public branche: any = {
        title: this.trans.get('checkout.employment_status.branche.title'),
        mappingKey: 'job_0',
        showIfEmploymentStatus: 'self_employed'
    };

    public explorations: any[] = [
        {
            title: this.trans.get('checkout.investments.subordinate_investments'),
            factor: 0.3333,
            mappingKey: 'subordinated',
            knowledges: [
                {
                    mappingKey: 'subordinated_0'
                },
                {
                    mappingKey: 'subordinated_1'
                },
                {
                    mappingKey: 'subordinated_2'
                }
            ]
        }, {
            title: this.trans.get('checkout.investments.equity_interest'),
            factor: 0.3333,
            mappingKey: 'equity',
            knowledges: [
                {
                    mappingKey: 'equity_0'
                }, {
                    mappingKey: 'equity_1'
                }, {
                    mappingKey: 'equity_2'
                }
            ]
        }, {
            title: this.trans.get('checkout.investments.shares'),
            factor: 0.3333,
            mappingKey: 'stocks',
            knowledges: [
                {
                    mappingKey: 'stocks_0'
                }, {
                    mappingKey: 'stocks_1'
                }, {
                    mappingKey: 'stocks_2'
                }
            ]
        }, {
            title: this.trans.get('checkout.investments.bonds'),
            factor: 0.3333,
            mappingKey: 'bonds',
            knowledges: [
                {
                    mappingKey: 'bonds_0'
                }, {
                    mappingKey: 'bonds_1'
                }, {
                    mappingKey: 'bonds_2'
                }
            ]
        }, {
            title: this.trans.get('checkout.investments.open_stock'),
            factor: 0.3333,
            mappingKey: 'funds',
            knowledges: [
                {
                    mappingKey: 'funds_0'
                }, {
                    mappingKey: 'funds_1'
                }, {
                    mappingKey: 'funds_2'
                }
            ]
        }
    ];
}

export default ExplorationStructure;
