import ExporoVue from '../ExporoVue';
import ContextService from '../Router/services/ContextService';
import Events from '@/events';

class Controller extends ExporoVue {

    public className: string = 'ClassName';

    getName(): string {

        return this.className;
    }

    getClassName(): string {

        return this.className;
    }

    constructor(name: string) {
        super();

        this.className = name;

        ContextService.getInstance().setContext(this, this.className);

        Events.$emit(`${this.className}Loaded`);
    }
}

export default Controller;
