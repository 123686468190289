import {isNullOrUndefined} from 'util';

class VueComponentHelper {

    static getParentsComponentByClassName(startComponent: any, name: string) {
        let currentComponent = startComponent.$parent;
        let component = null;
        let maxTries = 0;

        do {
            maxTries++;

            if (currentComponent) {
                if (currentComponent.$el.className.indexOf(name) >= 0) {
                    component = currentComponent;
                } else {
                    currentComponent = currentComponent.$parent;
                }
            }
        } while (null === component && maxTries <= 5);

        return component;
    }

    static getParentsComponentByMthodseImplemented(startComponent: any, methods: string[]) {
        let currentComponent = startComponent.$parent;
        let component = null;
        let maxTries = 0;

        do {
            maxTries++;

            if (currentComponent) {
                let allMethodsImplemented: boolean = true;

                methods.forEach((method: string) => {
                    if (undefined === currentComponent[method]) {
                        allMethodsImplemented = false;
                    }
                });

                if (allMethodsImplemented) {
                    component = currentComponent;
                } else {
                    currentComponent = currentComponent.$parent;
                }
            }
        } while (null === component && maxTries <= 5);

        return component;
    }
}

export default VueComponentHelper;
