

















import {AxiosResponse} from 'axios';
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CockpitButton from '../Controls/CockpitButton.vue';
import CockpitProgress from '../Controls/CockpitProgress.vue';
import CockpitExpander from '../Controls/CockpitExpander.vue';
import LoadingAnimation from '@/components/LoadingAnimation/LoadingAnimation.vue';
import ProgressData from '../models/ProgressData';
import UserData from '../models/UserData';
import Events from '@/events';
import RestApi from '../../Rest/RestApi';
import RequestCallback from '@/components/Rest/RequestCallback';
import RestError from '@/components/Rest/models/RestError';
import {HOST} from '../../Rest/services/RestApiService';
import UserRequestApi from '@/components/Rest/requests/UserRequestApi';
import ExporoVue from '@/components/ExporoVue';

@Component({
    components: {
        CockpitButton,
        CockpitProgress,
        CockpitExpander,
        LoadingAnimation
    }
})
class CockpitIdentification extends ExporoVue implements RequestCallback {

    private progressData: ProgressData;
    private isLoading: boolean = false;
    private windowRef: any;
    @Prop() private userdata!: UserData;
    @Prop() private userRestApi!: RestApi;

    constructor() {
        super();
        const identState = this.userdata.identificationState;
        let progress = 1;
        if ('open' === identState) { progress = 2; } else if ('success' === identState) { progress = 3; }
        this.progressData = new ProgressData([
            this.trans.get('cockpit.ident.states.open'),
            this.trans.get('cockpit.ident.states.running'),
            this.trans.get('cockpit.ident.states.done')], progress);
    }

    intentIdentification() {
        if (this.userdata.identificationUrl) {
            this.isLoading = true;
            this.windowRef = window.open();
            (this.userRestApi as any).getIdentUrl(this).addParam('url', this.userdata.identificationUrl.split(HOST)[1]);
        }
    }

    onFailure(error: RestError, id?: string): void {
        this.isLoading = false;
    }

    onSuccess(response: AxiosResponse, id?: string): void {
        this.isLoading = false;
        if (UserRequestApi.GET_IDENT_KEY === id && response.data.hasOwnProperty('redirectUrl')) {
            this.windowRef.location = response.data.redirectUrl;
        }
    }
}

export default CockpitIdentification;
