








import {Component, Vue, Prop, Watch} from 'vue-property-decorator';

@Component
export default class LoadingAnimation extends Vue {
    constructor() {
        super();
    }
}
