






























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '../../ExporoVue';
import CockpitPanel from '../Controls/CockpitPanel.vue';
import CockpitExpander from '../Controls/CockpitExpander.vue';
import UserData from '../models/UserData';
import DateFormatHelper from '../../Helper/DateFormatHelper';
import CockpitTabBar from '../Controls/CockpitTabBar.vue';
import OptionEntry from '../models/OptionEntry';
import CockpitRequestApi from '@/components/Rest/requests/CockpitRequestApi';
import RequestCallback from '@/components/Rest/RequestCallback';
import {AxiosResponse} from 'axios';
import RestError from '@/components/Rest/models/RestError';
import InvestmentPlan20 from '@/components/Cockpit/InvestList/InvestmentPlan20.vue';

@Component({
    components: {
        CockpitPanel,
        CockpitExpander,
        CockpitTabBar,
        InvestmentPlan20
    },
    filters: {
        slugToTitle(slug) {
            slug = slug.split('-');
            for (let i = 0; i < slug.length; i++) {
                slug[i] = slug[i].charAt(0).toUpperCase() + slug[i].slice(1);
            }
            return slug.join(' ');
        }
    }
})
class PageInvestplan extends ExporoVue implements RequestCallback {

    private isExpanded: boolean = false;
    private kpis: object[] = [];
    private documents: object[] = [];
    private projects: object[] = [];
    private planOptions: any[][] = [[]];
    private cockpitRequestApi: CockpitRequestApi;
    private panelIndex: number = 0;
    private documentData: any = null;
    private showInvestmentPlan10: boolean = true;
    @Prop() private userdata!: UserData;

    constructor() {
        super();
        this.cockpitRequestApi = this.restApi.create(new CockpitRequestApi());
    }

    initializeTabs() {
        if (this.userdata && this.userdata.investmentplans.length) {
            for (let i = 0; i < this.userdata.investmentplans.length; i++) {
                this.planOptions[0].push(
                    new OptionEntry(
                        '#' + (i + 1),
                        'investmentplan' + (i + 1),
                        () => {
                            this.panelIndex = i;
                            this.loadInvestmentplan();
                            this.getDocumentData();
                            this.showInvestmentPlan10 = true;
                        }
                    )
                );
            }
            this.planOptions[0].push(
                new OptionEntry(
                    '#New',
                    'new',
                    () => {
                        this.panelIndex = this.userdata.investmentplans.length + 1;
                        this.showInvestmentPlan10 = false;
                    }
                )
            );
            this.loadInvestmentplan();
            this.getDocumentData();
        } else if (this.userdata) {
            this.showInvestmentPlan10 = false;
        }
    }

    mounted() {
        this.initializeTabs();
    }

    getDocumentData() {
        this.cockpitRequestApi.getSavingPlanData(this).addParam('userId', this.userdata.rawData.userData.id).addParam('savingsId', this.userdata.investmentplans[this.panelIndex].id);
    }

    loadInvestmentplan() {
        const investmentplan = this.userdata.investmentplans[this.panelIndex];
        const DateFormat = ((x) => DateFormatHelper.getFormattedDateFromString(x, true));

        this.kpis = [];

        this.kpis.push({
            key: this.trans.get('cockpit.pages.investplan.kpis.id'),
            value: investmentplan.id
        });

        this.kpis.push({
            key: this.trans.get('cockpit.pages.investplan.kpis.contributions_sum'),
            value: investmentplan.contributions_sum
                * investmentplan.contributions_quantity + '€'
        });

        this.kpis.push({
            key: this.trans.get('cockpit.pages.investplan.kpis.contributions_sum_month'),
            value: investmentplan.contributions_sum + '€'
        });

        this.kpis.push({
            key: this.trans.get('cockpit.pages.investplan.kpis.contributions_phase_starts_at'),
            value: DateFormat(investmentplan.savingsplan_contract.contributions_phase_starts_at)
        });

        this.kpis.push({
            key: this.trans.get('cockpit.pages.investplan.kpis.interestrate_guaranteed'),
            value: investmentplan.interestrate_guaranteed + '%'
        });

        this.kpis.push({
            key: this.trans.get('cockpit.pages.investplan.kpis.contributions_phase_ends_at'),
            value: DateFormat(investmentplan.savingsplan_contract.contributions_phase_ends_at)
        });

        this.kpis.push({
            key: this.trans.get('cockpit.pages.investplan.kpis.next_payout'),
            value: DateFormat(investmentplan.next_payout)
        });

        this.kpis.push({
            key: this.trans.get('cockpit.pages.investplan.kpis.investor'),
            value: (this.getFullnameFromInvestor(investmentplan.investor) || this.userdata.fullname)
        });

        this.kpis.push({
            key: this.trans.get('cockpit.pages.investplan.kpis.expected_interest_rate'),
            value: investmentplan.expected_interest_rate + '%'
        });

        this.kpis.push({
            key: this.trans.get('cockpit.pages.investplan.kpis.status'),
            value: this.trans.get('cockpit.pages.investplan.kpis.status_repaid')
        });

        this.projects = this.userdata.investmentplanProjects;


    }

    getFullnameFromInvestor(investor: any) {
        let fullname: any = null;

        if (investor) {
            fullname = `${investor.first_name} ${investor.last_name}`;
        }

        return fullname;
    }

    onSuccess(response: AxiosResponse): void {
        this.documentData = response.data;
    }

    onFailure(error: RestError, id?: string): void {
    }

}

export default PageInvestplan;
