












import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '../../ExporoVue';
import PageData from '@/components/Cockpit/models/PageData';

@Component
class KpiPanel extends ExporoVue {
    @Prop() private pagedata!: PageData;

    constructor() {
        super();
    }
}

export default KpiPanel;
