import {Post} from '@/decorators/RestDecorators';
import RequestCallback from '../RequestCallback';
import RestApi from '@/components/Rest/RestApi';
import RequestApi from '@/components/Rest/models/RequestApi';

class LegalMailRequestApi extends RequestApi {

    @Post('/api/send-vib-legal-email')
    sendLegalMail(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/api/send-bondlight-legal-mail')
    sendBondLightLegalMail(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/api/send-baader-bank-email')
    sendBaaderBankMail(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }
}

export default LegalMailRequestApi;
