import RestErrorInterface from '@/components/Rest/models/RestErrorInterface';

class RestError {
    public errorCode: number;
    public errorMessage: any;
    public response: any;

    constructor(error: RestErrorInterface, response?: any) {
        this.errorCode = error.errorCode;
        this.errorMessage = error.errorMsg;
        this.response = response;
    }
}

export default RestError;
