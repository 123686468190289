






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
class PageView extends Vue {

    @Prop({default: '0'})
    public id!: string;
    public isVisible: boolean = false;

    constructor() {
        super();
    }

    onChanged() {
        // @TODO
    }
}

export default PageView;
