








import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import DropDown from '@/components/DropDown/DropDown.vue';
import ExporoVue from '@/components/ExporoVue';

@Component({
    components: {
        DropDown
    }
})
class DropDownGroup extends ExporoVue {
    @Prop() private dropDowns!: DropDown[];
    @Prop() private onChange!: (string, number) => number | string;
}

export default DropDownGroup;
