export enum InvestmentType {
    LOAN,
    SIGNING,
    MEZZBOND
}

interface Investment {
    getInvestSum(): number;
    isRunning(): boolean;
    getTitle(): string;
    getInterest(): number;
    getPeriod(): string;
    getCreatedAt(): string;
    getInvestor(): string;
    getFinancingEntity(): string;
    getId(): number;
    getDatePayBack(): string;
    getPaidAt(): string | null;
    getType(): InvestmentType;
}

export default Investment;
