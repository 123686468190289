import Events from '../../../events';

// @TODO: Deprecated
/* tslint:disable */
class InvestService {
    static investChangedChannel = 'investChanged';

    private INVEST_TYPE_PREPAY = 'prepay';
    private INVEST_TYPE_DEBIT = 'debit';
    private investType = 'default';
    private investSum: number | null = 0;
    private couponAdditional = 0;
    private couponAdditionalRate = 0;
    private investor: models.Investor | null = null;
    private couponRaw: string | null = null;
    private couponId: number | null = null;

    private formData: any = {
        pieces: 1
    };

    public changeFormByKey(name: string, value: string) {
        this.formData[name] = value;
    }

    public mergeFormData(exploration: any) {

        this.formData = {...this.formData, ...exploration};

        if ((this.INVEST_TYPE_PREPAY || this.INVEST_TYPE_PREPAY) === this.investType) {
            this.formData['investtype'] = this.investType;
        }

        if (this.getInvestSum() > 0) {
            this.formData['investsum'] = this.investSum;
        }

        if (this.couponRaw && this.couponId) {
            this.formData['coupon_raw'] = this.couponRaw;
            this.formData['coupon_id'] = this.couponId;
        }
    }

    public setCoupon(coupon: string, id: number) {
        this.couponRaw = coupon;
        this.couponId = id;
    }

    public updateFomData(data: string, value: any) {
        this.formData[data] = value;
    }

    public getFormData(filtered?: boolean) {

        return this.formData;

        const formData = new FormData();

        if (filtered) {
            for (const key in this.formData) {
                if (this.formData.hasOwnProperty(key)) {
                    if ('off' === this.formData[key]) {
                        delete this.formData[key];
                    } else {
                        formData.append(key, this.formData[key]);
                    }
                }
            }
        }

        return formData;
    }

    public getInvestType() {
        return this.investType;
    }

    public setInvestType(type: string) {
        this.investType = type;
        Events.$emit(InvestService.investChangedChannel);
    }

    public getInvestSum(): number {

        return this.investSum ? +this.investSum : 0;
    }

    public setInvestSum(sum: number | null) {
        this.investSum = sum;
        Events.$emit(InvestService.investChangedChannel);
    }

    public setCouponAdditional(additional: number) {
        this.couponAdditional = additional;
        Events.$emit(InvestService.investChangedChannel);
    }

    public getCouponAdditional(): number {

        return this.couponAdditional;
    }

    public setInvestor(investor: models.Investor | null) {
        this.investor = investor;
        Events.$emit(InvestService.investChangedChannel);
    }

    public getInvestor(): models.Investor | null {

        return this.investor;
    }

    getInvestTypeDebit() {

        return this.INVEST_TYPE_DEBIT;
    }

    getInvestTypePrepay() {

        return this.INVEST_TYPE_PREPAY;
    }
}

export default InvestService;
