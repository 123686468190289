





































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import URLHelper from '../Helper/URLHelper';
import CookieHelper from '../Helper/CookieHelper';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import ExporoVue from '@/components/ExporoVue';
import ExporoModal from '@/components/Modal/ExporoModal.vue';
import Toggle from '@/components/Toggle/Toggle.vue';
import Controller from '@/components/Router/Controller';
import ExporoTokenService from '@/components/JWT/services/ExporoTokenService';
import BulletPoint from '@/components/QuerySettings/BulletPoint.vue';
import Events from '../../events';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/types';

const axios = require('axios');

@Component({
  components: {
    ExporoModal,
    Toggle,
    BulletPoint
  }
})
class QuerySettings extends ExporoVue {

  private savedSettings: any = null;
  private showPPModal: boolean = false;
  private ppVariant: any = '1';
  private matchingUser: any = null;
  private wasUserAlreadyThere: boolean = false;
  private shouldFixLeads: boolean = true;
  private checked: boolean = false;

  constructor() {
    super();
  }

  gotToExclusive() {
    window.location.href = `https://exclusive.exporo.de?user_id=${this.matchingUser}`;
  }

  onClose() {
    window.location.href = window.location.href.split('?')[0];
  }

  onToggle(isChecked: boolean, name: string) {

    if ('speec' === name) {

      if (isChecked) {
        navigator.permissions.query({name: 'microphone'}).then((permissionStatus: any) => {

          const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
          const speechRecognition = new SpeechRecognition();

          if ('granted' !== permissionStatus.state) {
            this.savedSettings.speec = false;
            speechRecognition.start();
          }

          permissionStatus.onchange = (event: any) => {
            this.savedSettings.speec = 'granted' === event.target.state;

            LocalStorage.addBoolean(ExporoVue.ACTIVATE_SPEEC_KEY, this.savedSettings.speec);
            speechRecognition.stop();
          };
        });
      } else {
        LocalStorage.addBoolean(ExporoVue.ACTIVATE_SPEEC_KEY, isChecked);
      }
    }

    if ('spinner' === name) {
      LocalStorage.addBoolean(ExporoVue.ACTIVATE_SPINNER_KEY, isChecked);
    }
  }


  checkPPModal(userId: any) {
    if (this.checked) return;

    this.matchingUser = userId;
    let ppModalAlreadyShown: number = LocalStorage.get(ExporoVue.PP_MODAL_ALREADY_SHOWN, 0);
    this.wasUserAlreadyThere = ppModalAlreadyShown !== 0;
    this.shouldFixLeads = LocalStorage.getBoolean('shouldFixLead', true);

    if (this.wasUserAlreadyThere && this.shouldFixLeads) {
      ppModalAlreadyShown = 0;
      LocalStorage.addBoolean('shouldFixLead', false);
    }

    const idAsNumber = Number(userId);
    const isGerman: boolean = 'de' === this.cctld.toLowerCase();

    if (isGerman && idAsNumber && ppModalAlreadyShown < 1 && window.location.href.indexOf('#/cockpit') > -1) {

      axios.get('https://kpis.dwh.exporo.de/private_placement/pp_investors.json').then((response: any) => {
        const ppInvestors: number[] = response.data.private_placement_investor_ids;
        // this.showPPModal = ppInvestors.indexOf(idAsNumber) === -1;

        if (this.showPPModal) {
          LocalStorage.remove(ExporoVue.PP_MODAL_ALREADY_SHOWN);
          this.$nextTick(() => {
            ppModalAlreadyShown++;
            LocalStorage.add(ExporoVue.PP_MODAL_ALREADY_SHOWN, ppModalAlreadyShown);
          });
        }
      });
    }

    this.checked = true;
  }

  mounted() {
    const userId = LocalStorage.get(ExporoVue.USER_ID_KEY, null);

    if (userId) {
      this.checkPPModal(userId);
    } else {
      Events.$on('userIdLoaded', () => {
        const userId = LocalStorage.get(ExporoVue.USER_ID_KEY, null);
        this.checkPPModal(userId);
      });
    }

    Events.$on('openCockpit', () => {
      const userId = LocalStorage.get(ExporoVue.USER_ID_KEY, null);
      this.checkPPModal(userId);
    });

    const ppModal = URLHelper.getQueryParam('ppModal');

    if (ppModal) {
      this.ppVariant = ppModal;
      this.showPPModal = true;
    }

    const jwtRedirect = URLHelper.getQueryParam('jwtRedirect');

    if (jwtRedirect) {
      const tokenService = new ExporoTokenService();
      tokenService.fetchToken((token: string) => {
        if (token) {
          window.location.href = `${jwtRedirect}?jwtToken=${token}`;
        }
      });
    }

    const aaid = URLHelper.getQueryParam('a_aid');
    if (aaid && null === CookieHelper.getCookie('affiliated')) {
      CookieHelper.setCookie('affiliated', aaid);
    }

    const a_aid_ref = URLHelper.getQueryParam('a_aid_ref');
    if (a_aid_ref && null === CookieHelper.getCookie('a_aid_ref')) {
      CookieHelper.setCookie('a_aid_ref', a_aid_ref);
    }

    const isPortfolioFeature = URLHelper.hasQueryParam('portfolio-v2');
    if (isPortfolioFeature && null === CookieHelper.getCookie('portfolio-v2')) {
      CookieHelper.setCookie('portfolio-v2', 'true');
    }

    const cockpitSettings = URLHelper.getQueryParam('cockpit');

    if (cockpitSettings && 'v2' === cockpitSettings) {
      CookieHelper.setCookie('exporo-cockpit-version', cockpitSettings);
    } else if (cockpitSettings && 'v2' !== cockpitSettings) {
      CookieHelper.setCookie('exporo-cockpit-version', 'v1');
    }

    const cockpitUserId = URLHelper.getQueryParam('userid');

    if (cockpitUserId) {
      LocalStorage.add(ExporoVue.USER_ID_KEY, cockpitUserId);
    }

    const debug = URLHelper.getQueryParam('debug');

    if (debug) {
      LocalStorage.addBoolean(ExporoVue.ACTIVATE_LOGS_KEY, '1' === debug);
    }

    const hasExperimentalFeatures = URLHelper.hasQueryParam('experimental-features');

    if (hasExperimentalFeatures) {

      const useSpinner = LocalStorage.getBoolean(ExporoVue.ACTIVATE_SPINNER_KEY, false);
      const useSpeec = LocalStorage.getBoolean(ExporoVue.ACTIVATE_SPEEC_KEY, false);

      this.savedSettings = {
        speec: useSpeec,
        spinner: useSpinner
      };

      this.getModalService().openModal('experimental-features', {
        height: 'auto',
        width: '600px',
        top: '10%'
      });
    }

  }
}

export default QuerySettings;
