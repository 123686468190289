











































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CockpitExpander from './CockpitExpander.vue';
import ExporoVue from '@/components/ExporoVue';
import Events from '@/events';
import Update from '@/components/Cockpit/interfaces/Update';

@Component({
    components: {
        CockpitExpander
    }
})
class CockpitTimeline extends ExporoVue {

    @Prop() readonly updates!: Update[];
    private active: number = 99;

    setActive(active) {
        if (this.active !== active) {
            this.active = active;
        } else {
            this.active = 99;
        }
    }

    emit(index, newsIndex) {
        Events.$emit('changeGalleryIndex', {index: index, newsIndex: newsIndex});
    }
}

export default CockpitTimeline;

