






































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Accordion from '../../../Accordion/Accordion.vue';
import AccordionGroup from '../../../Accordion/AccordionGroup.vue';
import KnowledgeService from './services/KnowledgeService';
import ExplorationService from '../services/ExplorationService';
import Select from '@/components/Select/Select.vue';
import ExporoVue from '@/components/ExporoVue';


const STANDARD_KEY = '0';

@Component({
    components: {
        Accordion,
        AccordionGroup,
        Select,
    },
})
class Knowledge extends ExporoVue {

    @Prop()
    private name!: string;
    @Prop()
    private explorationService!: ExplorationService;
    private knowledge = STANDARD_KEY;
    private knowledgeService: KnowledgeService;

    private howLong: string = STANDARD_KEY;
    private howMuch: string = STANDARD_KEY;
    private howMany: string = STANDARD_KEY;

    data() {
        return {
            howLong: STANDARD_KEY,
            howMuch: STANDARD_KEY,
            howMany: STANDARD_KEY,
        };
    }

    changeKnowledge(index: number) {

        let value = this.howLong;

        switch (index) {
            case 0:
                value = this.howLong;
                break;
            case 1:
                value = this.howMuch;
                break;
            case 2:
                value = this.howMany;
                break;
        }

        this.explorationService.form.exploration[this.getTransformedName(index)] = value;
        this.explorationService.updateValue();
    }

    getTransformedName(index: number) {

        return this.name + '_' + index;
    }

    mounted() {
        this.howLong = this.explorationService.form.exploration[this.getTransformedName(0)];
        this.howMuch = this.explorationService.form.exploration[this.getTransformedName(1)];
        this.howMany = this.explorationService.form.exploration[this.getTransformedName(2)];
    }

    constructor() {
        super();
        this.knowledgeService = new KnowledgeService();
    }

}

export default Knowledge;
