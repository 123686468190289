import Credentials from '@/components/Auth/models/Credentials';

class CompletedCredentials extends Credentials {

    public passwordConfirmation: string;

    constructor(email: string , password: string , passwordConfirmation: string) {
        super(email, password);
        this.passwordConfirmation = passwordConfirmation;

    }
}

export default CompletedCredentials;
