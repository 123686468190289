import Investment, {InvestmentType} from './interfaces/investment';
import DateFormatHelper from '@/components/Helper/DateFormatHelper';
import Logger from '@/components/Helper/LogHelper';
import SubInvestorHelper from '@/components/Helper/SubInvestorHelper';

class SigningInvestment implements Investment {
    private _investSum: number = 0;
    private _isRunning: boolean = false;
    private _interest: number = -1;
    private _investor: string = '';
    private _createdAt: string = '';
    private _title: string = '';
    private _period: string = '';
    private _financingEntity: string = '';
    private _id: number = -1;
    private _datePayBack: string = '';
    private _paidAt: string = '';

    constructor(data: any, userdata: any) {
        this._investSum = Number(data.invest_sum || 0);

        const expireAt = new Date(DateFormatHelper.stringToAmericanDate(data.expire_at)).getTime();
        const now = new Date().getTime();

        this._isRunning = now < expireAt;

        if (data.paid_at) {
            const paidAtTime = new Date(DateFormatHelper.stringToAmericanDate(data.paid_at)).getTime();
            this._isRunning = paidAtTime > new Date().getTime();
        } else {
            this._isRunning = true;
        }

        this._paidAt = data.paid_at;

        this._title = data.title;
        this._investor = SubInvestorHelper.extractName(data, userdata);

        this._period = data.contract.payback_interval_in_months;
        this._createdAt = data.created_at;
        this._interest = Number(data.payout_number);
        this._financingEntity = data.contract.financing_entity;
        this._id = this.getSigningId(data, userdata) ?? data.signing_id;
        this._datePayBack = data.datePayBack || data.expire_at;
    }

    getSigningId(data, userData): number | null {
        return userData.rawData.investmentData.finanzierung.investments.find((investment) => {
            return data.id === investment.contract_id;
        })?.signing_id;
    }

    isRunning(): boolean {
        return this._isRunning;
    }

    getInvestSum(): number {

        return this._investSum;
    }

    getInterest(): number {
        return this._interest;
    }

    getInvestor(): string {
        return this._investor;
    }

    getCreatedAt(): string {
        return this._createdAt;
    }

    getPeriod(): string {
        return this._period;
    }

    getTitle(): string {
        return this._title;
    }

    getFinancingEntity(): string {
        return this._financingEntity;
    }

    getType(): InvestmentType {
        return InvestmentType.SIGNING;
    }

    getId(): number {
        return this._id;
    }

    getDatePayBack(): string {
        return this._datePayBack;
    }

    getPaidAt(): string | null {
        return this._paidAt;
    }
}

export default SigningInvestment;
