










import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import RequestCallback from '../Rest/RequestCallback';
import RestError from '../Rest/models/RestError';
import {AxiosResponse} from 'axios';
import Input from '@/components/Input/Input.vue';
import CheckoutRequestApi from '@/components/Rest/requests/CheckoutRequestApi';
import ExporoVue from '@/components/ExporoVue';

@Component({
    components: {
        Input
    }
})
export default class ImageUpload extends ExporoVue implements RequestCallback {

    @Prop()
    private name!: string;

    @Prop()
    private dataPath!: string;

    @Prop()
    private onComplete!: (string, any) => void;

    private checkoutRequestApi: CheckoutRequestApi;
    private value = null;

    constructor() {
        super();

        this.checkoutRequestApi = this.restApi.create(new CheckoutRequestApi());
    }

    onFileChanged(e) {
        const file = e.target.files[0];
        const formData = new FormData();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        formData.append('images', file);

        this.checkoutRequestApi.uploadInvestorImage(formData, this).addParam('url', this.dataPath);

    }

    onFailure(error: RestError): void {
    }

    onSuccess(response: AxiosResponse): void {
        this.value = response.data;
        if (this.onComplete) {
            this.onComplete(this.name, this.value);
        }
    }

}
