


































import ExporoVue from '../../ExporoVue';
import {Component, Prop} from 'vue-property-decorator';
import CockpitTimeline from '../Controls/CockpitTimeline.vue';
import UserData from '@/components/Cockpit/models/UserData';
import YieldReporting from '@/components/Cockpit/models/YieldReporting';

const marked = require('marked');

@Component({
    components: {
        CockpitTimeline
    }
})
class KpiReport extends ExporoVue {
    @Prop() private userdata!: UserData;
    @Prop() private yieldReporting!: YieldReporting;
    @Prop() private print!: () => {};
    @Prop() private showPrintButton!: boolean;

    constructor() {
        super();
    }
}

export default KpiReport;

