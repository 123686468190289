import BaseRoute from '@/components/Router/BaseRoute';
import CockpitRoutes from '@/routes/cockpit';
import UserRoutes from '@/routes/user';
import ModalRoutes from '@/routes/modal';
import CheckoutRoutes from '@/routes/checkout';
import GeneralRoutes from '@/routes/general';
import AuthRoutes from '@/routes/auth';

class Routes {
    private routes: BaseRoute[] = [
        new CockpitRoutes(),
        new UserRoutes(),
        new ModalRoutes(),
        new CheckoutRoutes(),
        new GeneralRoutes(),
        new AuthRoutes(),
    ];

    private urls: any = {};

    initRoutes() {
        this.routes.forEach((routeDefinition: BaseRoute) => {
            this.urls = {...this.urls, ...routeDefinition.init()};
        });
    }

    getRoute(name: string, parameters: any[]): string {
        return `#/${this.replaceParams(this.urls[name], parameters)}`;
    }

    replaceParams(url: string, parameters: any[], index: number = 0) {
        return url.replace(/\{.*?\}/g, () => {
            const param = parameters[index];
            index++;
            return param;
        });
    }
}

export default Routes;
