const axios = require('axios');

export const resumeSession = async (refreshToken: string) => {
  // const USER_POOL_ID = 'eu-central-1_Um8D678vf' // dev;
  const USER_POOL_ID = 'eu-central-1_l7h8zENgx'; // live;
  const AWS_REGION = USER_POOL_ID.split('_')[0];
  // const USER_POOL_CLIENT_ID = '7jf5n6cg6l7q9e4rssbb23lbuo' // dev;
  const USER_POOL_CLIENT_ID = '667fgdio3991qiv8c51ahgr76'; // live;

  const endpoint = `https://cognito-idp.${AWS_REGION}.amazonaws.com/`;

  await axios.request({
    url: endpoint,
    method: 'POST',
    headers: {
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
      'Content-Type': 'application/x-amz-json-1.1',
    },
    data: JSON.stringify({
      ClientId: USER_POOL_CLIENT_ID,
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    }),
  })
    .then((res) => {
        return res.data;
    })
    .then((res) => {
      const idToken = res.AuthenticationResult.IdToken;
      const accessToken = res.AuthenticationResult.AccessToken;
      const expiredIn = res.AuthenticationResult.ExpiresIn;

      const token = {
          idToken,
          accessToken,
          refreshToken,
          expiredIn,
      };

      localStorage.setItem('authToken', JSON.stringify(token));
    });
};

