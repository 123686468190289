import BaseRoute from '../components/Router/BaseRoute';
import {Route} from '@/components/Router/decorators/RouteDecorator';

class GeneralRoutes extends BaseRoute {

    @Route('/')
    private home;
}

export default GeneralRoutes;
