export default class TradeOffer {
    readonly _isin: string;
    readonly _contractId: number;
    readonly _projectName: string;
    readonly _availableAssets: number;
    readonly _pieceValue: number;
    readonly _repositoryType: string;
    readonly _assetId: string | null;

    private _offeredPieces: number = 0;
    private _salePricePerPiece: number = 0;
    private _bidsInProgress: number = 0;
    private _bidsFinished: number = 0;
    private _id: number = 0;

    constructor(
        isin: string,
        contractId: number,
        projectName: string,
        availableAssets: number,
        pieceValue: number,
        repositoryType: string,
        assetId: string | null
    ) {
        this._isin = isin;
        this._contractId = contractId;
        this._projectName = projectName;
        this._availableAssets = availableAssets;
        this._pieceValue = pieceValue;
        this._repositoryType = repositoryType;
        this._assetId = assetId;
    }

    get isin(): string {
        return this._isin;
    }

    get contractId(): number {
        return this._contractId;
    }

    get projectName(): string {
        return this._projectName;
    }

    get availableAssets(): number {
        return this._availableAssets;
    }

    get isSto(): boolean {
        return this._repositoryType === 'wallet';
    }

    get assetId(): string | null {
        return this._assetId;
    }

    set offeredPieces(data: number) {
        this._offeredPieces = data;
    }

    get offeredPieces(): number {
        return this._offeredPieces;
    }

    set salePricePerPiece(data: number) {
        this._salePricePerPiece = data;
    }

    get salePricePerPiece(): number {
        return this._salePricePerPiece;
    }

    set bidsInProgress(data: number) {
        this._bidsInProgress = data;
    }

    get bidsInProgress(): number {
        return this._bidsInProgress;
    }

    set bidsFinished(data: number) {
        this._bidsFinished = data;
    }

    get bidsFinished(): number {
        return this._bidsFinished;
    }

    set id(data: number) {
        this._id = data;
    }

    get id(): number {
        return this._id;
    }

    get totalValue(): number {
        return this._salePricePerPiece * this._offeredPieces;
    }

    get pieceValue(): number {
        return this._pieceValue;
    }

    get remainingPieces(): number {
        return this._availableAssets - this._offeredPieces * this._pieceValue;
    }

    validateAbleToTrade(): boolean {
        return this._availableAssets > this._offeredPieces * this._pieceValue;
    }

    get rejectablePieces(): number {
        return this._offeredPieces - this._bidsInProgress - this._bidsFinished;
    }

    resetOfferValue() {
        this._offeredPieces = 0;
        this._salePricePerPiece = 0;
        this._bidsFinished = 0;
        this._bidsInProgress = 0;
    }

}
