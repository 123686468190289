



























import {Component, Vue} from 'vue-property-decorator';
import AuthService from './services/AuthService';
import RequestCallback from '../Rest/RequestCallback';
import {AxiosResponse} from 'axios';
import Credentials from './models/Credentials';
import RestApiService from '../Rest/services/RestApiService';
import RestError from '../Rest/models/RestError';
import ExporoVue from '../ExporoVue';
import Input from '@/components/Input/Input.vue';
import Router from '../Router/Router';

@Component({
    components: {
        Input,
    },
    props: {
        openAuthModalByName: Function,
        modalNames: Object,
    },
})
export default class PasswordForget extends ExporoVue implements RequestCallback {

    public input: any;
    public $props: any;
    private authService: AuthService;
    private successText: any;
    private errorText: any = '';
    private resetPasswordComplete: boolean = false;


    constructor() {
        super();

        this.authService = new AuthService(new RestApiService(this));
    }


    data() {
        return {
            input: {
                email: '',
            },
        };
    }

    reset() {
        this.authService.resetPassword(new Credentials(this.input.email, null), this);
    }

    navigateToForgotModal(e) {
        e.preventDefault();
        this.closeModal();
        Router.navigate('openAuth', ['login-modal']);
    }

    closeModal(): void {
        this.resetPasswordComplete = false;
        this.getModalService().closeModal('password-forget-modal');
    }

    onFailure(error: RestError): void {
        if (error.errorMessage.hasOwnProperty('email')) {
            this.errorText = Array.isArray(error.errorMessage.email)
                ? this.errorText = error.errorMessage.email[0]
                : error.errorMessage.email;
        } else if (error.errorMessage.hasOwnProperty('message')) {
            this.errorText = error.errorMessage.message;
        } else {
            this.errorText = error.errorMessage;
        }
    }

    onSuccess(response: AxiosResponse): void {
        this.successText = response.data;
        this.resetPasswordComplete = true;
    }
}
