






















import {Component, Prop, Vue} from 'vue-property-decorator';
import TableData from './models/TableData';

@Component
class List extends Vue {

    @Prop()
    private viewChange!: (Element, number) => void;

    @Prop()
    private tableData!: TableData;

    @Prop()
    private title!: string;

    constructor() {
        super();
    }

    getStyles(index) {

        const transitionDelay = `${(index % 10) * 5}ms`;
        const animationDelay = `${(index % 10) * 70}ms`;

        return {
            'transition-delay': transitionDelay,
            'animation-delay': animationDelay
        };
    }

    mounted() {
        const listItems: any = document.getElementsByClassName('exporo-invest-entry');


        for (let i = 0; i < listItems.length; i++) {
            listItems[i].addEventListener('click', (event) => {
                this.viewChange(event.srcElement, i);
            }, false);
        }
    }

    open2(index, row) {
        const location: any = {
            name: 'item',
            params: {
                id: index, item: row
            }
        };

        this.$router.push(location);
    }

    open(e: any) {
        const td: Element = e.srcElement;
        const tr: any = td.parentNode;

        tr.className += ' open';
    }

    get columns() {
        if (this.tableData.data.length === 0) {

            return [];
        }

        return Object.keys(this.tableData.data[0]);
    }
}

export default List;
