import {ExplorationForm, StringKeyValueType} from '@/components/Checkout/Exploration/models/Types';
import ExporoVue from '@/components/ExporoVue';

// @TODO id deprecated?
/* tslint:disable */
class ExplorationService extends ExporoVue {

    static MIN_VALUE = 44;

    public employmentStatusOptions: StringKeyValueType = {
        'employee': this.trans.get('checkout.employment_status.employmentStatusOptions.employee'),
        'pensioner': this.trans.get('checkout.employment_status.employmentStatusOptions.pensioner'),
        'privateer': this.trans.get('checkout.employment_status.employmentStatusOptions.privateer'),
        'public_official': this.trans.get('checkout.employment_status.employmentStatusOptions.public_official'),
        'self_employed': this.trans.get('checkout.employment_status.employmentStatusOptions.self_employed'),
        'student': this.trans.get('checkout.employment_status.employmentStatusOptions.student'),
    };

    public selfEmployedOccupations: StringKeyValueType = {
        '-': '0'
    };

    private explorations = ['equity', 'bonds', 'subordinated', 'funds', 'stocks'];

    private explorationExcluded: any = ['value', 'employmentStatus', 'bonds', 'funds', 'equity', 'stocks', 'subordinated', 'perform'];

    private onValueChanged!: Function;

    public form: any = {
        exploration: {
            perform: '0',
            employmentStatus: 'employee',
            job_0: '0',
            job_1: '0',
            job_2: '0',
            _0_exploration_0: '0',
            _0_exploration_1: '0',
            _0_exploration_2: '0',
            _1_exploration_0: '0',
            _1_exploration_1: '0',
            _1_exploration_2: '0',
            _2_exploration_0: '0',
            _2_exploration_1: '0',
            _2_exploration_2: '0',
            _3_exploration_0: '0',
            _3_exploration_1: '0',
            _3_exploration_2: '0',
            _4_exploration_0: '0',
            _4_exploration_1: '0',
            _4_exploration_2: '0',
            explorationWarning: this.trans.get('checkout.employment_status.exploration_warning'),
            value: 0
        },
        knowledge: '0;0'
    };

    public changeFormByKey(name: string, value: string) {
        this.form[name] = value;
        this.updateValue();
    }

    public updateValue() {
        let value = 0;
        for (let formKey in this.form.exploration) {
            if ('value' !== formKey && 'perform' !== formKey) {
                let formValue = this.form.exploration[formKey];

                if ('string' === typeof formValue) {
                    formValue = formValue.split(';')[0];
                    formValue = formValue.replace(',', '.');
                }

                if (!isNaN(+formValue)) {
                    value += +formValue;
                }
            }
        }

        this.form.exploration.value = value;

        if (this.onValueChanged) {
            this.onValueChanged();
        }
    }

    public setValueChangedCallback(callback: Function) {
        this.onValueChanged = callback;
    }

    public jobOptions: StringKeyValueType = {};

    public jobOptionsPast: StringKeyValueType = {};

    public educationOptions: StringKeyValueType = {};

    constructor(data?: any) {
        super();

        if (data) {
            //this.trandformFormForVermAnlG(data);
        } else {
        }
        this.setDefaultJobs();

        this.jobOptions['-'] = '0;0',
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.asset_manager')] = '20;' + this.trans.get('checkout.employment_status.job_education.current_job.data.asset_manager'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.finanzberater')] = '20;' + this.trans.get('checkout.employment_status.job_education.current_job.data.finanzberater'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.finanzmakler')] = '20;' + this.trans.get('checkout.employment_status.job_education.current_job.data.finanzmakler'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.finanzvermittler')] = '20;' + this.trans.get('checkout.employment_status.job_education.current_job.data.finanzvermittler'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.investment_banker')] = '20;' + this.trans.get('checkout.employment_status.job_education.current_job.data.investment_banker'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.wirtschaftspruefer')] = '20;' + this.trans.get('checkout.employment_status.job_education.current_job.data.wirtschaftspruefer'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.bankangestellter')] = '10;' + this.trans.get('checkout.employment_status.job_education.current_job.data.bankangestellter'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.leitende_funktion_controlling')] = '10;' + this.trans.get('checkout.employment_status.job_education.current_job.data.leitende_funktion_controlling'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.leitende_funktion_finanzabteilung')] = '10;' + this.trans.get('checkout.employment_status.job_education.current_job.data.leitende_funktion_finanzabteilung'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.steuerberater')] = '10;' + this.trans.get('checkout.employment_status.job_education.current_job.data.steuerberater'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.kfm_angestellter')] = '10;' + this.trans.get('checkout.employment_status.job_education.current_job.data.handwerker'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.handwerker')] = '0;' + this.trans.get('checkout.employment_status.job_education.current_job.data.handwerker'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.rentner_pensionaer')] = '0;' + this.trans.get('checkout.employment_status.job_education.current_job.data.rentner_pensionaer'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.student_auszubildender')] = '0;' + this.trans.get('checkout.employment_status.job_education.current_job.data.student_auszubildender'),
            this.jobOptions[this.trans.get('checkout.employment_status.job_education.current_job.data.sonstige')] = '0';

        this.jobOptionsPast['-'] = '0;0',
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.asset_manager')] = '10;' +this.trans.get('checkout.employment_status.job_education.past_job.data.asset_manager'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.finanzberater')] = '10;' +this.trans.get('checkout.employment_status.job_education.past_job.data.finanzberater'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.finanzmakler')] = '10;' +this.trans.get('checkout.employment_status.job_education.past_job.data.finanzmakler'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.finanzvermittler')] = '10;' +this.trans.get('checkout.employment_status.job_education.past_job.data.finanzvermittler'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.investment_banker')] = '10;' +this.trans.get('checkout.employment_status.job_education.past_job.data.investment_banker'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.wirtschaftspruefer')] = '10;' +this.trans.get('checkout.employment_status.job_education.past_job.data.wirtschaftspruefer'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.bankangestellter')] = '5;' +this.trans.get('checkout.employment_status.job_education.past_job.data.bankangestellter'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.leitende_funktion_controlling')] = '5;' +this.trans.get('checkout.employment_status.job_education.past_job.data.leitende_funktion_controlling'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.leitende_funktion_finanzabteilung')] = '5;' +this.trans.get('checkout.employment_status.job_education.past_job.data.leitende_funktion_finanzabteilung'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.steuerberater')] = '5;' +this.trans.get('checkout.employment_status.job_education.past_job.data.steuerberater'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.kfm_angestellter')] = '5;' +this.trans.get('checkout.employment_status.job_education.past_job.data.kfm_angestellter'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.handwerker')] = '0;' +this.trans.get('checkout.employment_status.job_education.past_job.data.handwerker'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.rentner_pensionaer')] = '0;' +this.trans.get('checkout.employment_status.job_education.past_job.data.rentner_pensionaer'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.student_auszubildender')] = '0;' +this.trans.get('checkout.employment_status.job_education.past_job.data.student_auszubildender'),
            this.jobOptionsPast[this.trans.get('checkout.employment_status.job_education.past_job.data.sonstige')] = '0',

        this.educationOptions['-'] = '0;0',
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.promotion_habilitation')] = '10;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.promotion_habilitation'),
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.hochschulabschluss')] = '8;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.hochschulabschluss'),
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.kaufmaennische_berufsausbildung')] = '5;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.kaufmaennische_berufsausbildung'),
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.fachhochschul_hochschulreife')] = '3;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.fachhochschul_hochschulreife'),
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.sonstige_berufsausbildung')] = '1;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.sonstige_berufsausbildung'),
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.haupt_volks_realschulabschluss')] = '0;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.haupt_volks_realschulabschluss'),
            this.educationOptions[this.trans.get('checkout.employment_status.job_education.educational_achievement.data.keiner_der_genannten_abschluesse')] = '0;' + this.trans.get('checkout.employment_status.job_education.educational_achievement.data.keiner_der_genannten_abschluesse');

    }

    private setDefaultJobs() {
        this.form.exploration.job_0 = '0';
        this.form.exploration.job_1 = '0';
        this.form.exploration.job_2 = '0';
    }

    private trandformFormForVermAnlG(data) {
        for (let exploration in data) {
            let value = typeof data[exploration] === 'number' ? data[exploration] : this.transformDefaultValue(data[exploration]);

            if (-1 === this.explorationExcluded.indexOf(exploration)) {
                this.form.exploration[exploration] = value;
            } else {
                this.form[exploration] = value;
            }
        }
    }

    private transformDefaultValue(value: string) {

        if (!value || 'object' === typeof value) {

            return '';
        }

        if (1 === value.indexOf(';0')) {
            value = value.slice(0, -2);
        } else if (1 === value.indexOf(';')) {
            value = value.slice(0, -1);
        } else if ('? string: ?' === value) {
            value = '0';
        }

        return value;
    }
}

export default ExplorationService;
