



















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import StringHelper from '@/components/Helper/StringHelper';
import ExporoVue from '@/components/ExporoVue';
import RequestCallback from '@/components/Rest/RequestCallback';
import RestError from '@/components/Rest/models/RestError';
import {AxiosResponse} from 'axios';
import SecondaryMarketRequestApi from '@/components/Rest/requests/SecondaryMarketRequestApi';
import TradeOffer from '@/components/Cockpit/KPI/services/TradeOffer';

@Component({
    components: {},
    filters: {
        toEur(value) {
            return StringHelper.toEur(value);
        }
    }
})
class RejectOfferTransaction extends ExporoVue implements RequestCallback {

    @Prop() private tradeOffer!: TradeOffer;
    @Prop() private disabled!: boolean;

    private secondaryMarketRequestApi: SecondaryMarketRequestApi;
    private loading: boolean = false;
    private storeError: boolean = false;

    constructor() {
        super();
        this.secondaryMarketRequestApi = this.restApi.create(new SecondaryMarketRequestApi());
    }

    onFailure(error: RestError) {
        this.loading = false;
        this.storeError = true;
    }

    send(e) {
        e.preventDefault();
        this.secondaryMarketRequestApi.deleteOffer(this).addParam('offerId', String(this.tradeOffer.id));
        this.loading = true;
    }

    onSuccess(response: AxiosResponse, id): void {
        this.getModalService().closeModal('secondary-market-reject-offer');
        this.loading = false;
        this.storeError = false;
        this.getModalService().openModal('offer-success');
    }
}

export default RejectOfferTransaction;
