class KpiHelper {

    static sortByMonths(data: any[]): any[] {

        data.sort((a, b) => {
            if (a.fields.date && !a.fields.date.includes('Q')) {
                const timeA = '01.' + a.fields.date;
                const timeB = '01.' + b.fields.date;

                return new Date(timeA) > new Date(timeB) ? 1 : -1;
            }
            return 0;
        });

        return data;
    }

    static filterQuarterlyDevelopments(data: any): any[] {
        const developments: any[] = [];
        data.forEach((item: any) => {
            if (item.fields.currentDevelopment) {
                developments.push(item);
            }
        });
        return developments;
    }

    static sortByQuarters(data: any []): any[] {
        data.sort((a, b) => {
            const dateA = a.incomeOutcome.replace('Q', '01 0').replace(' -', '').split(' ').reverse().join('-').valueOf();
            const dateB = b.incomeOutcome.replace('Q', '01 0').replace(' -', '').split(' ').reverse().join('-').valueOf();
            return new Date(dateA).getTime() < new Date(dateB).getTime() ? 1 : -1;
        });

        return data;

    }

    static filterQuarter(data: any[]): any[] {
        const kpis: any[] = [];

        data.forEach((item: any) => {
            if (item.fields.date && item.fields.date.includes('Q')) {
                kpis.push(item);
            }
        });

        kpis.sort((a, b) => {
            const timeA = a.fields.date.replace('Q', '01 0').split(' ').reverse().join('/');
            const timeB = b.fields.date.replace('Q', '01 0').split(' ').reverse().join('/');
            return new Date(timeA).getTime() > new Date(timeB).getTime() ? 1 : -1;
        });

        return kpis;
    }

}

export default KpiHelper;
