class User {

    constructor(data: any) {

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                this[key] = data[key];
            }
        }
    }

    addData(key: string, value: any) {

        this[key] = value;
    }

}

export default User;
