








import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import StarBar from '@/components/CustomerRating/StarBar.vue';

@Component({
    components: {
        StarBar
    }
})

class CustomerRatingInstance extends ExporoVue {
    @Prop() customerReviewData !: any;
    constructor() {
        super();
    }
}


export default CustomerRatingInstance;
