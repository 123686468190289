import PercentFormatHelper from '@/components/Helper/PercentFormatHelper';

class SortHelper {

    static sortByDate(data: any[], column: string, ascending: boolean): any[] {
        data.sort((a: { column }, b: { column }) => {
            if (ascending) b = [a, a = b][0];

            return new Date(b[column].split('.').reverse().join('-')).getTime() - new Date(a[column].split('.').reverse().join('-')).getTime();
        });
        return data;
    }

    static sortArrayOfObjectsByDateWithKey(data: any[], key: string): any[] {
        data.sort((a, b) => {
            const dateA = new Date(a[key].split('.').reverse().join('-')).getTime();
            const dateB = new Date(b[key].split('.').reverse().join('-')).getTime();
            return dateA < dateB ? 1 : -1;
        });

        return data;
    }

    static sortByNumber(data: any[], column: string, ascending: boolean): any[] {
        data.sort((a: { column }, b: { column }) => {
            if (a[column].toString().includes('%')) {
                a[column] = PercentFormatHelper.stringToDecimal(a[column]);
                b[column] = PercentFormatHelper.stringToDecimal(b[column]);
            }
            if (ascending) b = [a, a = b][0];
            const columnBalueIsOnlyDigits = a[column].toString().search(/^[0-9]*$/gm) === 0;
            const aValue = columnBalueIsOnlyDigits ? parseInt(a[column], 10) : parseInt(a[column].toString().slice(0, -1).replace(/[^a-zA-Z0-9]/g, ''), 10);
            const bValue = columnBalueIsOnlyDigits ? parseInt(b[column], 10) : parseInt(b[column].toString().slice(0, -1).replace(/[^a-zA-Z0-9]/g, ''), 10);
            return aValue - bValue;
        });

        return data;
    }

    static sortByName(data: any[], column: string, ascending: boolean): any[] {
        data.sort((a: { column }, b: { column }) => {

            if (ascending) b = [a, a = b][0];

            if (b[column] < a[column]) return -1;
            if (b[column] > a[column]) return 1;
            return 0;
        });
        return data;
    }

}

export default SortHelper;
