
















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import KeyValue from '@/components/DropDown/KeyValue';
import NodeHelper from '@/components/Helper/NodeHelper';

@Component
class DropDown extends Vue {
    @Prop() private range!: KeyValue[];
    @Prop() private value!: string;
    @Prop() private defaultValue!: string;
    @Prop() private name!: string;
    @Prop() private onChange!: (string) => string;

    private selectedValue: string | undefined = '';
    private isOpen: boolean = false;

    mounted() {
        const exporoSelect: any = this.$refs['exporo-select'];
        document.addEventListener('click', (event) => {
            if (!NodeHelper.childOf(exporoSelect, event.target)) {
                this.isOpen = false;
            }
        });
        this.selectedValue = this.range.find((el) => {
            return el.key === this.value;
        })?.value;
    }

    toggleOpen() {
        this.isOpen = !this.isOpen;
    }

    changeValue(event: any, key: string, value: string) {
        event.stopPropagation();
        this.selectedValue = value;
        this.toggleOpen();
        this.onChange(key);
    }
}

export default DropDown;
