



































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import ExporoModal from '@/components/Modal/ExporoModal.vue';
import LogToSee from '@/components/TeaserProject/LoginToSeeModal.vue';
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue';
import UserRequestApi from '../Rest/requests/UserRequestApi';
import RequestCallback from '../Rest/RequestCallback';
import RestError from '../Rest/models/RestError';
import {AxiosResponse} from 'axios';
import {NOT_LOGGED_IN} from '@/components/Rest/models/Errors';
import store, {LOGIN, LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT} from '@/store';


@Component({
    store,
    components: {
        ExporoModal,
        LogToSee,
        ProgressBar
    }
})

class TeaserProject extends ExporoVue implements RequestCallback {

    @Prop() private factsImg!: string;
    @Prop() private ahref!: string;
    @Prop() private featured!: string;
    @Prop() private imgSrcFeatured!: string;
    @Prop() private imgSrc!: string;
    @Prop() private title!: string;
    @Prop() private srcMiles!: string;
    @Prop() private disrupter!: string;
    @Prop() private location!: string;
    @Prop() private returnPa!: string;
    @Prop() private returnTotal!: string;
    @Prop() private status!: string;
    @Prop() private backendId!: string;
    @Prop() private summarya1!: string;
    @Prop() private summarya2!: string;
    @Prop() private summaryb1!: string;
    @Prop() private summaryb2!: string;
    @Prop() private summaryc1!: string;
    @Prop() private summaryc2!: string;
    @Prop() private summaryd1!: string;
    @Prop() private summaryd2!: string;
    @Prop() private typebond!: string;
    @Prop() private type!: string;
    private userRequestApi: UserRequestApi;
    private isBlurred: boolean;

    constructor() {
        super();
        this.userRequestApi = this.restApi.create(new UserRequestApi());
        this.userRequestApi.isAuthenticated(this);
        this.isBlurred = false;
    }

    getBondType() {
        if (this.typebond === 'financing-bond' || this.typebond === 'equity') {
            return 'bonds';
        }

        return undefined;
    }

    onFailure(error: RestError): void {
        if (NOT_LOGGED_IN === error.errorCode) {
            // @TODO this.isBlurred = true;
        }
    }
    onSuccess(response: AxiosResponse): void {
        this.isBlurred = false;
    }

    openModal(name: string) {
        this.getModalService().openModal(name);
    }

    setImageSrc(): string {
        return this.featured === 'true' ? this.imgSrcFeatured : this.imgSrc;
    }
}


export default TeaserProject;
