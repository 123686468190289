






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';

@Component({
    components: {}
})

class LandingLottiImg extends ExporoVue {
    @Prop({default: false}) private isFinancing!: boolean;

    constructor() {
        super();
    }

    getImg(): string {
        if (this.isFinancing) {
            return 'https://exporo.imgix.net/uploads/landing-graphic-financing.png';
        } else {
            switch (this.cctld) {
                case 'NL':
                    return 'https://exporo-nl.imgix.net/uploads/Lotti_NL_CLose.jpeg';
                    break;
                case 'FR':
                    return 'https://exporo-fr.imgix.net/uploads/200108_Grafiken_Bestand_franz.jpg';
                    break;
                default:
                    return 'https://exporo.imgix.net/uploads/landing-graphic-portfolio.png';
                    break;
            }
        }
    }
}


export default LandingLottiImg;
