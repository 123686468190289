import User from '@/components/User/models/User';

class UserService {

    private userData: any;
    private user: any = null;

    constructor(data: any) {

        this.userData = data;
    }

    buildUser(additionals?: {[key: string]: string}): models.User {
        this.user = new User(this.userData);

        if (additionals) {
            for (const key in additionals) {
                if (additionals.hasOwnProperty(key)) {
                    this.user.addData(key, additionals[key]);
                }
            }
        }

        return this.user;
    }

    getUser(): models.User {

        if (!this.user) {

            return this.buildUser();
        }

        return this.user;
    }
}

export default UserService;
