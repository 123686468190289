











import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
    components: {}
})
class PageInvestors extends Vue {

    get spacing() {
        return '&ensp;'.repeat(this.expectedlength);
    }

    get loading() {
        return null === this.data;
    }

    $refs!: {
        content: HTMLElement,
        inner: HTMLElement,
    };

    private data!: any;
    private expectedlength!: number;
    private widthOldContent: number;
    private heightOldContent: number;

    constructor() {
        super();

        this.widthOldContent = 0;
        this.heightOldContent = 0;
    }

    mounted() {
        const targetNode = this.$refs.content;
        const config = {childList: true};
        const callback = this.listenChanges;
        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);
    }

    fixate() {
        this.widthOldContent = this.$refs.content.offsetWidth;
        this.heightOldContent = this.$refs.content.offsetHeight;
        this.$refs.content.style.width = `${this.widthOldContent}px`;
        this.$refs.content.style.height = `${this.heightOldContent}px`;
    }
    afterEnter() {
        this.$refs.content.style.height = 'auto';
        this.$refs.content.style.width = 'auto';
    }

    listenChanges(recordList, observer) {
        for (const record of recordList) {
            if (record.type === 'childList'
            && record.addedNodes.length
            && record.addedNodes[0].nodeName === 'DIV') {
                this.resizePanel();
            }
        }
    }

    resizePanel() {
        const heightPanel = this.$refs.content.offsetHeight;
        const heightContent = this.$refs.inner.offsetHeight;
        const heightDiff = heightContent - this.heightOldContent;

        const widthPanel = this.$refs.content.offsetWidth;
        const widthContent = this.$refs.inner.offsetWidth;
        const widthDiff = widthContent - this.widthOldContent;

        this.$refs.content.addEventListener('transitionend', () => {
            this.$refs.content.style.height = 'auto';
            this.$refs.content.style.width = 'auto';
        }, {once: true});

        this.$refs.content.style.height = `${heightPanel + heightDiff}px`;
        this.$refs.content.style.width = `${widthPanel + widthDiff}px`;
    }

}


export default PageInvestors;
