



















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import UserData from '@/components/Cockpit/models/UserData';

@Component
class InvestProgress extends ExporoVue {

    @Prop() private userdata!: UserData;
    @Prop() private investment!: any;

    constructor() {
        super();
    }

    onClick(event) {
        event.preventDefault();
    }

    isIdentState(): boolean {
        return this.userdata.identificationState === 'success';
    }

    hasTradingAccount(): boolean {
        return (this.investment._repositoryType === 'wallet' || this.investment._repositoryType === 'vault')
            ? this.userdata.hasWallet
            : this.userdata.rawData.userData.has_trading_account;
    }

    hasPaid(): boolean {
        // @TODO is paid is not set in equity
        return Boolean(this.investment._isPaid);
    }

    getStateName(state, name) {
        const done = state ? 'done' : 'not_done';
        return this.trans.get(`cockpit.pages.portfolio.progress_bar.${name}.${done}`);
    }

    isLatest(name) {
        const states = {
            ident: this.isIdentState(),
            depot: this.hasTradingAccount(),
            paid: this.hasPaid()
        };

        if (!states[name]) return false;

        if (name === 'paid') return true;

        if (name === 'depot' && !states.paid) return true;

        return name === 'ident' && !states.paid && !states.depot;
    }
}

export default InvestProgress;
