import {Get, Post} from '@/decorators/RestDecorators';
import RequestCallback from '../RequestCallback';
import RestApi from '@/components/Rest/RestApi';
import RequestApi from '@/components/Rest/models/RequestApi';

class PubSubRequestApi extends RequestApi {


    @Get('/pubsub/initial')
    getInitialData(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

}

export default PubSubRequestApi;
