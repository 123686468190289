
































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ForceEmailCheckbox from '@/components/Checkout/ForceEmailCheckbox/ForceEmailCheckbox.vue';
import ExporoVue from '../../ExporoVue';
import InvestService from '../services/InvestService';
import Events from '../../../events';
import Error from '@/components/Checkout/Validator/Error.vue';
import CheckoutValidator from '../Validator/CheckoutValidator';
import Investor = models.Investor;
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import InvestmentRequestPart from '../models/InvestmentRequestPart';
import StoreInvestmentRequestService from '../services/StoreInvestmentRequestService';


@Component({
    components: {
        ForceEmailCheckbox,
        Error,
        Checkbox
    }
})
class SummaryVermAnlG extends ExporoVue implements InvestmentRequestPart {

    public TITLE = 'Annahmeformular';

    private payloadPart: any = {
        i_have_read_all_docs: false,
        dsgvo_secupay_hint: false,
        agb: false,
        angebotsunterlagen_und_fernabsatzinformationen: false,
        nid: null,
        project_nid: null,
        slug: null
    };

    @Prop()
    private checkoutValidator!: CheckoutValidator;
    @Prop()
    private shouldHide!: boolean;
    @Prop()
    private switchStep!: () => void;
    @Prop()
    private vibSent?: object;
    @Prop()
    private user!: models.User;
    @Prop()
    private country!: string;
    @Prop()
    private detailPage!: object;
    @Prop()
    private investService!: InvestService;
    @Prop()
    private isFirstInvestment!: boolean;
    @Prop()
    private baaderDownloadFiles!: object[];
    @Prop()
    private baaderBankFiles!: object[];
    @Prop()
    private investmentProduct!: models.InvestmentProduct;
    @Prop()
    private step!: number;
    @Prop()
    private isPrivatePlacement!: boolean;

    private investType: string = 'default';
    private investSum: number = 0;
    private investSumText: number = 0;
    // @TODO
    private additionalRate: number | null = null;

    private investor: Investor | null = null;

    @Prop()
    private storeInvestmentRequestService!: StoreInvestmentRequestService;

    constructor() {
        super();
        this.updateInvestData();
        this.storeInvestmentRequestService.registerRequestPart(this);

        Events.$on(InvestService.investChangedChannel, () => {
            this.updateInvestData();
        });

        this.payloadPart.nid = (this.investmentProduct as any).nid;
        this.payloadPart.project_nid = (this.investmentProduct as any).nid;
        this.payloadPart.slug = (this.investmentProduct as any).slug;
    }

    onChangeInput(name: string) {
        const checkbox: any = document.getElementById(name);

        if (checkbox) {
            this.investService.updateFomData(name.toLowerCase(), checkbox.checked);

            if (this.payloadPart.hasOwnProperty(name.toLowerCase())) {
                this.payloadPart[name.toLowerCase()] = checkbox.checked;
            }
        }
    }

    updateInvestData() {
        this.investType = this.investService.getInvestType();
        this.investSum = this.investService.getInvestSum();
        this.investSumText = this.investService.getInvestSum();
        this.investor = this.investService.getInvestor();
        this.$forceUpdate();
    }

    openEditForm() {
        this.getModalService().openModal('user-edit');
    }

    getRequestPart(): object {

        return this.payloadPart;
    }

    get privatePlacement(): boolean {
        return this.isPrivatePlacement;
    }
}

export default SummaryVermAnlG;
