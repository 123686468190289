






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '../../ExporoVue';

@Component
class DetailPanel extends ExporoVue {

    constructor() {
        super();
    }
}

export default DetailPanel;
