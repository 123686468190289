
















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import RestApiService, {HOST} from '../Rest/services/RestApiService';
import {AxiosResponse} from 'axios';
import ExplorationWpHGLite from './ExplorationV2/ExplorationWpHGLite.vue';
import InvestmentWpHGLite from './Investment/InvestmentWpHGLite.vue';
import SummaryWpHGLite from './Summary/SummaryWpHGLite.vue';
import AuthService from '../Auth/services/AuthService';
import LocalStorage from '../LocalStorage/LocalStorage';
import RestError from '../Rest/models/RestError';
import {MISSING_USER_DETAILS, NOT_LOGGED_IN, VALIDATION_ERROR} from '../Rest/models/Errors';
import ExporoVue from '../ExporoVue';
import InvestService from './services/InvestService';
import CheckoutRequestApi from '../Rest/requests/CheckoutRequestApi';
import ExplorationService from './ExplorationV2/services/ExplorationService';
import CheckoutValidator from './Validator/CheckoutValidator';
import ValidatorCallback from './Validator/ValidatorCallback';
import {Actions} from '@/decorators/ModalDecorators';
import Events from '../../events';
import UserService from '../User/services/UserService';
import CheckoutDataWpHGLight from './models/CheckoutDataWpHGLight';
import InvestmentRequests from './models/InvestmentRequests';
import StoreInvestmentRequestService from './services/StoreInvestmentRequestService';
import RequestPartMissingError from './Error/RequestPartMissingError';
import ToastService from '../Toast/ToastService';
import Toast from '../Toast/Toast';
import BondStoreSuccess from './StoreSuccesView/BondStoreSuccess.vue';
import PubSubService, {TYPE_BOND} from '../PubSub/services/PubSubService';
import Tracking from '@/components/GoogleTagManager/services/Tracking';
import {Action} from '@/components/Router/decorators/RouteDecorator';
import Controller from '@/components/Router/Controller';
import Router from '@/components/Router/Router';
import OnSiteTracking from '@/components/GoogleTagManager/interfaces/OnSiteTracking';
import URLHelper from '@/components/Helper/URLHelper';
import SimpleSpinner from '@/components/ExporoSimpleSpinner/SimpleSpinner';


export const CHECKOUT_MODAL_NAME = 'checkout-modal-wphg-lite';

@Component({
    components: {
        ExplorationWpHGLite,
        InvestmentWpHGLite,
        SummaryWpHGLite,
        BondStoreSuccess,
    },
})
class CheckoutWpHGLite extends Controller implements ValidatorCallback, InvestmentRequests, OnSiteTracking {
    static CHECKOUT_MODAL_NAME = CHECKOUT_MODAL_NAME;

    private restApiService: RestApiService;
    private step: number = 1;
    private maxSteps: number = 3;
    private checkoutData: CheckoutDataWpHGLight | null = null;
    private authService: AuthService;
    private investService: InvestService;
    private projectTitle: string = '';
    private nextButtonText: string = ExporoVue.CHECKOUT_NEXT_TEXT;
    private backButtonText: string = this.trans.get('checkout.modal_controls.back');
    private nextButtonClass: string = 'btn';
    private checkoutRequestApi: CheckoutRequestApi;
    @Prop()
    private contractId!: string;
    private explorationService!: ExplorationService;
    private checkoutValidator: CheckoutValidator;
    private user!: models.User;
    storeInvestmentRequestService: StoreInvestmentRequestService;
    private checkoutDataComplete: boolean = false;
    private signingId: number | null = null;
    private availablePieces: number | null = null;
    private noPiecesAvailable: boolean = false;
    private shouldLockCheckout: boolean = false;
    private shouldLockCheckoutRequest: boolean = false;
    private availableInvestments: number = 0;
    private payload: any = null;
    private currentPayload: any = null;
    @Prop({default: ''})
    private projectType!: string;
    private isFinancing: boolean | null = null;
    private singleUnitThresholdText: string = '';

    constructor() {
        super('CheckoutWpHGLite');

        this.resetEvents();
        this.restApiService = new RestApiService(this);
        this.authService = new AuthService(this.restApiService);
        this.investService = new InvestService();
        this.checkoutRequestApi = this.restApi.create(new CheckoutRequestApi());
        this.checkoutValidator = new CheckoutValidator(this);
        this.storeInvestmentRequestService = new StoreInvestmentRequestService();
    }

    resetEvents() {
        Events.$off(ExporoVue.REST_API_ON_REQUEST_EVENT);
        Events.$off(ExporoVue.REST_API_ON_SUCCESS_EVENT);
        Events.$off(ExporoVue.REST_API_ON_FAILURE_EVENT);

        Events.$on(ExporoVue.REST_API_ON_REQUEST_EVENT, (url) => {
            if (url.indexOf('/investment/bonds/store') > -1) {
                SimpleSpinner.getInstance().show();
            }
        });

        Events.$on(ExporoVue.REST_API_ON_FAILURE_EVENT, (url, err: RestError) => {
            if (url.indexOf('/investment/bonds/store') > -1) {
                SimpleSpinner.getInstance().hide();
                if (err.errorCode !== VALIDATION_ERROR) {
                    this.closeModal();
                }
            }
        });

        Events.$on(ExporoVue.REST_API_ON_SUCCESS_EVENT, (url) => {
            if (url.indexOf('/investment/bonds/store') > -1) {
                SimpleSpinner.getInstance().hide(700);
            }
        });
    }

    getName() {

        return this.getClassName() || 'CheckoutWpHGLite';
    }

    updateControls() {
        Events.$emit('checkout-wphg-lite-', {
            step: this.step,
            nextButtonClass: this.nextButtonClass,
            nextButtonText: this.nextButtonText,
            shouldHide: null !== this.signingId,
        });
    }

    @Actions('checkout-wphg-lite-')
    getActions(): [object, object] {

        return [
            {
                title: this.backButtonText,
                function: 'back',
                options: {
                    spanClass: 'left',
                },
            },
            {
                title: ExporoVue.CHECKOUT_NEXT_TEXT,
                function: 'next',
                options: {
                    spanClass: 'right',
                },
            },
        ];
    }


    nextCallback() {
        this.next();
        this.updateControls();
    }

    backCallback() {
        this.back();
        this.updateControls();
    }


    mounted() {
        ExporoVue.USER_VALIDATION = 'BOND';

        this.step = 1;

        if (this.authService.isLoggedIn()) {
            this.checkoutRequestApi.getCheckoutWpHG(this).addParam('contract-id', this.contractId);
        } else {
            this.closeModal();
            LocalStorage.add(ExporoVue.REDIRECT_TO_KEY, CHECKOUT_MODAL_NAME);
            this.getModalService().openModal('login-modal');
        }

        Events.$on('checkout-wphg-lite-next', this.nextCallback);
        Events.$on('checkout-wphg-lite-back', this.backCallback);
    }

    closeModal(): void {
        this.step = 1;

        this.updateModalControls();
        this.getModalService().closeModal(CHECKOUT_MODAL_NAME);
    }

    onCloseModal() {
        Tracking.trackClose('checkout form', false, undefined, 'bond', this.step);

        this.step = 1;

        Events.$off('checkout-wphg-lite-next', this.nextCallback);
        Events.$off('checkout-wphg-lite-back', this.backCallback);
        this.signingId = null;

        this.updateModalControls();
        this.updateControls();
        // this.getModalService().closeModal(CHECKOUT_MODAL_NAME);
    }

    next() {
        this.checkForAvailableInvestments();

        if (!this.shouldLockCheckout) {

            if (this.checkoutData && this.checkoutValidator.prevalidate(JSON.parse(this.checkoutData.validation), this.step)) {
                if (this.step < this.maxSteps) {
                    this.step++;
                    Router.navigate('switchCheckoutStep', [this.step]);

                    Events.$emit('checkout-wphg-lite-', {
                        step: this.step,
                        nextButtonClass: this.nextButtonClass,
                        nextButtonText: this.nextButtonText,
                        shouldHide: null !== this.signingId,
                        // scrollTo: 0
                    });
                } else if (this.step === this.maxSteps) {

                    this.payload = this.storeInvestmentRequestService.getPayload();

                    if ('exploration' && 'pieces' && 'i_have_read_all_docs' in this.payload) {
                        if (Number(this.payload.pieces) > this.availableInvestments) {
                            const toastService = new ToastService();
                            toastService.addToast(new Toast(this.trans.get('checkout.WpHGLite.explorationWpHGLite.availableInvestments', [this.availableInvestments]), 5000, 4));
                            this.switchStep(1);
                        } else {
                            if (!this.shouldLockCheckoutRequest) {
                                this.shouldLockCheckoutRequest = true;
                                Tracking.trackSubmit('checkout form', false, 'bond', this.step);
                                this.payload.config = {
                                    timeout : 60 * 1000
                                };
                                this.checkoutRequestApi.storeCheckoutWpHG(this.payload, this);
                            }
                        }

                    } else {
                        throw new RequestPartMissingError('Request parts are missing. Request is not sendable!');
                        this.closeModal();
                    }
                }
            }
        }
    }

    back() {
        if (this.step > 1) {
            this.step--;
            Router.navigate('switchCheckoutStep', [this.step]);
        }
    }

    @Action('switchCheckoutStep', {'openCheckout': [{config: {always: true}}]})
    switchStepAction(step: string) {
        this.switchAndUpdate(Number(step), true);
    }

    switchStep(step: number, shouldScroll: boolean = true) {
        Router.navigate('switchCheckoutStep', [step]);
    }

    switchAndUpdate(step, shouldScroll) {
        this.step = step;
        this.onStepSwitched(shouldScroll);
        this.updateControls();
        this.checkForAvailableInvestments();
        if (step !== 1 || step === 1  && null !== this.checkoutData) {
            Tracking.trackOpen('checkout form', false, 'checkout step', 'bond', this.step);
        }
        this.trackCheckoutStep();
    }

    private checkForAvailableInvestments() {

        const contractId = this.checkoutData ? this.checkoutData.contract.id : null;

        if (contractId) {

            PubSubService.getInstance((data?) => {
                const project: any = PubSubService.getInstance().getProject(contractId, TYPE_BOND);

                this.availableInvestments = project.availableInvestment;

                if (this.availableInvestments <= 0 && !this.shouldLockCheckout) {
                    const toastService = new ToastService();

                    toastService.addToast(new Toast(this.trans.get('checkout.WpHGLite.explorationWpHGLite.no_availableInvestments'), 5000, 1));

                    this.shouldLockCheckout = true;
                }
            });
        }

        this.shouldLockCheckout = false;
    }

    onStepSwitched(shouldScroll: boolean = true) {
        this.updateModalControls();

        this.$nextTick(() => {
            const stepdivider = document.querySelector('.steps h2.active');
            if (stepdivider && shouldScroll) {
                stepdivider.scrollIntoView({block: 'start', behavior: 'smooth'});
            }
        });
    }

    private updateModalControls() {
        if (this.step >= this.maxSteps) {
            if (this.checkoutData && this.checkoutData.hasOwnProperty('isFirstInvestment')) {
                if (this.checkoutData.isFirstInvestment && !this.checkoutData.isBondTypeBlockchain) {
                    this.nextButtonText = this.trans.get('checkout.WpHGLite.explorationWpHGLite.nextButtonText_1');
                } else {
                    this.nextButtonText = this.trans.get('checkout.WpHGLite.explorationWpHGLite.nextButtonText_2');
                }
            } else {
                this.nextButtonText = this.trans.get('checkout.WpHGLite.explorationWpHGLite.nextButtonText_3');
            }


            this.nextButtonClass = 'btn green pull-right clearfix text-center finish';
        } else {
            this.nextButtonText = ExporoVue.CHECKOUT_NEXT_TEXT;
            this.nextButtonClass = 'btn';
        }


    }

    onFailure(error: RestError): void {
        this.shouldLockCheckoutRequest = false;
        Tracking.trackError('checkout form', true, 'checkout step', 'bond', this.step);
        if (error.errorCode === MISSING_USER_DETAILS) {
            this.getModalService().openModal('user-edit', {
                redirectToModal: CHECKOUT_MODAL_NAME,
                from: CHECKOUT_MODAL_NAME,
            });

            this.closeModal();
        } else if (error.errorCode === NOT_LOGGED_IN) {
            this.getModalService().openModal('login-modal', {
                redirectToModal: CHECKOUT_MODAL_NAME,
            });

            this.closeModal();
        } else {
            this.checkoutValidator.check(error.errorMessage);
        }
    }

    trackOnInvest() {
        const actualSum = this.investService.getInvestSum().toString();
        const pieceValue = this.checkoutData ? this.checkoutData.contract.piece_value.toString() : '0';

        Tracking.trackPurchase(
            `bond-${this.signingId}`,
            '',
            actualSum,
            '0',
            '0',
            '',
            this.contractId,
            this.projectTitle,
            'bond',
            pieceValue,
            this.currentPayload.pieces ? this.currentPayload.pieces.toString() : '1',
            ''
        );


      if ((window as any).abtasty) {
        (window as any).abtasty.send('transaction', {
          tid: `bond-${this.signingId}`
        });
      }

        if (this.checkoutData && (this.checkoutData.isFirstInvestment || this.checkoutData.isFirstInvestement)) {
            const aid = LocalStorage.get(ExporoVue.AFFILIATE_ID_KEY, null);
            Tracking.trackFirstInvestment(aid);
        }
    }

    trackCheckoutStep() {
        const brand = 'Exporo ' + URLHelper.getCCTLD();
        this.currentPayload = this.storeInvestmentRequestService.getPayload();
        const pieceValue = this.checkoutData ? this.checkoutData.contract.piece_value.toString() : '0';

        Tracking.trackCheckoutSteps(
            this.step,
            'bond',
            this.contractId,
            this.projectTitle,
            brand,
            'bond',
            pieceValue,
            this.currentPayload.pieces ? this.currentPayload.pieces.toString() : '1');
    }

    onSuccess(response: AxiosResponse): void {
        if (response.config.url && response.config.url.indexOf('/bonds') > 0 && 'post' === response.config.method) {

            const checkoutRoot = document.getElementsByClassName('checkout-root')[0];
            checkoutRoot.className += ' finish';

            this.signingId = response.data.signingId;
            this.trackOnInvest();

            this.updateControls();

        } else {
            this.checkoutData = response.data;

            this.$nextTick(() => {

                if (this.checkoutData) {

                	this.isFinancing = this.checkoutData.contract.product_type === 'finanzierung';

                	this.singleUnitThresholdText = this.checkoutData.singleUnitThresholdText || this.trans.get('checkout.WpHGLite.investmentWpHGLite.single_unit_threshold');

                    this.availablePieces = this.checkoutData.availablePieces;

                    if (this.availablePieces <= 0) {
                        this.noPiecesAvailable = true;
                        return;
                    }

                    this.explorationService = new ExplorationService();

                    if (!this.checkoutData.userHasCompleteData) {
                        this.getModalService().openModal('user-edit', {
                            redirectToModal: CHECKOUT_MODAL_NAME,
                            data: {
                                requiredUserEditFormFields: this.checkoutData.requiredUserEditFormFields,
                                isBondTypeBlockchain: this.checkoutData.isBondTypeBlockchain
                            },
                            from: CHECKOUT_MODAL_NAME,
                        });
                        this.closeModal();

                    } else if (this.availablePieces !== 0) {
                        Tracking.trackOpen('checkout form', false, 'checkout step', 'bond', this.step);
                    }

                    const userService = new UserService(this.checkoutData.user);
                    userService.buildUser({
                        usersIban: this.checkoutData.usersIban,
                        usersBic: this.checkoutData.usersBic,
                    });

                    this.user = userService.getUser();

                    this.investService.updateFomData('contract_id', this.checkoutData.contract.id);
                    this.projectTitle = this.checkoutData.contract.title;

                    this.checkoutDataComplete = true;
                    this.trackCheckoutStep();
                }
            });
        }
    }

    onValidatorError(step: any): void {
        this.switchStep(step, false);
        Tracking.trackError('checkout form', true, 'checkout step', 'bond', step);
    }

    getCheckoutType(): string {

        return 'bond';
    }

    getViewType(): string {

        return 'checkout form';
    }
}

export default CheckoutWpHGLite;
