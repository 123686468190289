























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import BuyTransaction from '@/components/SecondaryMarket/BuyTransaction.vue';
import CockpitButton from '@/components/Cockpit/Controls/CockpitButton.vue';
import ExporoModal from '@/components/Modal/ExporoModal.vue';
import ExporoVue from '@/components/ExporoVue';
import UserEdit from '@/components/User/Edit/UserEdit.vue';

@Component({
    components: {
        BuyTransaction,
        CockpitButton,
        ExporoModal,
        UserEdit
    }
})
class BuyModalContainer extends ExporoVue {

    @Prop() private data!: any;
    @Prop() private hasTradingAccountOrPendingCreation!: boolean;
    @Prop() private ident!: object;
    @Prop() private isInvestableData!: object;

    private iban: string = '';

    constructor() {
        super();
    }

    updateIban(val) {
        this.iban = val.target.value;
    }

    navigateToIDnow() {
        window.open('https://backend.exporo.de/user/me/identification?provider=idnow', '_blank');
    }

    navigateToPostIdent() {
        window.open('https://backend.exporo.de/user/me/identification?provider=post', '_blank');
    }

    navigateToUserEdit() {
        this.getModalService().openModal('user-edit');
    }
}

export default BuyModalContainer;
