





















































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import ExporoVue from '../../ExporoVue';
import RestApiService, {HOST} from '../../Rest/services/RestApiService';
import RequestCallback from '../../Rest/RequestCallback';
import RestError from '../../Rest/models/RestError';
import {AxiosResponse} from 'axios';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import InvestorRequestApi from '../../Rest/requests/InvestorRequestApi';
import InvestorFormData from './InvestorFormData';
import ImageUpload from '@/components/ImageUpload/ImageUpload.vue';
import Select from '@/components/Select/Select.vue';
import Input from '@/components/Input/Input.vue';
import URLHelper from '@/components/Helper/URLHelper';


@Component({
    components: {
        ImageUpload,
        Checkbox,
        Select,
        Input
    }
})
class InvestorForm extends ExporoVue implements RequestCallback {

    @Prop() private investor!: models.Investor;
    @Prop() private onInvestorChangeCallback!: (InvestorFormData) => void;
    @Prop() private isAdmin!: boolean;
    @Prop() private isNewInvestor!: boolean;

    private restApiService: RestApiService;
    private legal_entity = null;
    private investorApiService: InvestorRequestApi;
    private submitText = '';
    private investorFormData: InvestorFormData;
    private error: any = {};



    constructor() {
        super();

        this.submitText = this.trans.get('cockpit.pages.investors.submit_text_create');

        this.restApiService = new RestApiService(this);

        this.investorApiService = this.restApi.create(new InvestorRequestApi());

        if (this.investor) {
            this.submitText = this.trans.get('cockpit.pages.investors.submit_text_update');
            this.investorFormData = new InvestorFormData(this.investor);
        } else {
            this.investorFormData = new InvestorFormData();
        }

        if (this.isNewInvestor) {
            this.investorFormData.legal_entity = 1;
        }
    }

    onComplete(name: string, val: string) {
        this.investorFormData[name] = val;
    }

    isSexChecked(value: string): boolean {

        return value === this.investorFormData.sex;
    }

    createOrUpdate() {
        const form = this.investorFormData.getForm();

        if (this.investor) {
            this.investorApiService.updateInvestor(form, this);
        } else {
            this.investorApiService.createInvestor(form, this);
        }
    }

    onFailure(error: RestError): void {
        this.error = error.errorMessage;
    }

    onSuccess(response: AxiosResponse): void {
        this.investorFormData.id = response.data.investorId;
        if (0 >= 'reload'.indexOf(response.data)) {
            this.onInvestorChangeCallback(this.investorFormData);
        }
    }
}

export default InvestorForm;
