














import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import CustomerRatingInstance from '@/components/CustomerRating/CustomerRatingInstance.vue';
import CustomerRatingButton from '@/components/CustomerRating/CustomerRatingButton.vue';
import CustomerRatingFooter from '@/components/CustomerRating/CustomerRatingFooter.vue';

@Component({
    components: {
        CustomerRatingInstance,
        CustomerRatingButton,
        CustomerRatingFooter
    }
})

class CustomerRatingPostsList extends ExporoVue {

    @Prop() customerRatingList!: any[];

    constructor() {
        super();
    }
}

export default CustomerRatingPostsList;
