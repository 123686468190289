











import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import InvestEntry from '@/components/Cockpit/InvestList/InvestEntry.vue';
import InvestEntryData from '../InvestList/InvestEntryData';

@Component({
    components: {
        InvestEntry
    }
})
class GhostLayer extends Vue {

    private ghostData: InvestEntryData | null = null;
    private sizes: number[] = [];

    constructor() {
        super();
    }

    clearGhostContainer() {

        this.ghostData = null;
    }

    setGhost(ghostData: InvestEntryData, sizes: [number]) {
        this.ghostData = ghostData;
        this.sizes = sizes;
    }

    getStyle(index: number) {
        return {
            width: this.sizes[index] + 'px'
        };
    }

    get columns() {

        if (this.ghostData) {

            return Object.keys(this.ghostData.data);
        }

        return [];
    }
}

export default GhostLayer;
