















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import TableData from '../models/TableData';
import InvestOption from './InvestOption.vue';
import CockpitOptions from '../Controls/CockpitOptions.vue';
import CockpitSelect from '../Controls/CockpitSelect.vue';
import CockpitMenu from '../Controls/CockpitMenu.vue';
import ExporoVue from '@/components/ExporoVue';
import SortButton from '@/components/Cockpit/InvestList/SortButton.vue';
import UserTooltip from '@/components/User/Edit/UserTooltip.vue';

@Component({
    components: {
        InvestOption,
        CockpitOptions,
        CockpitSelect,
        CockpitMenu,
        SortButton,
        UserTooltip
    }
})
class InvestMenu extends ExporoVue {

    @Prop() private tableData!: TableData;
    @Prop() private pendingTableData!: TableData;
    @Prop() private headline!: string;
    @Prop() private filterStatus!: any[][];
    @Prop() private selected!: string;
    @Prop() private headlineTooltip!: string;
}

export default InvestMenu;
