import ExporoTokenService from '../../JWT/services/ExporoTokenService';
import RequestCallback from '../../Rest/RequestCallback';
import RestError from '../../Rest/models/RestError';
import {AxiosResponse} from 'axios';
import ExporoVue from '../../ExporoVue';
import ExporoConfig from '../../../ExporoConfig';
import SecondaryMarketRequestApi from '@/components/Rest/requests/SecondaryMarketRequestApi';

const EXPORO_MOCK_TOKEN_BAADER = ExporoConfig.EXPORO_MOCK_TOKEN_BAADER;
class SecondaryMarketApiService extends ExporoVue implements RequestCallback {

    private secondaryMarketRequestApi!: SecondaryMarketRequestApi;

    constructor() {
        super();
        this.secondaryMarketRequestApi = this.restApi.create(new SecondaryMarketRequestApi());
    }

    requestSecondaryMarket(callback: (data: any) => void) {
        const tokenService = new ExporoTokenService();
		tokenService.fetchToken( (token: string) => {

			this.secondaryMarketRequestApi.getAllOffers({token: token, callback: callback}, this);
		});
    }

    onFailure(error: RestError, id?: string): void {
        (error as any).callback();
    }

    onSuccess(response: AxiosResponse<any>, id?: string): void {
        (response as any).callback(response.data);
    }
}

export default SecondaryMarketApiService;
