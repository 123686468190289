class CacheBag {

    private reponses: any = {};
    private listeners: any = {};

    constructor() {

    }

    addResponse(url: string, response: any) {
        this.reponses[url] = response;
    }

    addListener(url: string, callback, component) {
        if (!this.listeners[url]) {
            this.listeners[url] = [];
        }

        let listenerAlreadySet: boolean = false;

        this.listeners[url].forEach((listener) => {
            if (listener.component === component) {
                listener.callback = callback;
                listenerAlreadySet = true;
            }
        });

        if (!listenerAlreadySet) {
            this.listeners[url].push({
                component: component,
                callback: callback
            });
        }
    }

    getListener(url: string) {

        return this.listeners[url];
    }

    getResponse(url: string) {

        return this.reponses[url];
    }
}

export default CacheBag;
