
















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '../../ExporoVue';
import {VueGoodTable} from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import StringHelper from '@/components/Helper/StringHelper';
import YieldReporting from '@/components/Cockpit/models/YieldReporting';


@Component({
    components: {
        VueGoodTable,
    },
})

class KpiTable extends ExporoVue {

    @Prop()
    searchPlaceholder!: string;

    @Prop()
    private yieldReporting!: YieldReporting;

    @Prop()
    rows!: string[];

    constructor() {
        super();
    }

    formatAccounting(value) {
        return StringHelper.toEur(value);
    }

    columns() {
        return [
            {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.income_outcome'),
                field: 'incomeOutcome',
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
                sortable: false,
            },
            {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.rental_income'),
                field: 'rentalIncome',
                type: 'number',
                formatFn: this.formatAccounting,
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            },
            {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.rental_loss'),
                field: 'rentalLoss',
                type: 'number',
                formatFn: this.formatAccounting,
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            },
            {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.operating_costs'),
                field: 'operatingCosts',
                type: 'number',
                formatFn: this.formatAccounting,
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            },
            {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.property_management'),
                field: 'propertyManagement',
                type: 'number',
                formatFn: this.formatAccounting,
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            },
            {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.management_costs'),
                field: 'managementCosts',
                type: 'number',
                formatFn: this.formatAccounting,
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            },
            {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.maintenance_cost'),
                field: 'maintenanceCosts',
                type: 'number',
                formatFn: this.formatAccounting,
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            }, {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.interest_payments'),
                field: 'interestPayments',
                type: 'number',
                formatFn: this.formatAccounting,
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            }, {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.real_estate_result'),
                field: 'realEstateResult',
                type: 'number',
                formatFn: this.formatAccounting,
                tdClass: 'text-right',
                thClass: 'vgt-left-align bold',
            }, {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.acquittance_payments'),
                field: 'acquittancePayments',
                type: 'number',
                formatFn: this.formatAccounting,
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            }, {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.maintenance_reserve_funds'),
                field: 'maintenanceReserveFunds',
                type: 'number',
                formatFn: this.formatAccounting,
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            }, {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.payout_euro'),
                field: 'payoutEuro',
                type: 'number',
                formatFn: this.formatAccounting,
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            }, {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.payout_percent'),
                field: 'payoutPercent',
                type: 'percentage',
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            }, {
                label: this.trans.get('cockpit.pages.detail.reportings_table_header.investor_capital'),
                field: 'investorCapital',
                type: 'number',
                formatFn: this.formatAccounting,
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            },
        ];
    }

    // @TODO Chris: JS Styling -> render function onSuccess()
    // calculateWidths() {
    //     this.$nextTick(() => {
    //         let containerWidth: number = 0;
    //         const root: any = this.$refs['root'];
    //         const trInHead = root.querySelector('thead > tr');
    //         const trsInBody = root.querySelectorAll('tbody > tr');
    //
    //         containerWidth += trInHead.offsetWidth;
    //
    //         trsInBody.forEach((tr) => {
    //             containerWidth += tr.offsetWidth;
    //         });
    //
    //         const table = root.getElementsByClassName('vgt-table')[0];
    //
    //         if (table) {
    //             table.style.width = `${containerWidth + 60}px`;
    //         }
    //     });
    // }

}


export default KpiTable;
