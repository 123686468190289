import RequestAdapter from '@/components/LoadingBar/adapter/RequestAdapter';

class LoadingBarService {
    private startCallback!: () => void;
    private stopCallback !: () => void;
    private requestAdapter?: RequestAdapter;
    private istStared: boolean = false;
    private IDLE_TIME: number = 1000;
    private CANCEL_TIME: number = 10000;

    constructor(requestAdapter?: RequestAdapter) {
        this.requestAdapter = requestAdapter;
    }

    public start() {
        if (this.requestAdapter) {
            if (this.requestAdapter && 0 === this.requestAdapter.length && false === this.istStared) {
                this.startCallback();
                this.istStared = true;
                this.initCancelCallback();
            }
        } else if (!this.requestAdapter && false === this.istStared) {
            this.startCallback();
            this.istStared = true;
            this.initCancelCallback();
        }
    }

    public stop() {
        if (this.requestAdapter) {
            setTimeout(() => {
                if (this.requestAdapter && 0 === this.requestAdapter.length && this.istStared) {
                    this.stopCallback();
                    this.istStared = false;
                }
            }, this.IDLE_TIME);
        } else if (!this.requestAdapter && this.istStared) {
            this.stopCallback();
            this.istStared = false;
        }
    }

    private initCancelCallback() {
        setTimeout(() => {
            this.stopCallback();
            this.istStared = false;
        }, this.CANCEL_TIME);
    }

    public addStartCallback(startCallback: () => void) {
        this.startCallback = startCallback;
    }

    public addStopCallback(stopCallback: () => void) {
        this.stopCallback = stopCallback;
    }
}

export default LoadingBarService;
