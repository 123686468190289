import {Cached, Get, Post} from '@/decorators/RestDecorators';
import RequestCallback from '../RequestCallback';
import RestApi from '@/components/Rest/RestApi';
import RequestApi from '@/components/Rest/models/RequestApi';

class CockpitRequestApi extends RequestApi {

    static DELETE_INVESTOR = 'DELETE_INVESTOR';
    static GET_DOCUMENTS = 'GET_DOCUMENTS';
    static GET_INVERSTORS = 'GET_INVERSTORS';
    static GET_CONTRACTS = 'GET_CONTRACTS';

    @Cached()
    @Get('/api/intern/cockpit/{userId}', 'getBasics')
    getBasics(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/cockpit/{userId}/investment/{investmentId}')
    getInvestment(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/cockpit/{userId}/documents', CockpitRequestApi.GET_DOCUMENTS)
    getDocuments(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/cockpit/{userId}/signing/files/{type}/{isin}', 'getDocumentData')
    getDocumentData(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/cockpit/{userId}/financing/files/{type}/{signingId}', 'getDocumentData')
    getFinancingDocuments(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/cockpit/{userId}/saving_plan/files/{savingsId}', 'getSavingPlanData')
    getSavingPlanData(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/cockpit/{userId}/investors', CockpitRequestApi.GET_INVERSTORS)
    getInvestors(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/cockpit/{userId}/export/csv')
    exportCSV(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/investor/{id}/delete/webapp', CockpitRequestApi.DELETE_INVESTOR)
    deleteInvestor(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/cockpit/switchVersion/{version}', 'switchVersion')
    switchVersion(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/cockpit/contracts', CockpitRequestApi.GET_CONTRACTS)
    getContracts(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

}

export default CockpitRequestApi;
