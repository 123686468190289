

















import Component from 'vue-class-component';
import ExporoVue from '@/components/ExporoVue';
import Toast from './Toast';
import Events from '@/events';
import ErrorForm from '@/components/ErrorForm/ErrorForm.vue';

@Component({
    components: {
        ErrorForm
    }
})
class ToastContainer extends ExporoVue {

    private toasts: Toast[] = [];

    constructor() {
        super();

        Events.$on('onToastRemoved', (toast: Toast) => {
            this.toasts.splice(this.toasts.indexOf(toast), 1);
        });

        Events.$on('onToastAdded', (toast: Toast) => {
            let toastAlreadyAdded: boolean = false;

            this.toasts.forEach((containerToast: Toast) => {
                if (containerToast.id === toast.id) {
                    toastAlreadyAdded = true;
                }
            });

            if (!toastAlreadyAdded) {
                this.toasts.push(toast);
                toast.show();
            }
        });
    }
}

export default ToastContainer;
