











import {Component, Prop, Vue} from 'vue-property-decorator';
import AuthService from '@/components/Auth/services/AuthService';
import RestApiService from '../Rest/services/RestApiService';
import store, {LOGOUT} from '../../store';
import ExporoVue from '../ExporoVue';
import UserRequestApi from '../Rest/requests/UserRequestApi';
import RestError from '../Rest/models/RestError';
import RequestCallback from '../Rest/RequestCallback';
import {AxiosResponse} from 'axios';

@Component({
  store
})
export default class AuthButtonLogout extends ExporoVue implements RequestCallback {

  public html: any;
  private authService: AuthService;

  @Prop({default: ''})
  private dataClass!: string;

  @Prop({default: ''})
  private dataStyle!: string;

  private userRequestApi: UserRequestApi;

  constructor() {
    super();

    this.authService = new AuthService(new RestApiService());
    this.userRequestApi = this.restApi.create(new UserRequestApi());
  }

  logout(): void {
    this.userRequestApi.logout(this);
  }

  get isLoggedIn(): boolean {
    return this.getStore().state.isLoggedIn;
  }

  onFailure(error: RestError): void {
  }

  onSuccess(response: AxiosResponse): void {
    const body = document.getElementsByTagName('body')[0];
    if (body && body.className.indexOf('authenticated') > -1) {
      body.className = body.className.replace('authenticated', '');
    }
    this.authService.logout();
    localStorage.removeItem('oldLogin');
  }
}
