import {Get, Post} from '@/decorators/RestDecorators';
import RequestCallback from '../RequestCallback';
import RestApi from '@/components/Rest/RestApi';
import RequestApi from '@/components/Rest/models/RequestApi';

class ActionCodeRequestApi extends RequestApi {

    @Get('/api/internal/action-code/{code}?product={product}&projectId={projectId}&investSum={investSum}')
    check(data?: any, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }
}

export default ActionCodeRequestApi;
