class RequestAdapter {
    get length(): number {
        return this._length;
    }

    set length(value: number) {
        this._length = value;
    }

    private request: string[] = [];
    private _length: number = 0;

    addRequest(request: string) {
        this.request.push(request);
        this.updateLength();
    }

    removeRequest(request: string) {

        this.request = this.removeFromArray(this.request, request);
        this.updateLength();
    }

    private updateLength() {
        this._length = this.request.length;
    }

    private removeFromArray(array, element) {

        return array.filter((el) => el !== element);
    }
}

export default RequestAdapter;
