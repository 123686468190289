import { render, staticRenderFns } from "./CockpitPanel.vue?vue&type=template&id=2378b035&scoped=true&"
import script from "./CockpitPanel.vue?vue&type=script&lang=ts&"
export * from "./CockpitPanel.vue?vue&type=script&lang=ts&"
import style0 from "./CockpitPanel.vue?vue&type=style&index=0&id=2378b035&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2378b035",
  null
  
)

export default component.exports