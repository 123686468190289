





import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import PubSubService, {TYPE_BOND, TYPE_PROJECT} from '@/components/PubSub/services/PubSubService';

@Component
class ProjectTotalInvestCount extends Vue {

    private pubSubService!: PubSubService;
    private total: number = 0;

    @Prop()
    private id!: string;

    @Prop({default: 'project'})
    private type!: string;

    constructor() {
        super();
    }

    mounted() {
        this.pubSubService = PubSubService.getInstance((data) => {

            let project: any = null;

            if (data && data.projects) {

                if ('project' === this.type) {
                    if (data.projects && data.projects.hasOwnProperty(this.id)) {
                        project = data.projects[this.id];
                    }
                } else {
                    if (data.projects.bonds && data.projects.bonds.hasOwnProperty(this.id)) {
                        project = data.projects.bonds[this.id];
                    }
                }

            } else if (void(0) === data) {
                project = this.pubSubService.getProject(this.id, (void(0) !== this.type && 'bonds' === this.type ? TYPE_BOND : TYPE_PROJECT));
            }

            this.displayByProject(project);
        });
    }

    displayByProject(project: any) {

        this.total = project.total;
    }
}

export default ProjectTotalInvestCount;
