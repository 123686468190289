import Events from '../../../events';
import ContextService from '@/components/Router/services/ContextService';

export function Action(methodName: string, beforeAction?: any) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {

        function checkConfig(key) {
            const params: any[] = beforeAction[key];

            if (params[0] && params[0].hasOwnProperty('config')) {
                const config = params[0].config;

                if (config.always) {
                    Events.$emit(`${key}`, beforeAction[key]);
                }
            }
        }

        Events.$on(methodName, (matches: any) => {
            target.$nextTick(() => {
                const controllerName = target.getName();
                const ctx = ContextService.getInstance().getContext(controllerName);
                if (ctx) {
                    descriptor.value.apply(ctx, matches);

                    if (beforeAction) {
                        const key = Object.keys(beforeAction)[0];
                        checkConfig(key);
                    }
                } else {
                    if (beforeAction) {
                        const key = Object.keys(beforeAction)[0];

                        Events.$emit(`${key}`, beforeAction[key]);

                        Events.$on(`${controllerName}Loaded`, () => {
                            target.$nextTick(() => {
                                descriptor.value.apply(ContextService.getInstance().getContext(controllerName), matches);
                            });
                        });
                    }
                }
            });
        });
    };
}

export function Route(url: string) {
    return (target: any, propertyKey: string) => {
        target[propertyKey] = {url: url};
    };
}
