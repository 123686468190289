import Middleware from '@/components/Rest/middleware/interfaces/Middleware';
import Closure from '@/components/Rest/middleware/core/Closure';
import BeforeData from '@/components/Rest/middleware/interfaces/BeforeData';
import AfterData from '@/components/Rest/middleware/interfaces/AfterData';
import Events from '@/events';


class LoadingBarMiddleware implements Middleware {


    constructor() {

    }

    before(data: BeforeData, closure: Closure): void {
        Events.$emit('start-loading-bar', data);
        closure.next(data);
    }

    after(data: AfterData, closure: Closure): void {
        Events.$emit('stop-loading-bar', data);
        closure.next(data);
    }
}

export default LoadingBarMiddleware;
