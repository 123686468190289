import {ContentfulClientApi} from 'contentful';
import IntegrationOptions from '@/components/Rest/models/IntegrationOptions';
import {AxiosResponse} from 'axios';
const contentful = require('contentful');

class ContentfulIntegration {

    private CONTENT_TOKEN: string = 'tn1wPGQcXCj9UPygQhLWrm9IFroiVwxzzVQEfARR5vs';
    private PROJECT_TOKEN: string = 'd1f999825f058fb0042f47c783e0b6c75e813f8fdd30cf69399b1e40ce3d92a7';
    private client: ContentfulClientApi;

    constructor() {
        this.client = this.createClient();
    }

    createClient(spaceId: string = 'ur2m9vf22wi7'): ContentfulClientApi {
        return contentful.createClient({
            space: spaceId,
            accessToken: spaceId === 'ur2m9vf22wi7' ? this.PROJECT_TOKEN : this.CONTENT_TOKEN,
        });
    }

    getEntries(options: IntegrationOptions) {
        if (options.data.spaceId) {
            this.client = this.createClient(options.data.spaceId);
        } else {
            this.client = this.createClient();
        }

        this.client.getEntries(options.data.query).then((entry) => {
            options.callback.onSuccess(entry as any, options.id);
        }).catch((err) => {
            options.callback.onFailure(err as any, options.id);
        });
    }


    getKpiValues(options: IntegrationOptions) {
        this.client.getEntries(options.data.query).then((entry) => {
            options.callback.onSuccess(entry as any, options.id);
        }).catch((err) => {
            options.callback.onFailure(err as any, options.id);
        });
    }


    getUpdates(options: IntegrationOptions) {
        this.client.getEntries(options.data.query).then((entry) => {
            options.callback.onSuccess(entry as any, options.id);
        }).catch((err) => {
            options.callback.onFailure(err as any, options.id);
        });
    }
    
}

export default ContentfulIntegration;
