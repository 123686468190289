import ExporoConfig from '@/ExporoConfig';

const EXPORO_LANGUAGE = ExporoConfig.EXPORO_LANGUAGE;

class URLHelper {

    static getQueryParam(paramName: string): string | null {
        const results = new RegExp('[\?&]' + paramName + '=([^&#]*)').exec(window.location.href);

        return results ? (decodeURI(results[1]) || null) : null;
    }

    static hasQueryParam(paramName: string): boolean {
        return null !== new RegExp('[\?&]' + paramName).exec(window.location.href);
    }

    static hasFragment(paramName: string): boolean {
        return null !== new RegExp(paramName).exec(window.location.href);
    }

    static getCCTLD(): string {
        return EXPORO_LANGUAGE ? EXPORO_LANGUAGE : (window.location.hostname.split('.').reverse()[0] || 'local').toUpperCase();
    }

    static deleteAbTastyQuery(): void {
        const location = { ...window.location };
        if (location.href.includes('?mrasn=')) {
            const splitUrl = location.href.split('?mrasn=');
            const hastTagIndex = splitUrl[1].indexOf('#');
            const substring = splitUrl[1].substring(hastTagIndex);
            window.history.replaceState({}, 'Cockpit', splitUrl[0] + substring);
        }
    }

}

export default URLHelper;
