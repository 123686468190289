





import {Component, Vue, Prop} from 'vue-property-decorator';

@Component
export default class AccordionGroup extends Vue {

    constructor() {
        super();
    }

    mounted() {
        if (this.$slots && this.$slots.default) {
            this.$slots.default.forEach((vnode: any) => {
                // @TODO IE classList not exist
                if (vnode.elm.classList && vnode.elm.classList.contains('exporo-accordion')) {
                    const shouldStayOpen = vnode.componentInstance.$options.propsData.open;
                    if (!shouldStayOpen && vnode.elm.children && vnode.elm.children.length >= 1) {
                        vnode.elm.children[0].addEventListener('click', this.handleClick.bind(this, vnode, vnode.key), true);
                    }
                }
            });
        }
    }

    handleClick(currentVnode: any, key) {
        if (this.$slots && this.$slots.default) {
            this.$slots.default.forEach((vnode: any) => {
                // @TODO IE classList not exist
                if (vnode.elm.classList && vnode.elm.classList.contains('exporo-accordion')) {
                    const shouldStayOpen = vnode.componentInstance.$options.propsData.open;
                    if (!shouldStayOpen && vnode.key !== key && (vnode.elm.classList && vnode.elm.classList.contains('exporo-accordion'))) {
                        vnode.componentInstance.show = false;
                    }
                }
            });
        }

        currentVnode.componentInstance.show = !currentVnode.componentInstance.show;
    }
}
