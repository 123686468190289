class RequestApi {

    getApiMethods() {

        return Object.getOwnPropertyNames(Object.getPrototypeOf(this));
    }

    getName(): string {
        let className: any = this;
        const matches = this.constructor.toString().match(/\w+/g);
        if (matches) {
            className = matches[1];
        }

        return className;
    }
}

export default RequestApi;
