import axios, {AxiosRequestConfig} from 'axios';
import Logger from '@/components/Helper/LogHelper';
import {BASE_TOKEN} from '@/components/Rest/services/RestApiService';

class KPIStore {
	private URL: string = 'https://kpis.dwh.exporo.de/home/kpis.json';
	static _instance: KPIStore;
	private kpis: any = null;
	private callbacks: any[] = [];

	static getInstance() {
		if (!KPIStore._instance) {
			KPIStore._instance = new KPIStore();
		}

		return KPIStore._instance;
	}

	constructor() {}

	public fetchKPIS() {
		if (null !== this.kpis) {
			return;
		}

		const config: AxiosRequestConfig = {
			url: this.URL,
			method: 'get'
		};

		axios.request(config).then((result: any) => {
			this.kpis = result.data;

			this.callbacks.forEach((callback: any) => {
				callback.cb(this.kpis[callback.type]);
			});
		}).catch((error: any) => {
			Logger.tag('KPIStore').log(error);
		});
	}

	public getKPIS(type: string, callback: (kpis: any) => void) {

		if (null !== this.kpis) {
			callback(this.kpis[type]);
		} else {
			this.callbacks.push({
				type: type,
				cb: callback
			});
		}
	}
}

export default KPIStore;
