import ValidatorCallback from '@/components/Checkout/Validator/ValidatorCallback';
import RuleHelper from '@/components/Checkout/Validator/RuleHelper';

export const MESSAGE_TYPE_SIMPLE = 1;
export const MESSAGE_TYPE_EXTENDET = 2;

const VALIDATION_ERROR_CLASS_NAME = 'validation-error';

class CheckoutValidator {

    private callback?: ValidatorCallback;
    private errors: any = {};
    private messageType: number = MESSAGE_TYPE_EXTENDET;
    private shouldLockStepSwitch: boolean = false;
    private earliestError: any;

    constructor(callback?: ValidatorCallback, messageType = MESSAGE_TYPE_EXTENDET) {
        this.callback = callback;
        this.messageType = messageType;
    }

    getEntriesOfObject(obj: object) {
        const ownProps = Object.keys(obj);
        let i = ownProps.length;

        const resArray = new Array(i);

        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    }

    prevalidate(data: any, step: number): boolean {
        this.clearErrors();

        const messages = data.messages;
        const rules = data.rules;
        let errorCount = 0;

        for (const [id, ruleString] of this.getEntriesOfObject(rules)) {

            const errorObject = this.errors[id];
            if (errorObject) {
                const errorNode = errorObject.error;
                const input = errorNode.previousElementSibling ? errorNode.previousElementSibling.querySelectorAll('input, select')[0] : null;

                if (input && errorObject.step <= step) {
                    const ruleHelper = new RuleHelper(rules[id], id);
                    ruleHelper.input = input;

                    if (!ruleHelper.conditionsAreComplied()) {
                        this.earliestError = (() => {
                            if (!this.earliestError || errorObject.step < this.earliestError.step) {
                                return errorObject;
                            }

                            return this.earliestError;
                        })();

                        const key = ruleHelper.getMessageKey(this.messageType);

                        if (key && key !== 'investsum') {
                            if (key) {

                                const errorMessage = messages[key];
                                this.shouldLockStepSwitch = true;
                                this.transformError(errorObject.error, errorMessage);
                                if (this.callback) {
                                    this.callback.onValidatorError(this.earliestError.step);
                                }
                            }

                            errorCount++;
                        }
                    }
                }
            }

        }

        this.shouldLockStepSwitch = false;

        return 0 === errorCount;
    }

    check(data: any): boolean {
        this.clearErrors();

        let reachedErrors: number = 0;

        for (const key in this.errors) {
            if (this.errors.hasOwnProperty(key)) {
                const value = this.errors[key];

                if (data[key]) {
                    if (this.callback && !this.shouldLockStepSwitch) {
                        this.shouldLockStepSwitch = true;
                        this.callback.onValidatorError(value.step);
                    }

                    const error = value.error;
                    this.transformError(error, data[key][0]);

                    reachedErrors++;
                }
            }
        }

        this.shouldLockStepSwitch = false;

        return 0 === reachedErrors;
    }

    private clearErrors(): void {
        [...document.getElementsByClassName(VALIDATION_ERROR_CLASS_NAME)].forEach((error: Element) => {
            const label = error.previousElementSibling ? error.previousElementSibling.getElementsByTagName('label')[0] : null;
            this.removeError(label, error);
        });
    }

    private transformError(error: any, message: string) {
        error.className = VALIDATION_ERROR_CLASS_NAME;
        error.innerHTML = '<p><span>' + message + '</span></p>';

        const label = error.previousElementSibling.getElementsByTagName('label')[0];
        const input = error.previousElementSibling.getElementsByTagName('input')[0];
        const select = error.previousElementSibling.getElementsByTagName('select')[0];

        if (label) {
            label.className += ' error';
            label.addEventListener('click', this.removeError.bind(this, label, error));
        }

        if (input) {
            input.addEventListener('click', this.removeError.bind(this, label, error));
            input.addEventListener('change', this.removeError.bind(this, label, error));
        }

        if (select) {
            select.addEventListener('change', this.removeError.bind(this, label, error));
        }

        error.scrollIntoView({block: 'end', behavior: 'smooth'});
    }

    removeError(label: any, error: any) {
        if (label) {
            label.classList.remove('error');
        }

        error.classList.add('hidden');

        this.earliestError = null;
    }

    addError(name: any, step: any, error: any) {

        this.errors[name] = {
            step: step,
            error: error
        };
    }
}

export default CheckoutValidator;
