






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
class SliderPage extends Vue {

}

export default SliderPage;
