class StringHelper {

    static toEur(string, lang = null, digit = 2): string {
        const result = this.format(string, digit, lang, {
            currency: 'EUR',
            style: 'currency'
        });

        return (!this.noLocaleStringSupport) ? result : `${result} €`;
    }

    static format(string, digit, lang, style) {
        const _d = (digit) ? digit : 0;
        const _s = (style) ? style : {};
        _s.minimumFractionDigits = _d;
        _s.maximumFractionDigits = _d;

        if (!this.noLocaleStringSupport) {
            return Number(string).toLocaleString((lang) ? lang : 'de-DE', _s);
        }

        return Number(string).toFixed(_d).split('').reverse().join('')
        .replace(/(\d{3}(?=\d{1,}))/g, '$1.')
        .split('').reverse().join('');
    }

    static noLocaleStringSupport = (() => {
        return (!('toLocaleString' in Number.prototype) || (Number(17288.28)).toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0 }) !== '17.288');
    })();
}

export default StringHelper;
