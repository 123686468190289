






import {Component, Prop, Vue} from 'vue-property-decorator';
import ModalOptions from '../models/ModalOptions';
import LocalStorage from '../../LocalStorage/LocalStorage';
import ExporoVue from '../../ExporoVue';
import Events from '../../../events';
import Controller from '@/components/Router/Controller';
import {Action} from '@/components/Router/decorators/RouteDecorator';
import Router from '@/components/Router/Router';
import ContextService from '@/components/Router/services/ContextService';
import Tracking from '@/components/GoogleTagManager/services/Tracking';

@Component
class ExporoModalService extends Vue {

    @Prop({default: null})
    private open!: string;

    @Prop()
    private modal!: string;

    constructor() {
        super();

        ContextService.getInstance().setContext(this, 'ExporoModalService');
    }

    getName() {

        return 'ExporoModalService';
    }

    mounted() {
        if (this.$slots.default && (null !== this.open || null !== this.modal)) {
            this.$slots.default.forEach((vnode: any) => {
                if (vnode.elm) {
                    vnode.elm.addEventListener('click', (e) => {
                        e.preventDefault();

                        Router.navigate('openModal', [this.open || this.modal]);
                    }, true);
                }
            });
        }
    }

    @Action('openModal')
    openModalAction(name: string, redirectBackUrl?: string) {
        this.openModal(name);
    }

    @Action('openModalAndRedirectBack')
    openModalActionRedirect(name: string, redirectBackUrl: string) {
        this.openModal(name, {
            redirectToUrl: redirectBackUrl
        });
    }

    openModal(name: string, options?: ModalOptions, scrollableOverlay: boolean = false) {

        if (options && options.redirectToModal && '' !== options.redirectToModal) {
            LocalStorage.add(ExporoVue.REDIRECT_TO_KEY, options.redirectToModal);
        }

        if (options && options.from && '' !== options.from) {
            LocalStorage.add(ExporoVue.MODAL_FROM_KEY, options.from);
        }

        if (options && options.data && options.data.hasOwnProperty('passwordResetToken')) {

            LocalStorage.add(ExporoVue.PASSWORD_RESET_TOKEN, (options.data as { passwordResetToken }).passwordResetToken);
        }

        if (options && options.data && options.data.hasOwnProperty('reload')) {

            Events.$on('onLoggedIn', () => {
                const reload: any = options.data.reload;
                if (typeof reload === 'string') {
                    window.location.href = reload;
                } else {
                    window.location.reload();
                }
            });
        }

        if (options && options.data && options.data.hasOwnProperty('isBondTypeBlockchain')) {

            LocalStorage.addBoolean(ExporoVue.REQUIRED_USER_EDIT_FORM_FIELDS_STO, (options.data as { isBondTypeBlockchain }).isBondTypeBlockchain);
        }

        if (options && options.onClose) {
            LocalStorage.addBoolean(ExporoVue.ON_CLOSE_MODAL, true);
            Events.$on('onClose', options.onClose);
        }

        if (options && options.redirectToUrl) {
            LocalStorage.addBoolean(ExporoVue.ON_CLOSE_MODAL, true);
            LocalStorage.addBoolean(ExporoVue.SHOULD_REDIRECT_AFTER_CLOSE, true);
            Events.$on('onClose', () => {
                Router.navigate('cockpitDetailRoute', [options.redirectToUrl?.split('-')[1], options.redirectToUrl?.split('-')[3]]);
            });
        }

        this.toggleModal(name, true, scrollableOverlay);
    }

    closeModal(name: string, response?: {}, preventNavigate?: boolean) {
        const shouldClose: boolean = LocalStorage.getBoolean(ExporoVue.ON_CLOSE_MODAL, false);
        const shouldRedirectAfterClose: boolean = LocalStorage.getBoolean(ExporoVue.SHOULD_REDIRECT_AFTER_CLOSE, false);
        if (shouldClose) {
            LocalStorage.remove(ExporoVue.ON_CLOSE_MODAL);
            Events.$emit('onClose', response);
        }
        this.toggleModal(name);
        if (!preventNavigate && !shouldRedirectAfterClose) {
            Router.navigate('home');
        }
        LocalStorage.remove(ExporoVue.SHOULD_REDIRECT_AFTER_CLOSE);
    }

    getModalByName(name: string) {

        return document.getElementById('exporo-modal-' + name);
    }

    private toggleModal(name: string, open: boolean = false, scrollableOverlay: boolean = false) {
        const modal: any = this.getModalByName(name);

        if (modal) {
            if (open) {
                if (modal.className.indexOf('open') === -1) {
                    modal.className += ' open';
                }
            } else {
                modal.className = modal.className.toString().replace(' open', '');
            }
        }

        const body: any = document.getElementsByTagName('body')[0];
        if (body) {
            if (open && !scrollableOverlay) {
                if (body.className.indexOf('exporo-not-scrollable') === -1) {
                    body.className += ' exporo-not-scrollable';
                }
            } else {
                body.className = body.className.toString().replace(' exporo-not-scrollable', '');
            }
        }
    }
}

export default ExporoModalService;
