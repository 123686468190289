import {FunctionalComponentOptions} from 'vue';

type FormDataFunction = () => FormData;

/* tslint:disable: variable-name */
class InvestorFormData implements models.Investor {
    address: string = '';
    cashboard: number = 0;
    city: string = '';
    country: string = '';
    created_at: string = '';
    deleted_at: any = '';
    dob: string = '';
    email: string = '';
    entity_authorized_as: any = '';
    entity_commercial_register_file: any = '';
    entity_commercial_register_number: any = '';
    entity_local_court: any = '';
    entity_name: any = '';
    entity_shareholders_file: any = '';
    entity_status: string = '';
    entity_tax_number: any = '';
    entity_tax_office: any = '';
    entity_type_of_company: any = '';
    first_name: string = '';
    id: number = -1;
    last_name: string = '';
    legal_entity: number = 0;
    phone: any = '';
    postal_code: string = '';
    sex: string = '';
    title: string = '';
    updated_at: string = '';
    user_id: number = -1;
    zoho_id: any = '';
    legal_entity_verification: number = 0;

    constructor(data?: any) {
        if (data) {
            const that: any = this;

            Object.keys(data).forEach((key: any) => {
                that[key] = data[key];
            });
        }
    }

    getForm() {
        const formData = new FormData();
        const that: any = this;

        for (const data in this) {
           if ('getForm' !== data) {
               formData.append(data, that[data]);
           }
        }

        return formData;
    }

    [key: string]: string | number | FormDataFunction;
}
/* tslint:enable */

export default InvestorFormData;
