import ExporoTokenService from '../../JWT/services/ExporoTokenService';
import RequestCallback from '../../Rest/RequestCallback';
import RestError from '../../Rest/models/RestError';
import {AxiosResponse} from 'axios';
import ExporoVue from '../../ExporoVue';
import WalletRequestApi from '../../Rest/requests/WalletRequestApi';
import ExporoConfig from '../../../ExporoConfig';

const EXPORO_MOCK_TOKEN_BAADER = ExporoConfig.EXPORO_MOCK_TOKEN_BAADER;
class WalletApiService extends ExporoVue implements RequestCallback {

    private walletRequestApi!: WalletRequestApi;

    constructor() {
        super();
        this.walletRequestApi = this.restApi.create(new WalletRequestApi());
    }

    getWallet(userId: number, callback: (data: any) => void, externalUserId?: string) {
        const tokenService = new ExporoTokenService();
        if (!EXPORO_MOCK_TOKEN_BAADER) {
            tokenService.fetchToken( (token: string) => {

                this.walletRequestApi.requestWallet({token: token, userId: externalUserId || userId, callback: callback}, this);
            }, externalUserId);
        } else {
            this.walletRequestApi.requestWallet({token: EXPORO_MOCK_TOKEN_BAADER, userId: userId, callback: callback}, this);
        }
    }

    onFailure(error: RestError, id?: string): void {
        (error as any).callback();
    }

    onSuccess(response: AxiosResponse<any>, id?: string): void {
        (response as any).callback(response.data);
    }
}

export default WalletApiService;
