export function Post(url?: string, id?: string) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        descriptor.value = () => {

            return {
                id,
                url,
                method: 'post'
            };
        };
    };
}

export function Get(url: string, id?: string) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {

        descriptor.value = () => {

            return {
                id,
                url,
                method: 'get',
                cached: false
            };
        };
    };
}

export function Delete(url: string, id?: string) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {

        descriptor.value = () => {

            return {
                id,
                url,
                method: 'delete',
                cached: false
            };
        };
    };
}

export function Cached() {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {

        const value = descriptor.value();
        value.cached = true;

        descriptor.value = () => {

            return value;
        };
    };
}
