











import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';

@Component({
    components: {}
})

class StarBar extends ExporoVue {
    @Prop() rating!: number;
    constructor() {
        super();
    }
}


export default StarBar;
