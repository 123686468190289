import TableData from '@/components/Cockpit/models/TableData';

class FilterTableDataHelper {

    static filterByColumn(data: TableData, column: string): TableData {

        const filteredData = data.data.filter((row: any) => {
            return row.Projekt === column;
        });

        return new TableData(filteredData);
    }
}

export default FilterTableDataHelper;
