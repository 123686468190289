






import {Component, Vue, Prop} from 'vue-property-decorator';
import RestError from '../Rest/models/RestError';

@Component
class ShowError extends Vue {
    @Prop() error!: any;
    @Prop() formDataKey!: string;

    showError(error: RestError, formDataKey: string): string | void {
        if (error.hasOwnProperty(formDataKey) && null !== error[formDataKey]) {
            if (typeof error[formDataKey] === 'string') {

                return error[formDataKey];
            }

            return error[formDataKey][0];
        }
    }

    formDataKeyExists() {
        return !!(this.error[this.formDataKey]);
    }
}

export default ShowError;
