import BaseRule from '../BaseRule';

export default class NotEqRequiredIfEq extends BaseRule {

    private toCheckInput;
    private should;

    constructor(rulesString, input, name, type) {
        super(rulesString, input, name, type);

        this.parseRulesString();

        this._valid = this.validate();
    }

    validate() {
        const value = this._input.value;

        if (this.toCheckInput.value === this.should) {
            if ('0;0' !== value) {

                return true;
            }
        } else {

            return true;
        }

        return false;
    }

    parseRulesString() {
        const rules = this._rulesString.split(',');
        this.toCheckInput = document.getElementById(rules[1]);

        if ('div' === this.toCheckInput.tagName.toLowerCase()) {
            this.toCheckInput = this.toCheckInput.querySelectorAll('input')[0];
        }

        this.should = rules[2];
    }
}
