import RequestCallback from '../RequestCallback';
import RestApi from '@/components/Rest/RestApi';
import {Baader} from '@/decorators/BaaderDecorators';
import RequestApi from '@/components/Rest/models/RequestApi';

class BaaderRequestApi extends RequestApi {

    @Baader('getWdp')
    requestBaader(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Baader('getDocuments')
    requestDocumentsBaader(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

}

export default BaaderRequestApi;
