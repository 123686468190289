import { render, staticRenderFns } from "./PageUserEdit.vue?vue&type=template&id=56054c35&scoped=true&"
import script from "./PageUserEdit.vue?vue&type=script&lang=ts&"
export * from "./PageUserEdit.vue?vue&type=script&lang=ts&"
import style0 from "./PageUserEdit.vue?vue&type=style&index=0&id=56054c35&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56054c35",
  null
  
)

export default component.exports