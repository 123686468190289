













import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
class BulletPoint extends Vue {

    constructor() {
        super();
    }
}

export default BulletPoint;
