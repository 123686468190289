








































import {Component, Vue} from 'vue-property-decorator';
import AuthService from './services/AuthService';
import RequestCallback from '../Rest/RequestCallback';
import {AxiosResponse} from 'axios';
import RestApiService from '../Rest/services/RestApiService';
import RestError from '../Rest/models/RestError';
import ExporoVue from '../ExporoVue';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import Input from '@/components/Input/Input.vue';
import ShowError from '../ErrorForm/ShowError.vue';
import CompletedCredentials from '@/components/Auth/models/CompletedCredentials';

@Component({
    props: {
        openAuthModalByName: Function,
        modalNames: Object,
    },
    components: {
        Input,
        ShowError,
    },
})
export default class PasswordReset extends ExporoVue implements RequestCallback {
    public input: any;
    public $props: any;
    private authService: AuthService;
    private id: string = '';
    private errorEmail: any = '';
    private errorPassword: any = '';
    private errorPasswordRepeat: any = '';
    private resetPasswordComplete: boolean = false;

    public inputChanged: any = {
        'email': false,
        'password': false,
    };


    constructor() {
        super();

        this.authService = new AuthService(new RestApiService(this));
    }

    data() {
        return {
            input: {
                email: '',
                password: '',
                passwordConfirmation: '',
            },

        };
    }

    reset() {
        this.authService.resetPasswordComplete(
            new CompletedCredentials(this.input.email, this.input.password, this.input.passwordConfirmation),
            this.id,
            this,
        );
    }

    openModalByName(name: string) {

        this.$props.openAuthModalByName(name);
    }

    closeModal(): void {
        this.resetPasswordComplete = false;
        this.getModalService().closeModal('password-forget-modal');
    }

    onFailure(error: RestError): void {
        const errorMessage = error.errorMessage;
        this.errorEmail = '';
        this.errorPassword = '';
        this.errorPasswordRepeat = '';
        if (errorMessage.hasOwnProperty('email')) {
            this.errorEmail = errorMessage.email[0];
        }

        if (errorMessage.hasOwnProperty('password')) {
            switch (errorMessage.password[0]) {
                case ('password_used_before'):
                    this.errorPassword = this.trans.get('auth.passwordChange.error_messages.password_used_before');
                    break;
                default:
                    this.errorPassword = errorMessage.password[0];
                    break;
            }

        }

        if ('' === this.errorEmail && '' === this.errorPassword) {
            this.errorEmail = error.response.statusText || 'error';
        }
    }

    onSuccess(response: AxiosResponse): void {
        this.resetPasswordComplete = true;
        window.location.href = window.location.href.slice(0, window.location.href.indexOf('?password/reset'));
    }

    mounted() {
        this.id = LocalStorage.get(ExporoVue.PASSWORD_RESET_TOKEN, '');
        LocalStorage.remove(ExporoVue.PASSWORD_RESET_TOKEN);
    }
}
