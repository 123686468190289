











































import {Component, Vue} from 'vue-property-decorator';
import AuthService from './services/AuthService';
import RequestCallback from '../Rest/RequestCallback';
import {AxiosResponse} from 'axios';
import Credentials from './models/Credentials';
import RestApiService from '../Rest/services/RestApiService';
import RestError from '../Rest/models/RestError';
import ExporoVue from '../ExporoVue';
import UserRequestApi from '../Rest/requests/UserRequestApi';
import Input from '@/components/Input/Input.vue';
import Events from '@/events';

@Component({
    components: {
        Input,
    },
    props: {
        openAuthModalByName: Function,
        modalNames: Object,
    },
})
export default class PasswordChange extends ExporoVue implements RequestCallback {

    public input: any;
    public $props: any;
    private successText: any;
    private restApiService: RestApiService;
    private userRequestApi: UserRequestApi;
    private error: any = {};

    private passwordChanged: boolean = false;

    constructor() {
        super();

        this.restApiService = new RestApiService(this);
        this.userRequestApi = this.restApi.create(new UserRequestApi());
    }

    mounted() {
      window.location.href = 'https://user.exporo.com/#/reset-password';
    }

    getTranslatedError(errorKey: string): string {
        const transKey = 'auth.passwordChange.error_messages.' + errorKey[0];
        return this.trans.get(transKey);
    }

    data() {
        return {
            input: {
                current_password: '',
                new_password: '',
                new_password_confirmation: '',
            },
        };
    }

    changePassword() {
        this.userRequestApi.changePassword(this.input, this);
    }

    openModalByName(name: string) {

        this.$props.openAuthModalByName(name);
    }

    closeModal(): void {
        this.getModalService().closeModal('password-change-modal');
    }

    onFailure(error: RestError): void {
        this.error = error.errorMessage;
    }

    onSuccess(response: AxiosResponse): void {
        this.successText = response.data;
        this.passwordChanged = true;
    }
}
