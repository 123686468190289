import {I18nResolver} from 'i18n-ts';
import en from '@/translations/en';
import de from '@/translations/de';
import nl from '@/translations/nl';
import fr from '@/translations/fr';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';
import Logger from '@/components/Helper/LogHelper';

class Trans {

    private static _instance: Trans;
    private locale: string = 'de';
    private messages;

    static getInstance(locale?: string): Trans {
        if (!Trans._instance) {
            Trans._instance = new Trans(locale);
        }

        if (void(0) !== locale && Trans._instance.locale !== locale) {
            Trans._instance = new Trans(locale);
        }

        return Trans._instance;
    }

    constructor(locale?: string) {

        this.locale = locale || 'de';

        const i18n = {
            en: en,
            de: de,
            nl: nl,
            fr: fr,
            default: de
        };

        this.messages = new I18nResolver(i18n, this.locale).translation;
    }

    byString(object, stringKey) {
        stringKey = stringKey.replace(/\[(\w+)]/g, '.$1');
        stringKey = stringKey.replace(/^\./, '');
        const a = stringKey.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            const k = a[i];

            if (k in object) {
                object = object[k];
            } else {
                return;
            }
        }

        return object;
    }

    get(key: string, params?: any[], locale?: string, isDefault?: boolean): string {
        let translatedText: any = null;

        if (params) {
            const translateFunction = this.byString(Trans.getInstance(locale).messages, key);

            if (typeof translateFunction === 'function') {
                translatedText = translateFunction(...params);
            } else {
                translatedText = `[ERROR] ${translateFunction}`;

                const message = `TranslationKey: "${key}" is not a function found [locale: ${Trans.getInstance().locale}]`;
                Logger.tag('Translation').error(message);
                Sentry.captureMessage(message, Severity.Error);
            }
        } else {
            translatedText = this.byString(Trans.getInstance(locale).messages, key);
        }

        if (void(0) === translatedText) {
            if (isDefault) {
                translatedText = 'no translation';
            } else {
                translatedText = this.getDefaultForMissing(key, params);
            }
        }

        return translatedText!;
    }

    getDefaultForMissing(key: string, params?: any[]): any {
        const message = `TranslationKey: "${key}" not found [locale: ${Trans.getInstance().locale}]`;
        Logger.tag('Translation').error(message);
        Sentry.captureMessage(message, Severity.Error);

        const locale = Trans.getInstance().locale;

        const translationByDefault = this.get(key, params, 'de', true);

        Trans.getInstance(locale);

        return translationByDefault;
    }

}

export default Trans;
