








import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import ExporoModal from '@/components/Modal/ExporoModal.vue';
import LoginToSeeModalContent from '@/components/TeaserProject/LoginToSeeModalContent.vue';
import store from '@/store';
import RequestCallback from '../Rest/RequestCallback';
import RestError from '../Rest/models/RestError';
import { NOT_LOGGED_IN } from '../Rest/models/Errors';
import { AxiosResponse } from 'axios';
import UserRequestApi from '../Rest/requests/UserRequestApi';

@Component({
    store,
    components: {
        ExporoModal,
        LoginToSeeModalContent,
    }
})

class LoginToSeeModal extends ExporoVue implements RequestCallback {
    @Prop() type!: string;
    private userRequestApi: UserRequestApi;
    private isBlurred: boolean;


    constructor() {
        super();
        this.userRequestApi = this.restApi.create(new UserRequestApi());
        this.userRequestApi.isAuthenticated(this);
        this.isBlurred = false;
    }


    onFailure(error: RestError): void {
        if (NOT_LOGGED_IN === error.errorCode) {
            // @TODO this.openLogToSeeModal();
        }
    }
    onSuccess(response: AxiosResponse): void {
    }

    openLogToSeeModal() {
        this.getModalService().openModal(`login-to-see-${this.type}`, {}, true);
    }
}

export default LoginToSeeModal;
