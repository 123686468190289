import {Get, Post} from '@/decorators/RestDecorators';
import RequestCallback from '../RequestCallback';
import RestApi from '@/components/Rest/RestApi';
import RequestApi from '@/components/Rest/models/RequestApi';

class ContactRequestApi extends RequestApi {


    @Post('/contact')
    send(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }
}

export default ContactRequestApi;
