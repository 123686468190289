import TrackService from '@/components/GoogleTagManager/services/TrackService';
import TransactionProducts from '@/components/GoogleTagManager/interfaces/TransactionProducts';

class Tracking {

    private static trackService: TrackService = new TrackService();

    public static trackUser(id: string, aid: string) {
        const pushUserObject = Tracking.trackService.createUserObject(id, aid);

        Tracking.trackService.pushUserToDataLayer(pushUserObject);
    }

    public static trackAny(pushable: any) {

        Tracking.trackService.pushAnyToDataLayer(pushable);
    }

    public static trackOpen(category: string, nonInteraction: boolean, label?: string, type?: string, step?: number): void {

        const pushObject = Tracking.trackService.createPushObject('open', category, nonInteraction, label, type, step);

        Tracking.trackService.pushToDataLayer(pushObject);
    }


    public static trackClose(category: string, nonInteraction: boolean, label?: string, type?: string, step?: number): void {

        const pushObject = Tracking.trackService.createPushObject('close', category, nonInteraction, label, type, step);

        Tracking.trackService.pushToDataLayer(pushObject);
    }


    public static trackSelect(selected: string, category: string, nonInteraction: boolean, label?: string, type?: string, step?: number): void {

        const pushObject = Tracking.trackService.createPushObject(selected, category, nonInteraction, label, type, step);

        Tracking.trackService.pushToDataLayer(pushObject);
    }


    public static trackSubmit(category: string, nonInteraction: boolean, type?: string, step?: number): void {

        const pushObject = Tracking.trackService.createPushObject('submit', category, nonInteraction, undefined, type, step);

        Tracking.trackService.pushToDataLayer(pushObject);
    }

    public static trackActivationSubmit(category: string, nonInteraction: boolean, userId: number, affiliated_partner_id?: string): void {

        const pushObject = Tracking.trackService.createPushObject('submit', category, nonInteraction, undefined, undefined, undefined);
        pushObject['user'] = {userId: userId, a_aid: affiliated_partner_id};

        Tracking.trackService.pushToDataLayer(pushObject);
    }


    public static trackError(category: string, nonInteraction: boolean, label?: string, type?: string, step?: number): void {

        const pushObject = Tracking.trackService.createPushObject('error', category, nonInteraction, label, type, step);

        Tracking.trackService.pushToDataLayer(pushObject);
    }

    public static trackCheckoutSteps(step: number, option: string, id: string, name: string, brand: string, variant: string, price: string, pieces: string) {

        const pushCheckoutObject = Tracking.trackService.createCheckoutProduct({
            'actionField': {
                'step': step,
                'option': option
            },
            'products': [{
                'id': id,
                'name': name,

                'brand': brand,
                'variant': variant,
                'price': price,
                'quantity': pieces,
            }]
        });

        Tracking.trackService.pushCheckoutToDataLayer(pushCheckoutObject);
    }

    public static trackProductDetailViews(id: string, name: string, brand: string, variant: string) {

        const pushDetailViewObject = Tracking.trackService.createDetailViewProduct({
            'id': id,
            'name': name,
            'brand': brand,
            'variant': variant
        });

        Tracking.trackService.pushDetailViewProductToDataLayer(pushDetailViewObject);
    }

    public static trackAddToCard(id: string, name: string, brand: string, variant: string): void {

        const pushCardObject = Tracking.trackService.createCardProduct({
            'id': id,
            'name': name,
            'brand': brand,
            'variant': variant,
            'quantity': '1'
        });

        Tracking.trackService.pushCardProductToDataLayer(pushCardObject);
    }

    public static trackFirstInvestment(aid: string | null) {
        const pushFirstInvestObject = Tracking.trackService.createFirstInvestObject(aid);

        Tracking.trackService.pushFirstInvestmentObjectToDataLayer(pushFirstInvestObject);
    }

    public static trackPurchase(id: string, affiliation: string, total: string, tax: string, shipping: string, transcoupon: string, sku: string, name: string, variant: string, price: string, quantity: number, coupon: string): void {

        const pushPurchaseObject = Tracking.trackService.createPurchaseObject(id, affiliation, total, tax, shipping, transcoupon, {
            'sku': sku,
            'name': name,
            'variant': variant,
            'category': 'bond-full',
            'price': price,
            'quantity': quantity,
            'coupon': coupon,
        });

        Tracking.trackService.pushPurchaseObjectToDataLayer(pushPurchaseObject);
    }
}


export default Tracking;
