




































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Checkbox from '@/components/Checkbox/Checkbox.vue';
import ContactRequestApi from '../Rest/requests/ContactRequestApi';
import ExporoVue from '../ExporoVue';
import RequestCallback from '../Rest/RequestCallback';
import RestError from '../Rest/models/RestError';
import {AxiosResponse} from 'axios';

@Component({
    components: {
        Checkbox
    }
})
class Contact extends ExporoVue implements RequestCallback {

    public input: any;
    private contactRequestApi: ContactRequestApi;
    private successText: string = '';

    data() {
        return {
            input: {
                name: '',
                email: '',
                subject: '',
                message: '',
                data_protection_exporo_ag: '',
                data_protection_exporo_investment_gmbh: ''
            }
        };
    }

    constructor() {
        super();

        this.contactRequestApi = this.restApi.create(new ContactRequestApi());
    }

    send() {
        this.contactRequestApi.send(this.input, this);
    }

    onFailure(error: RestError): void {

    }

    onSuccess(response: AxiosResponse): void {
        this.successText = 'Vielen Dank, wir werden uns umgehend bei Ihnen melden.';
    }
}

export default Contact;
