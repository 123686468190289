



















import {Component, Prop} from 'vue-property-decorator';
import AuthService, {TOKEN_KEY} from '@/components/Auth/services/AuthService';
import Tracking from '@/components/GoogleTagManager/services/Tracking';
import Login from '@/components/Auth/Login.vue';
import ExporoModal from '@/components/Modal/ExporoModal.vue';
import Register from '@/components/Auth/Register.vue';
import PasswordForget from '@/components/Auth/PasswordForget.vue';
import RestApiService, {HOST} from '@/components/Rest/services/RestApiService';
import store, {LOGIN, LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT} from '@/store';
import ExporoVue from '../ExporoVue';
import UserRequestApi from '../Rest/requests/UserRequestApi';
import RequestCallback from '../Rest/RequestCallback';
import RestError from '../Rest/models/RestError';
import {AxiosResponse} from 'axios';
import Events from '../../events';
import {NOT_LOGGED_IN} from '@/components/Rest/models/Errors';
import CookieHelper from '../Helper/CookieHelper';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import * as Sentry from '@sentry/vue';
import VersionManagerService from '@/components/VersionManager/VersionManagerService';
import URLHelper from '@/components/Helper/URLHelper';
import Router from '@/components/Router/Router';
import Controller from '@/components/Router/Controller';
import {Action} from '@/components/Router/decorators/RouteDecorator';

export const LOGIN_MODAL_NAME = 'login-modal';
export const REGISTER_MODAL_NAME = 'register-modal';
export const PASSWORD_FORGET_MODAL_NAME = 'password-forget-modal';

@Component({
  store,
  components: {
    PasswordForget,
    Register,
    Login,
    ExporoModal
  },
})
export default class AuthButton extends Controller implements RequestCallback {

  public html: any;
  private lastModalName: string = LOGIN_MODAL_NAME;
  private authService: AuthService;
  private isOldLogin: boolean = false;

  @Prop({default: ''})
  private dataClass!: string;

  @Prop({default: 'old'})
  private version!: string;

  @Prop({default: ''})
  private dataStyle!: string;

  private userRequestApi: UserRequestApi;

  constructor() {
    super('AuthButton');

    const api = new RestApiService();
    this.authService = new AuthService(api);

    this.userRequestApi = this.restApi.create(new UserRequestApi());
    this.userRequestApi.isAuthenticated(this);
  }

  getName() {

    return this.getClassName() || 'AuthButton';
  }

  isNewVersion() {

    const cockpitVersion = CookieHelper.getCookie('exporo-cockpit-version');

    if (cockpitVersion && 'v2' === cockpitVersion) {

      return true;
    }

    return 'new' === this.version;
  }

  getModalNames() {
    return {
      LOGIN_MODAL_NAME,
      REGISTER_MODAL_NAME,
      PASSWORD_FORGET_MODAL_NAME,
    };
  }

  get isLoggedIn(): boolean {
    return this.getStore().state.isLoggedIn;
  }

  navigate(e) {
    e.preventDefault();
    if (this.isOldLogin) {
      Router.navigate('openAuth', ['login']);
    } else {
      document.location.href = 'https://user.exporo.com/#/signin?redirectURL=' + window.location.href;
    }

  }

  signUp(e) {
    e.preventDefault();
    Router.navigate('openAuth', ['register']);
  }

  @Action('openAuth')
  openAuthModal() {
    this.openAuthModalByName('login-modal');
  }

  openAuthModalByName(name: string) {
    this.getModalService().closeModal(this.lastModalName, {}, true);

    this.getModalService().openModal(name, {
      height: 'auto',
      width: '600px',
      top: '10%',
    });

    this.lastModalName = name;
  }

  onFailure(error: RestError): void {
    if (NOT_LOGGED_IN === error.errorCode) {
      this.authService.logout();
      Events.$emit('authStateChanged', false);
      this.getStore().commit(LOGIN_FAILED);
      this.getStore().commit(LOGOUT);
      Tracking.trackError('auth form', true, 'login overlay');
    }
  }

  onSuccess(response: AxiosResponse, id): void {
    const userResponse: { authenticated: boolean, userId: number, affiliated_partner_id: number } = response.data;
    const body = document.getElementsByTagName('body')[0];

    if (response.config.url && response.config.url.indexOf('authenticated') > -1) {
      if (!userResponse.authenticated) {
        this.authService.logout();
        Events.$emit('authStateChanged', false);

        if (body && body.className.indexOf('authenticated') > -1) {
          body.className = body.className.replace(' authenticated', '');
        }

      } else {
        this.getStore().commit(LOGIN);
        this.getStore().commit(LOGIN_SUCCESS);
        Events.$emit('authStateChanged', true);

        if (body && body.className.indexOf('authenticated') === -1) {
          body.className += ' authenticated';
        }

        Sentry.configureScope((scope) => {
          scope.setUser({'id': String(userResponse.userId)});
        });

        if (userResponse.hasOwnProperty('userId')) {
          LocalStorage.add(ExporoVue.USER_ID_KEY, String(userResponse.userId));
          setTimeout(() => Events.$emit('userIdLoaded'), 500);
        }

        if (userResponse.hasOwnProperty('affiliated_partner_id')) {
          LocalStorage.add(ExporoVue.AFFILIATE_ID_KEY, String(userResponse.affiliated_partner_id));
        }

        Tracking.trackUser(userResponse.userId.toString(), userResponse.affiliated_partner_id ? userResponse.affiliated_partner_id.toString() : '0');

        let lifetime = 30;


        if (response.headers.hasOwnProperty('x-session-lifetime')) {
          lifetime = response.headers['x-session-lifetime'];
        }

        this.authService.storeLogin(JSON.stringify({
          'lifetime': lifetime,
          'saved_at': +new Date(),
        }));
      }
    } else {
      this.authService.logout();

      if (body && body.className.indexOf('authenticated') > -1) {
        body.className = body.className.replace(' authenticated', '');
      }
    }

    const webappVersion = VersionManagerService.getInstance().getVersion();
    LocalStorage.add(ExporoVue.WEBAPP_VERSION, webappVersion);
  }

  mounted() {
    // if (!this.authService.isLoggedIn()) {
    //   this.authService.logout();
    // }

    if (LocalStorage.get('oldLogin')) {
      this.isOldLogin = true;
    } else if (URLHelper.hasQueryParam('oldLogin')) {
      LocalStorage.add('oldLogin', true);
    }

    window.addEventListener('beforeunload', () => {
      LocalStorage.remove(ExporoVue.SENTRY_DIALOG_OPEN);
    });

    // const dialogAlreadyOpen: boolean = LocalStorage.getBoolean(ExporoVue.SENTRY_DIALOG_OPEN, false);
    // const userId = LocalStorage.get(ExporoVue.USER_ID_KEY, null);
    //
    // if (URLHelper.hasQueryParam('support') && false === dialogAlreadyOpen) {
    //   const id = URLHelper.getQueryParam('userId');
    //
    //   if (this.authService.isLoggedIn() || id) {
    //
    //     LocalStorage.addBoolean(ExporoVue.SENTRY_DIALOG_OPEN, true);
    //
    //
    //     Sentry.withScope((scope) => {
    //       scope.setExtra('screen', {
    //         availHeight: window.screen.availHeight,
    //         availWidth: window.screen.availWidth,
    //         width: window.screen.width,
    //         height: window.screen.height,
    //         colorDepth: window.screen.colorDepth,
    //         pixelDepth: window.screen.pixelDepth,
    //         orientation: {
    //           angle: window.screen.orientation.angle,
    //           type: window.screen.orientation.type
    //         }
    //       });
    //
    //       Sentry.captureMessage(`User ${userId || id || '0'} submitted feedback`, Severity.Info);
    //       Sentry.showReportDialog({
    //         title: this.trans.get('auth.submit_feedback.title'),
    //         subtitle: this.trans.get('auth.submit_feedback.subtitle'),
    //         subtitle2: '',
    //         labelSubmit: this.trans.get('auth.submit_feedback.label_submit'),
    //         successMessage: this.trans.get('auth.submit_feedback.success_message'),
    //         user: {
    //           email: `${userId || id || '0'}@exporo.de`,
    //           name: userId || id || '0',
    //         },
    //       });
    //     });
    //
    //     const intervalId = setInterval(() => {
    //       const sentryDialogWrapper = document.getElementsByClassName('sentry-error-embed-wrapper')[0];
    //       if (sentryDialogWrapper) {
    //         const close = sentryDialogWrapper.getElementsByClassName('close')[0];
    //         const submit = sentryDialogWrapper.getElementsByClassName('btn')[0];
    //         if (close) {
    //           close.addEventListener('click', () => {
    //             LocalStorage.remove(ExporoVue.SENTRY_DIALOG_OPEN);
    //           });
    //         }
    //
    //         if (submit) {
    //           submit.addEventListener('click', () => {
    //             LocalStorage.remove(ExporoVue.SENTRY_DIALOG_OPEN);
    //           });
    //         }
    //
    //         clearInterval(intervalId);
    //       }
    //     }, 500);
    //   } else {
    //     this.$nextTick(() => {
    //       this.getModalService().openModal('login-modal', {
    //         data: {
    //           reload: true
    //         }
    //       });
    //     });
    //   }
    // }
  }

  redirectToCockpit() {
    Events.$emit('open-cockpit');
    Router.navigate('cockpitRoute', ['yield']);
  }
}
