




import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
class BuyButton extends Vue {

    @Prop() onClick!: () => void;

    constructor() {
        super();
    }
}

export default BuyButton;
