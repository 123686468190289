











































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
class SortButton extends Vue {

    @Prop() active!: boolean;
    @Prop() topActive!: boolean;
    @Prop() botActive!: boolean;
    @Prop() isMobile!: boolean;

    private arrowActive: boolean = false;

    constructor() {
        super();
    }

    mounted() {
        this.arrowActive = this.active;
        if (this.isMobile) {
            this.arrowActive = true;
        }
    }
}

export default SortButton;
