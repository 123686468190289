import WalletIntegration from '@/components/Rest/integrations/WalletIntegration';


export function Wallet(id?: string) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        descriptor.value = () => {

            return {
                id: id ? id : propertyKey,
                integration: new WalletIntegration()
            };
        };
    };
}
