













import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import CockpitButton from '../Controls/CockpitButton.vue';
import ExporoVue from '@/components/ExporoVue';
import ExporoModal from '@/components/Modal/ExporoModal.vue';
import UserEdit from '@/components/User/Edit/UserEdit.vue';

@Component({
    components: {
        CockpitButton,
        ExporoModal,
        UserEdit
    }
})
class PageUserEdit extends ExporoVue {

    /*@Prop() private userdata!: UserData;
    private formData: object | null = null;
    private shouldShowUserEdit: boolean = false;*/

    constructor() {
        super();
    }
}

export default PageUserEdit;
