













import {Component, Vue} from 'vue-property-decorator';

@Component({
    components: {}
})

class InvestmentPlan20 extends Vue {
}

export default InvestmentPlan20;
