import Middleware from '@/components/Rest/middleware/interfaces/Middleware';
import Closure from '@/components/Rest/middleware/core/Closure';
import {USER_NOT_ACTIVATED} from '@/components/Rest/models/Errors';
import BeforeData from '@/components/Rest/middleware/interfaces/BeforeData';
import AfterData from '@/components/Rest/middleware/interfaces/AfterData';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import Events from '@/events';

class ActivationMiddleware implements Middleware {

    constructor() {

    }

    before(data: BeforeData, closure: Closure): void {
        closure.next(data);
    }

    after(data: AfterData, closure: Closure): void {
        const error = data.error;
        if (error && error.errorMessage && error.errorMessage.data && error.errorMessage.errorCode && USER_NOT_ACTIVATED === error.errorMessage.errorCode) {

            if (error.errorMessage.data.activationURL) {
                if (!LocalStorage.getBoolean('activation_redirect_enabled', false)) {
                    LocalStorage.addBoolean('activation_redirect_enabled', true);
                    if (window.location.href.indexOf('no-reload') === -1) {
                        window.location.href = error.errorMessage.data.activationURL + '&no-reload';
                        return;
                    }
                }
            }
        }

        closure.next(data);
    }
}

export default ActivationMiddleware;
