






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
class Scrollbar extends Vue {
    private shouldShow: boolean = true;

    @Prop()
    private scrollableMounted!: boolean;

    constructor() {
        super();

        if (typeof (window as any).CustomEvent !== 'function' ) {
            function CustomEvent( event, params ) {
                params = params || { bubbles: false, cancelable: false, detail: undefined };
                const evt = document.createEvent( 'CustomEvent' );
                evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );

                return evt;
            }

            CustomEvent.prototype = (window as any).Event.prototype;

            (window as any).CustomEvent = CustomEvent;
        }
    }

    @Watch('scrollableMounted')
    onMountScrollable() {
        const container: any = this.$refs.container;
        const wrapper: HTMLElement = container.parentElement;
        const content: any = wrapper.getElementsByClassName('login-inner')[0];
        const event = new CustomEvent('scroll');
        content.dispatchEvent(event);
    }


    mounted() {
        const scroll: any = this.$refs.scroll;
        const container: any = this.$refs.container;
        const wrapper: HTMLElement = container.parentElement;
        const content: any = wrapper.getElementsByClassName('login-inner')[0];

        content.addEventListener('scroll', () => {
            const scrollHeight = container.clientHeight * content.clientHeight / content.scrollHeight;
            scroll.style.height = scrollHeight + 'px';
            scroll.style.marginTop = container.clientHeight * content.scrollTop / content.scrollHeight + 'px';

            this.shouldShow = container.clientHeight !== scrollHeight;
        });

        const event = new CustomEvent('scroll');
        window.addEventListener('resize', content.dispatchEvent.bind(content, event));

        content.addEventListener('click', () => {
            setTimeout(() => {
                content.dispatchEvent(event);
            }, 100);
        });

        scroll.addEventListener('mousedown', (start: any) => {
            start.preventDefault();

            const y = scroll.offsetTop;

            const onMove = (end) => {
                const delta = end.pageY - start.pageY;
                scroll.style.marginTop = Math.min(container.clientHeight - scroll.clientHeight, Math.max(0, y + delta)) + 'px';
                content.scrollTop = (content.scrollHeight * scroll.offsetTop / container.clientHeight);
            };

            document.addEventListener('mousemove', onMove);
            document.addEventListener('mouseup', () => {

                document.removeEventListener('mousemove', onMove);
            });
        });
    }
}

export default Scrollbar;
