












import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

import Events from '@/events';

import Lightbox from 'vue-my-photos';

@Component({
    components: {
        Lightbox
    }
})
class Gallery extends Vue {

    @Prop()
    private imagesString!: string;
    @Prop()
    private base!: string;
    @Prop()
    private id!: string;
    private images: object[] = [];
    private index: any = null;

    constructor() {
        super();
    }

    mounted() {
        if (this.imagesString) {
            JSON.parse(this.imagesString).forEach((image: any) => {
                this.images.push({
                    name: escape(image.image) + '?w=1800&fit=clip'
                });
            });

            this.index = 0;
        }

        Events.$on(`openGallery${this.id}`, (index: number) => {

            const name = JSON.parse(this.imagesString)[index].image;

            const lightbox: any = this.$refs.hasOwnProperty('lightbox') ? this.$refs.lightbox : null;

            if (lightbox) {
                lightbox.show(escape(name) + '?w=1800&fit=clip');
            }
        });
    }
}

export default Gallery;
