import CardEntries from '@/components/Cockpit/interfaces/CardEntries.ts';

export default class Card {
    signing_id!: number;
    project!: string;
    isin!: string;
    period!: string;
    wkn!: string;
    investment!: string;
    interest!: string;
    piece_value!: string;
    pieces!: string;
    expected_interest!: string;
    expected_total_interest!: string;
    payoutdate!: string;
    payoutsum!: string;
    investor!: string;

    constructor(cardEntries: CardEntries) {
        Object.assign(this, cardEntries);
    }
}
