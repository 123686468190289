





























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import AppRouter from './AppRouter';
import InvestEntry from '@/components/Cockpit/InvestList/InvestEntry.vue';
import InvestEntryData from '../InvestList/InvestEntryData';

@Component({
    components: {
        InvestEntry
    }
})
class DetailView extends Vue {

    @Prop()
    private appRouter!: AppRouter;

    private id: number = -1;
    private visibilityState: string = '';
    private item: any = null;
    private sizes: number[] = [];
    private title: string = '';

    constructor() {
        super();
    }

    leave() {
        const entry: any = this.$refs.entry;

        entry.collapse();

        this.navigateBack(entry);
    }

    navigateBack(entry: any) {

        setTimeout(() => {
            const {ghostData, positions} = this.getGhostLayerData(entry.$el);

            this.appRouter.addSharedElement(positions, ghostData);
            this.visibilityState = 'leaving';

            this.appRouter.setRoute('/list', {});
        }, 500);
    }

    private getGhostLayerData(element) {
        const ghostData: InvestEntryData = new InvestEntryData(this.id, this.item);

        const sizes: number[] = [];

        [...element.getElementsByClassName('td')].forEach((td) => {
            sizes.push(td.offsetWidth);
        });

        const positions = {
            top: element.offsetTop,
            left: element.offsetLeft,
            width: element.offsetWidth,
            height: element.offsetHeight,
            sizes: sizes
        };

        return {ghostData, positions};
    }

    mounted() {
        this.id = Number(this.appRouter.params.id);
        this.sizes = this.appRouter.params.sizes;
        this.item = this.appRouter.params.item;

        setTimeout(() => {
            const entry: any = this.$refs.entry;

            this.appRouter
                .runAnimation(entry.$el.offsetTop, 20)
                .then(this.completeHandoff)
                .catch(this.completeHandoff);
        }, 100);
    }

    completeHandoff() {
        const entry: any = this.$refs.entry;

        entry.expand((title: string) => {
            this.title = title;
        });

        this.visibilityState = 'visible';
    }

    isHidden(el) {
        const style = window.getComputedStyle(el);

        return ((style.display === 'none') || (style.visibility === 'hidden'));
    }

    getStyle(index: number) {
        return {
            width: this.sizes[index] + 'px'
        };
    }

    get columns() {

        return Object.keys(this.item);
    }
}

export default DetailView;
