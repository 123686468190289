













import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import PubSubService from '../PubSub/services/PubSubService';

@Component
class ProgressBarCSSTest extends Vue {

    private pubSubService!: PubSubService;

    @Prop()
    private id!: string;

    private fundingInPercentage: number = 0;
    private remaining: number = 0;

    constructor() {
        super();
    }

    mounted() {
        this.pubSubService = PubSubService.getInstance((data) => {

            const pid = this.$attrs['data-id'];
            if (data.projects && pid && data.projects.hasOwnProperty(pid)) {
                const project: any = data.projects[pid];

                this.fundingInPercentage = project.fundingInPercentage;
                this.remaining = project.fundingTarget - project.total_as_float;
            }
            
        });
    }


    convert(value) {

        if (value < 0) {
            value = 0;
        }

        return ((Number(value) || 0).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'));
    }
}

export default ProgressBarCSSTest;
