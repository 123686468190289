













import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import TableData from '@/components/Cockpit/models/TableData';
import OptionEntry from '@/components/Cockpit/models/OptionEntry';
import Events from '@/events';
import InvestMenu from '@/components/Cockpit/InvestList/InvestMenu.vue';
import ExporoVue from '@/components/ExporoVue';
import ListView from '@/components/ListView/ListView.vue';
import DateFormatHelper from '@/components/Helper/DateFormatHelper';
import CockpitRouter from '@/components/Cockpit/CockpitRouter';
import Row from '@/components/ListView/interfaces/Row';
import StringHelper from '@/components/Helper/StringHelper';
import PaymentRow from '@/components/Cockpit/models/PaymentRow';
import Router from '@/components/Router/Router';
import FinancingListFilter from '@/components/Cockpit/Lists/FinancingListFilter.vue';

@Component({
  components: {
    FinancingListFilter,
    ListView
  }
})
class ListFinancingPayments extends ExporoVue {

  @Prop() private tableData!: TableData;

  private filterStatus!: any[];
  private defaultFilter: string = 'comingPayments';
  private selectable: boolean = true;
  private rows: any[] = this.tableData.rows;


  emptyCTA() {
    return {
      text: this.trans.get('invest_button.label'),
      action: (e) => {
        e.preventDefault();
        window.location.href = 'https://exporo.de/projekte/';
      }
    };
  }


  constructor() {
    super();

    this.selectable = !this.isMobile;
    this.tableData.emptyStateMessage = this.trans.get('cockpit.list_financing_payments.empty_state_message.disbursed');
    this.filterStatus = [

      new OptionEntry(
          this.trans.get('cockpit.pages.financing.filter.payments.comingPayments'),
          this.defaultFilter,
          () => {
            Events.$emit('filter', {type: 'state', selection: 'comingPayments', subMenu: 'payments'});
            Events.$emit('filter-payments', {
              type: 'state',
              selection: 'comingPayments',
              subMenu: 'payments'
            });
          }
      ),
      new OptionEntry(
          this.trans.get('cockpit.pages.financing.filter.payments.pastPayments'),
          'pastPayments',
          () => {
            Events.$emit('filter', {type: 'state', selection: 'pastPayments', subMenu: 'payments'});
            Events.$emit('filter-payments', {
              type: 'state',
              selection: 'pastPayments',
              subMenu: 'payments'
            });
          }
      ),
      new OptionEntry(
          this.trans.get('cockpit.pages.financing.filter.payments.allPayments'),
          'allPayments',
          () => {
            Events.$emit('filter', {type: 'state', selection: 'allPayments', subMenu: 'payments'});
            Events.$emit('filter-payments', {
              type: 'state',
              selection: 'allPayments',
              subMenu: 'payments'
            });
          }
      ),
    ];

    const rows: any[] = [];
    (this.tableData.data as PaymentRow[]).forEach((row: PaymentRow) => {
      const paymentRow = this.createPaymentRow(row);
      const isPaybackStarted = this.isPaybackStarted(paymentRow);

      if (this.isComingPayment(paymentRow) || isPaybackStarted) {

        if (isPaybackStarted) {
          paymentRow.payoutdate = this.trans.get('cockpit.list_financing_payments.payback_started');
        }

        if (row._payment_date_overwrite) {
          paymentRow.payoutdate = row._payment_date_overwrite;
          paymentRow.payoutsum = row._payment_value_overwrite;
        }

        rows.push(paymentRow);
      }
    });

    this.rows = rows;

    Events.$on('filter-payments', (data: any) => {
      this.filterPayments(data);
    });
  }

  filterPayments(data: any) {
    const filteredRows: any[] = [];

    (this.tableData.data as PaymentRow[]).forEach((row: PaymentRow) => {

      const paymentRow = this.createPaymentRow(row);
      const isPaybackStarted = this.isPaybackStarted(paymentRow);

      if (isPaybackStarted) {
        paymentRow.payoutdate = this.trans.get('cockpit.list_financing_payments.payback_started');
      }

      if ('all' === data.selection || 'allInvestments' === data.selection || 'allPayments' === data.selection) {
        filteredRows.push(paymentRow);

      } else if ('comingPayments' === data.selection) {

        if (this.isComingPayment(paymentRow) || isPaybackStarted) {
          filteredRows.push(paymentRow);
        }
      } else if ('pastPayments' === data.selection) {
        if (this.isPastPayment(paymentRow) && !isPaybackStarted) {
          filteredRows.push(paymentRow);
        }
      }
    });

    this.rows = filteredRows;
    this.setEmptyStateMessage(data);
  }

  setEmptyStateMessage(data: any): void {
    if (!this.rows.length) {
      if ('pastPayments' === data.selection) {
        this.tableData.emptyStateMessage = this.trans.get('cockpit.list_financing_payments.empty_state_message.disbursed');
      } else if ('comingPayments' === data.selection) {
        this.tableData.emptyStateMessage = this.trans.get('cockpit.list_financing_payments.empty_state_message.coming');
      } else {
        this.tableData.emptyStateMessage = this.trans.get('cockpit.list_financing_payments.empty_state_message.all');
      }
    } else {
      this.tableData.emptyStateMessage = '';
    }
  }

  createPaymentRow(payment: any) {

    let payoutDate: any = null;

    if (payment._paid_at) {
      if (payment._paid_at.split(' ').length < 2) {
        payoutDate = DateFormatHelper.parse(payment._paid_at, 'dd.MM.yyyy', 'dd.MM.yyyy');
      } else {
        payoutDate = DateFormatHelper.parse(payment._paid_at, 'yyyy-MM-dd HH:mm:ss', 'dd.MM.yyyy');
      }
    } else {
      if (payment._type && 'signing' === payment._type || 'mezzbond' === payment._type) {
        payoutDate = DateFormatHelper.getFormattedDateFromString(DateFormatHelper.stringToAmericanDate(payment._payoutdate), true);
      } else {
        payoutDate = payment._expected_at || payment._expire_at || '1970-01-01';

        if (payoutDate) {
          if (payoutDate.split(' ').length < 2) {
            payoutDate = DateFormatHelper.parse(payoutDate, 'dd.MM.yyyy', 'dd.MM.yyyy');
          } else {
            payoutDate = DateFormatHelper.parse(payoutDate, 'yyyy-MM-dd HH:mm:ss', 'dd.MM.yyyy');
          }
        }
      }
    }

    let type: number = 0;

    switch (payment._type) {
      case 'product.loan':
        type = 0;
        break;
      case 'product.mezzbond':
        type = 2;
        break;
      case 'product.signing':
        type = 1;
        break;
    }

    return {
      signingId: payment._signingId,
      realID: `${type}-${payment._signingId}`,
      title: payment._title,
      payoutsum: payment._payment_date_overwrite ? payment._payment_value_overwrite : payment._payoutsum,
      payoutdate: payment._payment_date_overwrite ?? payoutDate,
      _payoutdate: payoutDate,
      _paid_at: payment._paid_at,
      _expected_at: payment._expected_at,
      _expire_at: payment._expire_at,
      action: true
    };
  }

  isPaybackStarted(payment: any): boolean {
    if (payment._expected_at || payment._expire_at) {
      const expiredDate = new Date(DateFormatHelper.stringToAmericanDate(payment._expected_at || payment._expire_at));
      expiredDate.setDate(expiredDate.getDate() - 1);

      return expiredDate.getTime() < new Date().getTime() && null === payment._paid_at;
    }

    return false;
  }

  isComingPayment(payment: any): boolean {
    if (payment._paid_at) {
      const paidAtTime = new Date(DateFormatHelper.stringToAmericanDate(payment._paid_at)).getTime();
      return paidAtTime > new Date().getTime();
    } else {
      if (payment.payoutdate === 'Insolvenzverfahren eröffnet') {
        return new Date(DateFormatHelper.stringToAmericanDate(payment._payoutdate)).getTime() > new Date().getTime();
      }
    }

    return new Date(DateFormatHelper.stringToAmericanDate(payment.payoutdate)).getTime() > new Date().getTime();
  }

  isPastPayment(payment: any): boolean {
    if (payment._paid_at) {
      const paidAtTime = new Date(DateFormatHelper.stringToAmericanDate(payment._paid_at)).getTime();
      return paidAtTime < new Date().getTime();
    }
    return new Date(DateFormatHelper.stringToAmericanDate(payment.payoutdate)).getTime() < new Date().getTime();
  }

  columns() {
    return [
      {
        label: this.trans.get('cockpit.pages.financing.list_headers_payments.project'),
        field: 'title',
      },
      {
        label: this.trans.get('cockpit.pages.financing.list_headers_payments.payout_sum'),
        field: 'payoutsum',
        type: 'string',
        formatFn: this.formatE,
        tdClass: 'text-left',
        thClass: 'vgt-left-align',
        sortFn: ListFinancingPayments.sortCurrency
      },
      {
        label: this.trans.get('cockpit.pages.financing.list_headers_payments.payout_date'),
        field: 'payoutdate',
        type: 'date',
        formatFn: this.formatPayout,
        dateInputFormat: 'dd.mm.yyyy',
        dateOutputFormat: 'dd.mm.yyyy',
        sortFn: this.sortFn,
        tdClass: 'text-left',
        thClass: 'vgt-left-align',
      },
      {
        label: '',
        field: 'action'
      }
    ];
  }

  formatPayout(value) {
    if (0 === value) {
      return 'In Rückführung';
    }

    return value;
  }

  isInvalidDate(x, y) {
    return (x === this.trans.get('cockpit.list_financing_payments.payback_started') || y === this.trans.get('cockpit.list_financing_payments.payback_started')) ||
        ('--' === x || '--' === y) ||
        (0 === x || 0 === y);
  }

  sortFn(x, y, col, rowX, rowY) {
    if (this.isInvalidDate(x, y)) {
      return 1;
    }

    const xDate = new Date(DateFormatHelper.stringToAmericanDate(x)).getTime();
    const yDate = new Date(DateFormatHelper.stringToAmericanDate(y)).getTime();

    return (xDate < yDate ? -1 : (xDate > yDate ? 1 : 0));
  }

  format(value) {
    return `${value} Monate`;
  }

  formatE(value) {
    if (-1 === value) {
      return 'folgt';
    }

    if (value.indexOf('BOND_') > -1) {
      return StringHelper.toEur(value.replace('BOND_', ''));
    }

    if (isNaN(value)) {
      return value;
    }

    return StringHelper.toEur(value);
  }

  onClick(row: Row) {
    const tagName = (row.event.target as any).tagName;
    if (tagName !== 'INPUT' && tagName !== 'TH') {
      this.open(this.tableData.data.find((data: any) => {

        let type: number = 0;

        switch (data._type) {
          case 'product.loan':
            type = 0;
            break;
          case 'product.mezzbond':
            type = 2;
            break;
          case 'product.signing':
            type = 1;
            break;
        }

        return `${type}-${data._signingId}` === row.row.realID;
      }), row.row.realID, row.event);
    }
  }

  open(row: any, realID: any, event) {
    event.stopPropagation();

    const rootEl = document.querySelector('.exporo-cockpit-root');
    const rootHead = document.querySelector('.exporo-cockpit-header');
    if (rootEl && rootHead) {
      const rootHeadHeight = rootHead.scrollHeight;
      rootEl.scroll({top: rootHeadHeight, behavior: 'smooth'});
    }

    const [type, detailSlug, id] = document.location.href.split('cockpit/');

    Router.navigate('cockpitDetailRoute', [detailSlug, realID]);
  }

  private static sortCurrency(x: string, y: string): number {
    const xNum = Number(x.replace(/(^€|,|\s|B|O|N|D|_)/g, ''));
    const yNum = Number(y.replace(/(^€|,|\s|B|O|N|D|_)/g, ''));
    if (isNaN(xNum) && isNaN(yNum))
      return 0;
    else if (isNaN(xNum))
      return -1;
    else if (isNaN(yNum))
      return 1;
    return (xNum < yNum ? -1 : (xNum > yNum ? 1 : 0));
  }
}

export default ListFinancingPayments;
