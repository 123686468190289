import LoadingStatus from '../interfaces/UserDataLoadingStatus';
import CockpitInvestor from '../interfaces/UserDataCockpitInvestor';
import CockpitDocuments from '../interfaces/UserDataCockpitDocuments';
import Events from '@/events';
import {url} from 'inspector';

class UserData {

    private _hasLoaded: boolean = false;
    private _rawData: any = {};
    private _loadingStatus: LoadingStatus;
    public cockpitDocuments: CockpitDocuments;
    private _cockpitInvestors: CockpitInvestor[];
    private _projects: {} = {};
    public _offerPerProject: any = {};
    private _baaderData: any = {};
    private _walletData: any = {};
    private _hasWallet: boolean = false;
    private _contracts: { bond: any[], equity: any[] } = {bond: [], equity: []};

    constructor() {
        this._loadingStatus = {
            authentification: false,
            backendBase: false,
            backendDocuments: false,
            backendInvestors: false
        };

        this.cockpitDocuments = {
            yearlyDocuments: {},
            explorationFiles: [],
            baseDataFiles: [],
            depotFiles: [],
            quarterlyReports: [],
        };

        this._cockpitInvestors = [];

    }

    set contracts(contracts: any) {
        this._contracts = contracts;
    }

    get contracts(): any {
        return this._contracts;
    }

    get hasWallet(): boolean {
        return this._hasWallet;
    }

    set hasWallet(hasWallet: boolean) {
        this._hasWallet = hasWallet;
    }

    get hasLoaded(): boolean {
        return this._hasLoaded;
    }

    set hasLoaded(hasLoaded: boolean) {
        this._hasLoaded = hasLoaded;
    }

    get rawData(): any {
        return this._rawData;
    }

    get projects(): any {
        return this._projects;
    }

    get offerPerProject(): any {
        return this._offerPerProject;
    }

    public parseData(rawData: object): void {
        this._rawData = rawData;
        this._hasLoaded = true;
    }

    public addDocuments(cockpitDocuments: CockpitDocuments): void {
        this.cockpitDocuments = cockpitDocuments;
        this._loadingStatus.backendDocuments = true;
        Events.$emit('documentsYearlyLoaded');
    }

    public addProjects(projects: {}): void {
        this._projects = {...this._projects, ...projects};
    }

    public addInvestors(cockpitInvestors: any): void {

        for (const investor of cockpitInvestors) {
            this._cockpitInvestors.push({
                fullname: investor.title + ' ' + investor.first_name + ' ' + investor.last_name,
                ...investor
            });
        }

        this._loadingStatus.backendInvestors = true;
    }

    public removeInvestor(investorId: any) {
        for (let i = 0; i < this.investorList.length; i++) {
            const investor = this.investorList[i];
            if (investor.id === investorId) {
                this.investorList.splice(i, 1);
                return;
            }
        }
    }

    public addBaaderData(data: any): void {
        this._baaderData = data;
    }

    public addWalletData(data: any): void {
        this._walletData = data;
    }

    get documentsYearly() {
        return this.cockpitDocuments.yearlyDocuments;
    }

    get documentsExploration() {
        return this.cockpitDocuments.explorationFiles;
    }

    get documentsBaseData() {
        return this.cockpitDocuments.baseDataFiles;
    }

    get documentsDepot() {
        return this.cockpitDocuments.depotFiles;
    }

    get filesPayback() {
        const paybackDocuments = this._rawData.files.payback.finanzierung;
        const blockchainDocuments = this._rawData.files['blockchain-zinsabrechnung'];

        const tt: any = [];


        for (const paybackDocument of paybackDocuments) {
            const finalUrl = `investment${paybackDocument.link.split('investment')[1]}`;
            paybackDocument.link = finalUrl;

            tt.push(paybackDocument);
        }

        for (const blockchainDocument of blockchainDocuments) {
            const finalUrlBlockchain = `user${blockchainDocument.link.split('user')[1]}`;
            blockchainDocument.link = finalUrlBlockchain;

            tt.push(blockchainDocument);
        }

        tt.sort(
            (a, b) => {
                let dateA = new Date(0);
                let dateB = new Date(0);

                if (a.date) {
                    dateA = new Date(a.date.date);
                }

                if (b.date) {
                    dateB = new Date(b.date.date);
                }

                if (dateA < dateB) return 1;
                if (dateA > dateB) return -1;
                return 0;
            }
        );

        return tt;
    }

    get filesDepot() {
        const depotDocuments = this._rawData.files['baader-depot'];
        return depotDocuments;
    }

    get filesBaseData() {
        return this._rawData.files.stammdaten;
    }

    get filesExploration() {
        return this._rawData.files.angemessenheitspruefungen;
    }

    get investorList() {
        return this._cockpitInvestors;
    }

    get baaderData() {
        return this._baaderData;
    }

    get walletData() {
        return this._walletData;
    }

    get fullname() {
        if (this.hasLoaded) {
            return this._rawData.userData.title
                + ' ' + this._rawData.userData.first_name
                + ' ' + this._rawData.userData.last_name;
        } else {
            return 'ᴮ';
        }
    }

    get uid() {
        if (this.hasLoaded) {
            return this._rawData.userData.id;
        } else {
            return 'ᴮ';
        }
    }

    get displayLandingFinancing() {
        if (this.hasLoaded) {
            return this._rawData.hasInvestments.finanzierung;
        } else {
            return false;
        }
    }

    get displayLandingPortfolio() {
        if (this.hasLoaded) {
            return this._rawData.hasInvestments.bestand;
        } else {
            return false;
        }
    }

    get displayIdentification() {
        if (this.hasLoaded) {
            return this._rawData.ident.showResult;
        } else {
            return false;
        }
    }

    get identificationState() {
        if (this.hasLoaded) {
            return (!this._rawData.ident.result) ? 'notstarted' : this._rawData.ident.result.state;
        } else {
            return '';
        }
    }

    get identificationUrl() {
        if (this.hasLoaded) {
            return this._rawData.ident.urlWebapp;
        } else {
            return '';
        }
    }

    get equityInvestments() {
        if (this.hasLoaded) {
            return this._rawData.investmentData.bestand.investments.slice().sort(
                (a, b) => (new Date(a.created_at) < new Date(b.created_at)) ? 1 : -1
            );
        } else {
            return [];
        }
    }

    get financingInvestments() {
        if (this.hasLoaded) {
            return this._rawData.investmentData.finanzierung.investments.slice().sort(
                (a, b) => (new Date(a.created_at) < new Date(b.created_at)) ? 1 : -1
            );
        } else {
            return [];
        }
    }

    get averageEquityInterestPerYear() {
        if (this.hasLoaded) {
            // not finished: needs filter to account for year
            const equity = this.equityInvestments;
            const interestAccumulated = equity.reduce((acc, cur) => {
                return acc += parseFloat(cur.expected_return_rate);
            }, 0);
            return Math.round(interestAccumulated / equity.length * 100) / 100;
        } else {
            return 'ᴮ';
        }
    }

    get investedTotalSum() {
        if (this.hasLoaded) {
            const investmentData = this._rawData.investmentData;
            return investmentData.bestand.totalSum + investmentData.finanzierung.totalSum;
        } else {
            return 'ᴮ';
        }
    }

    get investedTotalEquitySum() {
        if (this.hasLoaded) {
            return this._rawData.investmentData.bestand.totalSum;
        } else {
            return 'ᴮ';
        }
    }

    get investedTotalFinancingSum() {
        if (this.hasLoaded) {
            return this._rawData.investmentData.finanzierung.totalSum;
        } else {
            return 'ᴮ';
        }
    }

    get amountRunningFinancingSum() {
        if (this.hasLoaded) {
            return this.financingInvestments.reduce((acc: number, cur) => {
                return ('repaid' !== cur.status) ? (acc + cur.investSum) : acc;
            }, 0);
        } else {
            return 'ᴮ';
        }
    }

    get amountRunningFinancingInvestments() {
        if (this.hasLoaded) {
            return this.financingInvestments.reduce((acc, cur) => {
                return ('repaid' !== cur.status) ? (acc + 1) : acc;
            }, 0);
        } else {
            return 'ᴮ';
        }
    }

    get averageFinancingInterest() {
        if (this.hasLoaded) {
            const interestSum = this.financingInvestments.reduce((acc, cur) => {
                return ('repaid' !== cur.status) ? (acc + cur.payout_number) : acc;
            }, 0);
            return (this.amountRunningFinancingInvestments) ? interestSum / this.amountRunningFinancingInvestments : 0;
        } else {
            return 'ᴮ';
        }
    }

    get investmentplan() {
        if (this.hasLoaded) {
            return this._rawData.investmentData.sparplan.investments[0];
        } else {
            return {};
        }
    }

    get investmentplans() {
        if (this.hasLoaded) {
            return this._rawData.investmentData.sparplan.investments;
        } else {
            return {};
        }
    }

    get investmentplanProjects() {
        if (this.hasLoaded) {
            return this._rawData.projectData.investmentplan;
        } else {
            return [];
        }
    }

}

export default UserData;
