















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {VNode} from 'vue';
import NodeHelper from '../Helper/NodeHelper';
import { debounce } from 'debounce';
import Tracking from '@/components/GoogleTagManager/services/Tracking';
import VueComponentHelper from '@/components/Helper/VueComponentHelper';
import OnSiteTracking from '@/components/GoogleTagManager/interfaces/OnSiteTracking';

@Component
class Select extends Vue {

    private currentInputValue: any = '';
    private inputModel: string = '';

    @Prop()
    private onChange!: (any) => void;

    @Prop()
    private value!: string;

    @Prop({default: 'name'})
    private name!: string;

    @Prop({default: 'Das Pflichtfeld'})
    private label!: string;

    private options: object[] = [];

    private isOpen: boolean = false;

    constructor() {
        super();
        if (this.value) {
            this.inputModel = this.value;
        }

        if (this.$slots && this.$slots.default) {
            this.$slots.default.forEach((slot: VNode) => {
                if ('option' === slot.tag && slot.data) {
                    const nodeProps = slot.data.hasOwnProperty('domProps') ? slot.data.domProps : slot.data.attrs;
                    let value = '';
                    let text = '';

                    if (slot.children && slot.children.length > 0) {
                        text = slot.children[0].text || '';
                    }

                    if (nodeProps && nodeProps.hasOwnProperty('value')) {
                        value = nodeProps.value + '';
                    }

                    this.options.push({
                        value,
                        text
                    });
                }
            });
        }

        this.resetCurrentInputValue = debounce(this.resetCurrentInputValue, 1000);
    }

    resetCurrentInputValue() {
        this.currentInputValue = '';
    }

    mounted() {
        const mdSelect: any = this.$refs['exporo-select'];
        const mdSelectLi = mdSelect.querySelectorAll('ul li');

        mdSelect.addEventListener('click', () => {
            mdSelect.classList.toggle('active');

            this.isOpen = mdSelect.classList.contains('active');
        });

        document.addEventListener('click', (event) => {
            if (!NodeHelper.childOf(mdSelect, event.target)) {
                const select: any = (this.$refs['exporo-select'] as any);
                if (select) {
                    select.classList.remove('active');
                }
                this.isOpen = false;
            }
        });

        mdSelect.addEventListener('keydown', (event: any) => {
            const key = event.key;
            this.currentInputValue += key;
            this.resetCurrentInputValue();

            let scrolled = false;

            if (this.isOpen) {
                mdSelectLi.forEach((li: any) => {
                    if (
                        !scrolled &&
                        li.textContent.trim().toLowerCase().startsWith(this.currentInputValue.toLowerCase())
                    ) {
                        const scrollable: any = this.$refs.scrollable;

                        if (scrollable) {
                            scrollable.scrollTo(0, li.offsetTop);
                        }

                        scrolled = true;
                    }
                });
            }
        });

        for (const liItem of mdSelectLi) {

            liItem.addEventListener('click', () => {

                const text = liItem.innerHTML;

                for (const item of mdSelectLi) {
                    item.classList.remove('active');
                }

                liItem.classList.add('active');

                (this.$refs.selectText as any).textContent = text;

                const value = liItem.dataset.value;

                this.inputModel = value;

                this.onChange(value);

                const checkoutVue: any = VueComponentHelper.getParentsComponentByClassName(this, 'exporo-checkout');
                const onSiteTrackingComponent: any = VueComponentHelper.getParentsComponentByMthodseImplemented(this, ['getCheckoutType', 'getViewType']);

                const type = checkoutVue ? checkoutVue.getCheckoutType() : undefined;
                const step = checkoutVue ? checkoutVue.step : undefined;

                if (onSiteTrackingComponent) {
                    Tracking.trackSelect(
                        'select',
                        onSiteTrackingComponent.getViewType(),
                        false,
                        'dropdown',
                        type,
                        step
                    );
                }
            });
        }

        const active: HTMLElement = mdSelect.querySelector('.active');
        if (active) {
            (this.$refs.selectText as any).textContent = active.innerHTML;
        }
    }
}

export default Select;
