






import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import AppRouter from '../AList/AppRouter';

@Component
class InvestEntry extends Vue {

    @Prop()
    private appRouter!: AppRouter;

    @Prop()
    private index!: number;

    @Prop()
    private data!: any;

    constructor() {
        super();
    }

    getStyles(index) {

        const transitionDelay = `${(index % 10) * 5}ms`;
        const animationDelay = `${(index % 10) * 70}ms`;

        return {
            'transition-delay': transitionDelay,
            'animation-delay': animationDelay
        };
    }

    open(index, data) {
        this.appRouter.setRoute('/' + index, {
            id: index, item: data
        });
    }

    public expand(setTitleCallback: (any) => void) {
        setTitleCallback(this.data.Projekt);

        const textPositions = this.appRouter.getTextPos();
        const root: any = this.$refs.root;

        root.className +=  ' expanding';
        root.style.height = `${root.offsetHeight * 2}px`;

        const tds: any = root.getElementsByClassName('td');
        let index: number = 0;

        const titles = Object.keys(this.data);

        [...tds].forEach((td: any) => {
            const image = td.getElementsByTagName('img')[0];
            const isText = void(0) === image;

            if (!isText) {
                td.className += ' title';
            } else {
                const p = td.getElementsByTagName('p')[0];
                p.style.lineHeight = '10px';

                const label = document.createElement('p');
                label.innerHTML = titles[index];
                label.className += 'label';
                td.appendChild(label);
            }

            if (isText && index > 4) {
                td.className += ' bottom';
            } else if (isText && index <= 4) {
                td.className += ' top';
            }

            index++;
        });


    }

    collapse() {
        const root: any = this.$refs.root;
        root.className =  root.className.replace(' expanding', ' collapsing');
    }
}

export default InvestEntry;
