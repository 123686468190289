











import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';

@Component({
    components: {}
})



class LandingVideo extends ExporoVue {
    @Prop({default: false}) private isFinancing!: boolean;
    srcVideo: string = '';

    constructor() {
        super();
    }

    mounted() {
        this.srcVideo = this.setSrc();
    }

    setSrc(): string {
        if (this.isFinancing) {
            return '//fast.wistia.net/embed/iframe/19h4sf2qni';
        } else {
            switch (this.cctld) {
                case 'NL':
                    return '//fast.wistia.net/embed/iframe/m5i3tgnb8d';
                    break;
                case 'FR':
                    return '';
                    break;
                default:
                    return '//fast.wistia.net/embed/iframe/ta6t5zw91v';
                    break;
            }
        }
    }
}


export default LandingVideo;
