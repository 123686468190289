











import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import CockpitOptions from './CockpitOptions.vue';
import CockpitCheckbox from './CockpitCheckbox.vue';
import Events from '@/events';

@Component({
    components: {
        CockpitOptions,
        CockpitCheckbox
    }
})
class CockpitMenu extends ExporoVue {
    
    @Prop() private label!: string;
    @Prop() private align!: string;
    @Prop() private options!: any[][];

    private menuId: string;
    private isOpen: boolean = false;


    constructor() {
        super();

        this.menuId = this.randomID();
    }

    mounted() {
        Events.$on('cockpit-click', (id) => {
            if (id !== this.menuId) {
                this.isOpen = false;
            }
        });
    }

    get alignment() {
        if ('left' === this.align) {
            return 'flex-start';
        } else if ('right' === this.align) {
            return 'flex-end';
        } else {
            return 'center';
        }
    }

    toggleNavigation() {
        this.isOpen = !this.isOpen;
        Events.$emit('cockpit-click', this.menuId);
    }

    randomID(): string {
        return Math.random().toString(36).substr(2, 6);
    }

}

export default CockpitMenu;
