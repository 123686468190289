class SubInvestorHelper {
    static extractName(data, userdata): string {
    	let subInvestorName: any = null;
		if (data.investor_entity_name && 'null' !== data.investor_entity_name) {
			subInvestorName = data.investor_entity_name;
		} else if (data.investor_first_name && data.investor_last_name) {
			subInvestorName = (`${data.investor_first_name} ${data.investor_last_name}`);
		} else {
			subInvestorName = userdata.fullname;
		}

		if ('null' === subInvestorName) {
			subInvestorName = userdata._rawData.userData.title
				+ ' ' + userdata._rawData.userData.first_name
				+ ' ' + userdata._rawData.userData.last_name;
		}

		return subInvestorName;
	}
}

export default SubInvestorHelper;
