





















































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Accordion from '../../Accordion/Accordion.vue';
import AccordionGroup from '../../Accordion/AccordionGroup.vue';
import InvestService from '../services/InvestService';
import ExplorationService from './services/ExplorationService';
import Knowledge from './Knowledge/Knowledge.vue';
import ExporoInput from '@/components/Input/Input.vue';
import Select from '@/components/Select/Select.vue';
import InvestmentRequestPart from '../models/InvestmentRequestPart';
import StoreInvestmentRequestService from '../services/StoreInvestmentRequestService';
import ExporoVue from '@/components/ExporoVue';



@Component({
    components: {
        Accordion,
        AccordionGroup,
        Knowledge,
        ExporoInput,
        Select,
    },
})
class ExplorationVermAnlG extends ExporoVue implements InvestmentRequestPart {

    private show = true;
    public TITLE = this.trans.get('checkout.VermAnlG.exploration.title');
    public $data: any;
    private shouldPerformExploration: string = '0';
    private shouldShowExplorationWarning: boolean = true;
    @Prop()
    private shouldHide!: boolean;
    @Prop()
    private switchStep!: () => void;
    @Prop()
    private storeInvestmentRequestService!: StoreInvestmentRequestService;
    @Prop()
    private investService!: InvestService;
    @Prop()
    private explorationService!: ExplorationService;
    @Prop()
    private explorationData!: any;


    @Watch('shouldPerformExploration')
    onShouldPerformExploration() {
        this.explorationService.form.exploration.perform = this.shouldPerformExploration;
    }

    onJobChanged() {
        this.explorationService.updateValue();
    }

    onValueChanged() {
        this.show = !(+this.explorationService.form.exploration.value >= ExplorationService.MIN_VALUE);
    }

    constructor() {
        super();
        this.explorationService.setValueChangedCallback(this.onValueChanged);
        this.shouldPerformExploration = this.explorationService.form.exploration.perform;

        if (void (0) === this.shouldPerformExploration) {
            this.shouldPerformExploration = '0';
        }

        this.explorationService.form.exploration.perform = this.shouldPerformExploration;

        this.storeInvestmentRequestService.registerRequestPart(this);
    }

    mounted() {
        this.explorationService.updateValue();
        this.onValueChanged();
    }

    getRequestPart(): object {

        return this.explorationService.form;
    }
}

export default ExplorationVermAnlG;
