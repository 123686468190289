









import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Events from '@/events';
import ExporoVue from '@/components/ExporoVue';

@Component
class ExporoSimpleSpinner extends Vue {

    private open: boolean = false;

    @Prop({default: false})
    private shouldShow!: boolean;

    constructor() {
        super();
        this.open = this.shouldShow;
    }

    mounted() {
        Events.$on(ExporoVue.SIMPLE_SPINNER_TOGGLE_EVENT, (open) => {
            this.open = open;
        });
    }
}

export default ExporoSimpleSpinner;
