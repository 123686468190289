import ExporoVue from '@/components/ExporoVue';

class UserEditOptions extends ExporoVue {
    date: number[] = [];
    months: any[] = [];
    years: number[] = [];
    allowedBirthYears: number[] = [];
    passYearRange: number[] = [];

    constructor() {
        super();
        this.date = Array.from({length: 31}, (x: number, i: number) => i + 1);
        this.months = [
            {label: this.trans.get('userEdit.months.jan'), value: 1},
            {label: this.trans.get('userEdit.months.feb'), value: 2},
            {label: this.trans.get('userEdit.months.mar'), value: 3},
            {label: this.trans.get('userEdit.months.apr'), value: 4},
            {label: this.trans.get('userEdit.months.may'), value: 5},
            {label: this.trans.get('userEdit.months.jun'), value: 6},
            {label: this.trans.get('userEdit.months.jul'), value: 7},
            {label: this.trans.get('userEdit.months.aug'), value: 8},
            {label: this.trans.get('userEdit.months.sep'), value: 9},
            {label: this.trans.get('userEdit.months.oct'), value: 10},
            {label: this.trans.get('userEdit.months.nov'), value: 11},
            {label: this.trans.get('userEdit.months.dec'), value: 12},
        ];
        this.years = Array.from({length: 60}, (x: number, i: number) => this.getCurrentYear() - i);
        this.allowedBirthYears = Array.from({length: 100}, (x: number, i: number) => this.getYearByAllowedAge(18) - i);
        this.passYearRange = Array.from({length: 11}, (x: number, i: number) => this.getCurrentYear() + i);
    }

    getYearByAllowedAge(age: number) {
        return new Date().getFullYear() - age;
    }

    getCurrentYear() {
        return new Date().getFullYear();
    }
}

export default UserEditOptions;
