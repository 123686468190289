







import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import SiriWave from 'siriwave/dist/siriwave.min';
import Router from '@/components/Router/Router';
import Events from '@/events';

@Component
class ExporoSiri extends Vue {

    private siriWave: any;
    private isSpeeching: boolean = false;
    private speechingText: string = '';
    private speechRecognition!: SpeechRecognition;
    private shouldShow: boolean = false;

    constructor() {
        super();
    }

    @Watch('isSpeeching')
    onSpeechingChanged() {
        if (this.isSpeeching) {
            this.speechingText = '';
            this.shouldShow = true;
            this.siriWave.start();
            this.speechRecognition.start();
        } else {
            setTimeout(() => {
                this.shouldShow = false;
            }, 1500);

            this.siriWave.stop();
            this.speechRecognition.stop();
        }
    }

    mounted() {

        Events.$on('enable-exporo-siri-feature', () => {
            this.siriWave = new SiriWave({
                container: document.getElementById('exporo-siri-root'),
                width: 350,
                height: 150,
                style: 'ios9',
                autostart: true
            });

            this.initSpeechListener();
        });
    }

    destroy() {
        document.removeEventListener('keydown', this.keyDownListener);
        document.removeEventListener('keyup', this.keyUpListener);
    }

    keyDownListener(e: any) {
        this.isSpeeching = 'r' === e.key;
    }

    keyUpListener(e: any) {
        if ('r' === e.key) {
            this.isSpeeching = false;
        }
    }

    initSpeechListener() {
        document.addEventListener('keydown', this.keyDownListener);
        document.addEventListener('keyup', this.keyUpListener);

        const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
        this.speechRecognition = new SpeechRecognition();

        this.speechRecognition.onresult = (event) => {
            const current = event.resultIndex;
            const transcript = event.results[current][0].transcript;

            this.speechingText = transcript;

            if ('öffne das cockpit' === transcript.toLowerCase()) {

                Router.navigate('cockpitRoute', ['yield']);
            } else if ('schließe das cockpit' === transcript.toLowerCase()) {
                Router.navigate('home');
                Events.$emit('close-cockpit', true);
            } else if (transcript.toLowerCase().indexOf('öffne das cockpit vom user') > -1 || transcript.toLowerCase().indexOf('öffne das cockpit von user') > -1) {
                const id = transcript.toLowerCase().split('öffne das cockpit vom user')[1] || transcript.toLowerCase().split('öffne das cockpit von user')[1];

                if (!isNaN(Number(id))) {
                    Router.navigate('cockpitRoute', ['yield']);
                    setTimeout(() => {
                        Router.navigate('cockpitRoute', [Number(id)]);
                    }, 1000);
                }
            }
        };
    }
}

export default ExporoSiri;
