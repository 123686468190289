
export function Actions(id: string) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        const actions = descriptor.value(id);

        descriptor.value = () => {

            return {
                id,
                actions
            };
        };
    };
}
