



















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import CustomerRatingPostsList from '@/components/CustomerRating/CustomerRatingPostsList.vue';
import CustomerRatingFooter from '@/components/CustomerRating/CustomerRatingFooter.vue';
import CustomerRatingButton from '@/components/CustomerRating/CustomerRatingButton.vue';
import RequestCallback from '../Rest/RequestCallback';
import ContentfulRequestApi from '@/components/Rest/requests/ContentfulRequestApi';
import RestError from '@/components/Rest/models/RestError';
import Logger from '@/components/Helper/LogHelper';

@Component({
    components: {
        CustomerRatingPostsList,
        CustomerRatingButton,
        CustomerRatingFooter
    }
})

class CustomerRating extends ExporoVue implements RequestCallback {
    private contentfulRequestApi: ContentfulRequestApi;
    private customerRatings: any[] = [];
    private maxSlidesNumber: number = 0;
    private activeSlide: number = 0;
    private noError: boolean = true;

    constructor() {
        super();
        this.contentfulRequestApi = this.restApi.create(new ContentfulRequestApi());
        this.contentfulRequestApi.getEntries({
            spaceId: 'uzhfucum7xuz',
            query: {
                'content_type': 'testimonials',
                'fields.id': 1,
                'locale' : this.cctld.toLowerCase()
            }
        }, this);
    }

    onFailure(error: RestError, id?: string): void {
        this.noError = false;
        Logger.tag('CustomerRating').error('Problem with the contenfull request', RestError);
    }

    onSuccess(response, id?: string): void {
        this.customerRatings = response.items[0].fields.npsResponsive;
        this.maxSlidesNumber = this.isMobile ? (this.customerRatings.length) : (this.customerRatings.length) / 2;
    }

    moveSlide(amount) {
        let newActiveSlide = -2;
        const newIndex = this.activeSlide + amount;
        if (newIndex > this.maxSlidesNumber - 1) newActiveSlide = 0;
        if (newIndex <= - 1 ) newActiveSlide = this.maxSlidesNumber - 1;
        this.activeSlide = newActiveSlide === - 2 ? newIndex : newActiveSlide;
    }
    jump(index) {
        this.activeSlide = index;
    }
}

export default CustomerRating;
