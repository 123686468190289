


























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import InvestList from '@/components/Cockpit/InvestList/InvestList.vue';
import TableData from '../models/TableData';
import AppRouter from './AppRouter';
import InvestEntryData from '../InvestList/InvestEntryData';
import InvestMenu from '@/components/Cockpit/InvestList/InvestMenu.vue';
import OptionEntry from '@/components/Cockpit/models/OptionEntry';
import ExporoVue from '@/components/ExporoVue';
import Events from '@/events';
import UserTooltip from '@/components/User/Edit/UserTooltip.vue';
import UserData from '@/components/Cockpit/models/UserData';

@Component({
    components: {
        InvestList,
        InvestMenu,
        UserTooltip
    }
})
class ListView extends ExporoVue {

    @Prop() private appRouter!: AppRouter;
    @Prop() private scrollable!: HTMLDivElement;
    @Prop() private tableData!: TableData;
    @Prop() private pendingTableData!: TableData;
    @Prop() private isDownload!: boolean;
    @Prop() private defaultFilter!: string;
    @Prop() private defaultActiveCol!: string;
    @Prop() private subMenu!: string;
    @Prop() private headline!: string;
    @Prop() private headlinePending!: string;
    @Prop() private userdata!: UserData;
    @Prop() private tooltip!: string;
    @Prop() private isPortfolioNew!: boolean;

    private additionalEmptyText: any = null;
    private pendingDefaultFilter: string = 'pending';

    private exitCardIndex: number = -1;
    private entryCardIndex: number = -1;
    private handoffDone: boolean = false;
    private listItem: any = null;
    private filterStatus!: any[][];
    private showPendingTable: boolean = false;

    constructor() {
        super();
        const prevUrl = '/-1';
        this.entryCardIndex = Number(prevUrl.substring(prevUrl.lastIndexOf('/') + 1));
        this.filterStatus = [
            [
                new OptionEntry(
                    this.trans.get('cockpit.pages.portfolio.pending_filter.pending'),
                    this.pendingDefaultFilter,
                    () => {
                        Events.$emit('filter-pending', {
                            type: 'state',
                            selection: 'pending',
                            subMenu: 'payments'
                        });
                    }
                ),
                new OptionEntry(
                    this.trans.get('cockpit.pages.portfolio.pending_filter.allInvestments'),
                    'allInvestments',
                    () => {
                        Events.$emit('filter-pending', {
                            type: 'state',
                            selection: 'allInvestments',
                            subMenu: 'payments'
                        });
                    }
                ),
            ]
        ];

    }

    mounted() {
        if (this.userdata) {
            this.showPendingTable = this.getPendingData();
        }

        if (this.tableData.options && this.tableData.options.additionalEmptyText) {
            this.additionalEmptyText = this.tableData.options.additionalEmptyText;
        }

        if (this.appRouter) {
            const id = this.appRouter.getPrevUrl().split('/')[1];
            this.listItem = document.getElementById('list-item-' + id);

            if (this.listItem) {
                this.listItem.classList.add('entrycard');
            }
        }

        if (!this.listItem) {
            this.completeHandoff();
            return;
        }

        if (this.listItem && null !== this.appRouter) {

            this.scrollable.scrollTo(0, Number(this.scrollable.getAttribute('data-top')));

            requestAnimationFrame(() => {
                this.appRouter
                    .runAnimation(this.listItem.offsetTop || 0, 10)
                    .then(this.completeHandoff)
                    .catch(this.completeHandoff);
            });
        }

    }

    getPendingData(): boolean {

        return this.tableData.data.length > 0;
    }

    onViewChange(e, index) {
        const id = this.appRouter.params.id;
        if (id) {
            this.listItem = document.getElementById('list-item-' + id);
            this.startLeave(e, index);
        }
    }

    startLeave(e, index) {
        const listItem = e.closest('.exporo-invest-entry');

        if (listItem) {
            this.exitCardIndex = index;

            if (this.tableData) {
                const ghostData: InvestEntryData = new InvestEntryData(index, this.tableData.data[index]);

                const sizes: number[] = [];
                const textPos: object[] = [];

                [...listItem.getElementsByTagName('td')].forEach((td) => {
                    const reacts = td.getBoundingClientRect();
                    sizes.push(td.offsetWidth);
                    textPos.push({
                        top: reacts.top,
                        left: reacts.left
                    });
                });

                const positions = {
                    top: listItem.offsetTop,
                    left: listItem.offsetLeft,
                    width: listItem.offsetWidth,
                    height: listItem.offsetHeight,
                    sizes: sizes,
                    textPos: textPos
                };

                this.scrollable.setAttribute('data-top', '' + this.scrollable.scrollTop);

                this.appRouter.addSharedElement(positions, ghostData);
            }
        }
    }

    completeHandoff() {
        this.handoffDone = true;
    }
}

export default ListView;
