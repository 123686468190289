import { render, staticRenderFns } from "./CockpitContent.vue?vue&type=template&id=a36e555c&scoped=true&"
import script from "./CockpitContent.vue?vue&type=script&lang=ts&"
export * from "./CockpitContent.vue?vue&type=script&lang=ts&"
import style0 from "./CockpitContent.vue?vue&type=style&index=0&id=a36e555c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a36e555c",
  null
  
)

export default component.exports