export const RULE_TYPE = {

    REQUIRED: 'REQUIRED',
    ACCEPTED: 'ACCEPTED',
    ACCEPTED_WITH: 'ACCEPTED_WITH',
    NOT_IN: 'NOT_IN',
    REQUIRED_IF: 'REQUIRED_IF',
    ACCEPTED_IF: 'ACCEPTED_IF',
    REQUIRED_IF_LT: 'REQUIRED_IF_LT',
    REQUIRED_IF_GT: 'REQUIRED_IF_GT',
    IN_RANGE: 'IN_RANGE',
    MIN: 'MIN',
    MAX: 'MAX',
    INVESTSUM_AMOUNT: 'INVESTSUM_AMOUNT',
    NOT_EQ_REQUIRED_IF_EQ: 'NOT_EQ_REQUIRED_IF_EQ',

};
