import DetailData from '@/components/Cockpit/interfaces/DetailData';
import getReportingUrlFromYieldDataEntry from '@/components/Helper/ReportingUrl';

export default class PageData {

    private _documentData: any = {};
    private _data!: DetailData;

    setData(data: DetailData, contracts: any) {
        this._data = data;
        this._data._reportingUrl = getReportingUrlFromYieldDataEntry(data, contracts);
    }

    get data(): DetailData {
        return this._data;
    }

    set documentData(data) {
        this._documentData = data;
    }

    get documentData(): any {
        return this._documentData;
    }

}
