



























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Toggle from '@/components/Toggle/Toggle.vue';
import ExporoVue from '../../ExporoVue';
import RequestCallback from '../../Rest/RequestCallback';
import RestError from '../../Rest/models/RestError';
import {AxiosResponse} from 'axios';
import RestApiService from '../../Rest/services/RestApiService';
import ContactCenterRequestApi from '../../Rest/requests/ContactCenterRequestApi';
import {contactCenter} from './models/ContactCenterControlsResponse';

@Component({
    components: {
        Toggle
    }
})
class ContactCenter extends ExporoVue implements RequestCallback {

    private contactCenterRequestApi: ContactCenterRequestApi;
    private restApiService: RestApiService;
    private contactCenterControlsResponse: contactCenter.ControlsResponse | null = null;

    constructor() {
        super();

        this.restApiService = new RestApiService(this);
        this.contactCenterRequestApi = this.restApi.create(new ContactCenterRequestApi());

        this.contactCenterRequestApi.getControls(this);
    }

    onToggle(isChecked, name) {

        if (this.contactCenterControlsResponse) {
            this.contactCenterRequestApi.saveSubscriptionSetiings({
                active: isChecked,
                messageType: name,
                uniqueUserHash: this.contactCenterControlsResponse.uniqueUserHash || ''
            }, this);
        }
    }

    onFailure(error: RestError): void {

    }

    onSuccess(response: AxiosResponse): void {
        if (response.config.url && response.config.url.indexOf('controls') > -1) {
            this.contactCenterControlsResponse = response.data;
        } else if (response.config.url && response.config.url.indexOf('save-subscription-settings')) {
            if (response.data) {
                // @TODO implements success message
            }
        }
    }
}

export default ContactCenter;
