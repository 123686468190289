




import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import DropDownGroup from '@/components/DropDown/DropDownGroup.vue';
import DropDowns from '@/components/DropDown/DropDowns';
import KeyValue from '@/components/DropDown/KeyValue';

@Component({
    components: {
        DropDownGroup
    }
})
class DateDropDown extends ExporoVue {

    @Prop() private updateDate!: (string) => {};
    @Prop() private date!: string;
    @Prop() private yearGap!: { start: number, end: number, gap: number };

    readonly day: string;
    readonly month: string;
    readonly year: string;

    private dayRange: KeyValue[] = [];
    private monthRange: KeyValue[] = [];
    private yearRange: KeyValue[] = [];

    private dropDowns: DropDowns[] = [];

    constructor() {
        super();
        this.day = this.date.split(' ')[0].split('-')[2];
        this.month = this.date.split(' ')[0].split('-')[1];
        this.year = this.date.split(' ')[0].split('-')[0];
    }

    mounted() {
        this.initDropDowns();
    }

    initDropDowns() {
        this.dayRange = this.initDayRange();
        this.monthRange = this.initMonthRange();
        this.yearRange = this.initYearRange(this.yearGap.start, this.yearGap.end, this.yearGap.gap);

        this.dropDowns = [
            {
                name: 'day',
                range: this.dayRange,
                value: this.day,
                defaultValue: this.trans.get('basic.day'),
            },
            {
                name: 'month',
                range: this.monthRange,
                value: this.month,
                defaultValue: this.trans.get('basic.month'),
            },
            {
                name: 'year',
                range: this.yearRange,
                value: this.year,
                defaultValue: this.trans.get('basic.year'),
            }
        ];
    }

    initDayRange(): KeyValue[] {
        return this.pushArrayToObject(
            Array.from({length: 31}, (x: number, i: number) =>
                this.parseSingleDigitToDouble((i + 1).toString()))
        );
    }

    initMonthRange(): KeyValue[] {
        return [
            {value: this.getMonthLabel(this.trans.get('userEdit.months.jan')), key: '01'},
            {value: this.getMonthLabel(this.trans.get('userEdit.months.feb')), key: '02'},
            {value: this.getMonthLabel(this.trans.get('userEdit.months.mar')), key: '03'},
            {value: this.getMonthLabel(this.trans.get('userEdit.months.apr')), key: '04'},
            {value: this.getMonthLabel(this.trans.get('userEdit.months.may')), key: '05'},
            {value: this.getMonthLabel(this.trans.get('userEdit.months.jun')), key: '06'},
            {value: this.getMonthLabel(this.trans.get('userEdit.months.jul')), key: '07'},
            {value: this.getMonthLabel(this.trans.get('userEdit.months.aug')), key: '08'},
            {value: this.getMonthLabel(this.trans.get('userEdit.months.sep')), key: '09'},
            {value: this.getMonthLabel(this.trans.get('userEdit.months.oct')), key: '10'},
            {value: this.getMonthLabel(this.trans.get('userEdit.months.nov')), key: '11'},
            {value: this.getMonthLabel(this.trans.get('userEdit.months.dec')), key: '12'},
        ];
    }

    initYearRange(start: number, end: number, gap: number): KeyValue[] {
        const range: string[] =
            Array(
                gap ?
                    gap + 1
                    : (end + 1) - start
            ).fill(0).map((el, index) => {
                return (
                    gap ?
                        start ?
                            start + index
                            : end - index
                        : start + index
                ).toString();
            });

        return this.pushArrayToObject(range);
    }

    pushArrayToObject(array: string[]): KeyValue[] {
        const keyValue: KeyValue[] = [];
        array.forEach((el: string) => {
            keyValue.push({key: el, value: el});
        });

        return keyValue;
    }

    getMonthLabel(label: string) {
        return this.isMobile ? label.substring(0, 3) : label;
    }

    onChange(value: string, index: number) {
        this.setNewValue(value, index);
        this.updateDate(this.makeFullDate());
    }

    parseSingleDigitToDouble(value: string) {
        return value.length === 1 ? '0' + value : value;
    }

    setNewValue(value: string, index: number) {
        this.parseSingleDigitToDouble(value);

        this[this.dropDowns[index].name] = value;
    }

    makeFullDate(): string {
        return [this.year, this.month, this.day].join('-') + ' 00:00:00';
    }

}

export default DateDropDown;
