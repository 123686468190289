









import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '@/components/ExporoVue';
import Events from '@/events';
import Router from '@/components/Router/Router';



@Component({
    components: {}
})

class InviteFriendButton extends ExporoVue {

    goToInviteAFriend() {
        Router.navigate('cockpitRouteMenu', ['recruitFriends']);
    }

}

export default InviteFriendButton;
