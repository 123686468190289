






































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Input from '@/components/Input/Input.vue';
import OfferTransactionController from '@/components/SecondaryMarket/controllers/OfferTransactionController';
import OfferTransactionResponseInterface from '@/components/SecondaryMarket/interfaces/OfferTransactionResponse';
import StringHelper from '@/components/Helper/StringHelper';
import UserTooltip from '@/components/User/Edit/UserTooltip.vue';
import ExporoVue from '@/components/ExporoVue';
import RequestCallback from '@/components/Rest/RequestCallback';
import SecondaryMarketRequestApi from '@/components/Rest/requests/SecondaryMarketRequestApi';
import RestError from '@/components/Rest/models/RestError';
import {AxiosResponse} from 'axios';
import Events from '@/events';
import TradeOffer from '@/components/Cockpit/KPI/services/TradeOffer';

@Component({
    components: {
        Input,
        UserTooltip
    },
    filters: {
        toEur(value) {
            return StringHelper.toEur(value);
        }
    }
})
class OfferTransaction extends ExporoVue implements RequestCallback {

    @Prop() private tradeOffer!: TradeOffer;

    private offerTransactionController: OfferTransactionController;
    private secondaryMarketRequestApi: SecondaryMarketRequestApi;

    private pieces: number = 0;
    private salePricePerPiece: number = 0;
    private postData!: {
        contract_id: number
        isin: string
        pieces_to_sell: number
        sale_price_per_piece: number
        sale_charge: number
        assetId: string | null
    };
    private loading: boolean = false;
    private storeError: boolean = false;

    constructor() {
        super();
        this.offerTransactionController = new OfferTransactionController(this.tradeOffer);
        this.secondaryMarketRequestApi = this.restApi.create(new SecondaryMarketRequestApi());
        this.$nextTick(this.updateData);
    }

    salePricePerPieceChanged(price) {
        this.offerTransactionController.salePricePerPieceChanged(Number(price));
        this.updateData();
    }

    piecesChanged(pieces) {
        this.offerTransactionController.piecesChanged(Number(pieces));
        this.updateData();
    }


    updateData() {
        this.pieces = this.offerTransactionController.pieces;
        this.salePricePerPiece = this.offerTransactionController.salePricePerPiece;
        this.$forceUpdate();
    }

    send(e) {
        e.preventDefault();
        this.offerTransactionController = new OfferTransactionController(this.tradeOffer);
        this.$nextTick(this.updateData);
        this.createPostData();
        this.secondaryMarketRequestApi.postOffer(this.postData, this);
        this.loading = true;
    }

    createPostData() {
        this.postData = {
            contract_id: this.tradeOffer.contractId,
            isin: this.tradeOffer.isin,
            pieces_to_sell: this.pieces,
            sale_price_per_piece: this.salePricePerPiece,
            sale_charge: this.offerTransactionController._insertioncharge,
            assetId: this.tradeOffer.assetId
        };
    }

    offerPlaced() {
        this.getModalService().closeModal('secondary-market-offer');
        this.loading = false;
        this.storeError = false;
        this.getModalService().openModal('offer-success');
        Events.$emit('secondary-market-do-offer');
    }

    onSuccess(response: AxiosResponse) {
        this.offerPlaced();
    }

    onFailure(error: RestError) {
        this.loading = false;
        this.storeError = true;
    }
}

export default OfferTransaction;
