import {StringKeyValueType} from '@/components/Checkout/Exploration/models/Types';
import ExporoVue from '@/components/ExporoVue';


// @TODO id deprecated?
/* tslint:disable */
class KnowledgeService extends ExporoVue {

    public howLongOptions: StringKeyValueType = {};

    public howMuchOptions: StringKeyValueType = {};

    public howManyOptions: StringKeyValueType = {};

    constructor() {
        super();

        this.howLongOptions['-'] = '0',
            this.howLongOptions[this.trans.get('checkout.investments.how_long.options.upto_3')] = '5;' + this.trans.get('checkout.investments.how_long.options.upto_3'),
            this.howLongOptions[this.trans.get('checkout.investments.how_long.options.btw_3_5')] = '10;' + this.trans.get('checkout.investments.how_long.options.btw_3_5'),
            this.howLongOptions[this.trans.get('checkout.investments.how_long.options.more_than_5')] = '20;' + this.trans.get('checkout.investments.how_long.options.more_than_5');

        this.howMuchOptions['-'] = '0',
            this.howMuchOptions[this.trans.get('checkout.investments.how_much.options.upto_2500')] = '1;' + this.trans.get('checkout.investments.how_much.options.upto_2500'),
            this.howMuchOptions[this.trans.get('checkout.investments.how_much.options.upto_5000')] = '1.5;' + this.trans.get('checkout.investments.how_much.options.upto_5000'),
            this.howMuchOptions[this.trans.get('checkout.investments.how_much.options.upto_10500')] = '2;' + this.trans.get('checkout.investments.how_much.options.upto_10500'),
            this.howMuchOptions[this.trans.get('checkout.investments.how_much.options.more_than_10500')] = '2.5;' + this.trans.get('checkout.investments.how_much.options.more_than_10500');

        this.howManyOptions['-'] = '0',
            this.howManyOptions[this.trans.get('checkout.investments.how_many.options.upto_5')] = '1;' + this.trans.get('checkout.investments.how_many.options.upto_5'),
            this.howManyOptions[this.trans.get('checkout.investments.how_many.options.btw_5_10')] = '1.5;' + this.trans.get('checkout.investments.how_many.options.btw_5_10'),
            this.howManyOptions[this.trans.get('checkout.investments.how_many.options.more_than_10')] = '2;' + this.trans.get('checkout.investments.how_many.options.more_than_10');

    }
}

export default KnowledgeService;
