import ExporoVue from '@/components/ExporoVue';

export default class HighChartsService extends ExporoVue {
    private _actual: number[] = [];
    private _target: number[] = [];
    private _date: string[] = [];
    private _type: string = '';
    private _unit: string = ' €';
    private _decimal: number = 2;
    private _legend: boolean = false;
    private _title: string = '';

    addActual(data: number) {
        this._actual.push(data);
    }

    addTarget(data: number) {
        this._target.push(data);
    }

    noTarget() {
        this._target = [];
    }

    addDate(data: string) {
        this._date.push(data);
    }

    set type(data: string) {
        this._type = data;
    }

    set unit(data: string) {
        this._unit = data;
    }

    set decimal(data: number) {
        this._decimal = data;
    }

    set legend(data: boolean) {
        this._legend = data;
    }

    set title(data: string) {
        this._title = data;
    }

    validateHighCharts(reportArrayLength: number): boolean {
        return this._target.length
            ? reportArrayLength === this._actual.length
            && reportArrayLength === this._target.length
            : reportArrayLength === this._actual.length;
    }

    validateEmptyCharts() {
        return this._actual.every((value) => value === 0);
    }

    getOptions(): any {
        return {
            chart: {
                type: this._type
            },
            title: {
                text: this._title
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: this._date,
                labels: {
                    rotation: -45,
                    style: {
                        color: '#888'
                    }
                },
            },
            yAxis: {
                title: {
                    text: '',
                },
                labels: {
                    style: {
                        color: '#888'
                    },
                },
            },
            tooltip: {
                shared: true,
                valueSuffix: this._unit,
                valueDecimals: this._decimal,
                backgroundColor: '#fff',
                borderRadius: 15,
                borderWidth: 0,
                snap: 50,
                split: true,
                distance: 30,
                style: {
                    color: '#565655',
                    fontSize: 12,
                },
            },
            plotOptions: {
                column: {
                    pointPadding: .05,
                    borderWidth: 0,
                    shadow: false,
                }
            },
            legend: {
                enabled: this._legend,
                backgroundColor: '#f5f2f2',
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                symbolPadding: 10,
                symbolWidth: 20,
                borderRadius: 12,
                padding: 8,
                itemStyle: {
                    color: '#565655',
                    fontWeight: 'normal',
                    fontSize: 10,
                },
            },
            series: [{
                name: this.trans.get('cockpit.pages.detail.reportings_charts.series_a'),
                data: this._actual,
                color: '#67a337',
            }, {
                name: this.trans.get('cockpit.pages.detail.reportings_charts.series_b'),
                data: this._target ? this._target : '',
                color: '#565655',
            }]
        };
    }
}
