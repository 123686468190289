import {Post} from '@/decorators/RestDecorators';
import RequestCallback from '../RequestCallback';
import RequestApi from '@/components/Rest/models/RequestApi';

class InvestorRequestApi extends RequestApi {

    @Post('/investor')
    createInvestor(data?: object, callback?: RequestCallback) {}

    @Post('/investor')
    updateInvestor(data?: object, callback?: RequestCallback) {}

}

export default InvestorRequestApi;
