import StringHelper from '@/components/Helper/StringHelper';
import DateFormatHelper from '@/components/Helper/DateFormatHelper';
import ExporoVue from '@/components/ExporoVue';
import PercentFormatHelper from '@/components/Helper/PercentFormatHelper';

export default class TableDataConstructionService extends ExporoVue {

    getSecondaryMarketEntry(data: any, type: string, contract: any, image: any, status: any, nextPayout: any): any {

        return {
            _id: contract.financing_entity,
            _contractId: contract.id,
            _title: contract.title,
            _isin: contract.isin,
            _investment: data.bid_pieces * data.piece_value,
            _period: contract.runningtime_in_months,
            _nextPayout: nextPayout,
            _wkn: contract.wkn,
            _image: image,
            _type: 'equity',
            _pendingType: type,
            _expectedInterest: contract.return_on_equity_rate_miete ? contract.return_on_equity_rate_miete : contract.return_on_equity_rate_overall,
            _status: status,
            _currentState: data.current_state,
            _isPaid: 'money_received' === data.current_state || 'transfer_order_granted' === data.current_state || 'charges_payed' === data.current_state || 'payout_done' === data.current_state,
            _pieces: data.pieces,
            _createdAt: DateFormatHelper.getFormattedDateFromString(data.accepted_at)
        };
    }

    getColumns() {
        return [
            {
                label: '',
                field: '_image',
                sortable: false,
            },
            {
                label: this.trans.get('cockpit.pages.portfolio.list_headers.project'),
                field: 'title',
                thClass: 'vgt-left-align',
            },
            {
                label: this.trans.get('cockpit.pages.portfolio.list_headers.investment'),
                field: 'investment',
                type: 'number',
                formatFn: this.formatE,
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            },
            {
                label: this.trans.get('cockpit.pages.portfolio.list_headers.expected_payout'),
                field: 'expectedInterest',
                type: 'percentage',
                tdClass: 'text-right',
                thClass: 'vgt-left-align',
            },
            {
                label: this.trans.get('cockpit.pages.portfolio.list_headers.invested_at'),
                field: 'createdAt',
                type: 'date',
                dateInputFormat: 'dd.mm.yyyy',
                dateOutputFormat: 'dd.mm.yyyy',
                tdClass: 'text-right',
                formatFn: this.checkNoDate,
                sortFn: this.sortFn,
                thClass: 'vgt-left-align',
            },
            {
                label: 'Quart. Reports',
                field: 'reporting',
                sortable: false,
                tdClass: 'text-right',
            },
            {
                label: 'Details',
                field: 'details',
                sortable: false,
                tdClass: 'text-right',
            }
        ];
    }

    getPendingColumns() {
        return [
            {
                label: '',
                field: '_image',
                sortable: false,
            },
            {
                label: this.trans.get('cockpit.pages.portfolio.list_headers.project'),
                field: 'title',
                sortable: false,
                thClass: 'vgt-left-align',
            },
            {
                label: this.trans.get('cockpit.pages.portfolio.list_headers.investment'),
                field: 'investment',
                type: 'number',
                formatFn: this.formatE,
                tdClass: 'text-right',
                thClass: 'vgt-right-align-unsortable',
                sortable: false,
            },
            {
                label: this.trans.get('cockpit.pages.portfolio.list_headers.invested_at'),
                field: 'createdAt',
                type: 'date',
                dateInputFormat: 'dd.mm.yyyy',
                dateOutputFormat: 'dd.mm.yyyy',
                tdClass: 'text-right',
                thClass: 'vgt-right-align-unsortable',
                formatFn: this.checkNoDate,
                sortFn: this.sortFn,
                sortable: false,
            },
            {
                label: this.trans.get('cockpit.pages.portfolio.list_headers.type'),
                field: 'type',
                tdClass: 'text-right',
                thClass: 'vgt-right-align-unsortable',
                sortable: false,
            },
            {
                label: 'ACTION',
                field: 'action',
                sortable: false,
            }
        ];
    }

    checkEmptyValue(value) {
        if (value === 0) {
            return 'folgt';
        }
        return PercentFormatHelper.numberToFixedPercent(value, 2);
    }

    checkNoDate(date: string): string {
        if (date === '01.01.1970') {
            return '-';
        }

        return date;
    }

    sortFn(x, y, col, rowX, rowY) {
        const xDate = new Date(DateFormatHelper.stringToAmericanDate(x)).getTime();
        const yDate = new Date(DateFormatHelper.stringToAmericanDate(y)).getTime();

        return (xDate < yDate ? -1 : (xDate > yDate ? 1 : 0));
    }

    formatE(value) {

        if (Infinity === value) {
            return 'Max. Stückzahl bereits verkauft';
        }

        return StringHelper.toEur(value);
    }
}
