






import {Component, Vue, Prop} from 'vue-property-decorator';

@Component
class ErrorForm extends Vue {
    constructor() {
        super();
    }
}

export default ErrorForm;
