import {Get, Post} from '@/decorators/RestDecorators';
import RequestCallback from '../RequestCallback';
import RestApi from '@/components/Rest/RestApi';
import RequestApi from '@/components/Rest/models/RequestApi';
import {SecondaryMarket} from '@/decorators/SecondaryMarketDecorator';

class SecondaryMarketRequestApi extends RequestApi {

    @SecondaryMarket()
    getAllOffers(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/api/intern/secondarymarket/offer/store')
    postOffer(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/secondarymarket/index', 'getOffers')
    getOffers(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/api/intern/secondarymarket/offer/destroy/{offerId}', 'deleteOffer')
    deleteOffer(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/secondarymarket/offer/isin/{isin}', 'offersByUserAndIsin')
    getOffersByUserAndIsin(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/secondarymarket/offer/user/{id}/isin/{isin}', 'offersByUserAndIsin')
    getOffersByUserIdAndIsin(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/secondarymarket/index/pending-data', 'getPendingData')
    getPendingData(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/secondarymarket/index/pending-data/{id}', 'getPendingData')
    getPendingDataByUserId(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Post('/api/intern/secondarymarket/bid/store')
    postBuy(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }

    @Get('/api/intern/secondarymarket/bid/bidscompletebyuser')
    getBidsComplete(data?: object, callback?: RequestCallback): RestApi {
        return {} as RestApi;
    }
}

export default SecondaryMarketRequestApi;
