class OptionEntry {
    get active(): boolean {
        return this._active;
    }
    private _active: boolean = true;
    private _visible: boolean = true;
    private _key: string;
    private _value: string;
    private _handler: (...args: any[]) => void;

    constructor(value: string, key?: string, handler?: (...args: any[]) => void, isOtherProfile: boolean = false, isAdmin: boolean = false) {
        this._value = value;
        this._key = key || value.replace(/\s/g, '').toLowerCase();

        const emptyhandler = () => {};
        this._handler = handler || emptyhandler;
        if (isOtherProfile) {
            this._active = !isOtherProfile;
        }
        if (this.key === 'userEdit') {
            this._visible = isAdmin;
        }
    }

    get key(): string {
        return this._key;
    }

    get value(): string {
        return this._value;
    }

    get visible(): boolean {
        return this._visible;
    }

    get handler(): (...args: any[]) => void {
        return this._handler;
    }

}

export default OptionEntry;
