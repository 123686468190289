















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
class Checkbox extends Vue {

    @Prop({default: ''})
    private id!: string;

    @Prop({default: false})
    private disabled!: boolean;

    @Prop({
        default: () => {
        },
    })
    private onChange!: (checked: boolean) => void;

    @Prop({default: false})
    private value!: boolean;

    private isChecked: boolean = false;

    constructor() {
        super();

        this.isChecked = this.value;
    }

    change(e) {
        this.isChecked = e.target.checked;
        this.onChange(this.isChecked);
    }
}

export default Checkbox;
