



















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '../ExporoVue';
import CockpitMenu from './Controls/CockpitMenu.vue';
import CockpitContent from './CockpitContent.vue';
import CockpitHeader from './CockpitHeader.vue';
import Events from '../../events';
import UserRequestApi from '../Rest/requests/UserRequestApi';
import RequestCallback from '../Rest/RequestCallback';
import RestError from '../Rest/models/RestError';
import UserData from './models/UserData';
import CockpitRequestApi from './../Rest/requests/CockpitRequestApi';
import RestApiService, {BASE_TOKEN} from '../Rest/services/RestApiService';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import store, {LOGIN, LOGIN_FAILED, LOGIN_SUCCESS} from '@/store';
import Logger from '@/components/Helper/LogHelper';
import ContentfulRequestApi from '@/components/Rest/requests/ContentfulRequestApi';
import TableDataService from '@/components/Cockpit/services/TableDataService';
import URLHelper from '@/components/Helper/URLHelper';
import AuthService, {TOKEN_KEY} from '@/components/Auth/services/AuthService';
import LoadingBarService from '@/components/LoadingBar/services/LoadingBarService';
import LoadingBar from '@/components/LoadingBar/LoadingBar.vue';
import {Action, Route} from '@/components/Router/decorators/RouteDecorator';
import Controller from '@/components/Router/Controller';
import Router from '@/components/Router/Router';
import RequestAdapter from '@/components/LoadingBar/adapter/RequestAdapter';
import ExporoSpinner from '@/components/ExporoSpinner/ExporoSpinner.vue';
import ExporoSiri from '@/components/ExperimentalFeatures/ExporoSiri.vue';
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import Investment from '@/components/Cockpit/Financing/interfaces/investment';
import {resumeSession} from '@/components/Helper/Auth';
import {Severity} from '@sentry/types';
import * as Sentry from '@sentry/vue';

const LOGGER_TAG: string = 'CockpitV2';

@Component({
  store,
  components: {
    CockpitMenu,
    CockpitContent,
    CockpitHeader,
    LoadingBar,
    ExporoSpinner,
    ExporoSiri
  }
})
class CockpitV2 extends Controller implements RequestCallback {

  private scrollable: HTMLDivElement | null = null;
  private shoudShow: boolean = true;
  private viewRefreshed: boolean = false;
  private open: boolean = false;
  private white: boolean = false;
  private userdata: UserData;
  private cockpitRequestApi: CockpitRequestApi;
  private contentfulRequestApi: ContentfulRequestApi;
  private userRequestApi: UserRequestApi;
  private authService: AuthService;
  private restApiService: RestApiService;
  private loadingBarService!: LoadingBarService;
  private loadingBarServiceCockpitV2!: LoadingBarService;
  private isBarLoading: boolean;
  private requestAdapter!: RequestAdapter;
  private interValRef: any = 0;
  private isLoggedIn: boolean = false;
  private loginStateFetched: boolean = false;
  private userDataComplete: boolean = true;
  private shoudLockLoadingBar: boolean = true;

  @Prop({default: false})
  private mobile!: boolean;

  private defaultConfig: object = {
    withCredentials: true,
    maxRedirects: 0,
  };

  constructor() {
    super('CockpitV2');
    this.resetEvents();

    this.restApiService = new RestApiService(this);
    this.authService = new AuthService(this.restApiService);
    this.userRequestApi = this.restApi.create(new UserRequestApi());
    this.cockpitRequestApi = this.restApi.create(new CockpitRequestApi());
    this.contentfulRequestApi = this.restApi.create(new ContentfulRequestApi());
    this.userdata = new UserData();
    this.isBarLoading = true;
    this.requestAdapter = new RequestAdapter();

    document.addEventListener('click', () => {
      Events.$emit('cockpit-click', '');
    });


    this.loadingBarService = new LoadingBarService();
    this.loadingBarServiceCockpitV2 = new LoadingBarService(this.requestAdapter);
  }


  getName() {
    return this.getClassName() || 'CockpitV2';
  }

  isOpenClassSet() {
    const cockpitRoot = document.getElementsByClassName('exporo-cockpit-root')[0];

    if (cockpitRoot) {
      return cockpitRoot.className.indexOf('open') > -1;
    }

    return false;
  }

  @Action('cockpitRoute')
  cockpitRouteAction(type) {
    if (!localStorage.getItem(TOKEN_KEY) && !localStorage.getItem('oldLogin')) {
      this.openCockpitIfClosedOrSetClass(type);
      // Events.$on('authStateChanged', (loggedIn: boolean) => {
      //   if (!loggedIn) {
      //     document.location.href = 'https://user.exporo.com/#/signin?redirectURL=' + window.location.href;
      //   }
      // });
    } else if (!localStorage.getItem(TOKEN_KEY) && localStorage.getItem('oldLogin')) {
      this.getModalService().openModal('login-modal');
    } else {
      this.openCockpitIfClosedOrSetClass(type);
    }
  }

  openCockpitIfClosedOrSetClass(type) {
    Events.$emit('openCockpit');
    if (isNaN(Number(type))) {
      const body: any = document.getElementsByTagName('body')[0];
      this.openCockpitIfClosed(type, body);
    } else {
      if (this.isOpenClassSet()) {
        window.location.reload();
      }
    }
  }

  @Action('cockpitDetailRoute')
  detailRouteAction(type: string, id: string) {
    this.openDetail(type, id);
  }

  @Action('detailSlider')
  detailSlider(type: string, id: string, slide: string) {
    const slideNumber = 'reporting' === slide ? 1 : 0;

    this.openDetail(type, id, () => {
      Events.$emit('switch-panel', slideNumber);
      setTimeout(() => {
        Events.$emit('updateSliderBubble', slide);
      }, 100);
    });
  }

  openDetail(type: string, id: string, callback?: any) {
    if (isNaN(Number(type))) {
      if (!this.open) {
        const body: any = document.getElementsByTagName('body')[0];

        this.openCockpit(body, undefined, false);
      }

      Events.$on('yieldDetailLoaded', () => {
        this.$nextTick(() => {
          Events.$emit('router-go', {'page': type});
          Events.$emit('updateBubble', {page: 'cockpit/' + type});
        });

        if ('yield' === type) {
          this.fetchDetailData(type, id, callback);
        } else {
          Events.$on('financingDetailLoaded', () => {
            this.fetchDetailData(type, id);
          });
        }
      });

      if (callback) {
        callback();
      }

      this.fetchDetailData(type, id, callback);
    }
  }

  init() {
    this.getStore().commit(LOGIN);
    const spinner2 = document.getElementById('checkoutLoading');

    axios.request(this.createConfig()).then((response: any) => {
      if (response.data && response.data.userId) {
        this.getStore().commit(LOGIN_SUCCESS);
        this.isLoggedIn = true;
        this.loginStateFetched = true;

        if (spinner2) {
          spinner2.remove();
        }

        LocalStorage.add(ExporoVue.USER_ID_KEY, String(response.data.userId));
        setTimeout(() => Events.$emit('userIdLoaded'), 500);

        const fragmentMatches = window.location.href.match(/cockpit\/(\d+)/);

        this.checkForExternalUserFragment(fragmentMatches);

        if (this.mobile) {
          const body: any = document.getElementsByTagName('body')[0];
          this.openCockpit(body, undefined, false);
        }

        this.backendRequest();
        this.contentfulRequest();
      }
    }).catch((error: AxiosError) => {
      this.getStore().commit(LOGIN_FAILED);


      if (spinner2) {
        spinner2.remove();
      }

      if (error.response && error.response.data && error.response.data.errorCode) {
        if (error.response.data.errorCode === 3) {
          this.isLoggedIn = false;
          this.loginStateFetched = true;
          window.location.href = this.getLoginUrl();
        }
      }
    });
  }

  createConfig() {
    let config: AxiosRequestConfig = {
      url: 'https://backend.exporo.de/api/user/authenticated',
      method: 'get',
      headers: {
        'x-api-access-key': BASE_TOKEN,
        'x-requested-with': 'XMLHttpRequest',
        'content-type': 'application/x-www-form-urlencoded'
      },
    };

    config = {...config, ...this.defaultConfig} as AxiosRequestConfig;

    return config;
  }

  toggleLoadingBar(shoudStart: boolean) {
    if (this.shoudLockLoadingBar) {
      setTimeout(this.toggleLoadingBar.bind(this, shoudStart), 100);
    } else {
      if (shoudStart) {
        this.loadingBarServiceCockpitV2.start();
      } else {
        this.loadingBarServiceCockpitV2.stop();
      }
    }
  }

  checkState() {
    if (document.readyState === 'complete') {
      clearInterval(this.interValRef);
      this.shoudLockLoadingBar = false;
    }
  }

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  initUserSessionEvent() {
    const userId = localStorage.getItem('USER_ID_KEY') || 'null';

    Sentry.configureScope((scope) => {
      scope.setUser({'id': userId});
    });

    window.addEventListener('message', async (event) => {
      const postedMessage = (typeof event.data === 'string' && this.isJsonString(event.data)) ? JSON.parse(event.data) : event.data;
      if (!!postedMessage.type && postedMessage.type.includes('sso')) {
        localStorage.removeItem('authToken');

        if (postedMessage.payload.error) {
          Logger.tag('auth').log(postedMessage);
          location.href = this.getLoginUrl();
        } else if (postedMessage.payload.refreshToken) {
          await resumeSession(postedMessage.payload.refreshToken);
        }
      }
    });
  }

  getLoginUrl() {
    // return `https://dev.user.exporo.de/master/#/signin?redirectURL=${encodeURIComponent(location.href)}`;
    return `https://user.exporo.de/#/signin?redirectURL=${encodeURIComponent(location.href)}`;
  }

  getSessionUrl() {
    // return 'https://api.user.exporo.de/stage/user/session';
    return 'https://api.user.exporo.de/prod/user/session';
  }

  mounted() {
    this.initUserSessionEvent();
    // URLHelper.deleteAbTastyQuery();

    if (window.location.href.indexOf('#/cockpit') === -1) {
      window.location.href += '#/cockpit/financing';
    }
    this.interValRef = setInterval(this.checkState.bind(this), 100);



    Events.$on('secondary-market-do-buy', () => {
      this.backendRequest();
    });

    this.scrollable = this.$refs.root as HTMLDivElement;
    const body: any = document.getElementsByTagName('body')[0];
    this.init();


    // if (this.authService.isLoggedIn()) {
    //
    //   if (URLHelper.hasFragment('cockpit/yield')) {
    //     this.openCockpit(body);
    //   }
    //
    //   this.backendRequest();
    //   this.contentfulRequest();
    // }

    // Events.$on('userIdLoaded', () => {
    //   if (!this.userdata.hasLoaded) {
    //     this.backendRequest();
    //   }
    //
    //   if (this.mobile) {
    //     this.openCockpit(body);
    //   }
    // });

    Events.$on('open-cockpit', () => {
      this.openCockpit(body);
    });

    Events.$on('close-cockpit', (preventScroll = false) => {

      if (!preventScroll) {
        window.scrollTo(0, Number(body.getAttribute('data-top')));
      }

      this.open = false;
      body.classList.remove('exporo-not-scrollable');
    });
  }

  private checkForExternalUserFragment(fragmentMatches) {
    if (null !== fragmentMatches && fragmentMatches.length > 0) {
      this.openCockpitById(fragmentMatches[1]);
    }
  }

  openCockpitById(id: string) {
    this.viewRefreshed = false;
    LocalStorage.add(ExporoVue.EXTERNAL_USER_COCKPIT_KEY, id);

    localStorage.setItem('externalCockpitId', id);

    const body: any = document.getElementsByTagName('body')[0];
    this.openCockpit(body, undefined, false);

    setTimeout(() => {
      window.location.href = window.location.href.replace(id, 'yield');
    }, 500);
  }

  resetExternalCockpit() {
    localStorage.removeItem('externalCockpitId');
    LocalStorage.remove(ExporoVue.EXTERNAL_USER_COCKPIT_KEY);
  }

  openCockpitIfClosed(page, body) {
    const [type, detailSlug, id] = page.split('/');

    if ('detail' !== detailSlug) {
      Events.$emit('updateBubble', {page: page});
    }

    if (!this.open) {
      this.openCockpit(body);
      Events.$emit('router-go', {'page': page, 'title': 'Bestand'});

      Events.$on('componentRouterHasMounted', () => {
        this.$nextTick(() => {
          Events.$emit('router-go', {'page': page, 'title': 'Bestand'});
        });
      });
    } else {
      Events.$emit('router-go', {'page': page, 'title': 'Bestand'});
    }
  }

  resetEvents() {
    Events.$off('start-loading-bar');
    Events.$off('stop-loading-bar');

    Events.$on('start-loading-bar', (payload) => {
      if (payload.hasOwnProperty('config')) {
        this.toggleLoadingBar(true);

        const url = payload.config.url;
        this.requestAdapter.addRequest(url);
      } else {
        Logger.tag('LoadingBar').error('Invalid Payload', payload);
      }
    });

    Events.$on('stop-loading-bar', (payload) => {
      if (payload.hasOwnProperty('response') && payload.response) {
        const url = payload.response.config.url;
        this.requestAdapter.removeRequest(url);

        this.toggleLoadingBar(false);
      } else {
        Logger.tag('LoadingBar').error('Invalid Payload', payload);
      }
    });
  }

  getEntryFromTableData(tableData: any, id): any {
    let entry: any = null;

    tableData._data.forEach((row) => {
      if (row._fp_id === id) {
        entry = row;
      } else if (row._signingId === Number(id)) {
        entry = row;
      }
    });

    return entry;

  }

  getEntryFromInvestments(investments: Investment[], id): Investment {
    let entry: any = null;

    investments.forEach((investment: Investment) => {
      if (`${investment.getType()}-${investment.getId()}` === id) {
        entry = investment;
      }
    });

    return entry;
  }

  fetchDetailData(type, id, callback?: void) {
    const tableData: any = TableDataService.get(type);

    if (tableData) {
      let entry: any = 'financing' === type ? this.getEntryFromInvestments(tableData, id) : this.getEntryFromTableData(tableData, id);

      if ('financing' === type) {
        const investment = (entry as Investment);
        if (null !== investment) {
          const cardEntries: any = Object.keys(investment).map((investment) => ({
            'key': investment,
            'value': investment[investment]
          }));
          this.$nextTick(() => {
            if ('financing' === type) {
              Router.navigate('cockpitDetailRoute', [type, `${investment.getType()}-${investment.getId()}`]);
            }

            this.routerGoDetail(investment, cardEntries, callback);
          });
        }
      } else {
        if (null === entry) {
          const tableData: any = TableDataService.get('pendingData');
          entry = this.getEntryFromTableData(tableData, id);
        }

        if (null !== entry) {
          const cardEntries: any = Object.keys(entry).map((entry) => ({'key': entry, 'value': entry[entry]}));
          this.$nextTick(() => {
            if ('financing' === type) {
              Router.navigate('cockpitDetailRoute', [type, entry._signingId]);
            }
            this.routerGoDetail(entry, cardEntries, callback);
          });
        }
      }
    }
  }

  routerGoDetail(entry, cardEntries, callback?: void) {
    Events.$emit('router-go', {
      'page': 'Detail',
      'data': {
        'card': cardEntries,
        'data': entry
      },
      'callback': callback
    });
  }

  openCockpit(body: any, type?: string, reset: boolean = true) {

    if (reset) {
      this.resetExternalCockpit();
    }

    this.$nextTick(() => {
      this.open = true;
      body.classList.add('exporo-not-scrollable');
      this.white = true;
    });
  }

  backendRequest() {
    const userid = LocalStorage.get(ExporoVue.USER_ID_KEY, '');

    const externalUserID = localStorage.getItem('externalCockpitId');

    if (userid && '0' !== userid) {
      this.cockpitRequestApi.getBasics(this).addParam('userId', externalUserID || userid);
    }
  }


  contentfulRequest() {
    const limit: number = 200;
    this.contentfulRequestApi.getEntries({query: {content_type: 'project', limit: limit}}, this);
    this.contentfulRequestApi.getEntries({query: {content_type: 'project', limit: limit, skip: limit}}, this);
    this.contentfulRequestApi.getEntries({query: {content_type: 'project', limit: limit, skip: limit}}, this);
  }


  onFailure(error: RestError): void {
    Logger.tag(LOGGER_TAG).error('Axios failed:', error);
  }

  onSuccess(response: AxiosResponse, id): void {
    if ('getBasics' === id) {

      Events.$emit('secondary-market-get-data', response.data);
      this.userdata._offerPerProject = response.data.offerPerProject;
    }

    if ('getEntries' === id && response.hasOwnProperty('items')) {

      const items = (response as any).items;
      const projects = {};

      items.forEach((item) => {
        try {
          const updates: object[] = [];

          if (item.fields.updates) {
            item.fields.updates.forEach((field) => {
              if (field.fields) {
                updates.push({
                  content: field.fields.content,
                  date: field.fields.date,
                  teaser: field.fields.teaser,
                  title: field.fields.title,
                  reportingQuarterId: field.fields.reportingQuarterId
                });
              }
            });
          }

          projects[item.fields.financingEntityId] = {
            id: item.fields.financingEntityId,
            title: item.fields.title,
            image: (item.fields.image || {fields: {file: {url: null}}}).fields.file.url,
            status: item.fields.status,
            updates: updates
          };
        } catch (e) {
          Logger.tag(LOGGER_TAG).error(item);
        }
      });
      this.userdata.addProjects(projects);
    }
    if (response.config && response.config.url) {
      const url: string = response.config.url || '';
      const isCockpit: boolean = url.indexOf('/api/intern/cockpit/') > -1;

      if (isCockpit) {
        this.userdata.parseData(response.data);
        Events.$emit('user-data-loaded', this.userdata);

        if (LocalStorage.getBoolean(ExporoVue.ACTIVATE_SPEEC_KEY, false)) {
          Events.$emit('enable-exporo-siri-feature');
        }

        if (false === this.viewRefreshed && LocalStorage.get(ExporoVue.EXTERNAL_USER_COCKPIT_KEY, null)) {
          this.shoudShow = false;
          this.$nextTick(() => {
            this.viewRefreshed = true;
            this.shoudShow = true;
            this.$forceUpdate();
          });
        }
      } else {
        Logger.tag(LOGGER_TAG).log('Got an unknown response back:', response);
      }
    }
  }

  clicked() {
    Events.$emit('cockpit-click');
  }
}

export default CockpitV2;
