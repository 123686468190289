import RestApiService from '@/components/Rest/services/RestApiService';
import Logger from '@/components/Helper/LogHelper';
import RequestApi from '@/components/Rest/models/RequestApi';
import CacheBag from '@/components/Rest/CacheBag';

class RestApi {
    static builder() {

        if (!this._instance) {
            this._instance = new RestApi(new RestApiService());
        }

        return this._instance;
    }

    private static _instance: any;
    private instances: {[key: string]: RestApi} = {};
    public restApiService: any;
    public params: {[key: string]: string} = {};
    private baseURL = '';
    private slugs: string[] = [];
    private methods: any = {};
    private instanceName: string = '';
    private cacheBag: CacheBag;

    constructor(restApiService: RestApiService) {
        this.restApiService = restApiService;
        this.cacheBag = new CacheBag();
    }

    public addParam(key: string, value: string): RestApi {
        this.params[key] = value;

        this.checkParams();

        return this;
    }

    public baseUrl(url: string): RestApi {
        this.baseURL = url;

        return this;
    }

    public build(): RestApi {

        return RestApi._instance;
    }

    create<T>(apiService: RequestApi): T {
        const instance: any = new RestApi(new RestApiService());

        for (const apiMethod of apiService.getApiMethods()) {

            instance[apiMethod] = (data: any, callback?: any): RestApi => {
                const result = apiService[apiMethod](instance);
                const integration = result.hasOwnProperty('integration') ? result.integration : null;
                let id = null;

                if (result.hasOwnProperty('id')) {
                    id = result.id;
                }

                if (void(0) === callback) {
                    callback = data;
                    data = null;
                }

                if (integration) {
                    integration[apiMethod]({
                        callback,
                        id,
                        data
                    });
                } else {
                    const url = RestApi._instance.baseURL + result.url;
                    const method = result.method;
                    const cached = result.cached;

                    if (-1 === url.search(/({)(.*?)(})/g)) {
                        instance.restApiService.setRequestCallback(callback);
                        instance.restApiService.setCacheBag(RestApi._instance.cacheBag);

                        if (cached) {

                            this.sendCachedRequest(url, callback, id, instance, method, data);

                        } else {
                            instance.restApiService[method](url, data, id);
                        }
                    } else {
                        instance.instanceName = apiMethod;
                        instance.methods[instance.instanceName] = {
                            method,
                            url,
                            data,
                            callback,
                            id,
                            cached
                        };

                        instance.slugs = [];
                        instance.params = {};

                        url.replace(/({)(.*?)(})/g, (replacerValue: string) => {
                            const key = replacerValue.slice(1, -1);

                            instance.slugs.push(key);
                        });
                    }
                }

                return instance;
            };
        }

        RestApi._instance.instances[apiService.getName()] = instance;

        return instance as T;
    }

    private call() {
        const method = this.methods[this.instanceName];

        const callback = method.callback;
        const data = method.data;
        const url = method.url;
        const methodName = method.method;
        const cached = method.cached;

        let id = null;

        if (method.hasOwnProperty('id')) {
            id = method.id;
        }

        const finalUrl = url.replace(/({)(.*?)(})/g, (replacerValue: string) => {
            const key = replacerValue.slice(1, -1);

            return this.getParam(key);
        });

        this.restApiService.setRequestCallback(callback);
        this.restApiService.setCacheBag(RestApi._instance.cacheBag);

        if (cached) {

            this.sendCachedRequest(finalUrl, callback, id, this, methodName, data);

        } else {
            this.restApiService[methodName](finalUrl, data, id);
        }
    }

    private sendCachedRequest(url, callback: any, id: any, instance: any, method, data: any) {
        const response = RestApi._instance.cacheBag.getResponse(url);

        if (null === response || undefined === response) {
            RestApi._instance.cacheBag.addListener(url, (response: any) => {

                if (response.errorCode) {
                    callback.onFailure.bind(callback, response, id)();
                } else {
                    callback.onSuccess.bind(callback, response, id)();
                }
            }, callback);
        }

        if (void(0) === response) {
            instance.restApiService[`${method}Cached`](url, data, id);
        } else if (response) {
            RestApi._instance.cacheBag.getListener(url).forEach((listener: any) => {
                if (listener.callback === callback) {
                    listener.callback(response);
                }
            });
        }
    }

    private checkParams() {

        const countSlugs = this.slugs.length;
        let countParams = 0;

        for (const i in this.slugs) {
            if (this.slugs.hasOwnProperty(i)) {
                const slug = this.slugs[i];

                if (this.getParam(slug)) {
                    countParams++;
                }
            }
        }

        if (countParams === countSlugs) {
            this.call();
        }
    }

    private getParam(key: string) {

        return this.params[key];
    }

}

export default RestApi;
