




















import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import ExporoVue from '../ExporoVue';
import UserRequestApi from '../Rest/requests/UserRequestApi';
import RequestCallback from '../Rest/RequestCallback';
import RestError from '../Rest/models/RestError';
import {AxiosResponse} from 'axios';
import DetailView from './AList/DetailView.vue';
import GhostLayer from './AList/GhostLayer.vue';
import CockpitTabBar from './Controls/CockpitTabBar.vue';
import CockpitMenu from './Controls/CockpitMenu.vue';
import CockpitSelect from './Controls/CockpitSelect.vue';
import CockpitDropBar from './Controls/CockpitDropBar.vue';
import InvestOption from './InvestList/InvestOption.vue';
import ComponentRouter from './ComponentRouter.vue';
import Events from '@/events';
import OptionEntry from './models/OptionEntry';
import UserData from './models/UserData';
import LocalStorage from '@/components/LocalStorage/LocalStorage';
import InviteFriendButton from '@/components/InviteFriendButton/InviteFriendButton.vue';
import {Action} from '@/components/Router/decorators/RouteDecorator';
import Router from '@/components/Router/Router';
import Controller from '@/components/Router/Controller';
import {LOGOUT} from '@/store';
import AuthService from '@/components/Auth/services/AuthService';
import RestApiService from '@/components/Rest/services/RestApiService';
import axios from 'axios';
import Logger from '@/components/Helper/LogHelper';
import CookieHelper from '@/components/Helper/CookieHelper';

@Component({
  components: {
    DetailView,
    GhostLayer,
    CockpitTabBar,
    CockpitDropBar,
    CockpitMenu,
    CockpitSelect,
    InvestOption,
    ComponentRouter,
    InviteFriendButton
  }
})
class CockpitContent extends Controller implements RequestCallback {

  @Prop({default: false}) open!: boolean;
  @Prop() userdata!: UserData;
  @Prop() private scrollable!: HTMLDivElement;
  @Prop() isMobileApp!: boolean;

  private menuOptions: any[][];
  private pageOptions: any[][];
  private userRestApi: UserRequestApi;
  private authService: AuthService;
  private isOtherProfile: boolean = false;
  private hideMyInvestorsMenu: boolean;

  getName() {

    return this.getClassName() || 'CockpitContent';
  }

  constructor() {
    super('CockpitContent');

    this.userRestApi = this.restApi.create(new UserRequestApi());
    this.authService = new AuthService(new RestApiService(this));

    this.isOtherProfile = null !== LocalStorage.get(ExporoVue.EXTERNAL_USER_COCKPIT_KEY, null);

    this.pageOptions = [
      [
        new OptionEntry(
            this.trans.get('cockpit.tabbar.portfolio'),
            'yield',
            () => {
              Router.navigate('cockpitRoute', ['yield']);
            }
        ),
        new OptionEntry(
            this.trans.get('cockpit.tabbar.financing'),
            'financing',
            () => {
              Router.navigate('cockpitRoute', ['financing']);
            }
        )
      ]
    ];

    // const authUser = JSON.parse(LocalStorage.get('Exporo.Auth.SignedInUser', '{}') || '{}');
    // const email = authUser.email;

    this.menuOptions = [
      [
        new OptionEntry(
            this.trans.get('cockpit.menu.friends'),
            'recruitFriends',
            () => {
              Router.navigate('cockpitRouteMenu', ['recruitFriends']);
            }
        ),
        new OptionEntry(
            this.trans.get('cockpit.menu.documents'),
            'documents',
            () => {
              Router.navigate('cockpitRouteMenu', ['documents']);
            }
        ),
        new OptionEntry(
            this.trans.get('cockpit.menu.investors'),
            'investors',
            () => {
              Router.navigate('cockpitRouteMenu', ['investors']);
            },
            this.isOtherProfile
        ),
        new OptionEntry(
            this.trans.get('cockpit.menu.eie_cockpit'),
            'eie',
            () => {
              Events.$emit('router-go', {url: 'https://app.exporo.de/'});
            }
        ),
        new OptionEntry(
            this.trans.get('cockpit.menu.trade_cockpit'),
            undefined,
            () => {
              Events.$emit('router-go', {url: 'https://trade.exporo.com/'});
            }
        )
      ], [
        new OptionEntry(
            this.trans.get('cockpit.menu.edit_user'),
            'profile',
            () => {
              Events.$emit('router-go', {url: 'https://user.exporo.de/#/profile'});
            }
        ),
        new OptionEntry(
            'Admin',
            'userEdit',
            () => {
              Router.navigate('cockpitRouteMenu', ['userEdit']);
            },
            false,
            this.userdata.rawData.userData.hasRoleAdmin
        ),
        new OptionEntry(
            this.trans.get('cockpit.menu.account'),
            undefined,
            () => {
              Events.$emit('router-go', {url: this.trans.get('cockpit.menu.account_url')});
            }
        ),
        new OptionEntry(
            this.trans.get('cockpit.menu.baader'),
            undefined,
            () => {
              Events.$emit('router-go', {url: this.trans.get('cockpit.menu.baader_url')});
            }
        ),
        new OptionEntry(
            this.trans.get('cockpit.menu.password_change'),
            'passwordChange',
            () => {
              Events.$emit('router-go', {url: 'https://user.exporo.com/#/reset-password'});
            }
        ),
      ], [
        new OptionEntry(
            this.trans.get('cockpit.menu.questions'),
            undefined,
            () => {
              Events.$emit('router-go', {url: this.trans.get('cockpit.menu.questions_url')});
            }
        ),
      ], [
        new OptionEntry(
            this.trans.get('cockpit.menu.logout'),
            undefined,
            () => {
              this.logout();
            }
        ),
      ]
    ];

    this.hideMyInvestorsMenu = this.cctld.toLowerCase() === 'nl' || this.cctld.toLowerCase() === 'fr';
    if (this.hideMyInvestorsMenu) {
      for (let i = 0; i < this.menuOptions.length; i++) {
        this.menuOptions[i] = this.menuOptions[i].filter((option) => option.key !== 'investors');
      }
    }
  }

  @Action('cockpitRouteMenu', {'cockpitRoute': ['yield']})
  changeCockpitPage(page: any) {
    this.$nextTick(() => {
      Events.$emit('router-go', {'page': page, 'title': 'Finanzierung'});
    });
  }

  private toggleInvestmentPlanOptionInTabBar = async () => {
    const hasInvestPlan10: boolean = this.userdata && this.userdata.investmentplan && this.userdata.investmentplan.id;
    let hasInvestPlan20: boolean = false;
    try {
      hasInvestPlan20 = await this.checkHasInvestmentPlan20(hasInvestPlan20);
    } catch (e) {

      Logger.tag('strategies').log(e);
    } finally {

      if (hasInvestPlan10 || hasInvestPlan20 || CookieHelper.getCookie('exporo_investmentplan')) {
        this.pageOptions[0].push(
            new OptionEntry(
                this.trans.get('cockpit.tabbar.investplan'),
                'investplan',
                () => {
                  Router.navigate('cockpitRoute', ['investplan']);
                }
            )
        );
      }
    }
  }

  private async checkHasInvestmentPlan20(hasInvestPlan20: boolean) {
    const token = JSON.parse(localStorage.getItem('authToken') || '');

    if (token) {
      const response = await axios.get('https://api.strategies.exporo.com/strategy/count', {
        headers: {
          'Authorization': `Bearer ${token.idToken}`
        }
      });

      hasInvestPlan20 = response.data.count > 0;
    }

    return hasInvestPlan20;
  }

  mounted() {
    this.toggleInvestmentPlanOptionInTabBar();
  }

  openModal(name: string) {
    this.getModalService().openModal(name);
  }

  logout() {
    localStorage.removeItem('externalCockpitId');
    this.userRestApi.logout(this);
    this.authService.logout();
    this.getStore().commit(LOGOUT);
  }

  onFailure(error: RestError, id?: string): void {
  }

  onSuccess(response: AxiosResponse, id?: string): void {
    if (this.isMobileApp) {
      setTimeout(() => window.location.href = '/', 100);
    } else {
      setTimeout(() => window.location.href = '/', 100);
    }
  }
}

export default CockpitContent;
