import { render, staticRenderFns } from "./InvestmentPlan20.vue?vue&type=template&id=cbad9114&scoped=true&"
import script from "./InvestmentPlan20.vue?vue&type=script&lang=ts&"
export * from "./InvestmentPlan20.vue?vue&type=script&lang=ts&"
import style0 from "./InvestmentPlan20.vue?vue&type=style&index=0&id=cbad9114&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbad9114",
  null
  
)

export default component.exports