








import {Component, Prop, Vue} from 'vue-property-decorator';
import AuthService from '@/components/Auth/services/AuthService';
import RestApiService from '../Rest/services/RestApiService';
import ExporoModal from '@/components/Modal/ExporoModal.vue';
import ExporoModalService from '@/components/Modal/services/ExporoModalService.vue';
import Spinner from '../ExporoSpinner/Spinner';

import Controller from '@/components/Router/Controller';
import {Action} from '@/components/Router/decorators/RouteDecorator';
import Router from '@/components/Router/Router';

import Tracking from '@/components/GoogleTagManager/services/Tracking';
import Events from '@/events';
import URLHelper from '@/components/Helper/URLHelper';

const spinner = Spinner.getInstance();

@Component({
    components: {
        ExporoModal
    }
})
export default class InvestButton extends Controller {

    private authService: AuthService;

    @Prop({default: ''})
    private projectId!: string;

    @Prop({default: ''})
    private projectSlug!: string;

    @Prop({default: ''})
    private projectTitle!: string;

    @Prop({default: ''})
    private projectType!: string;
    // private modalService: ExporoModalService;

    @Prop({default: ''})
    private dataClass!: string;

    private brand: string = 'Exporo DE';

    @Prop({default: ''})
    private dataStyle!: string;
    private mutableCheckout: string = 'exporo-checkout-wphg';

    @Prop()
    private checkout!: string;

    constructor() {
        super('InvestButton');

        this.authService = new AuthService(new RestApiService());
    }

    mounted() {
        if (this.projectId || this.projectTitle || this.projectType) {
            this.brand = 'Exporo ' + URLHelper.getCCTLD();
            Tracking.trackProductDetailViews(
                this.projectId,
                this.projectTitle,
                this.brand,
                this.projectType === 'equity' ? 'bond' : 'bond/mezz');
        }
    }

    getName() {

        return this.getClassName() || 'InvestButton';
    }


    data() {
        return {
            mutableCheckout: this.checkout,
        };
    }

    isLoggedIn(): boolean {

        return this.authService.isLoggedIn();
    }

    navigate(e) {
        e.preventDefault();
        Router.navigate('switchCheckoutStep', [1]);

        this.brand = 'Exporo ' + URLHelper.getCCTLD();
        Tracking.trackAddToCard(this.projectId,
            this.projectTitle === '' || this.projectSlug ? this.projectSlug : this.projectTitle,
            this.brand, this.projectType === 'equity' ? 'bond' : 'bond/mezz');
    }

    @Action('openCheckout')
    openCheckout() {
        this.open();
    }

    open() {
        if (this.isLoggedIn()) {
            spinner.show();
        }

        this.getModalService().openModal(this.mutableCheckout);

    }
}
