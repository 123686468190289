import MiddlewareLengthMissingError from '@/components/Rest/middleware/core/MiddlewareLengthMissingError';

class Closure {
    private _callback!: (data?: any) => void;
    private middlewareLength: number = -1;

    constructor(callback: (data?: any) => void) {
        this._callback = callback;
    }

    setMiddlewareLength(length: number) {
        this.middlewareLength  = length;
    }

    next(data?: any) {
        if (-1 === this.middlewareLength) {
            throw new MiddlewareLengthMissingError('Middleware length is missing!');
        }

        this.middlewareLength--;

        if (0 === this.middlewareLength) {
            this.call(data);
        }
    }

    call(data?: any) {
        this._callback(data);
    }
}

export default Closure;
