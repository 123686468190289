import { Vue } from 'vue-property-decorator';
import ExporoModalService from './Modal/services/ExporoModalService.vue';
import RestApi from '@/components/Rest/RestApi';
import {HOST} from '@/components/Rest/services/RestApiService';
import Trans from '@/Translations';
import URLHelper from '@/components/Helper/URLHelper';
import store from '@/store';

class ExporoVue extends Vue {

    public modalService!: ExporoModalService;
    static REDIRECT_TO_KEY: string = 'redirect-to-key';
    static MODAL_FROM_KEY: string = 'modal-from-key';
    static USER_ID_KEY: string = 'USER_ID_KEY';
    static AFFILIATE_ID_KEY: string = 'AFFILIATE_ID_KEY';
    static ACTIVATE_LOGS_KEY: string = 'ACTIVATE_LOGS_KEY';
    static ACTIVATE_SPINNER_KEY: string = 'ACTIVATE_SPINNER_KEY';
	static PP_MODAL_ALREADY_SHOWN: string = 'PP_MODAL_ALREADY_SHOWN';
    static ACTIVATE_SPEEC_KEY: string = 'ACTIVATE_SPEEC_KEY';
    static WEBAPP_VERSION: string = 'WEBAPP_VERSION_KEY';
    static COCKPIT_VERSION: string = 'COCKPIT_VERSION';
    static SENTRY_DIALOG_OPEN: string = 'SENTRY_DIALOG_OPEN';
    static ACTIVATION_ID: string = 'ACTIVATION_ID';
    static EXTERNAL_USER_COCKPIT_KEY: string = 'EXTERNAL_USER_COCKPIT_KEY';
    static PASSWORD_RESET_TOKEN: string = 'PASSWORD_RESET_TOKEN';
    static SPINNER_TOGGLE_EVENT = 'SPINNER_TOGGLE_EVENT';
    static SIMPLE_SPINNER_TOGGLE_EVENT = 'SIMPLE_SPINNER_TOGGLE_EVENT';
    static REST_API_ON_REQUEST_EVENT = 'REST_API_ON_REQUEST_EVENT';
    static REST_API_ON_SUCCESS_EVENT = 'REST_API_ON_SUCCESS_EVENT';
    static REST_API_ON_FAILURE_EVENT = 'REST_API_ON_FAILURE_EVENT';
    static USER_VALIDATION: '' | 'BOND' = '';
    static ON_CLOSE_MODAL: string = 'ON_CLOSE_MODAL';
    static SHOULD_REDIRECT_AFTER_CLOSE: string = 'SHOULD_REDIRECT_AFTER_CLOSE';
    static REQUIRED_USER_EDIT_FORM_FIELDS_STO: string = 'REQUIRED_USER_EDIT_FORM_FIELDS_STO';
    static CHECKOUT_NEXT_TEXT: string = 'Weiter';
    public restApi: RestApi;
    public isMobile: boolean = false;
    public trans!: Trans;
    public cctld!: string;
    public device: string;

    constructor() {
        super();

        if (window.innerWidth <= 450) {
            this.isMobile = true;
        }

        this.device = navigator.platform;

        this.cctld = URLHelper.getCCTLD();
        const locale = 'LOCALHOST' === this.cctld ? 'de' : this.cctld.toLowerCase();

        this.trans = Trans.getInstance(locale || 'de');
        ExporoVue.CHECKOUT_NEXT_TEXT = this.trans.get('checkout.modal_controls.next');

        this.restApi = RestApi.builder()
            .baseUrl(HOST)
            .build();
    }

  getStore() {
    return store;
  }

    getModalService(): any {
        if (!this.modalService) {
            this.modalService = new ExporoModalService();
        }

        return this.modalService;
    }
}

export default ExporoVue;
