































    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import RequestCallback from '@/components/Rest/RequestCallback';
    import {AxiosResponse} from 'axios';
    import Events from '@/events';
    import ExporoVue from '../../ExporoVue';
    import ExporoModal from '@/components/Modal/ExporoModal.vue';
    import OfferSuccess from '@/components/SecondaryMarket/OfferSuccess.vue';
    import OfferTransaction from '@/components/SecondaryMarket/OfferTransaction.vue';
    import RejectOfferTransaction from '@/components/SecondaryMarket/RejectOfferTransaction.vue';
    import SecondaryMarketRequestApi from '@/components/Rest/requests/SecondaryMarketRequestApi';
    import RestError from '@/components/Rest/models/RestError';
    import PageData from '@/components/Cockpit/models/PageData';
    import UserData from '@/components/Cockpit/models/UserData';
    import LocalStorage from '@/components/LocalStorage/LocalStorage';
    import TradeOffer from '@/components/Cockpit/KPI/services/TradeOffer';
    import Card from '@/components/Cockpit/models/Card';
    import ExporoTokenService from '@/components/JWT/services/ExporoTokenService';
    import UserTooltip from '@/components/User/Edit/UserTooltip.vue';

    @Component({
        components: {
            ExporoModal,
            OfferSuccess,
            OfferTransaction,
            RejectOfferTransaction,
            UserTooltip,
        }
    })
    class DetailPanel extends ExporoVue implements RequestCallback {
        @Prop() private pagedata!: PageData;
        @Prop() private userdata!: UserData;
        @Prop() private card!: Card;

        private secondaryMarketRequestApi: SecondaryMarketRequestApi;

        readonly tradeOffer: TradeOffer;
        readonly tradeOfferGroup: TradeOffer[] = [];

        private isLoaded: boolean = false;
        private isModalOpen: boolean = false;
        private isRejectionModalOpen: boolean = false;
        private toolTipText: string = 'Bitte beachten Sie, dass es sich hierbei um das avisierte Rückzahlungsdatum handelt. Durch die Volatilität des Projektentwicklungsgeschäftes sind Terminverschiebungen nicht ungewöhnlich. Sollte sich ein Rückzahlungstermin verschieben, wird Ihr Darlehen im Zeitraum der Verschiebung weiter verzinst. Sobald ein konkreter Termin zur Rückzahlung Ihres Darlehens feststeht, werden Sie hierüber gesondert informiert.';

        constructor() {
            super();
            this.tradeOffer = new TradeOffer(
                this.pagedata.data._isin,
                this.pagedata.data._contractId,
                this.pagedata.data._title,
                this.pagedata.data._investment,
                this.pagedata.data._piece_value,
                this.pagedata.data._repositoryType,
                this.pagedata.data._assetId,
            );

            this.secondaryMarketRequestApi = this.restApi.create(new SecondaryMarketRequestApi());
			this.isLoaded = true;
            // this.secondaryMarketRequestApi.getOffersByUserAndIsin(this).addParam('isin', this.pagedata.data._isin);
        }

        onSuccess(response: AxiosResponse, id): void {
            this.filterIsinOffers(response.data);
            this.isLoaded = true;
        }

        onFailure(error: RestError) {

        }

        validateProjectTradeStatus() {
            return this.pagedata.data._tradeable && this.pagedata.data._status === 'funded';
        }

        openModal(event: any) {
            event.preventDefault();

            if (this.pagedata.data._assetId && ('wallet' === this.pagedata.data._repositoryType || 'vault' === this.pagedata.data._repositoryType)) {
                this.secondaryMarketRedirect(this.pagedata.data._assetId);
            } else {
				this.secondaryMarketRedirect(this.pagedata.data._isin);
            }
        }

        secondaryMarketRedirect(assetId: any) {
			window.open(`https://user.exporo.com/#onboarding?successUrl=https://trade.exporo.com/selloffer?asset_id=${assetId}`);
        }

        openRejectionModal(event, index: number) {
            event.preventDefault();
            this.isRejectionModalOpen = true;
            this.getModalService().openModal('secondary-market-reject-offer-' + index);
        }

        filterIsinOffers(data) {
            data?.forEach((offer, index) => {
                this.tradeOfferGroup.push(new TradeOffer(
                    this.pagedata.data._isin,
                    this.pagedata.data._contractId,
                    this.pagedata.data._title,
                    this.pagedata.data._investment,
                    this.pagedata.data._piece_value,
                    this.pagedata.data._repositoryType,
                    this.pagedata.data._assetId,
                ));

                this.tradeOfferGroup[index].offeredPieces = Number(offer.max_pieces_to_sell);
                this.tradeOfferGroup[index].salePricePerPiece = offer.sale_price_per_piece;
                this.tradeOfferGroup[index].bidsFinished = offer.bids_finished ?? 0;
                this.tradeOfferGroup[index].bidsInProgress = offer.bids_in_progress ?? 0;
                this.tradeOfferGroup[index].id = offer.id;

                this.tradeOffer.offeredPieces += Number(offer.max_pieces_to_sell);
                this.tradeOffer.salePricePerPiece = offer.sale_price_per_piece;
            });
        }

    }

    export default DetailPanel;
