


import {Component, Vue} from 'vue-property-decorator';

@Component
export default class App extends Vue {

    private style: string = `
            :root {
                --color-branding: #55c;
                --size-height: 5px;
            }
            .exporo-progresstest ~ .exporo-progresstest ~ .exporo-progresstest ~ .exporo-progresstest ~ .exporo-progresstest {
                max-width: 600px;
            }
            .exporo-progresstest ~ .exporo-progresstest ~ .exporo-progresstest ~ .exporo-progresstest ~ .exporo-progresstest .exporo-progressbartest-info {
                font-family: monospace;
                font-weight: bold;
                margin-bottom: 5px;
            }
            .exporo-progresstest ~ .exporo-progresstest ~ .exporo-progresstest ~ .exporo-progresstest ~ .exporo-progresstest .exporo-progressbartest-meter {
                background-color: darkslategray;
                padding: 2px;
            }
            .exporo-progresstest ~ .exporo-progresstest ~ .exporo-progresstest ~ .exporo-progresstest ~ .exporo-progresstest .exporo-progressbartest-meterbar {
                background-color: mediumspringgreen;
            }`;


    created() {
        if (this.style !== null) {
            const brandedStylesheet = document.querySelector('style#exporo-branded-style') as HTMLElement;
            if (brandedStylesheet instanceof HTMLStyleElement) {
                brandedStylesheet.innerHTML = this.style;
            } else {
                const stylesheet = document.createElement('style');
                stylesheet.id = '#exporo-branded-style';
                stylesheet.innerHTML = this.style;
                document.head.appendChild(stylesheet);
            }
        }
    }


}


