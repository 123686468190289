import BaseRule from '../BaseRule';

export default class NotInRule extends BaseRule {

    private _value;

    constructor(rulesString, input, name, type) {
        super(rulesString, input, name, type);

        this._value = null;
        this.parseRulesString();

        this._valid = this.validate();
    }

    validate() {
        const explorationSelect: any = document.getElementById('exploration.employmentStatus');

        if (this._value !== false) {
            if (this._input.value === this._value && explorationSelect.value === 'self_employed') {

                return false;
            }
        }

        return true;
    }

    parseRulesString() {
        const rules = this._rulesString.split('|');
        const notInPosition = rules.indexOf('not_in:0;0');

        this._value = notInPosition < 0 ? false : rules[notInPosition].split(':')[1];
    }
}
