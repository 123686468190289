import BaseRule from '../BaseRule';

export default class MaxRule extends BaseRule {

    private _value;
    private _max;

    constructor(rulesString, input, name, type) {
        super(rulesString, input, name, type);

        this._value = null;
        this._max = null;
        this.parseRulesString();
        this._valid = this.validate();
    }

    validate() {

        return this._value !== '' && this._value <= this._max;
    }

    parseRulesString() {
        const rules = this._rulesString.split('|');

        rules.forEach((item: string) => {
            if (item.indexOf('max') > -1) {
                this._max = item.split(':')[1];
            }
        });

        this._value = Number(this._input.value);
    }
}
