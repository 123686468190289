import ContentfulIntegration from '@/components/Rest/integrations/ContentfulIntegration';

export function Contentful(id?: string) {
    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
        descriptor.value = () => {

            return {
                id: id ? id : propertyKey,
                integration: new ContentfulIntegration()
            };
        };
    };
}
