import BaseRule from '../BaseRule';

export default class RequiredRule extends BaseRule {

    constructor(rulesString, input, name, type) {
        super(rulesString, input, name, type);

        this._valid = this.validate();
    }

    validate() {

        const isCheckbox = (this._input || {}).type === 'checkbox';

        if (isCheckbox) {

            return this._input.checked;
        } else {

            return '' !== this._input.value && null !== this._input.value;
        }
    }
}
