import { render, staticRenderFns } from "./ViewPager.vue?vue&type=template&id=56fd3d78&scoped=true&"
import script from "./ViewPager.vue?vue&type=script&lang=ts&"
export * from "./ViewPager.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56fd3d78",
  null
  
)

export default component.exports