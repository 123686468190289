import LocalStorage from '@/components/LocalStorage/LocalStorage';

const FORCE_EMAIL_STORAGE_KEY = 'force-email-service-';

class ForceEmailService {
    private vibSent: boolean = false;
    private emails: string[];
    private nid: number = -1;

    constructor(vibSent: boolean, emails: string[], nid: number) {
        this.vibSent = vibSent;
        this.emails = emails;
        this.nid = nid;
    }

    addEmail(email: string) {
        LocalStorage.addBoolean(FORCE_EMAIL_STORAGE_KEY + email + '_' + this.nid, true);
    }

    isAllEmailsSent() {
        if (this.vibSent)
            return true;

        let countEmails: number = 0;

        this.emails.forEach((email: string) => {
            if (LocalStorage.getBoolean(FORCE_EMAIL_STORAGE_KEY + email + '_' + this.nid)) {
                countEmails++;
            }
        });

        this.vibSent = countEmails === this.emails.length;

        return this.vibSent;
    }

    addFile(file: string) {
        const files = this.getFiles();
        let fileExist = false;

        files.forEach((storedFile) => {
            if (file === storedFile) {
                fileExist = true;
            }
        });

        if (!fileExist) {
            files.push(file);
        }

        LocalStorage.add(FORCE_EMAIL_STORAGE_KEY + '_files_' + this.nid, JSON.stringify(files));
    }

    getFiles(): string[] {
        const filesObject = LocalStorage.get(FORCE_EMAIL_STORAGE_KEY + '_files_' + this.nid, '');

        if (filesObject) {

            return JSON.parse(filesObject);
        }

        return [];
    }
}

export default ForceEmailService;
