import axios, {AxiosError} from 'axios';
import * as Sentry from '@sentry/vue';

class TanganyApiService {

    async getWallet(userId: any) {
        const token = localStorage.getItem('authToken');
        if (token) {
            const response = await axios.get(`https://api.vault.exporo.com/users/me/wallets/${userId}/tokens`, {
                headers: {
                    'Authorization': 'Bearer ' +  JSON.parse(token)?.idToken
                }
            }).catch((error: AxiosError) => {
                return false;
            });

            return response;
        }
    }
}

export default TanganyApiService;
